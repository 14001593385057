import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import {
  CheckboxField,
  NumberInputField,
  RevenueField,
  SelectField,
  TextAreaField,
  TextField,
  ToggleButtonField,
} from "components/HookFormControls";
import { ADVANCE_CURRENCIES, CONTRACT_TYPE, YES_NO } from "constants/options";
import { ApiKeyFields } from "types";
import { getUserName } from "utils";

import { FORM_LABELS, HELPER_TEXT, TEXT_AREA_MAX_LENGTH } from "./constants";

interface AgreementInformationProps extends StepFlowControllerProps {
  isAdmin?: boolean;
  isClientConfirmation?: boolean;
}

const AgreementInformation: FC<AgreementInformationProps> = (props) => {
  const { isAdmin, isClientConfirmation } = props;
  const { control, watch, trigger } = useFormContext();
  const {
    revisedFormStore: { editsLvl1 },
  } = useStores();

  useEffect(() => {
    if (isClientConfirmation) {
      const subscription = watch((value, { name }) => {
        if (name === ApiKeyFields.info_confirmation) {
          trigger(ApiKeyFields.info_confirmation);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [isClientConfirmation]);

  const getDefaultTextAreaProps = () => ({
    control: control,
  });

  const getLastUserEdit = (fieldName: ApiKeyFields) => {
    const last = editsLvl1.find((edit) => edit.fieldName === fieldName && edit.isAdmin);
    return `Last Edited by: ${last ? getUserName(last.user) : "—"}`;
  };

  const watchMarketingService = watch(ApiKeyFields.marketing_service);
  const watchAdvance = watch(ApiKeyFields.advance);

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem
            fieldName={ApiKeyFields.royalty_rate}
            label={FORM_LABELS[ApiKeyFields.royalty_rate]}
            helperText={isAdmin ? getLastUserEdit(ApiKeyFields.royalty_rate) : HELPER_TEXT[ApiKeyFields.royalty_rate]}
            isOptionalField={!isAdmin}
          >
            {(field) => (
              <NumberInputField
                {...field}
                stringMode
                hideControlArrows
                {...getDefaultTextAreaProps()}
                addonAfter={<span>%</span>}
              />
            )}
          </FormGridFieldItem>
          <FormGridFieldItem
            fieldName={ApiKeyFields.contract_term}
            label={FORM_LABELS[ApiKeyFields.contract_term]}
            helperText={isAdmin ? getLastUserEdit(ApiKeyFields.contract_term) : HELPER_TEXT[ApiKeyFields.contract_term]}
            isOptionalField={!isAdmin}
          >
            {(field) => (
              <NumberInputField {...field} stringMode hideControlArrows {...getDefaultTextAreaProps()} addonAfter={"Year(s)"} />
            )}
          </FormGridFieldItem>
          <FormGridFieldItem
            fieldName={ApiKeyFields.contract_renewal}
            label={FORM_LABELS[ApiKeyFields.contract_renewal]}
            helperText={isAdmin ? getLastUserEdit(ApiKeyFields.contract_renewal) : HELPER_TEXT[ApiKeyFields.contract_renewal]}
            isOptionalField={!isAdmin}
          >
            {(field) => (
              <NumberInputField {...field} stringMode hideControlArrows {...getDefaultTextAreaProps()} addonAfter={"Year(s)"} />
            )}
          </FormGridFieldItem>
          {(isClientConfirmation && watchMarketingService !== false) || isAdmin ? (
            <FormGridFieldItem
              fieldName={ApiKeyFields.marketing_service}
              label={FORM_LABELS[ApiKeyFields.marketing_service]}
              helperText={isAdmin ? getLastUserEdit(ApiKeyFields.marketing_service) : HELPER_TEXT[ApiKeyFields.marketing_service]}
              isOptionalField={!isAdmin}
            >
              {(field) => <ToggleButtonField {...field} {...getDefaultTextAreaProps()} options={YES_NO} />}
            </FormGridFieldItem>
          ) : null}
          {isAdmin ? (
            <FormGridFieldItem
              fieldName={ApiKeyFields.special_provisions}
              label={FORM_LABELS[ApiKeyFields.special_provisions]}
              helperText={getLastUserEdit(ApiKeyFields.special_provisions)}
              isOptionalField
            >
              {(field) => <TextAreaField {...field} {...getDefaultTextAreaProps()} rows={3} maxLength={500} />}
            </FormGridFieldItem>
          ) : null}
          {(isClientConfirmation && Boolean(watchAdvance)) || isAdmin ? (
            <FormGridFieldItem
              fieldName={ApiKeyFields.advance}
              label={FORM_LABELS[ApiKeyFields.advance]}
              helperText={isAdmin ? getLastUserEdit(ApiKeyFields.advance) : HELPER_TEXT[ApiKeyFields.advance]}
              isOptionalField
            >
              {(field) => (
                <RevenueField
                  {...field}
                  {...getDefaultTextAreaProps()}
                  selectFieldName={ApiKeyFields.advance_currency}
                  options={ADVANCE_CURRENCIES}
                  stringMode={false}
                  allowOnlyNumber={false}
                />
              )}
            </FormGridFieldItem>
          ) : null}
          {isAdmin ? (
            <>
              <FormGridFieldItem
                fieldName={ApiKeyFields.advance_detail}
                label={FORM_LABELS[ApiKeyFields.advance_detail]}
                helperText={getLastUserEdit(ApiKeyFields.advance_detail)}
                isOptionalField
              >
                {(field) => <TextAreaField {...field} {...getDefaultTextAreaProps()} rows={3} maxLength={TEXT_AREA_MAX_LENGTH} />}
              </FormGridFieldItem>
              <FormGridFieldItem
                fieldName={ApiKeyFields.contract_type}
                label={FORM_LABELS[ApiKeyFields.contract_type]}
                helperText={getLastUserEdit(ApiKeyFields.contract_type)}
              >
                {(field) => <SelectField {...field} {...getDefaultTextAreaProps()} options={CONTRACT_TYPE} />}
              </FormGridFieldItem>
              <FormGridFieldItem
                fieldName={ApiKeyFields.reviewer_name}
                label={FORM_LABELS[ApiKeyFields.reviewer_name]}
                helperText={getLastUserEdit(ApiKeyFields.reviewer_name)}
              >
                {(field) => <TextField {...field} {...getDefaultTextAreaProps()} />}
              </FormGridFieldItem>
            </>
          ) : null}
          {isClientConfirmation ? (
            <>
              <Grid item xs={12} md={12}>
                <Divider orientation="horizontal" />
              </Grid>
              <FormGridFieldItem label={""} fieldName={ApiKeyFields.info_confirmation} hideLabelGrid inputGrid={{ md: 12 }}>
                {(field) => (
                  <CheckboxField
                    {...field}
                    {...getDefaultTextAreaProps()}
                    label={FORM_LABELS[ApiKeyFields.info_confirmation]}
                    isRequiredField
                  />
                )}
              </FormGridFieldItem>
            </>
          ) : null}
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default observer(AgreementInformation);
