import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";
import trim from "lodash/trim";

import type { ConvertCamelToSnake, SnakeKeysToCamel } from "./types";

/**
 * Replaces empty strings and `undefined` values with `null` in a given object.
 * Recursively processes nested objects, while ignoring arrays.
 *
 * @param {T} obj - The object to process.
 * @returns {T} - The transformed object with empty strings and `undefined` values replaced by `null`.
 *
 * @template T
 * @example
 * replaceEmptyAndUndefinedWithNull({ name: "", age: undefined }); // { name: null, age: null }
 */
export function replaceEmptyAndUndefinedWithNull<T extends Record<string, unknown>>(obj: T): T {
  function transformValue(value: unknown): unknown {
    if (isObject(value) && !isArray(value)) {
      return mapValues(value, transformValue);
    }
    if (typeof value === "string") {
      value = trim(value);
    }
    return value === "" || value === undefined ? null : value;
  }
  return mapValues(obj, transformValue) as T;
}

export const getUserName = ({ firstName, lastName }: { firstName: string | null; lastName: string | null }) => {
  return firstName && lastName ? `${firstName} ${lastName}` : firstName ?? lastName ?? "";
};

/**
 * Converts all keys in an object from snake_case to camelCase.
 * Recursively processes nested objects while preserving other values.
 * Handles string inputs as well.
 *
 * @param {T} input - The object with keys in snake_case or a snake_case string.
 * @returns {SnakeKeysToCamel<T> | string} - The object with keys converted to camelCase or the converted string.
 *
 * @template T
 * @example
 * convertSnakeToCamel({ first_name: "John", last_name: "Doe" }); // { firstName: "John", lastName: "Doe" }
 * convertSnakeToCamel("version_title"); // "versionTitle"
 */
export function convertSnakeToCamel<T extends Record<string, unknown>>(input: T): SnakeKeysToCamel<T>;
export function convertSnakeToCamel(input: string): string;
export function convertSnakeToCamel<T extends Record<string, unknown>>(input: T | string): SnakeKeysToCamel<T | string> {
  if (typeof input === "string") {
    return input.replace(/_([a-z0-9])/g, (_, char) => char.toUpperCase());
  }
  const result: Record<string, unknown> = {};
  Object.entries(input).forEach(([key, value]) => {
    const camelKey: string = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase()).replace(/_([0-9])/g, "$1");
    if (value && typeof value === "object" && !Array.isArray(value)) {
      result[camelKey] = convertSnakeToCamel(value as Record<string, unknown>);
    } else {
      result[camelKey] = value;
    }
  });
  return result as SnakeKeysToCamel<T>;
}

/**
 * Converts all keys in an object from camelCase to snake_case.
 * Recursively processes nested objects while preserving other values.
 * Handles string inputs as well.
 *
 * @param {T} input - The object with keys in camelCase or a camelCase string.
 * @returns {ConvertCamelToSnake<T> | string} - The object with keys converted to snake_case or the converted string.
 *
 * @template T
 * @example
 * convertCamelToSnakeCase({ firstName: "John", lastName: "Doe" }); // { first_name: "john", last_name: "doe" }
 * convertCamelToSnakeCase("versionTitle"); // "version_title"
 */
export function convertCamelToSnakeCase<T extends Record<string, unknown>>(input: T): Record<string, unknown>;
export function convertCamelToSnakeCase(input: string): string;
export function convertCamelToSnakeCase<T extends Record<string, unknown>>(input: T | string): Record<string, unknown> | string {
  if (typeof input === "string") {
    return input
      .replace(/([A-Z])/g, "_$1")
      .replace(/([a-z])([0-9])/g, "$1_$2")
      .toLowerCase();
  }
  return Object.entries(input).reduce<Record<string, unknown>>((acc, [key, value]) => {
    const snakeKey = key
      .replace(/([A-Z])/g, "_$1")
      .replace(/([a-z])([0-9])/g, "$1_$2")
      .toLowerCase();
    if (isObject(value) && !isArray(value)) {
      acc[snakeKey] = convertCamelToSnakeCase(value as Record<string, unknown>);
    } else {
      acc[snakeKey] = value;
    }
    return acc;
  }, {}) as ConvertCamelToSnake<T>;
}
