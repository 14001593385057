import { MUSIC_GENRES } from "constants/release";

export const getSubGenres = (genreKey: string): LabelValue[] => {
  const genre = MUSIC_GENRES[genreKey];

  if (!genre || !genre.sub_genres) {
    return [];
  }

  return Object.entries(genre.sub_genres).map(([subValue, subLabel]) => ({
    label: subLabel,
    value: subValue,
  }));
};
