import { CLIENT_DISTRIBUTION_AGREEMENT_SIDEBAR_FIELDS } from "components/RegistrationForm/AgreementInformation/constants";
import { DISTRIBUTION_INFORMATION_SIDEBAR_FIELDS } from "components/RegistrationForm/DistributionInformation/constants";
import { ROUTES } from "constants/routes";

export const progressBarConfig = [
  {
    title: "progress.clientInformation.title",
    subRoutes: [
      { route: ROUTES.DISTR_LEGAL, fields: [] },
      { route: ROUTES.DISTR_LEGAL_ADDRESS(), fields: [] },
    ],
    description: "progress.clientInformation.description",
  },
  {
    title: "progress.artistCatalog.title",
    subRoutes: [{ route: ROUTES.DISTR_LEGAL_CATALOG(), fields: [] }],
    description: "progress.artistCatalog.description",
  },
  {
    title: "progress.agreementInformation.title",
    subRoutes: [{ route: ROUTES.DISTR_LEGAL_AGREEMENT(), fields: CLIENT_DISTRIBUTION_AGREEMENT_SIDEBAR_FIELDS }],
    description: "progress.agreementInformation.description",
  },
  {
    title: "progress.distributionAndPayout.title",
    subRoutes: [{ route: ROUTES.DISTR_LEGAL_DISTRIBUTION(), fields: DISTRIBUTION_INFORMATION_SIDEBAR_FIELDS }],
    description: "progress.distributionAndPayout.description",
  },
];
