import { FC } from "react";
import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const FallbackWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const BodyWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1rem;
`;

const CodeBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  padding: 24px;
  gap: 10px;
  background-color: ${({ theme }) => theme.palette.text.primary};
  color: ${({ theme }) => theme.palette.common.white};
`;

const Code = styled.code`
  font-weight: 485;
  font-size: 12px;
  line-height: 16px;
  word-wrap: break-word;
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh - 450px);
  color: ${({ theme }) => theme.palette.common.white};
`;

const ReloadContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const Fallback: FC<{ error: Error }> = ({ error }) => {
  const errorDetails = error.stack || error.message;

  const handleClickToClipBoard = () => {
    navigator.clipboard.writeText(errorDetails);
  };

  return (
    <FallbackWrapper>
      <BodyWrapper>
        <Typography align="center" variant="h6">
          Sorry, an error occured while processing your request. If you request support, be sure to copy the details of this
          error.
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <CodeBlockWrapper>
          <Typography align="center">Error details</Typography>
          <Code onClick={handleClickToClipBoard}>{errorDetails}</Code>
        </CodeBlockWrapper>
        <ReloadContainer>
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Reload the app
          </Button>
        </ReloadContainer>
      </BodyWrapper>
    </FallbackWrapper>
  );
};

export default Fallback;
