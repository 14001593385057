import { Components } from "@mui/material/styles";

import { colors } from "../colors";

const disabledLightState = {
  color: colors.gray926,
  backgroundColor: colors.white,
  border: `1px solid ${colors.gray215}`,
};

const disabledDarkState = {
  color: colors.gray780,
  backgroundColor: colors.dark950,
  border: `1px solid ${colors.gray449}`,
};

export const MuiButton: Components["MuiButton"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      fontSize: 18,
      fontWeight: 700,
      textTransform: "unset",
      boxShadow: "none",
      ":hover": {
        boxShadow: "none",
      },
      ":disabled": {
        cursor: "not-allowed",
        pointerEvents: "inherit",
        "svg path": {
          fill: colors.gray926,
        },
      },
    },
    sizeLarge: {
      minWidth: "140px",
    },
    sizeMedium: {
      minHeight: "48px",
      minWidth: "132px",
    },
    sizeSmall: {
      fontSize: 14,
      minHeight: "36px",
      minWidth: "100px",
    },
    outlinedError: {
      color: colors.red701,
      borderColor: colors.red666,
    },
    outlinedSuccess: {
      color: colors.green607,
    },
    containedPrimary: {
      backgroundColor: colors.blue364,
      ":hover": {
        backgroundColor: colors.blue480,
      },
      ":disabled": {
        ...disabledLightState,
      },
      "svg path": {
        fill: colors.white,
      },
    },
    containedSecondary: {
      color: colors.black418,
      ":disabled": disabledLightState,
    },
    outlinedPrimary: {
      color: colors.dark233,
      backgroundColor: "transparent",
      borderColor: colors.orange633,
      ":hover": {
        ":disabled": disabledLightState,
        borderColor: colors.orange633,
        backgroundColor: colors.orange129,
      },
    },
  },
  variants: [
    {
      props: { variant: "link" },
      style: {
        height: "fit-content",
        padding: 0,
        color: colors.blue364,
        backgroundColor: "transparent",
        fontWeight: 500,
        "&:hover": {
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
        "svg path": {
          fill: colors.blue364,
        },
        "&:disabled": {
          "svg path": {
            fill: colors.gray749,
          },
        },
      },
    },
    // custom color props
    {
      props: { color: "primaryDark" },
      style: {
        color: colors.black418,
        backgroundColor: colors.blue699,
        ":disabled": disabledDarkState,
        ":hover": {
          backgroundColor: colors.blue498,
        },
      },
    },
    {
      props: { color: "secondaryDark" },
      style: {
        color: colors.white,
        backgroundColor: colors.gray449,
        ":disabled": disabledDarkState,
        ":hover": {
          backgroundColor: colors.gray566,
        },
      },
    },
    {
      props: { variant: "outlined", color: "primaryDark" },
      style: {
        color: colors.white,
        backgroundColor: "transparent",
        borderColor: colors.orange633,
        ":hover": {
          borderColor: colors.orange633,
          backgroundColor: colors.orange827,
        },
      },
    },
  ],
};
