import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { FormHelperTextContainerStyled } from "components/Layout";
import { ApiKeyFields } from "types";

import { defaultValues as commonDefaultValues } from "../AddWork/constants";

export const FORM_FIELDS = [ApiKeyFields.agency, ApiKeyFields.iswc];
export const CHECKBOX_FIELDS = [ApiKeyFields.isMedley, ApiKeyFields.isCover];

export const OPTIONAL_FIELDS: { [key: string]: true } = {
  [ApiKeyFields.iswc]: true,
};

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.title]: "Composition Title",
  [ApiKeyFields.language]: "Language",
  [ApiKeyFields.agency]: "Agency",
  [ApiKeyFields.iswc]: "ISWC (Optional)",
  [ApiKeyFields.lyrics]: "Lyrics",
  [ApiKeyFields.isMedley]: "Please check if this song is medley or derivative",
  [ApiKeyFields.isCover]: "Please check if this song is a cover",
};

export const HELPER_TEXT: { [key: string]: string | ReactNode } = {
  [ApiKeyFields.agency]: "Royalty/licensing organization for this published composition (e.g., BMI, ASCAP, Harry Fox Agency).",
  [ApiKeyFields.iswc]: "Please enter the ISWC code in the following format e.g. T-034524680-1",
  [ApiKeyFields.isMedley]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        <b>Medley:</b> When an artist sings parts of multiple songs in one single track.
      </Typography>
      <Typography variant="body1" color="text.label">
        <b>Derivative:</b> When you take someone’s original composition and add your own melodies or lyrics.
      </Typography>
    </FormHelperTextContainerStyled>
  ),
  [ApiKeyFields.lyrics]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        Your work will not be saved without text.
      </Typography>
      <Typography variant="body1" color="text.label">
        If you do not have all your lyrics just yet, please type anything you’d like in this field in order to save your progress!
        If this is instrumental, please write `Instrumental’.
      </Typography>
    </FormHelperTextContainerStyled>
  ),
};

export const PLACEHOLDER_TEXT: { [key: string]: string } = {
  [ApiKeyFields.lyrics]:
    "Submit your lyrics as sang in your audio recording.\nBe sure to identify the different sections of your song with headers (Verses, Chorus, Bridge, etc.). Please capitalize the first letter of each line.",
};

export const defaultValues = {
  ...commonDefaultValues,
  [ApiKeyFields.lyrics]: "",
  [ApiKeyFields.isMedley]: null,
  [ApiKeyFields.isCover]: null,
};
