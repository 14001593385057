import React, { FC } from "react";
import styled from "@emotion/styled";

import Alert, { alertClasses } from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import { InfoIcon } from "assets/icons/14px";

const ReleaseAlertStyled = styled(Alert)`
  display: flex;

  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }

  .${alertClasses.message} {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

interface ReleaseAlertProps {
  title: string;
  children?: React.ReactNode;
}

const ReleaseAlert: FC<ReleaseAlertProps> = ({ title, children }) => {
  return (
    <ReleaseAlertStyled variant="standard" icon={<InfoIcon />}>
      <Typography variant="caption" color="text.label" textTransform="none" flex="1" whiteSpace="pre-line">
        {title}
      </Typography>
      {children}
    </ReleaseAlertStyled>
  );
};

export default ReleaseAlert;
