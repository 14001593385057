import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import omit from "lodash/omit";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";

import { ApiKeyFields, TracksForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import { defaultValues } from "./TrackForm/constants";

export const prepareFormData = (rowData: TracksForm) => {
  const omitData = omit(rowData, [ApiKeyFields.isFocus]);
  return replaceEmptyAndUndefinedWithNull(pick(omitData, Object.keys(defaultValues)));
};

export const prepareFormRequest = (
  formData: TracksForm,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
  isAdmin?: boolean,
) => {
  const omitData = omit(formData, [
    ApiKeyFields.id,
    ApiKeyFields.comments,
    ApiKeyFields.edits,
    ApiKeyFields.audioBlobPath,
    ...(isAdmin ? [ApiKeyFields.aiAudio] : []),
  ]);
  const filteredData = pickBy(omitData, (_, key) => dirtyFields[key]);
  return replaceEmptyAndUndefinedWithNull(filteredData);
};
