import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const CONTACT_TEXT_FIELDS = [ApiKeyFields.artist_name, ApiKeyFields.legal_first_name, ApiKeyFields.legal_last_name];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.legal_first_name]: "Legal First Name",
  [ApiKeyFields.legal_last_name]: "Legal Last Name",
  [ApiKeyFields.artist_name]: "Artist Name",
  [ApiKeyFields.email]: "Email",
  [ApiKeyFields.phone]: "Phone Number",
};

export const HELPER_TEXT = {
  [ApiKeyFields.legal_first_name]: "Enter your first name as it appears on your W9 or tax forms.",
  [ApiKeyFields.legal_last_name]: "Enter your last name as it appears on your W9 or tax forms.",
  [ApiKeyFields.artist_name]: "The name of the artist as it appears on Spotify or other DSPs.",
  [ApiKeyFields.email]: "This will be our primary way of communicating with the artist.",
  [ApiKeyFields.phone]: "E.g. “+1 401 XXX XXXX”",
} as Record<ApiKeyFields, string>;

export const CONTACT_SIDEBAR_FIELDS = [...CONTACT_TEXT_FIELDS, ApiKeyFields.email, ApiKeyFields.phone].map((field) => ({
  field,
  required: true,
})) as SubRouteFieldProps[];
