import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { DndTableGrid, ReleaseTableListBlock } from "components";
import { LabelWithAsterisk } from "components";
import { TableNoDataMessage, usePrimaryFormState } from "components/ReleaseForm";
import { ReleaseArtists } from "types";

import { getColumns } from "./columns";
import { ARTIST_MAX_LIMIT } from "./constants";
import { ArtistBlockProps } from "./types";
import { useArtists } from "./useArtists";

const TableNoData = () => <TableNoDataMessage text="There are no artist(s) added." />;

const ArtistsBlock: FC<ArtistBlockProps> = ({ track }) => {
  const {
    revisedFormStore: {
      comments: { tertiaryComments },
    },
  } = useStores();
  const { fields, handleChangeRows, handleAddArtist, handleEditArtist, handleDeleteClick, handleCopyArtist } = useArtists(track);
  const { isReadOnly } = usePrimaryFormState();

  const getRowComments = (artist: ReleaseArtists) =>
    tertiaryComments.filter(({ releaseArtistId }) => releaseArtistId === artist.id);

  const columns = useMemo(
    () => getColumns({ onEditClick: handleEditArtist, onDeleteClick: handleDeleteClick, getRowComments, isReadOnly }),
    [handleEditArtist, handleDeleteClick],
  );

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <ReleaseTableListBlock
      title={<LabelWithAsterisk>Artist(s)</LabelWithAsterisk>}
      itemsCount={itemsCount}
      maxLimit={ARTIST_MAX_LIMIT}
      onAddClick={handleAddArtist}
      addBtnText="Add Artist"
      onCopyClick={handleCopyArtist}
      copyBtnText="Copy Artist"
      hideActionBtn={isReadOnly}
    >
      <DndTableGrid
        rows={fields}
        columns={columns}
        onOrderChange={handleChangeRows}
        slots={{ noRowsOverlay: TableNoData }}
        isDragEnabled={!isReadOnly}
      />
    </ReleaseTableListBlock>
  );
};

export default observer(ArtistsBlock);
