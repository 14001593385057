import { FC } from "react";

import { Chip } from "components/FormComponents";
import { ReleaseOpStatus } from "types";

interface ReleaseOpStatusChipProps {
  status: ReleaseOpStatus | null;
  handleDelete?: (e: React.MouseEvent) => void;
}

const ReleaseStatusChip: FC<ReleaseOpStatusChipProps> = ({ status, handleDelete }) => {
  const props = {
    ...(handleDelete
      ? {
          onDelete: (event: React.MouseEvent) => handleDelete(event),
          onMouseDown: (event: React.MouseEvent) => event.stopPropagation(),
          onClick: (event: React.MouseEvent) => handleDelete(event),
        }
      : void 0),
  };

  let label = "";

  switch (status) {
    case ReleaseOpStatus.Draft:
      label = "Draft";
      break;
    case ReleaseOpStatus.Validated:
      label = "Validated";
      break;
    case ReleaseOpStatus.Submitted:
      label = "Submitted";
      break;
    case ReleaseOpStatus.Distributed:
      label = "Distributed";
      break;
    case ReleaseOpStatus.Released:
      label = "Released";
      break;
    case ReleaseOpStatus.TakenDown:
      label = "Taken Down";
      break;
    case ReleaseOpStatus.Deleted:
      label = "Deleted";
      break;
    case ReleaseOpStatus.Retracted:
      label = "Retracted";
      break;
    case ReleaseOpStatus.Error:
      label = "Error";
      break;
  }

  return status ? <Chip color="default" label={label} {...props} /> : null;
};

export default ReleaseStatusChip;
