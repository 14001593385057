import styled from "@emotion/styled";

import IconButton from "@mui/material/IconButton";

import { NavLinkStyled } from "../AdminNav";

export const IconButtonStyled = styled(IconButton)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondaryDark.main};
  }

  svg path {
    fill: ${({ theme }) => theme.palette.icon};
  }
`;

export const SettingsNavLinkStyled = styled(NavLinkStyled)`
  width: fit-content;
  padding: 0;
`;
