import { ROUTES } from "constants/routes";

export const progressBarConfig = (baseRoute: string) => {
  const createRoute = (url: string) => `${baseRoute}/${url}`;
  return [
    {
      title: "progress.qualificationInformation.title",
      subRoutes: [
        {
          route: baseRoute,
          fields: [],
        },
      ],
      description: "progress.qualificationInformation.description",
    },
    {
      title: "progress.clientInformation.title",
      subRoutes: [
        {
          route: createRoute(ROUTES.CONTACT_ROUTE),
          fields: [],
        },
        { route: createRoute(ROUTES.ADDRESS_ROUTE), fields: [] },
      ],
      description: "progress.clientInformation.description",
    },
    {
      title: "progress.artistCatalog.title",
      subRoutes: [{ route: createRoute(ROUTES.CATALOG_ROUTE), fields: [] }],
      description: "progress.artistCatalog.description",
    },
    {
      title: "progress.agreementInformation.title",
      subRoutes: [{ route: createRoute(ROUTES.AGREEMENT_ROUTE), fields: [] }],
      description: "progress.agreementInformation.description",
    },
    {
      title: "progress.distributionAndPayout.title",
      subRoutes: [{ route: createRoute(ROUTES.DISTRIBUTION_ROUTE), fields: [] }],
      description: "Almost done! Just a few more steps to create your Distribution Agreement.",
    },
  ];
};
