import { FC, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet, useParams } from "react-router-dom";
import { CustomFormProps, CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Grid from "@mui/material/Grid";

import FormProgressBar from "components/FormProgressBar";
import { PageContainer, ProgressBarStyled } from "components/Layout";
import { defaultDistributionInfoValues } from "components/RegistrationForm/ClientFormStep1/constants";
import { prepareDistributionInfoForm } from "components/RegistrationForm/ClientFormStep1/helpers";
import { defaultDistributionLegalValues } from "components/RegistrationForm/ClientFormStep2/constants";
import { prepareDistributionLegalForm } from "components/RegistrationForm/ClientFormStep2/helpers";
import { RegistrationFormsProvider } from "components/RegistrationForm/RegistrationFormsContext";
import { ROUTES } from "constants/routes";
import { DistributionInfoForm, DistributionLegalForm } from "types/revisedForms";

import Header from "./Header";
import { progressBarConfig } from "./progressBarConfig";

const AccountReview: FC = () => {
  const {
    revisedFormStore: {
      comments: { primaryComments },
      retrieveDistrLegal,
      distributionInfo,
      distributionLegal,
      resetRevisedForm,
    },
  } = useStores();
  const distributionInfoForm = useForm<DistributionInfoForm>({
    defaultValues: defaultDistributionInfoValues,
  });
  const distributionLegalForm = useForm<DistributionLegalForm>({
    defaultValues: defaultDistributionLegalValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      retrieveDistrLegal(id);
    }
    return resetRevisedForm;
  }, [id]);

  useEffect(() => {
    if (distributionInfo) {
      distributionInfoForm.reset(prepareDistributionInfoForm(distributionInfo));
    }
  }, [distributionInfo]);

  useEffect(() => {
    if (distributionLegal) {
      distributionLegalForm.reset(prepareDistributionLegalForm(distributionLegal) as DistributionLegalForm);
    }
  }, [distributionLegal]);

  const config = useMemo(() => progressBarConfig(ROUTES.ACCOUNTS_ID(id)), [id]);

  const customFormConfig: CustomFormProps = {
    readOnly: {
      fields: true,
    },
    comments: {
      fields: false,
    },
    edit: {
      fields: false,
    },
  };

  return (
    <CustomFormProvider {...customFormConfig}>
      <RegistrationFormsProvider distributionInfoForm={distributionInfoForm}>
        <FormProvider {...distributionLegalForm}>
          <PageContainer noBottomSpacing>
            <Header />
            <Grid container flex={1}>
              <Grid item xs={12} md={3}>
                <ProgressBarStyled>
                  <FormProgressBar config={config} comments={primaryComments} />
                </ProgressBarStyled>
              </Grid>
              <Grid item xs={12} md={9} display="flex" flexDirection="column">
                <Outlet />
              </Grid>
            </Grid>
          </PageContainer>
        </FormProvider>
      </RegistrationFormsProvider>
    </CustomFormProvider>
  );
};

export default observer(AccountReview);
