import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

export const AdminDistrInfoRoute: RouteObject[] = [
  {
    path: ROUTES.APPLICATIONS_DISTR_INFO_ID(),
    async lazy() {
      return { Component: (await import("./DistributionInfo")).default };
    },
    children: [
      {
        index: true,
        async lazy() {
          return { Component: (await import("./Pages")).AdminDistributionQualification };
        },
      },
      {
        path: ROUTES.CONTACT_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminContact };
        },
      },
      {
        path: ROUTES.ADDRESS_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminAddressInformation };
        },
      },
      {
        path: ROUTES.CATALOG_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminDistributionCatalog };
        },
      },
      {
        path: ROUTES.AGREEMENT_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminDistributionAgreement };
        },
      },
    ],
  } as RouteObject,
];
