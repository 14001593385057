import { Components } from "@mui/material/styles";

export const MuiFormHelperText: Components["MuiFormHelperText"] = {
  styleOverrides: {
    root: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
};
