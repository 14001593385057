import React, { FC, useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useUserNotificationState } from "hooks/useUserNotificationState";
import { observer } from "mobx-react";
import { useStores } from "stores";

import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { CloseIcon } from "assets/icons/24px";
import EmptyTable1x from "assets/images/1x/Illustration_Empty-Table1x.jpg";
import EmptyTable2x from "assets/images/2x/Illustration_Empty-Table2x.png";
import { ProgressiveImage } from "components";
import { InputIconButton } from "components/HookFormControls/InputIconButton";
import { MessageContainerStyled, PlaceHolderExample } from "components/Layout";

import NotificationItem from "./NotificationItem";
import { DrawerTitleStyled, EmptyNotificationContainer, LoaderContainerStyled, NotificationsContainerStyled } from "./styled";

const Notifications: FC = () => {
  const {
    uiStore: { closeDrawer },
    notificationStore: { notifications, notificationsCount, hasMoreNotifications, isLoading, getCurrentNotifications },
  } = useStores();
  const [page, setPage] = useState<number>(1);
  const { notification_with_release_relations } = useUserNotificationState();

  useEffect(() => {
    return () => {
      getCurrentNotifications(void 0, void 0, true, notification_with_release_relations);
    };
  }, []);

  const onLoadMore = useCallback(async () => {
    const offset = page * 10;
    await getCurrentNotifications(offset, void 0, void 0, notification_with_release_relations);
    setPage((prevPage) => prevPage + 1);
  }, [page]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreNotifications,
    onLoadMore,
  });

  return (
    <React.Fragment>
      <DrawerTitleStyled>
        <Typography variant="h2">Notifications ({notificationsCount})</Typography>
        <InputIconButton size="small" onClick={closeDrawer}>
          <CloseIcon />
        </InputIconButton>
      </DrawerTitleStyled>
      <Divider />
      <NotificationsContainerStyled>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
        {isLoading || hasMoreNotifications ? (
          <LoaderContainerStyled ref={sentryRef}>
            <CircularProgress />
          </LoaderContainerStyled>
        ) : null}
        {!notifications.length && !isLoading ? (
          <EmptyNotificationContainer>
            <MessageContainerStyled>
              <ProgressiveImage placeholder={EmptyTable1x} src={EmptyTable2x}>
                {(src) => <PlaceHolderExample cardImage={src} />}
              </ProgressiveImage>
              <Typography variant="body1">You have no active notifications!</Typography>
            </MessageContainerStyled>
          </EmptyNotificationContainer>
        ) : null}
      </NotificationsContainerStyled>
    </React.Fragment>
  );
};

export default observer(Notifications);
