const EN_LANG = "en_us";

// Language codes represented in "IETF language tags"
// export const SUPPORTED_LOCALES = [EN_LANG, "en_gb", "es", "de"];
export const SUPPORTED_LOCALES = [EN_LANG];

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];
export const DEFAULT_LOCALE: SupportedLocale = EN_LANG;
export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  [EN_LANG]: "English (US)",
  en_gb: "English (UK)",
  es: "Español",
  de: "Deutsch",
};

export enum RESOURCES_KEYS {
  COMMON = "common",
  QUALIFICATION = "qualification",
  OPTIONS = "options",
  APPLICATIONS = "applications",
  ERRORS_AND_NOTIFICATIONS = "errorsAndNotifications",
  WELCOME = "welcome",
  REGISTRATION = "registration",
}
