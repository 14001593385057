import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const clientId = process.env.REACT_APP_B2C_CLIENT_ID as string;
const authority = process.env.REACT_APP_B2C_AUTHORITY as string;
const knownAuthority = process.env.REACT_APP_B2C_KNOWN_AUTHORITY as string;
const knownAuthorities = [knownAuthority];

export const authorities = {
  signIn: `${authority}/B2C_1A_SIGNIN_CONDITIONALACCESS`,
  signUp: `${authority}/B2C_1_sign_up`,
  resetPassword: `${authority}/B2C_1_reset_password`,
};

export const config: Configuration = {
  auth: {
    clientId,
    authority: authorities.signIn,
    redirectUri: `${window.location.origin}`,
    knownAuthorities,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const instance = new PublicClientApplication(config);
export default instance;
