import React from "react";
import styled from "@emotion/styled";

import { DataGrid, DataGridProps } from "@mui/x-data-grid";

import { SortingAscIcon, SortingDescIcon, SortingEmptyIcon } from "assets/icons/14px";

import NoRowsOverlay from "./NoRowsOverlay";
import TableLoadingOverlay from "./TableLoadingOverlay";
import TablePagination from "./TablePagination";

const DataGridStyled = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== "sortingOrder",
})(
  ({ theme }) => `
  .Mui-hovered .hiddenElement {
    display: flex;
  }

  .MuiDataGrid-cell {
    cursor: pointer;
    &:focus, &:focus-within {
      outline: none;
    }
  }

  .MuiDataGrid-row.userInactive {
    color: ${theme.palette.text.disabled};
    .MuiTypography-root {
      color: ${theme.palette.text.disabled};
    }
    .MuiChip-filled {
      color: ${theme.palette.text.disabled};
      background-color: transparent;
      border: 1px solid ${theme.palette.divider};
    }
  }
`,
) as typeof DataGrid;

export type TableProps = DataGridProps & {
  hidePagination?: boolean;
};

const Table = ({ hidePagination, ...props }: TableProps) => {
  return (
    <DataGridStyled
      pagination
      sortingMode="server"
      paginationMode="server"
      sortingOrder={["asc", "desc"]}
      rowSelection={false}
      disableRowSelectionOnClick
      {...props}
      slots={{
        pagination: hidePagination ? null : TablePagination,
        columnSortedAscendingIcon: SortingAscIcon,
        columnSortedDescendingIcon: SortingDescIcon,
        columnUnsortedIcon: SortingEmptyIcon,
        loadingOverlay: TableLoadingOverlay,
        noResultsOverlay: NoRowsOverlay,
        noRowsOverlay: NoRowsOverlay,
        ...props.slots,
      }}
    />
  );
};

export default Table;
