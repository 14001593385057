import { useParams } from "react-router-dom";
import { COMMENT_MODE, COMMENT_TYPE, CustomFormProps } from "context/CustomFormContext";
import { useStores } from "stores";

import { ApiKeyFields, CommentForm, PrimaryFormNames, ReleaseStatus } from "types";

import { ReleaseUrlParams } from "../types";

export type ReturnReleaseFormState = CustomFormProps & {
  isReadOnly: boolean;
  isEditable: boolean;
};

export const usePrimaryFormState = (): ReturnReleaseFormState => {
  const {
    revisedFormStore: {
      commentsLvl1FieldKeys,
      comments: { postPrimaryComment, patchPrimaryComment, deletePrimaryComment, primaryComments },
      release,
    },
    userStore: { isAdmin },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const commentCallback = async (comment: CommentForm, mode: COMMENT_MODE) => {
    if (!releaseId) return;
    switch (mode) {
      case COMMENT_MODE.ADD:
        postPrimaryComment(userId, PrimaryFormNames.Releases, releaseId, comment);
        return;
      case COMMENT_MODE.EDIT:
        patchPrimaryComment(userId, PrimaryFormNames.Releases, releaseId, comment.fieldName, comment);
        return;
      case COMMENT_MODE.DELETE:
        deletePrimaryComment(userId, PrimaryFormNames.Releases, releaseId, comment.fieldName);
        return;
    }
  };

  if (release?.status === ReleaseStatus.Released) {
    return {
      isReadOnly: true,
      readOnly: {
        fields: true,
      },
      edit: { fields: [] },
      comments: { fields: [] },
      isAdmin,
      isEditable: false,
    };
  }

  if (isAdmin) {
    switch (release?.status) {
      case ReleaseStatus.Draft:
        return {
          isReadOnly: true,
          readOnly: {
            fields: true,
          },
          edit: { fields: [] },
          comments: { fields: [] },
          isAdmin,
          isEditable: false,
        };
      case ReleaseStatus.InRevision:
        return {
          isReadOnly: true,
          readOnly: {
            fields: true,
          },
          edit: {
            fields: true,
            excludeFields: [ApiKeyFields.cover_art_blob_path, ApiKeyFields.ai_cover_art, ApiKeyFields.date_confirmation],
          },
          comments: {
            fields: true,
            excludeFields: [ApiKeyFields.schedule_earlier, ApiKeyFields.date_confirmation],
            commentCallback,
            formComments: primaryComments,
          },
          isAdmin,
          isEditable: true,
        };
      case ReleaseStatus.ChangesNeeded:
        return {
          isReadOnly: true,
          readOnly: {
            fields: true,
          },
          edit: { fields: false },
          comments: { fields: commentsLvl1FieldKeys, formComments: primaryComments, commentTypes: COMMENT_TYPE.READ_ONLY },
          isAdmin,
          isEditable: false,
        };
    }
  }

  switch (release?.status) {
    case ReleaseStatus.Draft:
      return {
        isReadOnly: false,
        readOnly: { fields: false },
        edit: { fields: false },
        comments: { fields: false },
        isEditable: false,
      };
    case ReleaseStatus.InRevision:
      return {
        isReadOnly: true,
        readOnly: { fields: true },
        edit: { fields: false },
        comments: { fields: false },
        isEditable: false,
      };
    case ReleaseStatus.ChangesNeeded:
      return {
        isReadOnly: false,
        readOnly: { fields: true, excludeFields: commentsLvl1FieldKeys },
        edit: { fields: false },
        comments: {
          fields: commentsLvl1FieldKeys,
          formComments: primaryComments,
          commentCallback,
          commentTypes: COMMENT_TYPE.CLIENT,
        },
        isEditable: true,
      };
  }

  return {
    isReadOnly: true,
    readOnly: { fields: false },
    edit: { fields: false },
    comments: { fields: false },
    isEditable: false,
  };
};
