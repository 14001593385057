import * as zod from "zod";

import { MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, MIN_LEN_NUM_ERR_TEXT, SELECT_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const schema = zod.object({
  [ApiKeyFields.artistName]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.role]: zod.string({ invalid_type_error: SELECT_ERR_TEXT }).min(1, { message: SELECT_ERR_TEXT }),
});
