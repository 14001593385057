import { GridColDef } from "@mui/x-data-grid";

import { RowModifyActions } from "components/ReleaseForm";
import type { ReleaseColumnsProps } from "components/ReleaseForm/types";
import { ApiKeyFields, Songwriters } from "types";

import { AFFILIATION_COL, COUNTRY_COL, SPLIT_COL } from "../columns";

export const getColumns = ({
  onEditClick,
  onDeleteClick,
  getRowComments,
  isReadOnly,
}: ReleaseColumnsProps<Songwriters>): GridColDef<Songwriters>[] => [
  {
    field: ApiKeyFields.name,
    headerName: "Songwriter Name",
    sortable: false,
    flex: 1,
    minWidth: 250,
    disableColumnMenu: true,
  },
  AFFILIATION_COL,
  COUNTRY_COL,
  SPLIT_COL,
  {
    field: ApiKeyFields.actions,
    sortable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: ({ row }) => (
      <RowModifyActions
        key={row.id}
        record={row}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        getRowComments={getRowComments}
        isReadOnly={isReadOnly}
      />
    ),
  },
];
