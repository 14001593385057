import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.artistName]: "",
  [ApiKeyFields.role]: "",
};

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.artistName]: "Artist",
  [ApiKeyFields.role]: "Role",
};
