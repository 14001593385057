import * as XLSX from "xlsx";

import { RELEASE_ROLES_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

import { TRACK_LANGUAGES_OPTIONS } from "../constants";

interface ArtistTemplateData {
  [ApiKeyFields.artistName]: string;
  [ApiKeyFields.isFeatured]: boolean | null;
  [ApiKeyFields.appleUrl]: string | null;
  [ApiKeyFields.spotifyUrl]: string | null;
}

interface ContributorTemplateData {
  [ApiKeyFields.artistName]: string;
  [ApiKeyFields.role]: string;
}

export interface TrackTemplateData {
  [ApiKeyFields.title]: string;
  [ApiKeyFields.versionTitle]: string | null;
  [ApiKeyFields.previewStartsAt]: string | null;
  [ApiKeyFields.language]: string;
  [ApiKeyFields.aiAudio]: boolean | null;
  artists: ArtistTemplateData[];
  contributors: ContributorTemplateData[];
}

const getBoolValue = (value: string) => (value === "YES" ? true : null);

const getLanguageValue = (value: string) => {
  const option = TRACK_LANGUAGES_OPTIONS.find((opt) => opt.label === value?.trim());
  return option ? option.value : null;
};

const getContributorRole = (value: string) => {
  const option = RELEASE_ROLES_OPTIONS.find((opt) => opt.label === value?.trim());
  return option ? option.value : null;
};

export function processTrackTemplate(data: ArrayBuffer): TrackTemplateData[] {
  // Read the Excel file
  const workbook = XLSX.read(data);
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  // Convert the sheet to JSON format
  const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });

  const trackDataList: TrackTemplateData[] = [];

  // Iterate through the rows to find the relevant data
  for (let i = 0; i < sheetData.length; i++) {
    const row = sheetData[i] as string[];

    // Find the start of a new track
    if (row[0] && row[0].startsWith("Track #")) {
      // Ensure there are enough rows for headers and values
      if (i + 2 < sheetData.length) {
        const headers = sheetData[i + 1] as string[];
        const values = sheetData[i + 2] as string[];

        const trackTitle = values[headers.indexOf("Track title")] || "";
        const language = getLanguageValue(values[headers.indexOf("Language")]) || "";

        // Skip if trackTitle or language is not provided
        if (!trackTitle) {
          continue;
        }

        const trackData: TrackTemplateData = {
          [ApiKeyFields.title]: trackTitle,
          [ApiKeyFields.versionTitle]: values[headers.indexOf("Version title")] || null,
          [ApiKeyFields.previewStartsAt]: values[headers.indexOf("Preview start time")] || null,
          [ApiKeyFields.language]: language,
          [ApiKeyFields.aiAudio]: getBoolValue(values[headers.indexOf("AI tools used?")]),
          artists: [],
          contributors: [],
        };

        // Extract artist data (columns H-K, 13 rows)
        const artistStartIndex = i + 3; // Artists start after the track data row
        for (let j = artistStartIndex; j < artistStartIndex + 13 && j < sheetData.length; j++) {
          const artistRow = sheetData[j] as string[];
          if (artistRow[6]) {
            // Check if there is data in the Artist name column
            const artistData: ArtistTemplateData = {
              [ApiKeyFields.artistName]: artistRow[6] || "",
              [ApiKeyFields.isFeatured]: getBoolValue(artistRow[7]),
              [ApiKeyFields.appleUrl]: artistRow[8] || null,
              [ApiKeyFields.spotifyUrl]: artistRow[9] || null,
            };
            trackData.artists.push(artistData);
          }
        }

        // Extract contributor data (columns M-N, 20 rows)
        const contributorStartIndex = artistStartIndex; // Contributors start at the same level as artists
        for (let j = contributorStartIndex; j < contributorStartIndex + 20 && j < sheetData.length; j++) {
          const contributorRow = sheetData[j] as string[];
          if (contributorRow[11]) {
            // Check if there is data in the Contributor name column
            const contributorData: ContributorTemplateData = {
              [ApiKeyFields.artistName]: contributorRow[11] || "",
              [ApiKeyFields.role]: getContributorRole(contributorRow[12]) || "",
            };
            trackData.contributors.push(contributorData);
          }
        }

        trackDataList.push(trackData);
      }
    }
  }

  return trackDataList;
}
