import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

export const ApplicationTableRoute: RouteObject[] = [
  {
    path: ROUTES.APPLICATIONS,
    async lazy() {
      return { Component: (await import("./Applications")).default };
    },
    children: [
      {
        index: true,
        async lazy() {
          return { Component: (await import("./Tables/ApplicationDistributionTable")).default };
        },
      },
    ],
  },
];
