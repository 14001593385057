import { FC } from "react";
import { useFormContext } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { CheckboxField, FormBlockGridGap, SelectField, TextAreaField, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { ApiKeyFields } from "types";

import { TRACK_LANGUAGES_OPTIONS } from "../../Tracks/constants";

import { CHECKBOX_FIELDS, FORM_FIELDS, FORM_LABELS, HELPER_TEXT, OPTIONAL_FIELDS, PLACEHOLDER_TEXT } from "./constants";
import { WorkFormProps } from "./types";

const WorkForm: FC<WorkFormProps> = ({ extendedForm }) => {
  const { control } = useFormContext();
  return (
    <Grid container gap={FormBlockGridGap}>
      <FormGridFieldItem
        fieldName={ApiKeyFields.title}
        label={FORM_LABELS[ApiKeyFields.title]}
        helperText={HELPER_TEXT[ApiKeyFields.title]}
      >
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.language]} fieldName={ApiKeyFields.language}>
        {(field) => <SelectField {...field} control={control} options={TRACK_LANGUAGES_OPTIONS} />}
      </FormGridFieldItem>
      {extendedForm
        ? CHECKBOX_FIELDS.map((fieldName) => (
            <FormGridFieldItem
              key={fieldName}
              fieldName={fieldName}
              label={FORM_LABELS[fieldName]}
              helperText={HELPER_TEXT[fieldName]}
              isOptionalField
            >
              {(field) => <CheckboxField {...field} control={control} />}
            </FormGridFieldItem>
          ))
        : null}
      {FORM_FIELDS.map((fieldName) => (
        <FormGridFieldItem
          key={fieldName}
          fieldName={fieldName}
          label={FORM_LABELS[fieldName]}
          helperText={HELPER_TEXT[fieldName]}
          isOptionalField={OPTIONAL_FIELDS[fieldName]}
        >
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ))}
      {extendedForm ? (
        <FormGridFieldItem
          label={FORM_LABELS[ApiKeyFields.lyrics]}
          fieldName={ApiKeyFields.lyrics}
          helperText={HELPER_TEXT[ApiKeyFields.lyrics]}
        >
          {(field) => (
            <TextAreaField
              {...field}
              control={control}
              placeholder={PLACEHOLDER_TEXT[ApiKeyFields.lyrics]}
              rows={5}
              maxLength={5000}
            />
          )}
        </FormGridFieldItem>
      ) : null}
    </Grid>
  );
};

export default WorkForm;
