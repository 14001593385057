import { useStores } from "stores";

import { UserStatus } from "types";

export const useUserNotificationState = () => {
  const {
    userStore: { user, userStatus },
  } = useStores();

  const notification_with_release_relations = userStatus === UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE;

  if (!user || user?.isAdmin) {
    return { showNotification: false, notification_with_release_relations };
  }

  const showNotification =
    user?.status &&
    user.status > UserStatus.EDIT_DISTRIBUTION_INFO_FORM &&
    [
      UserStatus.UPDATE_DISTRIBUTION_INFO_FORM,
      UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM,
      UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE,
      UserStatus.DAP_RELEASE_MANAGER_AVAILABLE,
    ].includes(user.status);

  return { showNotification, notification_with_release_relations };
};
