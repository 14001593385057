import React, { FC } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import NoMobile1x from "assets/images/1x/Illustration_No-Mobile1x.jpg";
import NoMobile2x from "assets/images/2x/Illustration_No-Mobile2x.png";
import Logo from "assets/images/im_logo_dark.png";
import { AppBarStyled, ProgressiveImage, ToolbarStyled } from "components";
// import LanguageSelect from "components/LanguageSelect";
import { MessageContainerStyled, PageContainer, PlaceHolderExample } from "components/Layout";

const MobileLock: FC = () => {
  const handleClickToClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <React.Fragment>
      <AppBarStyled position="sticky">
        <ToolbarStyled sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="img" src={Logo} sx={{ width: 242 }} />
        </ToolbarStyled>
      </AppBarStyled>
      <PageContainer justifyContent="space-between" includeStickyBar>
        {/* <LanguageSelect /> */}
        <div></div>
        <MessageContainerStyled>
          <ProgressiveImage placeholder={NoMobile1x} src={NoMobile2x}>
            {(src) => <PlaceHolderExample cardImage={src} />}
          </ProgressiveImage>
          <Typography variant="h3">{`Sorry for the inconvenience, but this page isn't mobile-friendly yet!`}</Typography>
          <Typography variant="h3">{`Please open from a laptop or computer for the best experience. Thanks!`}</Typography>
        </MessageContainerStyled>
        <Button size="small" variant="contained" onClick={handleClickToClipBoard}>
          Copy a Page Link to the Clipboard
        </Button>
      </PageContainer>
    </React.Fragment>
  );
};

export default MobileLock;
