import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { WorksForm } from "types";

export const useWorkNotificationParams = (fields: WorksForm[], handleWorkClick: (work: WorksForm) => void) => {
  const location = useLocation();
  const hasProcessed = useRef(false);

  const processWork = () => {
    const releaseWorkId = location.state?.releaseWorkId;
    if (!releaseWorkId) return;

    const findWork = fields.find(({ id }) => id === releaseWorkId);
    if (findWork) {
      handleWorkClick(findWork);
      hasProcessed.current = true;
    }
  };

  useEffect(() => {
    if (hasProcessed.current) return;
    if (fields.length) processWork();
  }, [fields, location]);
};
