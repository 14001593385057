import React, { FC } from "react";
import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom";

import ComingSoon from "components/ComingSoon";
import ErrorBoundary from "components/ErrorBoundary";
// import DPForm from "components/QualificationForm/GenericDistributionAndPublishing"; MIM-438
// import GenericDistributionAndPublishingInvite from "components/QualificationForm/GenericDistributionAndPublishing"; MIM-438
import GenericDistributionForm from "components/QualificationForm/GenericDistributionForm";
// import GenericPublishingForm from "components/QualificationForm/GenericPublishingForm"; MIM-438
import { ROUTES } from "constants/routes";
import Dashboard from "pages/_Samples/Samples";
import { AccountTableRoute } from "pages/Admin/Accounts";
import { AccountReviewRoute } from "pages/Admin/Accounts/AccountReview";
import { ApplicationTableRoute } from "pages/Admin/Applications";
// import PublishingReview from "pages/Admin/Applications/QualificationReview/PublishingReview"; MIM-438
import { AdminDistrInfoRoute } from "pages/Admin/Applications/DistributionRegistration/DistributionInfo";
// import QualificationForm from "components/QualificationForm/Form/QualificationForm";
import { AdminDistrLegalRoute } from "pages/Admin/Applications/DistributionRegistration/DistributionLegal";
import { AdminReleaseRoute } from "pages/Admin/Releases/Release";
// import DistributionAndPublishingInvite from "pages/Admin/Applications/InviteUsers/DistributionAndPublishingInvite"; MIM-438
// MIM-438
// import PublishingInvite from "pages/Admin/Applications/InviteUsers/PublishingInvite";
// import DistributionAndPublishingReview from "pages/Admin/Applications/QualificationReview/DistributionAndPublishingReview";
// import DistributionAndPublishing from "pages/User/Qualification/DistributionAndPublishing"; MIM-438
// import Publishing from "pages/User/Qualification/Publishing"; MIM-438
import RoleSelect from "pages/User/Qualification/RoleSelect";
import { ClientDistrInfoRoute } from "pages/User/Registration/DistributionInfo";
import { DistrLegalRoute } from "pages/User/Registration/DistrLegal";
import { ClientReleaseFormRoute } from "pages/User/Releases/Release";
import {
  AppUserRole,
  DistributionType,
  // PublishingType, MIM-438
  UserStatus,
} from "types";

// import Welcome from "pages/Welcome";
import Root from "./Root";
import { AuthenticatedRoute, UnauthenticatedRoute, UserPermissionRoute, UserRoleRoute } from "./RouteGuards";

const routes: RouteObject[] = [
  {
    path: ROUTES.ROOT,
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ROUTES.SAMPLES,
        element: process.env.REACT_APP_NODE_ENV === "development" ? <Dashboard /> : null,
      },
      {
        path: ROUTES.PUBLISHING_ADMINISTRATION,
        async lazy() {
          return { Component: (await import("pages/PublishingAdministration")).default };
        },
      },
      /* PublicRoutes */
      {
        element: UnauthenticatedRoute,
        children: [
          {
            path: ROUTES.LOGIN,
            async lazy() {
              return { Component: (await import("pages/Welcome")).default };
            },
          },
          {
            path: ROUTES.LABEL,
            async lazy() {
              return { Component: (await import("pages/LabelCooperate")).default };
            },
          },
        ],
      },
      /* AuthenticatedRoutes */
      {
        element: <AuthenticatedRoute />,
        children: [
          {
            path: ROUTES.ROOT,
            async lazy() {
              return { Component: (await import("./ManageUser")).default };
            },
          },
          {
            path: ROUTES.SETTINGS,
            async lazy() {
              return { Component: (await import("pages/Settings")).default };
            },
          },
          {
            path: ROUTES.RESET_PASSWORD,
            async lazy() {
              return { Component: (await import("pages/ResetPassword")).default };
            },
          },
          {
            path: ROUTES.SAVING,
            async lazy() {
              return { Component: (await import("pages/SuccessSignUp")).default };
            },
          },
          {
            path: ROUTES.APPLICATION_SETTINGS,
            async lazy() {
              return { Component: (await import("pages/Admin/ApplicationSettings")).default };
            },
          },
          /* Admin routes */
          {
            element: <UserRoleRoute allowedRole={[AppUserRole.ADMIN]} />,
            children: [
              {
                path: ROUTES.ADMINS,
                async lazy() {
                  return { Component: (await import("pages/Admin")).Admins };
                },
              },
              {
                path: ROUTES.RELEASES,
                async lazy() {
                  return { Component: (await import("pages/Admin/Releases")).default };
                },
              },
              ...AdminReleaseRoute,
              ...AccountTableRoute,
              ...AccountReviewRoute,
              {
                path: ROUTES.INVITE_ADMIN(),
                async lazy() {
                  return { Component: (await import("pages/Admin")).InviteAdmin };
                },
              },
              ...ApplicationTableRoute,
              // MIM-438
              // { path: ROUTES.APPLICATIONS_PUBLISHING_ID(), element: <PublishingReview /> },
              {
                path: ROUTES.APPLICATIONS_DISTRIBUTION_ID(),
                async lazy() {
                  return { Component: (await import("pages/Admin/Applications/QualificationReview/DistributionReview")).default };
                },
              },
              ...AdminDistrInfoRoute,
              ...AdminDistrLegalRoute,
              // MIM-438
              // {
              //   path: ROUTES.APPLICATIONS_DISTRIBUTION_AND_PUBLISHING_ID(),
              //   element: <DistributionAndPublishingReview />,
              // },
              {
                path: ROUTES.INVITE_DISTRIBUTION(),
                async lazy() {
                  return { Component: (await import("pages/Admin/Applications/InviteUsers/DistributionInvite")).default };
                },
                children: [
                  { index: true, element: <RoleSelect inviteUser /> },
                  { path: ROUTES.FORM_ROUTE, element: <GenericDistributionForm isInvited /> },
                ],
              },
              // MIM-438
              // {
              //   path: ROUTES.INVITE_PUBLISHING(),
              //   element: <PublishingInvite />,
              //   children: [
              //     { index: true, element: <RoleSelect inviteUser /> },
              //     { path: ROUTES.FORM_ROUTE, element: <GenericPublishingForm isInvited /> },
              //   ],
              // },
              // MIM-438
              // {
              //   path: ROUTES.INVITE_DISTRIBUTION_AND_PUBLISHING(),
              //   element: <DistributionAndPublishingInvite />,
              //   children: [
              //     { index: true, element: <RoleSelect inviteUser /> },
              //     { path: ROUTES.FORM_ROUTE, element: <GenericDistributionAndPublishingInvite isInvited /> },
              //   ],
              // },
            ],
          },
          /* Common user routes */
          {
            element: <UserRoleRoute allowedRole={[AppUserRole.COMMON]} />,
            children: [
              {
                element: <UserRoleRoute allowedRole={[AppUserRole.COMMON]} />,
                children: [
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[1]]} />,
                    children: [
                      {
                        path: ROUTES.QUALIFICATION,
                        async lazy() {
                          return { Component: (await import("pages/User/Qualification")).default };
                        },
                      },
                      {
                        element: <UserPermissionRoute allowedPermission={[...Object.keys(DistributionType)]} />,
                        children: [
                          {
                            path: ROUTES.DISTRIBUTION,
                            async lazy() {
                              return { Component: (await import("pages/User/Qualification/Distribution")).default };
                            },
                            children: [
                              {
                                index: true,
                                async lazy() {
                                  return { Component: (await import("pages/User/Qualification/RoleSelect")).default };
                                },
                              },
                              {
                                path: ROUTES.FORM_ROUTE,
                                async lazy() {
                                  return {
                                    Component: (await import("components/QualificationForm/GenericDistributionForm")).default,
                                  };
                                },
                              },
                            ],
                          },
                        ],
                      },
                      // MIM-438
                      // {
                      //   element: <UserPermissionRoute allowedPermission={[...Object.keys(PublishingType)]} />,
                      //   children: [
                      //     {
                      //       path: ROUTES.PUBLISHING,
                      //       element: <Publishing />,
                      //       children: [
                      //         { index: true, element: <RoleSelect /> },
                      //         { path: ROUTES.FORM_ROUTE, element: <GenericPublishingForm /> },
                      //       ],
                      //     },
                      //   ],
                      // },
                      // MIM-438
                      // {
                      //   element: (
                      //     <UserPermissionRoute
                      //       allowedPermission={[...Object.keys(DistributionType), ...Object.keys(PublishingType)]}
                      //     />
                      //   ),
                      //   children: [
                      //     {
                      //       path: ROUTES.DISTRIBUTION_AND_PUBLISHING,
                      //       element: <DistributionAndPublishing />,
                      //       children: [
                      //         { index: true, element: <RoleSelect /> },
                      //         { path: ROUTES.FORM_ROUTE, element: <DPForm /> },
                      //       ],
                      //     },
                      //   ],
                      // },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[101], UserStatus[103]]} />,
                    children: ClientDistrInfoRoute,
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[104], UserStatus[106]]} />,
                    children: DistrLegalRoute,
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[107]]} />,
                    children: [
                      {
                        path: ROUTES.COMING_SOON,
                        async lazy() {
                          return { Component: (await import("pages/User/RegistrationStatus/FormApproved")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[108]]} />,
                    children: [
                      {
                        path: ROUTES.CONTRACT_REVIEW,
                        async lazy() {
                          return { Component: (await import("pages/User/OneClick/ContractReview")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[109]]} />,
                    children: [
                      {
                        path: ROUTES.CONTRACT_SIGNED,
                        async lazy() {
                          return { Component: (await import("pages/User/OneClick/ContractSigned")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[401], UserStatus[403]]} />,
                    children: [
                      {
                        path: ROUTES.DASHBOARD,
                        async lazy() {
                          return { Component: (await import("pages/User/Releases/Dashboard")).default };
                        },
                      },
                      ...ClientReleaseFormRoute,
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[402]]} />,
                    children: [
                      {
                        path: ROUTES.COMING_SOON_PUBLISHING,
                        element: <ComingSoon />,
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[102], UserStatus[105]]} />,
                    children: [
                      {
                        path: ROUTES.IN_REVIEW,
                        async lazy() {
                          return { Component: (await import("pages/User/RegistrationStatus/WaitingForReview")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[201]]} />,
                    children: [
                      {
                        path: ROUTES.QUALIFIED,
                        async lazy() {
                          return { Component: (await import("pages/User/QualificationStatus/Qualified")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[3]]} />,
                    children: [
                      {
                        path: ROUTES.IN_REVIEW,
                        async lazy() {
                          return { Component: (await import("pages/User/QualificationStatus/WaitingForQualification")).default };
                        },
                      },
                    ],
                  },
                  {
                    element: <UserPermissionRoute allowedPermission={[UserStatus[2]]} />,
                    children: [
                      {
                        path: ROUTES.COLLABORATION_STATUS,
                        async lazy() {
                          return { Component: (await import("pages/User/QualificationStatus/NotQualified")).default };
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.NOT_EXIST,
            async lazy() {
              return { Component: (await import("pages/NotExist")).NotExist };
            },
          },
          {
            path: "*",
            async lazy() {
              return { Component: (await import("pages/NotExist")).NotExistRedirect };
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const App: FC = () => <RouterProvider router={router} />;

export default App;
