import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { ROUTES } from "constants/routes";

const ONE_MINUTE = 60000;
const ONE_HOUR = 3600000;
const TWENTY_FOUR_HOURS = 24 * ONE_HOUR;

const lastActivityKey = "lastActivityTime";
const logoutEventKey = "logoutEvent";

export const useUserInactivity = (timeout = TWENTY_FOUR_HOURS): void => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated || process.env.REACT_APP_NODE_ENV === "development") {
      return;
    }

    const updateLastActivityTime = () => {
      localStorage.setItem(lastActivityKey, Date.now().toString());
    };

    const checkInactivity = () => {
      const lastActivityTime = parseInt(localStorage.getItem(lastActivityKey) || "0");
      if (Date.now() - lastActivityTime > timeout) {
        localStorage.removeItem(lastActivityKey);
        localStorage.setItem(logoutEventKey, Date.now().toString());
        instance.logoutRedirect({
          postLogoutRedirectUri: `${window.location.origin}${ROUTES.LOGIN}?inactive=true`,
        });
      }
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === logoutEventKey) {
        window.location.href = `${window.location.origin}${ROUTES.LOGIN}?inactive=true`;
        localStorage.removeItem(logoutEventKey);
      }
    };

    window.addEventListener("mousemove", updateLastActivityTime);
    window.addEventListener("keydown", updateLastActivityTime);
    window.addEventListener("storage", handleStorageChange);

    const interval = setInterval(checkInactivity, ONE_MINUTE);

    updateLastActivityTime();

    return () => {
      window.removeEventListener("mousemove", updateLastActivityTime);
      window.removeEventListener("keydown", updateLastActivityTime);
      window.removeEventListener("storage", handleStorageChange);
      localStorage.removeItem(lastActivityKey);
      clearInterval(interval);
    };
  }, [isAuthenticated, instance, timeout]);
};
