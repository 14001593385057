import styled from "@emotion/styled";

export const ActionsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .MuiDivider-root {
    height: 20px;
  }
`;
