import { FC } from "react";
import styled from "@emotion/styled";

import { EmergingIcon, EstablishedIcon } from "assets/icons/14px";
import { DistributionTier } from "types";

const TierContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const UserTier: FC<{ tier: DistributionTier }> = ({ tier }) => {
  switch (tier) {
    case DistributionTier.Entry:
      return <span>Entry</span>;
    case DistributionTier.Emerging:
      return (
        <TierContainerStyled>
          <EmergingIcon />
          <span>Emerging</span>
        </TierContainerStyled>
      );
    case DistributionTier.Established:
      return (
        <TierContainerStyled>
          <EstablishedIcon />
          Established
        </TierContainerStyled>
      );
    default:
      return <span>—</span>;
  }
};

export default UserTier;
