import * as XLSX from "xlsx";

interface onDownloadFileProps {
  title: string;
  file: string;
  fileFormat?: string;
}
export const onDownloadFile = ({ title, file, fileFormat }: onDownloadFileProps) => {
  const link = document.createElement("a");
  link.href = file;
  const downloadTitle = fileFormat ? `${title}.${fileFormat}` : title;
  link.setAttribute("download", downloadTitle);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const readFileAsArrayBuffer = (file: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as ArrayBuffer);
      } else {
        reject(new Error("Failed to read file"));
      }
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };
    reader.readAsArrayBuffer(file);
  });
};

export const processWorkbookData = (data: ArrayBuffer): unknown[] => {
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  return XLSX.utils.sheet_to_json(sheet);
};
