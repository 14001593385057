import { GridSortModel } from "@mui/x-data-grid";

import { defaultSortData } from "pages/Admin/Admins/constants";
import { sortingMapping } from "pages/Admin/constants";
import { ApiKeyFields } from "types";

export const getSortingData = (sortModel: GridSortModel) => {
  if (!sortModel.length) {
    return defaultSortData;
  }
  const { field, sort } = sortModel[0];
  if (!field || !sort) return defaultSortData;
  return {
    user_order_by_field_name: sortingMapping[field as ApiKeyFields],
    user_order: sort,
  };
};

export interface Filters {
  [ApiKeyFields.user_is_active]: boolean | string;
  [ApiKeyFields.createdAt]: DateRangeValue | null;
}

interface ProcessedFilters {
  [ApiKeyFields.user_is_active]?: boolean | null;
  user_created_at_from?: string | null;
  user_created_at_to?: string | null;
}

export const processFilters = (filters?: Filters) => {
  if (!filters) {
    return {};
  }
  const processedFilters: ProcessedFilters = {};
  const processBooleanOrString = (value: boolean | string): boolean | undefined => {
    return typeof value === "string" ? undefined : value;
  };
  processedFilters[ApiKeyFields.user_is_active] = processBooleanOrString(filters[ApiKeyFields.user_is_active]);
  const createdAtRange: DateRangeValue | null = filters[ApiKeyFields.createdAt];
  if (createdAtRange) {
    const [from, to] = createdAtRange;
    processedFilters.user_created_at_from = from
      ? from.set("hour", 0).set("minute", 0).set("second", 0).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    processedFilters.user_created_at_to = to
      ? to.set("hour", 23).set("minute", 59).set("second", 59).format("YYYY-MM-DDTHH:mm:ss")
      : null;
  }
  return processedFilters;
};
