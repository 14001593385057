import React from "react";
import styled from "@emotion/styled";

import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";

import { CheckboxStyled } from "components/FormControls";

export const ColorCircle = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color ?? "none"};
  border-radius: 50%;
`;

export const MenuItemStyled = React.memo(styled(MenuItem)`
  &.Mui-selected {
    background-color: transparent;
  }
`);

export const ListSubheaderStyled = React.memo(
  styled(ListSubheader)(
    ({ theme }) => `
  color: ${theme.palette.text.primary};
  font-weight: 700;
  border-bottom: 1px solid ${theme.palette.divider};
  pointer-events: none;
`,
  ),
);

export const SelectCheckboxStyled = styled(CheckboxStyled)`
  padding: 0 9px;
`;
