import React, { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { COMMENT_MODE, COMMENT_TYPE, CustomFormContext } from "context";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { AngleDownIcon, InfoIcon } from "assets/icons/14px";
import { DeleteIcon } from "assets/icons/24px";
import { ConditionalTooltip } from "components/FormComponents";
import { CheckboxStyled } from "components/FormControls";
import { TextAreaField } from "components/HookFormControls";
import { ApiKeyFields, CommentForm } from "types";
import { Comment, CommentStatus } from "types/revisedForms";

import { BlockContainer, ButtonsContainer } from "../Styled";

import { getDefaultValues, prepareFormData } from "./helpers";
import { schema } from "./validation";

const FlexStyled = styled.div`
  flex: 1;
  justify-content: flex-start;
`;

const SelectRoleStyled = styled("b", {
  shouldForwardProp: (prop) => !["forAdminOnly"].includes(prop),
})<{ forAdminOnly?: boolean }>`
  cursor: ${({ forAdminOnly }) => (forAdminOnly ? "default" : "pointer")};
`;

const ReadOnlyTextContainerStyled = styled.div`
  display: flex;
  align-item: center;
  gap: 4px;
`;

const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => `path { fill: ${theme.palette.error.main} }`);

interface CommentPopoverProps {
  fieldName: ApiKeyFields;
  getComment: Comment | undefined;
  handleClose: () => void;
}

const CommentPopover: FC<CommentPopoverProps> = ({ fieldName, getComment, handleClose }) => {
  const { commentCallback, getCommentType, isAdmin } = useContext(CustomFormContext);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const commentType = getCommentType(fieldName);

  const form = useForm<CommentForm>({
    defaultValues: getDefaultValues(fieldName, commentType),
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = form;

  useEffect(() => {
    if (getComment) {
      reset(prepareFormData(getComment));
    }
  }, [getComment]);
  const [forAdmin, status] = watch([ApiKeyFields.forAdmin, ApiKeyFields.status]);
  const dropdownOpen = Boolean(dropdownAnchorEl);

  const onSubmit = handleSubmit((data) => {
    if (commentCallback) {
      commentCallback(data, getComment ? COMMENT_MODE.EDIT : COMMENT_MODE.ADD);
      handleClose();
    }
  });

  const handleCommentDelete = () => {
    if (commentCallback) {
      commentCallback(getDefaultValues(fieldName, commentType), COMMENT_MODE.DELETE);
      handleClose();
    }
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onForAdminChange = (value: boolean) => {
    setValue(ApiKeyFields.forAdmin, value, { shouldTouch: true });
    handleMenuClose();
  };

  const handleResolvedChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setValue(ApiKeyFields.status, checked ? CommentStatus.RESOLVED : CommentStatus.ACTIVE, { shouldTouch: true });
  };

  const handleClientAndAdminsClick = () => onForAdminChange(false);
  const handleAdminsOnlyClick = () => onForAdminChange(true);

  const isResolvedByClient =
    isAdmin &&
    commentType === COMMENT_TYPE.CLIENT_AND_ADMIN &&
    getComment?.status === CommentStatus.RESOLVED &&
    !getComment.forAdmin;

  return (
    <FormProvider {...form}>
      <BlockContainer>
        <FlexStyled>
          <Typography variant="body1" display="flex" alignItems="center" gap="5px">
            {commentType === COMMENT_TYPE.CLIENT ? (
              <SelectRoleStyled>Please Check this Comment</SelectRoleStyled>
            ) : (
              <React.Fragment>
                <span>Comment for</span>{" "}
                <SelectRoleStyled onClick={handleMenuClick} forAdminOnly={commentType === COMMENT_TYPE.ADMIN}>
                  {forAdmin ? "Admins Only" : "Client & Admins"}
                </SelectRoleStyled>
                {!getComment && commentType !== COMMENT_TYPE.ADMIN ? <AngleDownIcon /> : null}
              </React.Fragment>
            )}
          </Typography>
          {!getComment && commentType !== COMMENT_TYPE.ADMIN ? (
            <Menu anchorEl={dropdownAnchorEl} open={dropdownOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleClientAndAdminsClick}>Client & Admins</MenuItem>
              <MenuItem onClick={handleAdminsOnlyClick}>Admins Only</MenuItem>
            </Menu>
          ) : null}
        </FlexStyled>
        {getComment && (commentType === COMMENT_TYPE.CLIENT_AND_ADMIN || commentType === COMMENT_TYPE.ADMIN) ? (
          <IconButton size="small" onClick={handleCommentDelete}>
            <DeleteIconStyled />
          </IconButton>
        ) : null}
      </BlockContainer>
      <Divider />
      <BlockContainer>
        <TextAreaField
          fieldName={ApiKeyFields.text}
          control={control}
          multiline
          rows={3}
          autoFocus
          // Text area in readOnly mode for Client and commentType = READ_ONLY
          readOnly={commentType === COMMENT_TYPE.CLIENT || commentType === COMMENT_TYPE.READ_ONLY || isResolvedByClient}
        />
      </BlockContainer>
      <Divider />
      <ButtonsContainer>
        {getComment ? (
          <FlexStyled>
            {commentType === COMMENT_TYPE.READ_ONLY ||
            // If the client returns the form to the admin with the resolved comment, then the admin can't change the resolved status.
            // Admin change status if it's for admin only comment.
            (isAdmin && commentType === COMMENT_TYPE.CLIENT_AND_ADMIN && !getComment.forAdmin) ? (
              <ConditionalTooltip
                title="To update the status of a comment, please post a new one."
                isTooltipVisible={isResolvedByClient}
              >
                <ReadOnlyTextContainerStyled>
                  {isAdmin &&
                  commentType === COMMENT_TYPE.CLIENT_AND_ADMIN &&
                  getComment.status === CommentStatus.RESOLVED &&
                  !getComment.forAdmin ? (
                    <InfoIcon />
                  ) : null}
                  <Typography variant="caption" color="text.label">
                    {status === CommentStatus.ACTIVE ? "Resolve" : "Resolved"}
                  </Typography>
                </ReadOnlyTextContainerStyled>
              </ConditionalTooltip>
            ) : (
              <FormControlLabel
                control={<CheckboxStyled />}
                checked={status === CommentStatus.RESOLVED}
                onChange={handleResolvedChange}
                label={status === CommentStatus.ACTIVE ? "Resolve" : "Resolved"}
              />
            )}
          </FlexStyled>
        ) : null}
        {commentType !== COMMENT_TYPE.READ_ONLY && !isResolvedByClient ? (
          <Button variant="contained" color="primary" size="small" onClick={onSubmit} disabled={!isValid}>
            {getComment ? "Update" : "Add"}
          </Button>
        ) : null}
      </ButtonsContainer>
    </FormProvider>
  );
};

export default CommentPopover;
