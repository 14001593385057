import { useLocation } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ROUTES } from "constants/routes";

export const useMobileViewBlock = (): { isViewNotAllowed: boolean } => {
  const theme = useTheme();
  const location = useLocation();
  const mobileBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  const ignoreMobileViewRoutes = [
    ROUTES.ROOT,
    ROUTES.LOGIN,
    ROUTES.SAVING,
    ROUTES.RESET_PASSWORD,
    ROUTES.QUALIFICATION,
    ROUTES.DISTRIBUTION,
    ROUTES.DISTRIBUTION_FORM(),
    ROUTES.DISTRIBUTION_AND_PUBLISHING,
    ROUTES.DISTRIBUTION_AND_PUBLISHING_FORM(),
    ROUTES.PUBLISHING,
    ROUTES.PUBLISHING_FORM(),
    ROUTES.COLLABORATION_STATUS,
    ROUTES.IN_REVIEW,
    ROUTES.SETTINGS,
    ROUTES.LABEL,
    ROUTES.NOT_EXIST,
  ];

  const isCurrentRouteIgnored = ignoreMobileViewRoutes.some((route) => route === location.pathname);

  return { isViewNotAllowed: !isCurrentRouteIgnored && mobileBreakPoint && process.env.REACT_APP_NODE_ENV !== "development" };
};
