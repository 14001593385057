import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiSelect: Components["MuiSelect"] = {
  styleOverrides: {
    root: {
      minWidth: "100px",
      ".MuiInputAdornment-root": {
        marginRight: 25,
      },
    },
    standard: {
      paddingBottom: 0,
    },
    multiple: {
      backgroundColor: colors.white,
    },
  },
  variants: [
    {
      props: { color: "primary" },
      style: {
        backgroundColor: colors.gray257,
        borderColor: colors.gray749,
        ":disabled": {
          borderColor: "red",
        },
      },
    },
    {
      props: { variant: "standard" },
      style: {
        ".MuiInputAdornment-root": {
          marginRight: "8px",
        },
        backgroundColor: "transparent",
      },
    },
  ],
};
