import { Notification } from "types";

export const buildStateWithParams = (notification: Notification, keys: (keyof Notification)[]): Record<string, string> => {
  const stateParams = keys.reduce(
    (state, key) => {
      const value = notification[key];
      if (typeof value === "string" && value) {
        state[key] = value;
      }
      return state;
    },
    {} as Record<string, string>,
  );

  return stateParams;
};
