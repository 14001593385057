import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import { NumberInput, Select } from "components/FormControls";
import { RESOURCES_KEYS } from "i18n";

import { useReadOnlyParams } from "../useReadOnlyParams";
import { ReadOnlyField } from "..";

const RevenueInputStyled = styled("div")(
  ({ theme }) => `display: flex;

  .ant-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-wrapper {
    flex: 1;
  }

  .MuiNumberInput-root, .MuiInputBase-root:not(:first-of-type) {
    max-height: 37px !important;
  }

  .MuiInputBase-root:not(:first-of-type) {
    width: 144px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .MuiOutlinedInput-notchedOutline {
      border-left: none;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-left: 2px solid ${theme.palette.primary.main};
    }

    .MuiOutlinedInput-notchedOutline:hover {
      border-left: 2px solid red;
    }
  }`,
);

type RevenueFieldProps<FieldType extends FieldValues> = {
  fieldName: string;
  selectFieldName: string;
  control: Control<FieldType>;
  options: LabelValue[];
  readOnly?: boolean;
  stringMode?: boolean;
  defaultError?: boolean;
  allowOnlyNumber?: boolean;
};

const RevenueField = <FieldType extends FieldValues>({
  fieldName,
  selectFieldName,
  control,
  options,
  readOnly,
  stringMode,
  defaultError = true,
  allowOnlyNumber = true,
}: RevenueFieldProps<FieldType>) => {
  const { t } = useTranslation([RESOURCES_KEYS.ERRORS_AND_NOTIFICATIONS]);
  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  return (
    <FormControl fullWidth>
      <RevenueInputStyled>
        <Controller
          name={fieldName as FieldPath<FieldType>}
          control={control}
          render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
            return readOnly ? (
              <ReadOnlyField {...fieldProps} />
            ) : (
              <div className="input-wrapper">
                <NumberInput
                  {...fieldProps}
                  stringMode={stringMode}
                  onKeyDown={
                    allowOnlyNumber
                      ? (event) => {
                          if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }
                      : void 0
                  }
                  onChange={onChange}
                />
                {error?.message?.trim() ? (
                  <Typography variant="caption" color={defaultError ? "error.main" : "text.warning"}>
                    {defaultError ? error.message : t("pleaseCheckCurrency")}
                  </Typography>
                ) : null}
              </div>
            );
          }}
        />
        <Controller
          name={selectFieldName as FieldPath<FieldType>}
          control={control}
          render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
            <Select
              {...fieldProps}
              {...(readOnlyProps as { disabled?: boolean })}
              onChange={onChange}
              fullWidth
              error={!!error}
              options={options}
            />
          )}
        />
      </RevenueInputStyled>
    </FormControl>
  );
};

export default RevenueField;
