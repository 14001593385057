import axios from "axios";
import type { publishingAdministratorForm } from "types";

export function postPublishing(publishing: publishingAdministratorForm) {
  return axios
    .create({
      baseURL: process.env.REACT_APP_API_V1,
    })
    .post("/publishing", { publishing });
}
