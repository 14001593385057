export const templateFonts = {
  "Open Sans": "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
  Montserrat: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap",
  Oregano: "https://fonts.googleapis.com/css2?family=Oregano:ital@0;1&display=swap",
  Inter: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
  Roboto:
    "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  "PT Serif": "https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap",
  "Playfair Display": "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap",
};

const templateFontsImport = Object.entries(templateFonts)
  .map(([, url]) => `@import url('${url}'); `)
  .sort()
  .join("");

export const insertHTMLContentIntoTemplate = (content: string) => {
  const template = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <style>${templateFontsImport}</style>
    </head>
        <body>
            ${content}
        </body>
    </html>
    `;

  return template;
};

export const extractBodyContent = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const body = doc.body;
  return body.innerHTML;
};
