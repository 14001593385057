import { ChangeEvent, useEffect, useState } from "react";
import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FormLabelWithHint from "../FormLabelWithHint";
import { useReadOnlyParams } from "../useReadOnlyParams";

import { CharLabelStyled, HelperTextContainerStyled, TextAreaFieldStyled } from "./styled";
import { TextAreaFieldType } from "./types";

const TextAreaField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  maxLength = 1000,
  labelHint,
  readOnly,
  hideCharCount,
  customCount,
  formatValueFunc,
  ...props
}: TextAreaFieldType<FieldType>) => {
  const { watch } = useFormContext();
  const [charCount, setCharCount] = useState(watch(fieldName)?.length || 0);

  useEffect(() => {
    const subscription = watch((value) => {
      const fieldValue = value[fieldName] || "";
      setCharCount(fieldValue.length);
    });

    return () => subscription.unsubscribe();
  }, [fieldName]);

  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  const handleInputChange =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let { value } = event.target;
      if (formatValueFunc) {
        value = formatValueFunc(value);
      }
      onChange(value);
    };

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabelWithHint label={label} labelHint={labelHint} /> : null}
            <TextAreaFieldStyled
              multiline
              {...fieldProps}
              {...props}
              {...(readOnlyProps as TextFieldProps)}
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange(onChange)}
              fullWidth
              helperText={
                <HelperTextContainerStyled>
                  {error?.message?.trim() ?? props.helperText}
                  {!readOnly && !hideCharCount ? (
                    <CharLabelStyled charCount={charCount} maxLength={maxLength}>
                      {customCount ?? (
                        <Typography variant="caption" whiteSpace="nowrap">
                          {charCount}
                          {" / "}
                          {maxLength}
                        </Typography>
                      )}
                    </CharLabelStyled>
                  ) : null}
                </HelperTextContainerStyled>
              }
              error={!!error}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default TextAreaField;
