import React, { FC } from "react";
import { useDropzone } from "react-dropzone";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { CheckGreenIcon } from "assets/icons/14px";
import { DownloadIcon, UploadIcon } from "assets/icons/24px";
import { convertToBytes } from "utils";

import {
  ActionContainerStyled,
  ActionOverlayStyled,
  BrowseFilesTextStyled,
  CloseIconSvgStyled,
  DeleteIconSvgStyled,
  DropZoneBoxStyled,
  ReuploadContainerStyled,
} from "./styled";
import { DropZoneProps } from "./types";

const DropZone: FC<DropZoneProps> = ({
  options,
  value,
  maxSizeLimit,
  dropFileText,
  readOnly,
  isDownloadAllowed,
  isDeleteAllowed,
  isDeletedState,
  handleDelete,
  handleDownload,
  onDrop,
}) => {
  const { getRootProps, getInputProps, open, isDragAccept, isFocused } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...options,
    disabled: options?.disabled || readOnly,
    maxSize: maxSizeLimit ? convertToBytes(maxSizeLimit[0], maxSizeLimit[1]) : void 0,
    onDrop,
  });

  return (
    <DropZoneBoxStyled
      {...getRootProps({ isDragAccept, isFocused, refKey: "innerRef" })}
      disabled={options?.disabled || readOnly}
    >
      <input {...getInputProps()} />
      {value ? (
        <ReuploadContainerStyled>
          <Typography variant="caption" color="text.success" fontWeight={700} display="flex" gap="4px">
            <span>Uploaded</span>
            <CheckGreenIcon />
          </Typography>
          {readOnly && (isDownloadAllowed || isDeleteAllowed) && (
            <ActionOverlayStyled>
              {isDownloadAllowed ? (
                <ActionContainerStyled>
                  <IconButton onClick={handleDownload} size="small">
                    <DownloadIcon />
                  </IconButton>
                  <Typography variant="caption" color="text.header">
                    Download the File
                  </Typography>
                </ActionContainerStyled>
              ) : null}
              {isDeleteAllowed ? (
                <ActionContainerStyled>
                  <IconButton onClick={handleDelete} size="small">
                    <DeleteIconSvgStyled />
                  </IconButton>
                  <Typography variant="caption" color="text.header">
                    Remove the File
                  </Typography>
                </ActionContainerStyled>
              ) : null}
            </ActionOverlayStyled>
          )}
          {!isDragAccept && !readOnly ? (
            <ActionOverlayStyled>
              <IconButton onClick={open} size="small">
                <UploadIcon />
              </IconButton>
              <Typography variant="caption" color="text.header">
                Update the File
              </Typography>
            </ActionOverlayStyled>
          ) : null}
        </ReuploadContainerStyled>
      ) : isDeletedState ? (
        <Typography variant="caption" color="text.error" fontWeight={700} display="flex" gap="4px">
          <span>Removed</span>
          <CloseIconSvgStyled />
        </Typography>
      ) : (
        <>
          <Typography variant="caption" color="text.label">
            {dropFileText ?? "Drop your file"}
          </Typography>
          <Typography variant="caption" color="text.label">
            or
          </Typography>
          <BrowseFilesTextStyled variant="caption" color="primary.main" onClick={readOnly ? void 0 : open}>
            click to browse files.
          </BrowseFilesTextStyled>
        </>
      )}
    </DropZoneBoxStyled>
  );
};

export default DropZone;
