import pick from "lodash/pick";

import { WorksForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import { defaultValues } from "../WorkForm/constants";

export const getFormData = (rowData: WorksForm) => {
  return replaceEmptyAndUndefinedWithNull(pick(rowData, Object.keys(defaultValues)));
};
