import isNill from "lodash/isNil";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";

import { ApiKeyFields, UserArtist } from "types";
import { formatNumberLocale } from "utils";

const artistDataFields = [
  ApiKeyFields.administratorName,
  ApiKeyFields.annualRevenue,
  ApiKeyFields.annualRevenueCurrency,
  ApiKeyFields.agreementExpiresAt,
  ApiKeyFields.bio,
  ApiKeyFields.coveredSongs,
  ApiKeyFields.recordingLanguage,
  ApiKeyFields.referredBy,
  ApiKeyFields.songwriterName,
  ApiKeyFields.spotifyArtistId,
  ApiKeyFields.youtubeChannelId,
  ApiKeyFields.usedSongs,
  ApiKeyFields.artistName,
  ApiKeyFields.websiteUrl,
  ApiKeyFields.tiktokUrl,
  ApiKeyFields.instagramUrl,
  ApiKeyFields.facebookUrl,
  ApiKeyFields.twitterHandle,
  ApiKeyFields.residenceCountry,
  ApiKeyFields.spotifyMonthlyListeners,
];

export const prepareQualificationFormData = (user: UserArtist) => {
  const { artists, ...data } = user;
  const artistData = pick(artists[0], artistDataFields);

  return mapValues(
    {
      ...data,
      ...artistData,
      [ApiKeyFields.hasMultipleChurches]: Boolean(artistData[ApiKeyFields.usedSongs]),
      [ApiKeyFields.haveCoveredSongs]: Boolean(artistData[ApiKeyFields.coveredSongs]),
      [ApiKeyFields.hasAdministrator]: Boolean(
        artistData[ApiKeyFields.administratorName] || artistData[ApiKeyFields.agreementExpiresAt],
      ),
      [ApiKeyFields.spotifyMonthlyListeners]: formatNumberLocale(artistData[ApiKeyFields.spotifyMonthlyListeners]),
    },
    (value) => (isNill(value) ? "—" : value),
  );
};
