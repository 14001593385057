import { FC, ReactNode } from "react";

import Grid from "@mui/material/Grid";

import { FormContainerStyled } from "../FormContainerStyled";

const FormGrid: FC<{
  children: ReactNode;
  leftSideGrid?: ReactNode;
  rightSideGrid?: ReactNode;
  isRegistrationFlow?: boolean;
}> = ({ children, leftSideGrid, rightSideGrid, isRegistrationFlow }) => {
  return (
    <>
      <Grid container spacing={2} flex={1} justifyContent="center" position="relative">
        {leftSideGrid}
        <Grid item xs={isRegistrationFlow ? 8 : 10} md={isRegistrationFlow ? 8 : 10} lg={8}>
          <FormContainerStyled isRegistrationFlow={isRegistrationFlow}>{children}</FormContainerStyled>
        </Grid>
        {rightSideGrid}
      </Grid>
    </>
  );
};

export default FormGrid;
