import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import DragHandle from "./DragHandle";

interface ColumnDefinition<T> {
  field: keyof T;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  renderCell?: (params: { value: any; row: T }) => React.ReactNode;
  align?: "left" | "right" | "center";
  minWidth?: number;
}

interface RowWithId {
  id: string;
}

interface DraggableGridRowProps<T extends RowWithId> {
  row: T;
  columns: Array<ColumnDefinition<T>>;
  isDragEnabled?: boolean;
}

const StyledTableRow = styled(TableRow)`
  &:hover {
    .hiddenElement {
      display: flex;
    }
  }

  .MuiTableCell-root {
    padding: 0 16px;
  }
`;

const DraggableGridRow = <T extends RowWithId>({ row, columns, isDragEnabled = true }: DraggableGridRowProps<T>) => {
  const { attributes, setNodeRef, transform, transition, listeners } = useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <StyledTableRow ref={setNodeRef} style={style} {...attributes}>
      {isDragEnabled && (
        <TableCell {...listeners}>
          <DragHandle id={row.id} />
        </TableCell>
      )}
      {columns.map((column) => (
        <TableCell
          key={String(column.field)}
          align={column.align || "left"}
          style={{ minWidth: column.minWidth, height: "50px" }}
        >
          {column.renderCell ? column.renderCell({ value: row[column.field], row }) : (row[column.field] as React.ReactNode)}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

export default DraggableGridRow;
