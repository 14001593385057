import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import Typography from "@mui/material/Typography";

import { RESOURCES_KEYS } from "i18n";

import { handleNavLinkClick } from "./helpers";
import StepStatusIcon from "./StepStatusIcon";
import {
  ActionBlock,
  DescriptionBlock,
  IconButtonStyled,
  NavigationBlock,
  NavigationBlockContainer,
  NavLinkStyled,
  StepBlock,
  StepsContainerStyled,
  TypographyTitleStyled,
} from "./styled";
import { ProgressBarProps } from "./types";

const ProgressBar: FC<ProgressBarProps> = ({ config, comments = [], isClientReview }) => {
  const { t } = useTranslation([RESOURCES_KEYS.REGISTRATION]);
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <StepsContainerStyled>
      {config.map(({ title, subRoutes, description }, index: number) => (
        <StepBlock key={title}>
          <TypographyTitleStyled>
            {index + 1} {t(title)}
          </TypographyTitleStyled>
          <ActionBlock>
            <NavigationBlock>
              {subRoutes.map(({ route, fields, stepTitle, subRouteConfig }) => (
                <NavigationBlockContainer key={route}>
                  <NavLinkStyled to={route} end onClick={(event) => handleNavLinkClick(event, errors)}>
                    <IconButtonStyled size="small">
                      <StepStatusIcon
                        fields={fields}
                        comments={comments}
                        subRouteConfig={subRouteConfig}
                        isClientReview={isClientReview}
                      />
                    </IconButtonStyled>
                  </NavLinkStyled>
                  {stepTitle ? (
                    <Typography variant="caption" color="text.label">
                      {t(stepTitle)}
                    </Typography>
                  ) : null}
                </NavigationBlockContainer>
              ))}
            </NavigationBlock>
            {description ? (
              <DescriptionBlock>
                <Typography variant="caption" color="text.label">
                  {t(description)}
                </Typography>
              </DescriptionBlock>
            ) : null}
          </ActionBlock>
        </StepBlock>
      ))}
    </StepsContainerStyled>
  );
};

export default observer(ProgressBar);
