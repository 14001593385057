import { GridColDef, GridSortModel } from "@mui/x-data-grid";

import { TableClipboardCol } from "components/Table";
import { EN_DATE_FORMAT } from "constants/options";
import { ApiKeyFields, User, UserArtist, UserListResponse } from "types";
import { getUserName, utcToFormat } from "utils";

import { AdminTableAction } from "../Components";

export const defaultSortData = {
  user_order_by_field_name: "created_at",
  user_order: "desc",
};

export const defaultSortModel: GridSortModel = [
  {
    field: ApiKeyFields.createdAt,
    sort: "desc",
  },
];

export const commonTableData = (): UserListResponse => ({
  numUsers: 0,
  users: [],
});

export const defaultValues = {
  [ApiKeyFields.user_is_active]: true,
  [ApiKeyFields.createdAt]: null,
};

type ColumnProps = {
  currentUser: User | null;
  handleUserStatusChange: (user: UserArtist) => void;
};

export const getColumns = ({ currentUser, handleUserStatusChange }: ColumnProps): GridColDef[] => [
  {
    field: ApiKeyFields.firstName,
    headerName: "Admin Name",
    valueGetter: ({ row }) => {
      return getUserName(row);
    },
    renderCell: ({ value }) => <TableClipboardCol text={value} />,
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.email,
    headerName: "Admin Email",
    renderCell: ({ value }) => <TableClipboardCol text={value} />,
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.createdAt,
    headerName: "Created",
    valueGetter: ({ value }) => {
      return utcToFormat(value, EN_DATE_FORMAT);
    },
    renderCell: ({ value }) => <TableClipboardCol text={value} />,
    sortable: false,
    width: 136,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.isActive,
    sortable: false,
    width: 40,
    type: "actions",
    getActions: (params) => [
      <AdminTableAction
        key={params.id}
        handleActivateClick={() => handleUserStatusChange(params.row as UserArtist)}
        row={params.row as UserArtist}
        currenUser={currentUser}
      />,
    ],
    disableColumnMenu: true,
  },
];
