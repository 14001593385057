import { FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useStores } from "stores";

import Typography from "@mui/material/Typography";

import { ConfirmModal } from "components";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { PrimaryFormNames, SecondaryFormId, SecondaryFormNames, WorksForm } from "types";

import AddWork from "./AddWork";
import Work from "./Work";

export const useWorks = () => {
  const {
    revisedFormStore: {
      works: { isLoading },
    },
    uiStore: { openModal, setOverlayLoading, resetLoading },
  } = useStores();
  const { fetchWorksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const form = useFormContext<{ works: WorksForm[] }>();
  const { control, setValue } = form;
  const { fields } = useFieldArray({
    control,
    name: "works",
    keyName: "_id",
  });

  const handleWorksArrayChange = (data: WorksForm) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [data, ...fields];
    setValue("works", newArray, { shouldDirty: false });
  };

  const handleOpenWorkClick = (work: WorksForm) => {
    openModal({
      component: (modalProps) => <Work {...modalProps} work={work} successCb={handleWorksArrayChange} />,
      fullScreen: true,
      disableEscapeKeyDown: true,
    });
  };

  const handleAddWorkClick = () => {
    openModal({
      component: (modalProps) => (
        <FormProvider {...form}>
          <AddWork {...modalProps} successCallback={handleWorksArrayChange} />
        </FormProvider>
      ),
    });
  };

  const handleDeleteWork = async (record: WorksForm, closeModal: () => void) => {
    if (releaseId) {
      try {
        setOverlayLoading(true);
        const requestParams: SecondaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          primaryFormId: releaseId,
          secondaryFormName: SecondaryFormNames.ReleaseWorks,
          secondaryFormId: record.id as string,
        };
        await revisedFormService.deleteSecondaryFormId(requestParams);
        await fetchWorksComments();
        closeModal();
        const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
        setValue("works", updatedArray, { shouldDirty: false });
      } finally {
        resetLoading();
      }
    }
  };

  const handleDeleteClick = (record: WorksForm) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the composition?"
          text={
            <Typography variant="body1">
              Remove <b>{record.title}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteWork(record, modalProps.closeModal)}
        />
      ),
    });
  };

  return {
    fields,
    isLoading,
    handleOpenWorkClick,
    handleAddWorkClick,
    handleDeleteClick,
    handleWorksArrayChange,
  };
};
