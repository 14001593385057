import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import find from "lodash/find";
import { useStores } from "stores";

import { ProgressBarConfigProps } from "components/FormProgressBar";
import { ROUTES } from "constants/routes";
import { ApiKeyFields, Notification } from "types";

import { buildStateWithParams } from "./helpers";

export const useFieldRoute = () => {
  const {
    notificationStore: { deleteCurrentNotification },
    uiStore: { closeDrawer },
  } = useStores();
  const navigate = useNavigate();

  const handleNotificationAction = useCallback(
    async (progressBarConfig: ProgressBarConfigProps[], notification: Notification) => {
      let route: string | null = null;
      let stateParams: Record<string, string> = {};

      if (notification[ApiKeyFields.releaseId]) {
        if (notification[ApiKeyFields.releaseWorkId]) {
          route = ROUTES.CLIENT_DRAFT_RELEASE_ID_WORKS(notification.releaseId!);
          stateParams = buildStateWithParams(notification, [
            ApiKeyFields.releaseWorkId,
            ApiKeyFields.releaseSongwriterId,
            ApiKeyFields.releasePublisherId,
          ]);
        } else if (notification[ApiKeyFields.releaseTrackId]) {
          route = ROUTES.CLIENT_DRAFT_RELEASE_ID_TRACKS(notification.releaseId!);
          stateParams = buildStateWithParams(notification, [
            ApiKeyFields.releaseTrackId,
            ApiKeyFields.releaseArtistId,
            ApiKeyFields.releaseContributorId,
          ]);
        }
      }

      if (!route) {
        const foundRoute = progressBarConfig.find((configItem) =>
          configItem.subRoutes.some((subRoute) =>
            subRoute.fields.some((fieldItem) => fieldItem.field === notification.fieldName),
          ),
        );

        if (foundRoute) {
          const matchedSubRoute = find(foundRoute.subRoutes, (subRoute) =>
            subRoute.fields.some((fieldItem) => fieldItem.field === notification.fieldName),
          );
          if (matchedSubRoute) {
            route = matchedSubRoute.route;
            stateParams = {};
          }
        }
      }

      if (route) {
        navigate(route, { state: stateParams });
        deleteCurrentNotification(notification.id);
        closeDrawer();
      }
    },
    [navigate, deleteCurrentNotification, closeDrawer],
  );

  return { handleNotificationAction };
};
