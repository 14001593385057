import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

import { ClientGenreTerritory, ClientReleaseDate, ClientTitleCoverArt, ClientTracks, ClientWorks } from "./Pages";
import Release from "./Release";

export const ClientReleaseFormRoute: RouteObject[] = [
  {
    path: ROUTES.CLIENT_DRAFT_RELEASE_ID(),
    element: <Release />,
    children: [
      {
        index: true,
        element: <ClientTitleCoverArt />,
      },
      {
        path: ROUTES.GENRE_ROUTE,
        element: <ClientGenreTerritory />,
      },
      {
        path: ROUTES.WORKS_ROUTE,
        element: <ClientWorks />,
      },
      {
        path: ROUTES.TRACKS_ROUTE,
        element: <ClientTracks />,
      },
      {
        path: ROUTES.RELEASE_DATE_ROUTE,
        element: <ClientReleaseDate />,
      },
    ],
  },
];
