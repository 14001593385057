import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ReleaseTableListBlock } from "components";
import { LabelWithAsterisk } from "components";
import DndMuiTable from "components/DndMuiTable";
import { FooterSplitTotal, usePrimaryFormState } from "components/ReleaseForm";
import { Publishers } from "types";

import { getColumns } from "./columns";
import { PUBLISHERS_MAX_LIMIT } from "./constants";
import { PublishersBlockProps } from "./types";
import { usePublishers } from "./usePublishers";

const PublishersBlock: FC<PublishersBlockProps> = ({ work }) => {
  const {
    revisedFormStore: {
      comments: { tertiaryComments },
    },
  } = useStores();
  const {
    fields,
    // publishersLoading,
    handleChangeRows,
    handleAddPublisher,
    handleEditPublisher,
    handleDeleteClick,
    handleCopyPublisher,
  } = usePublishers(work);

  const getRowComments = (publisher: Publishers) =>
    tertiaryComments.filter(({ releasePublisherId }) => releasePublisherId === publisher.id);

  const { isReadOnly } = usePrimaryFormState();
  const columns = useMemo(
    () => getColumns({ onEditClick: handleEditPublisher, onDeleteClick: handleDeleteClick, getRowComments, isReadOnly }),
    [handleEditPublisher, handleDeleteClick],
  );

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <ReleaseTableListBlock
      title={<LabelWithAsterisk>Publisher(s)</LabelWithAsterisk>}
      itemsCount={itemsCount}
      maxLimit={PUBLISHERS_MAX_LIMIT}
      onAddClick={handleAddPublisher}
      addBtnText="Add Publisher"
      onCopyClick={handleCopyPublisher}
      copyBtnText="Copy Publisher"
      hideActionBtn={isReadOnly}
    >
      <DndMuiTable
        rows={fields}
        columns={columns}
        onOrderChange={handleChangeRows}
        isDragEnabled={!isReadOnly}
        emptyText="There are no publisher(s) added."
        footer={<FooterSplitTotal fields={fields} columns={columns} isDragEnabled={!isReadOnly} />}
      />
    </ReleaseTableListBlock>
  );
};

export default observer(PublishersBlock);
