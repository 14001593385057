import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import pickBy from "lodash/pickBy";

import { WorksForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils/transformObjects";

export const prepareRequest = (formData: WorksForm, dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>) => {
  const filteredData = pickBy(formData, (_, key) => dirtyFields[key]);
  return replaceEmptyAndUndefinedWithNull(filteredData);
};
