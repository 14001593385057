import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import some from "lodash/some";

import Grid from "@mui/material/Grid";

import { FormBlock, FormBlockGridGap, SelectField, TextAreaField, TextFieldStyled } from "components";
import { FormGridFieldItem, FormGridItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { MUSIC_GENRE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT, OPTIONAL_FIELDS, TEXT_AREA_MAX_LENGTH } from "./constants";
import { getSubGenres } from "./helpers";

const GenreTerritory: FC<StepFlowControllerProps> = (props) => {
  const { control, watch, setValue } = useFormContext();

  const [watchGenre, watchSubGenre] = watch([ApiKeyFields.genre, ApiKeyFields.sub_genre]);
  const [subGenreOptions, setSubGenreOptions] = useState<LabelValue[]>([]);

  useEffect(() => {
    if (watchGenre) {
      const options = getSubGenres(watchGenre);
      setSubGenreOptions(options);
      setValue(ApiKeyFields.sub_genre, some(options, { value: watchSubGenre }) ? watchSubGenre : "", {
        shouldValidate: true,
        shouldDirty: false,
      });
    } else {
      setSubGenreOptions([]);
    }
  }, [watchGenre]);

  const isSubGenreLength = !subGenreOptions.length;

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.genre]} fieldName={ApiKeyFields.genre}>
            {(field) => <SelectField {...field} control={control} options={MUSIC_GENRE_OPTIONS} />}
          </FormGridFieldItem>
          <FormGridFieldItem
            label={FORM_LABELS[ApiKeyFields.sub_genre]}
            fieldName={ApiKeyFields.sub_genre}
            disabled={isSubGenreLength}
            isOptionalField={OPTIONAL_FIELDS[ApiKeyFields.sub_genre]}
          >
            {(field) => <SelectField {...field} control={control} options={subGenreOptions} disabled={isSubGenreLength} />}
          </FormGridFieldItem>
          <FormGridItem label={FORM_LABELS[ApiKeyFields.territory]} isOptionalField>
            <TextFieldStyled disabled value="Worldwide" variant="filled" />
          </FormGridItem>
          <FormGridFieldItem
            label={FORM_LABELS[ApiKeyFields.territory_notes]}
            helperText={HELPER_TEXT[ApiKeyFields.territory_notes]}
            fieldName={ApiKeyFields.territory_notes}
            isOptionalField={OPTIONAL_FIELDS[ApiKeyFields.territory_notes]}
          >
            {(field) => <TextAreaField {...field} control={control} rows={5} maxLength={TEXT_AREA_MAX_LENGTH} />}
          </FormGridFieldItem>
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default GenreTerritory;
