import React from "react";
import { DefaultValues, FieldValues, FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodSchema } from "zod";

import FormBar from "components/FormComponents/FormBar";
import { PageContainer } from "components/Layout";
import PageLeaveGuard from "components/PageLeaveGuard";

interface GenericFormProps<T extends FieldValues> {
  defaultValues: DefaultValues<T>;
  schema: ZodSchema;
  titleKey: string;
  resourceKey: string;
  onSubmit: (form: UseFormReturn<T>) => (formData: T) => Promise<void>;
  onBackClick?: () => void;
  submitBtnText?: string;
  formRoutes?: string[];
  children?: React.ReactNode;
}

const GenericForm = <T extends FieldValues>({
  defaultValues,
  schema,
  titleKey,
  resourceKey,
  submitBtnText,
  formRoutes,
  onSubmit,
  onBackClick,
}: GenericFormProps<T>) => {
  const { t } = useTranslation([resourceKey]);
  const navigate = useNavigate();

  const form = useForm<T>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const backClickHandler = onBackClick || (() => navigate(-1));

  return (
    <FormProvider {...form}>
      {formRoutes ? <PageLeaveGuard formRoutes={formRoutes} /> : null}
      <PageContainer>
        <FormBar
          title={t(titleKey)}
          onSubmit={form.handleSubmit(onSubmit(form))}
          handleBackClick={backClickHandler}
          submitBtnText={submitBtnText}
        />
        <Outlet />
      </PageContainer>
    </FormProvider>
  );
};

export default GenericForm;
