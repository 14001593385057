import styled from "@emotion/styled";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export const FormAccordionStyled = styled(Accordion)`
  box-shadow: none;
`;

export const FormAccordionSummaryStyled = styled(AccordionSummary)`
  min-height: 56px;
  gap: 5px;
  border-radius: 8px;
  padding: 0 8px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.surface};
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 13px 12px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const FormAccordionDetailsStyled = styled(AccordionDetails)`
  padding: 17px 20px;
`;
