import { action, computed, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { userService } from "services";
import { UserArtist, UserByIdResponse } from "types";

export class QualificationStore {
  @observable qualificationForm: UserArtist | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @flow.bound
  *retrieveQualificationFormById(id: string): MobxGenerator<UserByIdResponse> {
    const { user } = yield userService.getUserById(id);
    this.qualificationForm = user;
  }

  @action
  toggleUserIsActive = (isActive: boolean) => {
    if (!this.qualificationForm) {
      return;
    }
    this.qualificationForm = { ...this.qualificationForm, isActive };
  };

  @flow.bound
  *manualQualification(id: string) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield Promise.all([this.retrieveQualificationFormById(id)]);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @action.bound
  resetQualificationForm = () => {
    this.qualificationForm = null;
  };

  @computed
  get formStatus() {
    return this.qualificationForm?.status || null;
  }
}
