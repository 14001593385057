import * as zod from "zod";

import {
  MAX_LENGTH_INPUT,
  MIN_LEN_ERR_TEXT,
  MIN_LEN_NUM_ERR_TEXT,
  NUMBER_POSITIVE_TEXT,
  NUMBER_TWO_DECIMALS_LIMIT_TEXT,
  SELECT_ERR_TEXT,
} from "constants/validation";
import { ApiKeyFields } from "types";

import { emailRefineSchema } from "../../AddEditForm/validation";

const commonSchema = zod.object({
  [ApiKeyFields.name]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.rightsAdministrator]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.affiliation]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.country]: zod.string({ invalid_type_error: SELECT_ERR_TEXT }).min(1, { message: SELECT_ERR_TEXT }),
  [ApiKeyFields.split]: zod
    .number({ required_error: NUMBER_POSITIVE_TEXT, invalid_type_error: NUMBER_POSITIVE_TEXT })
    .positive()
    .max(100)
    .refine(
      (val) => {
        return /^\d+(\.\d{1,2})?$/.test(val.toString());
      },
      {
        message: NUMBER_TWO_DECIMALS_LIMIT_TEXT,
      },
    ),
});

export const schema = zod.intersection(commonSchema, emailRefineSchema);
