import axios from "axios";
import type { LabelForm } from "types";

export function postLabel(label: LabelForm) {
  return axios
    .create({
      baseURL: process.env.REACT_APP_API_V1,
    })
    .post("/labels", { label });
}
