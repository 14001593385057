import { ApiKeyFields } from "types";

export const sortingMapping: Partial<Record<ApiKeyFields, string>> = {
  [ApiKeyFields.firstName]: "first_name",
  [ApiKeyFields.email]: "email",
  [ApiKeyFields.distributionType]: "distribution_type",
  [ApiKeyFields.distributionTier]: "distribution_tier",
  [ApiKeyFields.publishingType]: "publishing_type",
  [ApiKeyFields.status]: "status",
  [ApiKeyFields.qualifiedAt]: "qualified_at",
  [ApiKeyFields.artistName]: "artist_name",
  [ApiKeyFields.songwriterName]: "songwriter_name",
  [ApiKeyFields.createdAt]: "created_at",
};
