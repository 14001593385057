import React from "react";
import { Control, Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextFieldProps } from "@mui/material/TextField";

import { TextFieldStyled } from "components/FormControls";

import ClearInputAdornment from "../ClearInputAdornment";
import { useReadOnlyParams } from "../useReadOnlyParams";

type TextFieldType<FieldType extends FieldValues> = TextFieldProps & {
  fieldName: string;
  control: Control<FieldType>;
  disableClear?: boolean;
  readOnly?: boolean;
};

const TextField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  readOnly,
  disableClear,
  ...props
}: TextFieldType<FieldType>) => {
  const { setValue } = useFormContext();

  const handleClearClick = () => {
    setValue(fieldName, "", { shouldDirty: true, shouldValidate: true });
  };

  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <TextFieldStyled
              fullWidth
              {...fieldProps}
              {...props}
              {...(readOnlyProps as TextFieldProps)}
              helperText={error?.message?.trim() ?? props.helperText}
              InputProps={{
                endAdornment:
                  fieldProps.value?.length && !disableClear ? (
                    <ClearInputAdornment handleClearClick={handleClearClick} isReadOnly={readOnly} />
                  ) : null,
                ...props.InputProps,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
              error={!!error}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default TextField;
