import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Divider from "@mui/material/Divider";

import { FormBarContainer, HeaderContainer, HeaderDetailInfo } from "components/FormComponents";
import { EN_DATE_FORMAT } from "constants/options";
import { ROUTES } from "constants/routes";
import { DistributionTier } from "types";
import { getUserName, utcToFormat } from "utils";

import { ToggleUserActive } from "../../Components";

const Header: FC = () => {
  const {
    qualificationStore: { qualificationForm },
  } = useStores();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(ROUTES.ACCOUNTS);
  };

  const userName = useMemo(() => (qualificationForm ? getUserName(qualificationForm) : ""), [qualificationForm]);

  return (
    <FormBarContainer title={userName} handleBackClick={handleBackClick} noMarginBottom>
      <HeaderContainer>
        {qualificationForm ? (
          <>
            <HeaderDetailInfo
              title="Agreement End"
              value={
                qualificationForm.distributionContracts[0]?.csEndsAt
                  ? utcToFormat(qualificationForm.distributionContracts[0]?.csEndsAt, EN_DATE_FORMAT)
                  : "—"
              }
            />
            <HeaderDetailInfo
              title="Tier"
              value={qualificationForm.distributionTier ? DistributionTier[qualificationForm.distributionTier] : "-"}
            />
          </>
        ) : null}
        <Divider orientation="vertical" />
        <ToggleUserActive />
      </HeaderContainer>
    </FormBarContainer>
  );
};

export default observer(Header);
