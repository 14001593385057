import React from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import MuiDrawer from "@mui/material/Drawer";

const Drawer = () => {
  const {
    uiStore: { isDrawerOpen, drawerProps, closeDrawer },
  } = useStores();

  if (!isDrawerOpen || !drawerProps?.component) {
    return null;
  }

  const { component: ModalContent, ...props } = drawerProps;

  return (
    <MuiDrawer {...props} open={isDrawerOpen} anchor={drawerProps.anchor || "right"} onClose={closeDrawer}>
      <ModalContent />
    </MuiDrawer>
  );
};

export default observer(Drawer);
