import { FORM_LABELS as AGREEMENT_INFORMATION_FORM_LABELS } from "components/RegistrationForm/AgreementInformation/constants";
import { FORM_LABELS as DISTRIBUTION_CATALOG_FORM_LABELS } from "components/RegistrationForm/ArtistCatalog/DistributionCatalog/constants";
import { FORM_LABELS as ADDRESS_SIDEBAR_FORM_LABELS } from "components/RegistrationForm/ClientInformation/Address/constants";
import { FORM_LABELS as CONTACT_SIDEBAR_FORM_LABELS } from "components/RegistrationForm/ClientInformation/Contact/constants";
import { FORM_LABELS as DISTRIBUTION_INFORMATION_FORM_LABELS } from "components/RegistrationForm/DistributionInformation/constants";

export const progressBarFieldLabels = {
  ...DISTRIBUTION_CATALOG_FORM_LABELS,
  ...ADDRESS_SIDEBAR_FORM_LABELS,
  ...CONTACT_SIDEBAR_FORM_LABELS,
  ...AGREEMENT_INFORMATION_FORM_LABELS,
  ...DISTRIBUTION_INFORMATION_FORM_LABELS,
};
