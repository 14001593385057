import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";

type FabLinkProps = {
  disabled?: boolean;
  text?: string;
  onClick?: () => void;
  children?: ReactNode;
};

const LinkStyled = styled.div<{ disabled?: boolean }>`
  padding: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;
  min-height: 160px;
  height: calc(100vh - 297px);
  position: fixed;
  bottom: 76px;

  .MuiTypography-root {
    margin-top: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  ${({ disabled, theme }) => disabled && `pointer-events: none; .MuiTypography-root{ color: ${theme.palette.text.disabled} } `}
`;

const FormFab: FC<FabLinkProps> = ({ disabled, onClick, text, children }) => {
  return (
    <LinkStyled onClick={onClick} disabled={disabled}>
      <div>
        <Fab color="primary" disabled={disabled}>
          {children}
        </Fab>
        <Typography color="text.label">{text}</Typography>
      </div>
    </LinkStyled>
  );
};

export default FormFab;
