import { useMemo } from "react";
import { useStores } from "stores";

import { DistributionType, PublishingType, UserStatus } from "types";

export const useUserPermissions = () => {
  const {
    userStore: { user },
  } = useStores();

  return useMemo(() => {
    if (!user || !user.status) return [];
    const permissions: string[] = [UserStatus[user.status]];
    if (user.status === UserStatus.INITIAL) {
      if (user.distributionType) {
        permissions.push(DistributionType[user.distributionType]);
      }
      if (user.publishingType) {
        permissions.push(PublishingType[user.publishingType]);
      }
    }
    return permissions;
  }, [user]);
};
