import { action, computed, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { userService } from "services";
import { DistributionType, PublishingType, User } from "types";

type UserUpdateData = Partial<User>;

export class UserStore {
  @observable user: User | null = null;
  @observable isTaCRequired: boolean = false;
  @observable isLoading: boolean = true;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  retrieveUser = async () => {
    try {
      const { user } = await userService.getCurrent();
      this.setUser(user);
    } catch (error) {
      const axiosError = error as AxiosApiError;
      if (axiosError.response?.data.errorCode === "UNACCEPTED_CURRENT_USER") {
        this.isTaCRequired = true;
      }
    } finally {
      this.setLoading(false);
    }
  };

  updateUser = (user: UserUpdateData) => {
    if (!this.user) return;
    this.user = { ...this.user, ...user };
  };

  @flow.bound
  *patchUser(user: UserUpdateData) {
    this.rootStore.uiStore.setOverlayLoading(true);
    yield userService.patchCurrent({ user });
    this.updateUser(user);
  }

  setDistribution = (distributionType: DistributionType | null) => {
    if (!this.user) return;
    this.user = { ...this.user, distributionType };
  };

  setPublishing = (publishingType: PublishingType | null) => {
    if (!this.user) return;
    this.user = { ...this.user, publishingType };
  };

  setDistributionAndPublishing = ({
    distributionType = null,
    publishingType = null,
  }: {
    distributionType?: DistributionType | null;
    publishingType?: PublishingType | null;
  }) => {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      this.patchUser({
        distributionType,
        publishingType,
      });
      this.updateUser({ distributionType, publishingType });
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  };

  @action
  setUser = (user: User) => {
    this.user = user;
  };

  @action
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @computed
  get isAdmin() {
    return this.user?.isAdmin;
  }

  @computed
  get userStatus() {
    return this.user?.status;
  }
}
