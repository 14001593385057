import styled from "@emotion/styled";

export const DrawerTitleStyled = styled.div`
  padding: 23px 32px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const NotificationsContainerStyled = styled.div`
  height: 100%;
  overflow: auto;
  padding: 16px 18px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LoaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 0;
`;

export const EmptyNotificationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
