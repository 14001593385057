import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { SelectProps } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { Select } from "components/FormControls";
import { LAYOUT } from "theme";

import { useReadOnlyParams } from "../useReadOnlyParams";

type SelectFieldType<FieldType extends FieldValues> = SelectProps<string | number> & {
  fieldName: string;
  control: Control<FieldType>;
  options: LabelValue<string | number>[];
  readOnly?: boolean;
};

const SelectField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  options,
  readOnly,
  ...props
}: SelectFieldType<FieldType>) => {
  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label ? <FormLabel>{label}</FormLabel> : null}
          <Select
            value={value || ""}
            {...fieldProps}
            MenuProps={{
              slotProps: {
                paper: { sx: { maxHeight: LAYOUT.DROPDOWN_MAX_HEIGHT } },
              },
            }}
            {...props}
            {...(readOnlyProps as SelectProps<string | number>)}
            ref={fieldProps.ref}
            onChange={onChange}
            options={options}
            error={!!error}
          />
          {error?.message?.trim() ? (
            <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
              {error?.message}
            </Typography>
          ) : null}
        </FormControl>
      )}
    />
  );
};

export default SelectField;
