import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiCheckbox: Components["MuiCheckbox"] = {
  defaultProps: {
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "inherit",
        svg: {
          rect: {
            fill: colors.white,
          },
        },
        ":hover": {
          backgroundColor: "inherit",
        },
        "&.Mui-checked": {
          svg: {
            rect: {
              stroke: colors.gray749,
            },
            "g > path": {
              fill: colors.gray749,
            },
          },
        },
      },
    },
  },
};
