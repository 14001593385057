import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="fixed">
      <Typography variant="body1" flex={1}>
        Please make sure that the cover file you use meets the following requirements:
      </Typography>
      <ul>
        {[
          "Format: TIFF (lossless LZW compressed)",
          "8-bit RGB (no CMYK, spot colors, alpha channel(s), or layers)",
          "Dimensions: Minimum 1400px wide by 1400px high, maximum 4000px wide by 4000px high",
          "Aspect Ratio: 1:1",
          "Color Profile: Color space and embedded color profile must match",
        ].map((text) => (
          <li key={text}>
            <Typography variant="body1">{text}</Typography>
          </li>
        ))}
      </ul>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
