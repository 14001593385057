import React, { useContext } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Outlet } from "react-router-dom";

import { DistributionInfoForm } from "types/revisedForms";

export type MultiStepForm = {
  distributionInfoForm?: UseFormReturn<DistributionInfoForm>;
};

export const MultiStepFormContext = React.createContext<MultiStepForm | null>(null);

export const RegistrationFormsProvider = ({ children, distributionInfoForm }: MultiStepForm & { children: React.ReactNode }) => {
  return <MultiStepFormContext.Provider value={{ distributionInfoForm }}>{children}</MultiStepFormContext.Provider>;
};

export const DistributionInfoFormProvider = () => {
  const forms = useContext(MultiStepFormContext);

  if (!forms?.distributionInfoForm) {
    return null;
  }

  return (
    <FormProvider {...forms.distributionInfoForm}>
      <Outlet />
    </FormProvider>
  );
};
