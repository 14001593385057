import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import WelcomePage1x from "assets/images/1x/Illustration_Welcome-Page1x.jpg";
import WelcomePage2x from "assets/images/2x/Illustration_Welcome-Page2x.png";
import { ProgressiveImage } from "components";
import { RESOURCES_KEYS } from "i18n";

const SideBarTextStyled = styled.div`
  position: absolute;
  padding: 20px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  bottom: 0;
  background-color: rgba(20, 24, 26, 0.5);
`;

const WelcomeContainerStyled = styled(Container)`
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: rgba(20, 24, 26);
  position: relative;
`;

const WelcomePageImgStyled = styled.img`
  width: 100%;
`;

const WelcomeSidebar: FC = () => {
  const { t } = useTranslation([RESOURCES_KEYS.WELCOME, RESOURCES_KEYS.COMMON]);

  return (
    <WelcomeContainerStyled disableGutters>
      <ProgressiveImage placeholder={WelcomePage1x} src={WelcomePage2x}>
        {(src) => <WelcomePageImgStyled src={src} alt="Welcome" />}
      </ProgressiveImage>
      <SideBarTextStyled>
        <Typography variant="h2">{t("welcome.side.title")}</Typography>
        <Typography variant="body1">{t("welcome.side.subtitle")}</Typography>
      </SideBarTextStyled>
      <Box flex={1} alignItems="center" display="flex" />
    </WelcomeContainerStyled>
  );
};

export default WelcomeSidebar;
