import { FC } from "react";
import styled from "@emotion/styled";
import DatePickerAnt, { RangePickerProps } from "antd/lib/date-picker";

import { CalendarIcon, CloseIcon } from "assets/icons/24px";

const { RangePicker: RangePickerAnt } = DatePickerAnt;

export const RangePickerStyled = styled(RangePickerAnt)(
  ({ theme }) => `
  &.ant-picker-focused {
    border-width: 2px;
  }

  svg path { fill: ${theme.palette.icon} }
`,
);

const RangePicker: FC<RangePickerProps> = (props) => {
  return (
    <RangePickerStyled
      placeholder={["", ""]}
      size="small"
      popupStyle={{ zIndex: 2000 }}
      getPopupContainer={() => document.getElementsByTagName("body")[0]}
      suffixIcon={<CalendarIcon />}
      allowClear={{
        clearIcon: <CloseIcon />,
      }}
      {...props}
    />
  );
};
export default RangePicker;
