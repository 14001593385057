import { DropzoneOptions, ErrorCode, FileRejection } from "react-dropzone";

import { getFileExtensionsString, SizeUnit } from "utils";

export const handleFileErrors = (
  fileRejections: FileRejection[],
  options: DropzoneOptions = {},
  setDropZoneError: (message: string | null) => void,
  maxSizeLimit?: [number, SizeUnit],
) => {
  const { errors } = fileRejections[0];

  if (errors.some((e) => e.code === ErrorCode.FileTooLarge) && maxSizeLimit) {
    const [size, unit] = maxSizeLimit;

    setDropZoneError(
      `The file was not uploaded. Please correct the issue and upload the file again. Your file size must not exceed ${size} ${unit}.`,
    );
    return true;
  }

  if (errors.some((e) => e.code === ErrorCode.FileInvalidType)) {
    const supportedTypes = getFileExtensionsString(options.accept!);
    setDropZoneError(
      `The file was not uploaded. Please correct the issue and upload the file again. Your file format must be: ${supportedTypes}.`,
    );
    return true;
  }

  if (errors.some((e) => e.code === ErrorCode.TooManyFiles)) {
    setDropZoneError(`These files were not uploaded. Please do not upload more than 1 file.`);
    return true;
  }

  return false;
};
