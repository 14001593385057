import { ApiKeyFields } from "./apiKeyFields";

export enum TemplateName {
  EN_US_DISTR = "EN_US_distribution_contract.tpl",
}

export interface TemplateResponse {
  [ApiKeyFields.template]: string;
}

export interface TemplatePayload {
  [ApiKeyFields.template]: string;
}

export interface TemplatePreviewPayload {
  [ApiKeyFields.template]: string;
  [ApiKeyFields.templateFields]: {
    [key: string]: string;
  };
}
