import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { RESOURCES_KEYS } from "i18n";

export const useAxiosErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation([RESOURCES_KEYS.ERRORS_AND_NOTIFICATIONS]);

  return {
    parseAxiosError: (axiosError: AxiosApiError) => {
      if (axiosError.response?.data) {
        const singleErrorMessage = axiosError.response.data.errorCode;
        if (singleErrorMessage) {
          enqueueSnackbar(t(singleErrorMessage), { iconType: "warning", autoHideDuration: 10000 });
        }
      }
    },
  };
};
