import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.title]: "",
  [ApiKeyFields.language]: "",
};

export const FORM_FIELDS = [ApiKeyFields.title, ApiKeyFields.language];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.title]: "Track Title",
  [ApiKeyFields.language]: "Language",
};
