import { MouseEvent } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";

export const handleNavLinkClick = (
  event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  errors: FieldErrors<FieldValues>,
) => {
  if (Object.keys(errors).length > 0) {
    event.preventDefault();
  }
};
