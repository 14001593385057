import dayjs, { Dayjs } from "dayjs";

export const disabledDate = (current: Dayjs | null): boolean => {
  if (!current) {
    return false;
  }
  if (current.day() !== 5) {
    return true;
  }
  const today = dayjs();
  const firstAvailableDate = today.day() === 5 ? today.add(5, "week").day(5) : today.add(4, "week").day(5);
  return current.isBefore(firstAvailableDate, "day");
};
