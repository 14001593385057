import { FC } from "react";

import IconButton from "@mui/material/IconButton";

import { LockRedIcon, UnlockUserIcon } from "assets/icons/24px";
import { RowActionsContainerStyled } from "components";
import { User, UserArtist } from "types";

type ApplicationTableActionsProps = {
  row: UserArtist;
  currenUser: User | null;
  handleActivateClick: () => void;
};
export const ApplicationTableActions: FC<ApplicationTableActionsProps> = ({ row, currenUser, handleActivateClick }) => {
  return (
    <RowActionsContainerStyled className="hiddenElement">
      <IconButton onClick={handleActivateClick} disabled={currenUser?.id === row.id} size="small" color="info">
        {row.isActive ? <LockRedIcon /> : <UnlockUserIcon />}
      </IconButton>
    </RowActionsContainerStyled>
  );
};

type AdminTableActionProps = {
  row: UserArtist;
  currenUser: User | null;
  handleActivateClick: () => void;
};
export const AdminTableAction: FC<AdminTableActionProps> = ({ row, currenUser, handleActivateClick }) => {
  return (
    <RowActionsContainerStyled className="hiddenElement">
      <IconButton onClick={handleActivateClick} disabled={currenUser?.id === row.id} size="small" color="info">
        {row.isActive ? <LockRedIcon /> : <UnlockUserIcon />}
      </IconButton>
    </RowActionsContainerStyled>
  );
};
