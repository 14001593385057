import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

import FormLabel, { formLabelClasses } from "@mui/material/FormLabel";

import { InfoIcon } from "assets/icons/14px";
import { ConditionalTooltip } from "components";

const FormLabelContainerStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;

  .${formLabelClasses.root} {
    margin-bottom: 0;
  }
`;

const FormLabelWithHint: FC<{ label: ReactNode; labelHint?: ReactNode }> = ({ label, labelHint }) => {
  return (
    <FormLabelContainerStyled>
      <FormLabel>{label}</FormLabel>
      {labelHint ? (
        <ConditionalTooltip title={labelHint} isTooltipVisible={Boolean(labelHint)}>
          <InfoIcon />
        </ConditionalTooltip>
      ) : null}
    </FormLabelContainerStyled>
  );
};

export default FormLabelWithHint;
