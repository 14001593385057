import { alpha, Components } from "@mui/material/styles";

import { colors } from "../colors";

const MuiBackdrop: Components["MuiBackdrop"] = {
  styleOverrides: {
    root: {
      backgroundColor: alpha(colors.gray257, 0.6),
    },
  },
};

export default { MuiBackdrop };
