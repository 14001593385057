import { FC } from "react";
import { DropzoneOptions } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import {
  CheckboxField,
  DropZoneField,
  FormBlock,
  FormBlockGridGap,
  OptionFieldsAlert,
  StepFlowController,
  StepFlowControllerProps,
} from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { TextField } from "components/HookFormControls";
import { ApiKeyFields, FileOptionProps } from "types";
import { SizeUnit } from "utils";

import { DROPZONE_TEXT, FORM_LABELS, HELPER_TEXT, OPTIONAL_FIELDS, TEXT_FIELDS } from "./constants";
import HelpAccordionText from "./HelpAccordionText";
import { validateTiffFile } from "./helpers";

interface TitleCoverArtProps extends StepFlowControllerProps {
  fileAction: (fieldName: ApiKeyFields, options: FileOptionProps) => void;
  isAdmin?: boolean;
}

const TitleCoverArt: FC<TitleCoverArtProps> = (props) => {
  const { isAdmin, fileAction } = props;
  const { control } = useFormContext();

  const getDefaultDropzoneProps = () => ({
    maxSizeLimit: [100, "MB"] as [number, SizeUnit],
    options: {
      multiple: false,
      accept: {
        "image/tiff": [".tiff"],
      },
    } as DropzoneOptions,
    isDownloadAllowed: isAdmin,
    fileAction,
    customValidation: validateTiffFile,
  });

  return (
    <>
      <StepFlowController {...props}>
        {!isAdmin ? <OptionFieldsAlert /> : null}
        <FormBlock>
          <Grid container gap={FormBlockGridGap}>
            {TEXT_FIELDS.map((fieldName) => (
              <FormGridFieldItem
                key={fieldName}
                label={FORM_LABELS[fieldName]}
                helperText={HELPER_TEXT[fieldName]}
                fieldName={fieldName}
                isOptionalField={OPTIONAL_FIELDS[fieldName]}
              >
                {(field) => <TextField {...field} control={control} />}
              </FormGridFieldItem>
            ))}
            <FormGridFieldItem
              label={FORM_LABELS[ApiKeyFields.cover_art_blob_path]}
              helperText={HELPER_TEXT[ApiKeyFields.cover_art_blob_path]}
              fieldName={ApiKeyFields.cover_art_blob_path}
            >
              {(field) => (
                <DropZoneField
                  {...field}
                  {...getDefaultDropzoneProps()}
                  control={control}
                  dropFileText={DROPZONE_TEXT}
                  formFile={false}
                />
              )}
            </FormGridFieldItem>
            <Grid item xs={12} md={12}>
              <Divider orientation="horizontal" />
            </Grid>
            <FormGridFieldItem label={""} fieldName={ApiKeyFields.ai_cover_art} hideLabelGrid inputGrid={{ md: 11 }}>
              {(field) => <CheckboxField {...field} control={control} label={FORM_LABELS[ApiKeyFields.ai_cover_art]} />}
            </FormGridFieldItem>
          </Grid>
        </FormBlock>
      </StepFlowController>
      {!props.isAdmin ? <HelpAccordionText /> : null}
    </>
  );
};

export default observer(TitleCoverArt);
