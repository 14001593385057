import * as zod from "zod";

import { MAX_INPUT_LENGTH_ERR_MSG, MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, REQUIRED_EMAIL_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const emailRefineSchema = zod
  .object({
    [ApiKeyFields.advance]: zod.boolean(),
    [ApiKeyFields.email]: zod.string().max(MAX_LENGTH_INPUT, MAX_INPUT_LENGTH_ERR_MSG).trim().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data[ApiKeyFields.advance] === false) {
      if (!data[ApiKeyFields.email]) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: [ApiKeyFields.email],
          message: MIN_LEN_ERR_TEXT,
        });
      } else {
        const emailSchema = zod.string().email({ message: REQUIRED_EMAIL_TEXT });
        const emailCheck = emailSchema.safeParse(data[ApiKeyFields.email]);
        if (!emailCheck.success) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: [ApiKeyFields.email],
            message: REQUIRED_EMAIL_TEXT,
          });
        }
      }
    }
  });
