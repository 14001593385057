import useBasicStores from "hooks/useBasicStores";
import { RootStore as InternalRootStore } from "stores/_rootStore";

export * from "./_rootStore";
export * from "./uiStore";
export * from "./qualificationStore";
export * from "./revisedFormStore";
export * from "./userStore";
export * from "./notificationsStore";
export * from "./tablesStore";

export const useStores = (): InternalRootStore => useBasicStores<InternalRootStore>();
