import React from "react";
import styled from "@emotion/styled";
import isNil from "lodash/isNil";

import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox";

import { CheckboxCheckedIcon, CheckboxIcon } from "assets/icons";

const StyledCheckbox = React.forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
  const checkedValue = !isNil(props.value) ? props.value : false;

  return (
    <CheckboxMui
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      size="small"
      color="primary"
      ref={ref}
      checked={checkedValue as boolean}
      {...props}
    />
  );
});

StyledCheckbox.displayName = "StyledCheckbox";

export const CheckboxStyled = styled(StyledCheckbox)`` as typeof CheckboxMui;
