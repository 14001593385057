import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { TracksForm } from "types";

export const useTrackNotificationParams = (fields: TracksForm[], handleTrackClick: (work: TracksForm) => void) => {
  const location = useLocation();
  const hasProcessed = useRef(false);

  const processTrack = async () => {
    const releaseTrackId = location.state?.releaseTrackId;
    if (!releaseTrackId) return;

    const findTrack = fields.find(({ id }) => id === releaseTrackId);
    if (findTrack) {
      handleTrackClick(findTrack);
      hasProcessed.current = true;
    }
  };

  useEffect(() => {
    if (hasProcessed.current) return;
    if (fields.length) processTrack();
  }, [fields, location]);
};
