import { FC } from "react";

import { Chip } from "components/FormComponents";
import { ReleaseStatus } from "types";

interface UserStatusChipProps {
  status: ReleaseStatus | null;
  handleDelete?: (e: React.MouseEvent) => void;
}

const ReleaseStatusChip: FC<UserStatusChipProps> = ({ status, handleDelete }) => {
  const props = {
    ...(handleDelete
      ? {
          onDelete: (event: React.MouseEvent) => handleDelete(event),
          onMouseDown: (event: React.MouseEvent) => event.stopPropagation(),
          onClick: (event: React.MouseEvent) => handleDelete(event),
        }
      : void 0),
  };

  switch (status) {
    case ReleaseStatus.Draft:
      return <Chip color="primary" label="Draft" {...props} />;
    case ReleaseStatus.InRevision:
      return <Chip color="warning" label="Release In Review" {...props} />;
    case ReleaseStatus.ChangesNeeded:
      return <Chip color="primary" label="Release Pending" {...props} />;
    case ReleaseStatus.Released:
      return <Chip color="success" label="Approved" {...props} />;
    case ReleaseStatus.TakenDown:
      return <Chip color="error" label="Taken Down" {...props} />;
    default:
      return null;
  }
};

export default ReleaseStatusChip;
