import React from "react";

import IconButton from "@mui/material/IconButton";
import { GridValidRowModel } from "@mui/x-data-grid";

import { DeleteIcon, EditIcon, ViewIcon } from "assets/icons/24px";
import { RowActionsContainerStyled, RowActionsWrapperStyled } from "components";

import CommentsStatus from "./CommentsStatus";
import { RowActionsProps } from "./types";

const RowActions = <T extends GridValidRowModel>({
  record,
  onEditClick,
  onDeleteClick,
  getRowComments,
  isReadOnly = false,
}: RowActionsProps<T>) => {
  const comments = getRowComments?.(record);

  return (
    <RowActionsWrapperStyled key={record.id}>
      <CommentsStatus comments={comments} />
      <RowActionsContainerStyled className="hiddenElement">
        {onEditClick ? (
          <IconButton size="small" color="info" onClick={() => onEditClick(record)}>
            {isReadOnly ? <ViewIcon /> : <EditIcon />}
          </IconButton>
        ) : null}
        {!isReadOnly && onDeleteClick && (
          <IconButton size="small" color="info" onClick={() => onDeleteClick(record)}>
            <DeleteIcon />
          </IconButton>
        )}
      </RowActionsContainerStyled>
    </RowActionsWrapperStyled>
  );
};

export default RowActions;
