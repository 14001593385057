import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const TEXT_FIELDS = [ApiKeyFields.title, ApiKeyFields.version_title];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.title]: "Release Title",
  [ApiKeyFields.version_title]: "Version Title (Optional)",
  [ApiKeyFields.cover_art_blob_path]: "Cover Art",
  [ApiKeyFields.ai_cover_art]:
    "Check this box if artificial intelligence tools were used to assist in the creation of the cover art.",
};

export const HELPER_TEXT = {
  [ApiKeyFields.cover_art_blob_path]: "You can review file requirements in the help section below.",
  [ApiKeyFields.version_title]: "E.g. Live, Acoustic, Remix, etc.",
} as Record<ApiKeyFields, string>;

export const DROPZONE_TEXT = "Drop your cover art here";

export const OPTIONAL_FIELDS: { [key: string]: true } = {
  [ApiKeyFields.version_title]: true,
};

export const TITLE_COVER_ART_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.title, required: true },
  { field: ApiKeyFields.version_title, required: false },
  { field: ApiKeyFields.cover_art_blob_path, required: true },
  { field: ApiKeyFields.ai_cover_art, required: false },
] as SubRouteFieldProps[];
