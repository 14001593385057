export const MAX_LENGTH_INPUT = 255;
export const MAX_LENGTH_TEXTAREA = 250;

export const REQUIRED_EMAIL_TEXT = "Please enter the correct email address.";
export const MIN_LEN_ERR_TEXT = "Please enter at least 1 character.";
export const MIN_LEN_NUM_ERR_TEXT = "Please enter at least 1 digit.";
export const SELECT_ERR_TEXT = "Please select a value.";
export const REQUIRED_PHONE_TEXT = "Please check the number.";
export const NUMBER_POSITIVE_TEXT = "Please check the number. It cannot be negative or zero.";
export const NUMBER_POSITIVE_INTEGER_TEXT = "Please check the number. It cannot be negative, zero or decimal.";
export const NUMBER_POSITIVE = "Please check the number. It cannot be zero.";
export const REQUIRED_ISWC = "Please enter a valid ISWC number.";
export const NUMBER_TWO_DECIMALS_LIMIT_TEXT = "Please check the number. It must have no more than 2 decimal places.";

export const MIN_LEN_ERR_MSG = { message: MIN_LEN_ERR_TEXT };
export const MAX_INPUT_LENGTH_ERR_MSG = { message: "String must contain at most 255 character(s)." };
export const MIN_LEN_NUM_ERR_MSG = { message: MIN_LEN_NUM_ERR_TEXT };
export const MAX_LEN_TEXTAREA_ERR_MSG = { message: "Please remove any unnecessary text." };
export const SELECT_ERR_MSG = { message: SELECT_ERR_TEXT };
export const DATE_ERR_MSG = { message: "Please select a date." };
export const CHECKBOX_ERR_MSG = { message: "Please check the box." };
export const NUMBER_MAX_LENGTH_ERR_MSG = { message: "Number must be less than or equal to 1000000000000." };
export const MAX_NUM_VALUE_ERR_MSG = (num: number) => ({ message: `Value must be lower than ${num}.` });
