export const isCloseToMaxLength = (charCount: number, maxLength: number = 1000) => {
  return maxLength - charCount <= 100;
};

export const formatNumberLocale = (number?: number | null) => {
  if (!number) return void 0;
  return number.toLocaleString("en-US");
};

/**
 * Converts a given size from KB, MB, or GB to bytes.
 * @param {number} size - The size to be converted.
 * @param {SizeUnit} unit - The unit of the size: "KB" for kilobytes, "MB" for megabytes, or "GB" for gigabytes.
 * @returns {number} - The size in bytes.
 * @throws {Error} - Throws an error if the unit is not one of "KB", "MB", or "GB".
 */
export type SizeUnit = "KB" | "MB" | "GB";
export const convertToBytes = (size: number, unit: SizeUnit): number => {
  switch (unit) {
    case "KB":
      return size * 1024;
    case "MB":
      return size * 1024 * 1024;
    case "GB":
      return size * 1024 * 1024 * 1024;
    default:
      throw new Error("Wrong Unit");
  }
};
