import { memo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { GridRow, GridRowProps } from "@mui/x-data-grid";

const DraggableGridRow = memo((params: GridRowProps) => {
  const { attributes, setNodeRef, transform, transition } = useSortable({ id: params.rowId });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <GridRow {...params} />
    </div>
  );
});

DraggableGridRow.displayName = "DraggableGridRow";

export default DraggableGridRow;
