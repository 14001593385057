import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { DistributionForm } from "components/QualificationForm/GenericDistributionForm";
import { prepareQualificationFormData } from "components/QualificationForm/prepareFormValues";

const QualificationForm: FC<StepFlowControllerProps> = (props) => {
  const {
    qualificationStore: { qualificationForm },
  } = useStores();
  const form = useForm();
  const { reset } = form;

  useEffect(() => {
    if (qualificationForm) {
      reset(prepareQualificationFormData(qualificationForm));
    }
  }, [qualificationForm]);

  return (
    <FormProvider {...form}>
      <StepFlowController prevBtnDisabled isRegistrationFlow {...props}>
        <DistributionForm readOnly />
      </StepFlowController>
    </FormProvider>
  );
};

export default observer(QualificationForm);
