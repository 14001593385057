import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Typography from "@mui/material/Typography";

import { ConfirmModal, DndOrderIndex } from "components";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { handleDuplicationError } from "components/ReleaseForm/TrackManager/helpers";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  SecondaryFormNames,
  Songwriters,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
  WorksForm,
} from "types";

import AddEditSongwriter from "./AddEditSongwriter";

export const useSongwriters = (work: WorksForm) => {
  const {
    revisedFormStore: {
      works: { songwriters, songwritersLoading },
    },
    uiStore: { setOverlayLoading, resetLoading, openModal },
  } = useStores();
  const { fetchWorksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { enqueueSnackbar } = useSnackbar();

  const { control, setValue, watch } = useFormContext<{ songwriters: Songwriters[] }>();
  const { fields } = useFieldArray({
    control,
    name: "songwriters",
    keyName: "_id",
  });

  useEffect(() => {
    songwriters && setValue("songwriters", songwriters, { shouldDirty: false });
  }, [songwriters]);

  const handleSongwritersArrayChange = (data: Songwriters) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue("songwriters", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: Songwriters[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch("songwriters")];
      try {
        setValue("songwriters", rows, { shouldDirty: false });
        const requestParams: TertiaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseWorks,
          tertiaryFormName: TertiaryFormNames.ReleaseSongwriters,
          primaryFormId: releaseId!,
          secondaryFormId: work.id!,
          tertiaryFormId: currentItemId,
        };
        await revisedFormService.reorderTertiaryForm(requestParams, { afterId });
      } catch {
        setValue("songwriters", prevValues, { shouldDirty: false });
      }
    },
    [setValue, work],
  );

  const handleDeleteSongwriter = async (record: Songwriters, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: TertiaryFormId = {
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
        tertiaryFormName: TertiaryFormNames.ReleaseSongwriters,
        primaryFormId: releaseId!,
        secondaryFormId: work.id!,
        tertiaryFormId: record.id as string,
      };
      await revisedFormService.deleteTertiaryFormId(requestParams);
      await fetchWorksComments();
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue("songwriters", updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleAddSongwriter = () => {
    openModal({
      component: (modalProps) => <AddEditSongwriter {...modalProps} work={work} successCallback={handleSongwritersArrayChange} />,
    });
  };

  const fetchSongwriters = useCallback(
    async ({ offset, limit }: { offset: number; limit: number }) => {
      const response = await revisedFormService.retrieveReleaseSongwritersOptions(
        { primaryFormId: releaseId! },
        { offset, limit, not_release_work_id: work.id!, order_by_field_name: ApiKeyFields.name },
      );
      return response.revisedForms;
    },
    [work],
  );

  const copySongwriter = useCallback(
    async (value: string, options: Songwriters[]) => {
      if (!work.id || !releaseId) return;
      try {
        setOverlayLoading(true);
        const findSongwriter = options.find(({ id }) => id === value);
        if (findSongwriter) {
          const requestParams: TertiarySecondaryFormId = {
            [ApiKeyFields.userId]: userId,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseWorks,
            [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseSongwriters,
            [ApiKeyFields.primaryFormId]: releaseId,
            [ApiKeyFields.secondaryFormId]: work.id,
          };
          const { revisedForm } = await revisedFormService.postTertiaryForm(requestParams, findSongwriter);
          handleSongwritersArrayChange(revisedForm);
        }
      } catch (error) {
        handleDuplicationError(error as AxiosApiError, enqueueSnackbar);
      } finally {
        resetLoading();
      }
    },
    [work, fields],
  );

  const handleCopySongwriter = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchSongwriters}
          mapFunction={(record) => ({ label: record.name!, value: record.id! })}
          modalTitle="Copy Songwriter"
          inputLabel="Select Songwriter"
          onSubmitCb={copySongwriter}
        />
      ),
      fullWidth: true,
    });
  };

  const handleEditSongwriter = (record: Songwriters) => {
    openModal({
      component: (modalProps) => (
        <AddEditSongwriter {...modalProps} work={work} record={record} successCallback={handleSongwritersArrayChange} />
      ),
    });
  };

  const handleDeleteClick = (record: Songwriters) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the songwriter?"
          text={
            <Typography variant="body1">
              Remove <b>{record.name}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteSongwriter(record, modalProps.closeModal)}
        />
      ),
    });
  };

  return {
    fields,
    songwritersLoading,
    handleAddSongwriter,
    handleCopySongwriter,
    handleEditSongwriter,
    handleDeleteClick,
    handleSongwritersArrayChange,
    handleChangeRows,
    handleDeleteSongwriter,
  };
};
