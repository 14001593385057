import { FC, useMemo } from "react";
import { useSnackbar } from "notistack";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { CopyIcon } from "assets/icons/14px";
import { RowHighlightContainerStyled } from "components/Layout";

import { EndAdornmentStyled } from "./styled";
import { TableClipboardColProps } from "./types";

const TableClipboardCol: FC<TableClipboardColProps> = ({ text, endAdornment }) => {
  const { enqueueSnackbar } = useSnackbar();

  const notEmptyText = useMemo(() => text?.trim(), [text]);

  const handleClipboardClick = () => {
    if (notEmptyText) {
      navigator.clipboard.writeText(text);
      enqueueSnackbar("The value was copied to your clipboard.");
    }
  };

  const TextTypography = (
    <Typography maxWidth="100%" noWrap>
      {text}
    </Typography>
  );

  return notEmptyText ? (
    <Tooltip title={text} placement="top-start" arrow>
      <RowHighlightContainerStyled onClick={handleClipboardClick}>
        <CopyIcon />
        {TextTypography}
        {endAdornment ? <EndAdornmentStyled>{endAdornment}</EndAdornmentStyled> : null}
      </RowHighlightContainerStyled>
    </Tooltip>
  ) : (
    TextTypography
  );
};

export default TableClipboardCol;
