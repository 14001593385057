import { FORM_LABELS as RELEASE_DATE_FORM_LABELS } from "components/ReleaseForm/ReleaseDate/constants";
import { FORM_LABELS as GENRE_TERRITORY_FORM_LABELS } from "components/ReleaseForm/ReleaseInformation/GenreTerritory/constants";
import { FORM_LABELS as TITLE_COVER_ART_FORM_LABELS } from "components/ReleaseForm/ReleaseInformation/TitleCoverArt/constants";
import { FORM_LABELS as TRACK_LABELS } from "components/ReleaseForm/TrackManager/Tracks/constants";
import { FORM_LABELS as TRACK_ARTIST_LABELS } from "components/ReleaseForm/TrackManager/Tracks/Track/ListBlocks/ArtistsBlock/AddEditArtist/constants";
import { FORM_LABELS as TRACK_CONTRIBUTOR_LABELS } from "components/ReleaseForm/TrackManager/Tracks/Track/ListBlocks/ContributorsBlock/AddEditContributor/constants";
import { FORM_LABELS as TRACK_FORM_LABELS } from "components/ReleaseForm/TrackManager/Tracks/Track/TrackForm/constants";
import { FORM_LABELS as WORKS_LISTS_LABELS } from "components/ReleaseForm/TrackManager/Works/Work/ListBlocks/AddEditForm/contstants";
import { FORM_LABELS as WORK_FORM_LABELS } from "components/ReleaseForm/TrackManager/Works/WorkForm/constants";
import { ApiKeyFields } from "types";

export const progressBarFieldLabels = {
  ...WORK_FORM_LABELS,
  ...WORKS_LISTS_LABELS,
  ...TRACK_LABELS,
  ...TRACK_FORM_LABELS,
  ...TRACK_ARTIST_LABELS,
  ...TRACK_CONTRIBUTOR_LABELS,
  ...GENRE_TERRITORY_FORM_LABELS,
  ...TITLE_COVER_ART_FORM_LABELS,
  ...RELEASE_DATE_FORM_LABELS,
  [ApiKeyFields.name]: "Name",
  [ApiKeyFields.title]: "Title",
};
