import { DropzoneOptions } from "react-dropzone";

import W8 from "assets/docs/W8.pdf";
import W8E from "assets/docs/W8E.pdf";
import W9 from "assets/docs/W9.pdf";
import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const DISTRIBUTION_INFORMATION_FIELDS = [
  ApiKeyFields.signer_name,
  ApiKeyFields.legal_name,
  ApiKeyFields.tax_form_blob_path,
  ApiKeyFields.payment_form_blob_path,
  ApiKeyFields.form_confirmation,
];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.signer_name]: "Who will be responsible for signing the Agreement?",
  [ApiKeyFields.legal_name]: "Legal Name / Entity",
  [ApiKeyFields.tax_form_blob_path]: "Please upload Artist/Entity tax form",
  [ApiKeyFields.payment_form_blob_path]: "Please upload the Royalty Payout Information",
  [ApiKeyFields.form_confirmation]: "I confirm that the forms are filled out correctly and signed by the proper legal entity",
};

export const HELPER_TEXT = {
  [ApiKeyFields.signer_name]: "Please provide the name of the individual with the legal authority to sign this agreement",
  [ApiKeyFields.legal_name]:
    "Enter your legal name or the business entity name exactly as it appears on the W9 or tax form that will be listed on the distribution agreement",
} as Record<ApiKeyFields, string>;

export const DROP_FILE_TEXT = {
  [ApiKeyFields.tax_form_blob_path]: "Drop your signed tax form here",
  [ApiKeyFields.payment_form_blob_path]: "Drop your signed payment form here",
} as Record<ApiKeyFields, string>;

export const DISTRIBUTION_INFORMATION_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.signer_name, required: true },
  { field: ApiKeyFields.legal_name, required: true },
  { field: ApiKeyFields.tax_form_blob_path, required: true },
  { field: ApiKeyFields.payment_form_blob_path, required: true },
  { field: ApiKeyFields.form_confirmation, required: true },
] as SubRouteFieldProps[];

export const wForms = [
  { title: "W9", file: W9 },
  { title: "W8", file: W8 },
  { title: "W8E", file: W8E },
];

export const fileOptions: DropzoneOptions = {
  accept: {
    "application/pdf": [".pdf"],
  },
};
