import { GridColDef } from "@mui/x-data-grid";

import { RowModifyActions } from "components/ReleaseForm";
import { ApiKeyFields, ReleaseTrackWorks } from "types";

interface getColumnsProps {
  onDeleteClick: (record: ReleaseTrackWorks) => void;
  isReadOnly?: boolean;
}

export const getColumns = ({ onDeleteClick, isReadOnly }: getColumnsProps): GridColDef<ReleaseTrackWorks>[] => [
  {
    field: ApiKeyFields.title,
    headerName: "Composition Title",
    sortable: false,
    valueGetter: ({ row }) => (row[ApiKeyFields.releaseWork] ? row[ApiKeyFields.releaseWork].title : null),
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.agency,
    headerName: "Agency",
    valueGetter: ({ row }) => (row[ApiKeyFields.releaseWork] ? row[ApiKeyFields.releaseWork].agency : null),
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.iswc,
    headerName: "ISWC",
    valueGetter: ({ row }) => row[ApiKeyFields.releaseWork]?.iswc,
    renderCell: ({ value }) => <span>{value ?? "—"}</span>,
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.actions,
    sortable: false,
    type: "actions",
    disableColumnMenu: true,
    getActions: ({ row }) => {
      return [<RowModifyActions key={row.id} record={row} onDeleteClick={onDeleteClick} isReadOnly={isReadOnly} />];
    },
  },
];
