import { RouteObject } from "react-router-dom";

import { DistributionInfoFormProvider } from "components/RegistrationForm/RegistrationFormsContext";
import { ROUTES } from "constants/routes";

import AccountReview from "./AccountReview";
import {
  AccountAgreement,
  AccountCatalog,
  AccountContact,
  AccountDistribution,
  AccountInformation,
  AccountQualification,
} from "./Pages";

export const AccountReviewRoute: RouteObject[] = [
  {
    path: ROUTES.ACCOUNTS_ID(),
    element: <AccountReview />,
    children: [
      { index: true, element: <AccountQualification /> },
      {
        element: <DistributionInfoFormProvider />,
        children: [
          { path: ROUTES.CONTACT_ROUTE, element: <AccountContact /> },
          { path: ROUTES.ADDRESS_ROUTE, element: <AccountInformation /> },
          { path: ROUTES.CATALOG_ROUTE, element: <AccountCatalog /> },
        ],
      },
      { path: ROUTES.AGREEMENT_ROUTE, element: <AccountAgreement /> },
      { path: ROUTES.DISTRIBUTION_ROUTE, element: <AccountDistribution /> },
    ],
  },
];
