import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ReleaseTableListBlock, Table } from "components";
import { LabelWithAsterisk } from "components";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { ReleaseAlert, TableNoDataMessage, usePrimaryFormState } from "components/ReleaseForm";
import { WorksForm } from "types";

import { getColumns } from "./columns";
import { WORKS_MAX_LIMIT } from "./constants";
import { useWorkNotificationParams } from "./useWorkNotificationParams";
import { useWorks } from "./useWorks";

const TableNoData = () => <TableNoDataMessage text="There are no composition(s) added." />;

const Works: FC<StepFlowControllerProps> = (props) => {
  const {
    revisedFormStore: {
      comments: { secondaryComments },
    },
  } = useStores();
  const { fields, isLoading, handleOpenWorkClick, handleAddWorkClick, handleDeleteClick } = useWorks();
  useWorkNotificationParams(fields as WorksForm[], handleOpenWorkClick);

  const getRowComments = (work: WorksForm) => secondaryComments.filter(({ releaseWorkId }) => releaseWorkId === work.id);

  const { isReadOnly } = usePrimaryFormState();
  const columns = useMemo(
    () => getColumns({ onEditClick: handleOpenWorkClick, onDeleteClick: handleDeleteClick, getRowComments, isReadOnly }),
    [handleOpenWorkClick, handleDeleteClick],
  );

  const worksCount = Boolean(fields.length) ? fields.length : null;

  return (
    <StepFlowController {...props}>
      <ReleaseAlert
        title={`Please add the composition(s) that will be attached to the track(s) in the release.\n A Composition is the song itself. It's the lyrics and melody (aka the sheet music). This is the copyright publishers primarily deal with.`}
      />
      <ReleaseTableListBlock
        title={<LabelWithAsterisk>Composition(s)</LabelWithAsterisk>}
        itemsCount={worksCount}
        maxLimit={WORKS_MAX_LIMIT}
        onAddClick={handleAddWorkClick}
        isLoading={isLoading}
        addBtnText="Add Composition"
        hideActionBtn={isReadOnly}
      >
        <Table
          columns={columns}
          rows={fields}
          loading={isLoading}
          hideFooter
          autoHeight
          slots={{
            noRowsOverlay: TableNoData,
          }}
        />
      </ReleaseTableListBlock>
    </StepFlowController>
  );
};

export default observer(Works);
