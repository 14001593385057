import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiRadio: Components["MuiRadio"] = {
  styleOverrides: {
    root: {
      "&.Mui-disabled": {
        pointerEvents: "inherit",
        cursor: "not-allowed",
        "svg > circle": {
          stroke: colors.gray926,
        },
      },
    },
  },
};
