import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import IconButton from "@mui/material/IconButton";

import { LockRedIcon, UnlockUserIcon } from "assets/icons/24px";
import BlockUserDialog from "components/BlockUserModal";

const ToggleUserActive: FC = () => {
  const {
    qualificationStore: { qualificationForm, toggleUserIsActive },
    uiStore: { openModal },
  } = useStores();

  const changeUserIsActive = (id: string, isActive: boolean) => {
    toggleUserIsActive(isActive);
  };

  const handleActiveClick = () => {
    openModal({
      component: (modalProps) => (
        <BlockUserDialog {...modalProps} user={qualificationForm!} changeUserIsActive={changeUserIsActive} />
      ),
      PaperProps: { sx: { width: "448px" } },
    });
  };

  return qualificationForm ? (
    <IconButton size="small" onClick={handleActiveClick}>
      {qualificationForm.isActive ? <LockRedIcon /> : <UnlockUserIcon />}
    </IconButton>
  ) : null;
};

export default observer(ToggleUserActive);
