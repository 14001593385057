import React, { FC, useContext, useMemo, useState } from "react";
import { CustomFormContext } from "context";
import { observer } from "mobx-react";

import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

import { ApiKeyFields } from "types";
import { convertCamelToSnakeCase } from "utils";

import { ANCHOR } from "../constants";

import CommentIcon from "./CommentIcon";
import CommentPopover from "./CommentPopover";

interface CommentModeProps {
  fieldName: ApiKeyFields;
}

const CommentMode: FC<CommentModeProps> = ({ fieldName }) => {
  const { formComments } = useContext(CustomFormContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getComment = useMemo(
    () => formComments.find((comment) => comment.fieldName === convertCamelToSnakeCase(fieldName)),
    [formComments, fieldName],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton size="small" onClick={handleClick} tabIndex={-1}>
        <CommentIcon comment={getComment} />
      </IconButton>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={ANCHOR}
          transformOrigin={ANCHOR}
          // slotProps={{ paper: { sx: { width: "348px" } } }}
          keepMounted={false}
        >
          <CommentPopover fieldName={fieldName} getComment={getComment} handleClose={handleClose} />
        </Popover>
      )}
    </>
  );
};

export default observer(CommentMode);
