import { PaletteOptions } from "@mui/material/styles";

export const colors = {
  white: "#FFFFFF",
  black: "#000000",

  black418: "#14181A",

  blue30: "#E7F4FE",
  blue364: "#0864A6",
  blue480: "#004A80",
  blue498: "#4C9ED9",
  blue699: "#66BFFF",

  dark233: "#2B3033",
  dark950: "#222526",

  red50: "#FFE0E0",
  red666: "#E64545",
  red701: "#A6323B",

  pink101: "#FFEBEC",

  green175: "#E4F7EF",
  green200: "#19A66B",
  green607: "#177354",
  green804: "#00804A",

  orange129: "#FFEDE5",
  orange618: "#F2A918",
  orange633: "#FF6F33",
  orange827: "#99370F",

  yellow312: "#FFEBC4",
  yellow800: "#73620B",
  yellow902: "#FFF2CC",

  brown702: "#8C472A",

  gray215: "#DAE1E5",
  gray257: "#F2F5F7",
  gray449: "#45494D",
  gray564: "#525E66",
  gray566: "#5B6166",
  gray706: "#97A0A6",
  gray736: "#ACB7BF",
  gray749: "#CED4D9",
  gray780: "#797D80",
  gray926: "#9DA2A6",
};

export const paletteLight: PaletteOptions = {
  primary: {
    main: colors.blue364,
    dark: colors.blue480, // Hover
  },
  secondary: {
    main: colors.gray215,
    dark: colors.gray736, // Hover
  },
  branding: {
    light: colors.orange129, // hover
    main: colors.orange633,
  },
  success: {
    main: colors.green200,
    light: colors.green175,
  },
  warning: {
    main: colors.orange618,
    light: colors.yellow902,
  },
  error: {
    main: colors.red666,
    light: colors.pink101,
  },
  background: {
    body: colors.white,
    surface: colors.gray257,
    blue: colors.blue30,
  },
  text: {
    primary: colors.dark233,
    header: colors.black418,
    label: colors.gray564,
    error: colors.red701,
    disabled: colors.gray926,
    warning: colors.yellow800,
    success: colors.green607,
    branding: colors.brown702,
  },
  divider: colors.gray749,
  icon: colors.gray706,
};

export const paletteDark: PaletteOptions = {
  primaryDark: {
    main: colors.blue699,
    dark: colors.blue498, // Hover
  },
  secondaryDark: {
    main: colors.gray449,
    dark: colors.gray566, // Hover
  },
  brandingDark: {
    main: colors.orange633,
    dark: colors.orange827, // hover
  },
  backgroundDark: {
    body: colors.dark950,
  },
  textDark: {
    primary: "#F0F4F7",
    secondary: "#FFFFFF", // Text Headers
    disabled: colors.gray780,
    label: "#C3D7E5",
  },
  dividerDark: "#515659",
  iconDark: "#747B80",
};
