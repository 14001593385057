import { useEffect } from "react";

import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

import Table, { TableProps } from "components/Table";

interface TableGenericProps extends TableProps {
  getTableData: (defaultSort?: GridSortModel) => void;
  setPaginationModel: (paginationModel: GridPaginationModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  defaultSortModel?: GridSortModel;
  ignoreInnerTableApiCall?: boolean;
}

const TableGeneric = (props: TableGenericProps) => {
  const {
    getTableData,
    setPaginationModel,
    setSortModel,
    defaultSortModel,
    sortModel,
    paginationModel,
    ignoreInnerTableApiCall,
  } = props;

  useEffect(() => {
    if (!ignoreInnerTableApiCall) {
      getTableData(defaultSortModel);
    }
  }, [getTableData, defaultSortModel]);

  const onPaginationModelChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    getTableData();
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    getTableData();
  };

  return (
    <Table
      {...props}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      sortModel={sortModel}
      paginationModel={paginationModel}
    />
  );
};

export default TableGeneric;
