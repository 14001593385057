import { FC } from "react";

import Typography from "@mui/material/Typography";

import { AngleDownIcon } from "assets/icons/24px";

import { FormAccordionDetailsStyled, FormAccordionStyled, FormAccordionSummaryStyled } from "./styled";
import { FormAccordionProps } from "./types";

const FormAccordion: FC<FormAccordionProps> = ({ title, children, expanded }) => {
  return (
    <FormAccordionStyled square expanded={expanded}>
      <FormAccordionSummaryStyled expandIcon={<AngleDownIcon />}>
        <Typography variant="button" textTransform="none" fontWeight="600">
          {title}
        </Typography>
      </FormAccordionSummaryStyled>
      <FormAccordionDetailsStyled>{children}</FormAccordionDetailsStyled>
    </FormAccordionStyled>
  );
};

export default FormAccordion;
