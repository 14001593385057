import { useCallback } from "react";
import { FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useStores } from "stores";

import Typography from "@mui/material/Typography";

import { ConfirmModal, DndOrderIndex } from "components";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  SecondaryFormId,
  SecondaryFormNames,
  SecondaryPrimaryFormId,
  Tracks,
  TracksForm,
} from "types";

import AddTrack from "./AddTrack";
import Track from "./Track";

export const useTracks = () => {
  const {
    revisedFormStore: {
      release,
      tracks: { isLoading },
    },
    uiStore: { openModal, setOverlayLoading, resetLoading },
  } = useStores();
  const { fetchTracksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const form = useFormContext<{ tracks: TracksForm[] }>();
  const { control, setValue, watch } = form;
  const { fields } = useFieldArray({
    control,
    name: "tracks",
    keyName: "_id",
  });

  const handleTracksArrayChange = (data: TracksForm) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue("tracks", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: TracksForm[], { afterId, currentItemId }: DndOrderIndex) => {
      if (!releaseId) return;
      const prevValues = [...watch("tracks")];
      try {
        setValue("tracks", rows, { shouldDirty: false });
        const requestParams: SecondaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseTracks,
          primaryFormId: releaseId,
          secondaryFormId: currentItemId,
        };
        await revisedFormService.reorderSecondaryForm(requestParams, { afterId });
      } catch {
        setValue("tracks", prevValues, { shouldDirty: false });
      }
    },
    [setValue, release],
  );

  const handleFocusTrackChange = async (record: TracksForm) => {
    if (!releaseId) return;
    try {
      setOverlayLoading(true);
      const requestParams: SecondaryFormId = {
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId: releaseId,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        secondaryFormId: record.id as string,
      };
      const isFocus = record[ApiKeyFields.isFocus] === true ? false : true;
      const newArray = fields.map((track) => (track.id === record.id ? { ...track, isFocus } : { ...track, isFocus: false }));
      setValue("tracks", newArray, { shouldDirty: false });
      await revisedFormService.postFocusTrack(requestParams, { isFocus });
    } finally {
      resetLoading();
    }
  };

  const handleOpenTrackClick = (track: TracksForm) => {
    openModal({
      component: (modalProps) => {
        return <Track {...modalProps} track={track} successCb={handleTracksArrayChange} />;
      },
      fullScreen: true,
      disableEscapeKeyDown: true,
    });
  };

  const handleAddTrackClick = () => {
    openModal({
      component: (modalProps) => (
        <FormProvider {...form}>
          <AddTrack {...modalProps} successCallback={handleTracksArrayChange} />
        </FormProvider>
      ),
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleDeleteTrack = async (record: TracksForm, closeModal: () => void) => {
    if (releaseId) {
      try {
        setOverlayLoading(true);
        const requestParams: SecondaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseTracks,
          primaryFormId: releaseId,
          secondaryFormId: record.id as string,
        };
        await revisedFormService.deleteSecondaryFormId(requestParams);
        await fetchTracksComments();
        closeModal();
        const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
        setValue("tracks", updatedArray, { shouldDirty: false });
      } finally {
        resetLoading();
      }
    }
  };

  const handleDeleteClick = (record: TracksForm) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the track?"
          text={
            <Typography variant="body1">
              Remove <b>{record.title}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteTrack(record, modalProps.closeModal)}
        />
      ),
    });
  };

  const fetchExistingTracks = async ({ offset, limit }: { offset: number; limit: number }) => {
    const response = await revisedFormService.retrieveExistingTrackOptions({
      offset,
      limit,
      order_by_field_name: ApiKeyFields.title,
    });
    return response.revisedForms;
  };

  const addExistingTrack = useCallback(
    async (value: string, options: Tracks[]) => {
      if (!releaseId) return;
      try {
        setOverlayLoading(true);
        const findRecord = options.find(({ id }) => id === value);
        if (findRecord) {
          const requestParams: SecondaryPrimaryFormId = {
            userId: null,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
            [ApiKeyFields.primaryFormId]: releaseId!,
          };
          const copyPayload: Tracks = {
            ...findRecord,
            [ApiKeyFields.isFocus]: null,
            [ApiKeyFields.audioBlobRelationIds]: findRecord[ApiKeyFields.audioBlobPath]
              ? [findRecord[ApiKeyFields.releaseId]!, findRecord[ApiKeyFields.id]!]
              : null,
          };
          const { revisedForm } = await revisedFormService.postSecondaryForm(requestParams, copyPayload);
          handleTracksArrayChange(revisedForm);
        }
      } finally {
        resetLoading();
      }
    },
    [fields],
  );

  const handleAddExistingTrack = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchExistingTracks}
          mapFunction={(record) => ({ label: record.title!, value: record.id! })}
          modalTitle="Add the Existing Track"
          inputLabel="Select Track"
          onSubmitCb={addExistingTrack}
        />
      ),
      fullWidth: true,
    });
  };

  return {
    fields,
    isLoading,
    handleTracksArrayChange,
    handleAddExistingTrack,
    handleChangeRows,
    handleOpenTrackClick,
    handleAddTrackClick,
    handleDeleteClick,
    handleFocusTrackChange,
  };
};
