import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { FiltersProps } from "pages/Admin/Accounts/Filters/types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

export const processFilters = (filters?: FiltersProps) => {
  if (!filters) {
    return {};
  }
  return omitBy(replaceEmptyAndUndefinedWithNull(filters), isNull);
};
