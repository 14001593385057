import { FC } from "react";

import Typography from "@mui/material/Typography";

import { PageContainer } from "components/Layout";

const ComingSoon: FC = () => {
  return (
    <PageContainer justifyContent="center">
      <Typography variant="h2" color="text.disabled">
        Coming Soon
      </Typography>
    </PageContainer>
  );
};

export default ComingSoon;
