import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";

import { SearchIcon } from "assets/icons/24px";
import { DrawerActionsStyled, DrawerBodyStyled, DrawerStyled } from "components";
import { RangePickerField, SelectCheckboxField, ToggleButtonField } from "components/HookFormControls";
import {
  EN_DATE_FORMAT,
  // USER_DP_APPLICATION_STATUSES, USER_PUBLISHING_APPLICATION_STATUSES
} from "constants/options";
import { ApiKeyFields } from "types";

// import { ApplicationTableTab } from "../../types";
import { declinedAccountsOptions, invitedClientsOptions } from "./constants";
import { RenderStatuses } from "./RenderStatuses";
import { ApplicationFilters, FilterDrawerProps } from "./types";

const FilterDrawer: FC<FilterDrawerProps> = ({ open, isAdmin, statuses, onFiltersSubmit, handleResetFilters }) => {
  const {
    uiStore: { isLoading },
  } = useStores();
  const { control, handleSubmit } = useFormContext<ApplicationFilters>();

  return (
    <DrawerStyled open={open} variant="persistent" anchor="left">
      <DrawerBodyStyled>
        {!isAdmin && statuses ? (
          <SelectCheckboxField
            control={control}
            fieldName={ApiKeyFields.user_statuses}
            label="Statuses"
            options={statuses}
            renderValue={RenderStatuses}
          />
        ) : null}
        <RangePickerField
          control={control}
          fieldName={ApiKeyFields.createdAt}
          placeholder={[EN_DATE_FORMAT, EN_DATE_FORMAT]}
          label="Created"
        />
        <ToggleButtonField
          control={control}
          fieldName={ApiKeyFields.user_is_active}
          label="Declined Accounts"
          options={declinedAccountsOptions}
          fullWidth
        />

        {!isAdmin ? (
          <ToggleButtonField
            control={control}
            fieldName={ApiKeyFields.user_is_invited}
            label="Invited Clients"
            options={invitedClientsOptions}
            fullWidth
          />
        ) : null}
      </DrawerBodyStyled>
      <DrawerActionsStyled>
        <Button size="small" variant="link" disabled={isLoading} onClick={handleResetFilters}>
          Reset to Default
        </Button>
        <Button
          size="small"
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={handleSubmit(onFiltersSubmit)}
          disabled={isLoading}
        >
          Search
        </Button>
      </DrawerActionsStyled>
    </DrawerStyled>
  );
};

export default observer(FilterDrawer);
