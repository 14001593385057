import styled from "@emotion/styled";

export const ContentContainerStyled = styled.div`
  display: flex;
  gap: 20px;

  img {
    height: 64px;
    width: 64px;
  }
`;

export const TypographyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
