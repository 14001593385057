import { FC } from "react";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export const TableTooltipCol: FC<{ text: string; title?: string }> = ({ text, title }) => {
  return (
    <Tooltip title={title ?? text} placement="top-start" arrow>
      <Typography maxWidth="100%" noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
};
