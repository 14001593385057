import { GridColDef } from "@mui/x-data-grid";

import { RowModifyActions } from "components/ReleaseForm";
import type { ReleaseColumnsProps } from "components/ReleaseForm/types";
import { RELEASE_ROLES_OPTIONS } from "constants/release";
import { ApiKeyFields, Contributors } from "types";

export const getColumns = ({
  onEditClick,
  onDeleteClick,
  getRowComments,
  isReadOnly,
}: ReleaseColumnsProps<Contributors>): GridColDef[] => [
  {
    field: ApiKeyFields.artistName,
    headerName: "Artist",
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.role,
    headerName: "Role",
    renderCell: ({ value }) => RELEASE_ROLES_OPTIONS.find((option) => option.value === value)?.label,
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.actions,
    sortable: false,
    type: "actions",
    disableColumnMenu: true,
    getActions: ({ row }) => {
      return [
        <RowModifyActions
          key={row.id}
          record={row}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          getRowComments={getRowComments}
          isReadOnly={isReadOnly}
        />,
      ];
    },
  },
];
