import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiTableBody: Components["MuiTableBody"] = {
  styleOverrides: {
    root: {
      "& .MuiTableRow-root": {
        "&:hover": {
          backgroundColor: colors.blue30,
        },
      },
    },
  },
};

export default { MuiTableBody };
