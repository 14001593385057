import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.royalty_rate]: "Integrated Fee",
  [ApiKeyFields.contract_term]: "Term of the Agreement",
  [ApiKeyFields.marketing_service]: "This agreement includes Marketing Services.",
  [ApiKeyFields.advance]: "Advance (Optional)",
  [ApiKeyFields.advance_currency]: "Currency",
  [ApiKeyFields.advance_detail]: "Advance Notes (Optional)",
  [ApiKeyFields.contract_type]: "Agreement Type",
  [ApiKeyFields.reviewer_name]: "IMR Reviewer Name",
  [ApiKeyFields.contract_renewal]: "Autorenewal Term",
  [ApiKeyFields.special_provisions]: "Special Provisions (Optional)",
  [ApiKeyFields.info_confirmation]:
    "I confirm that I have read the information on this page that will be added to the agreement.",
};

export const DISTRIBUTION_AGREEMENT_FORM_FIELDS = [
  ApiKeyFields.royalty_rate,
  ApiKeyFields.contract_term,
  ApiKeyFields.marketing_service,
  ApiKeyFields.advance,
  ApiKeyFields.advance_currency,
  ApiKeyFields.advance_detail,
  ApiKeyFields.contract_type,
  ApiKeyFields.reviewer_name,
  ApiKeyFields.contract_renewal,
  ApiKeyFields.special_provisions,
];

export const RESET_INFO_CONFIRMATION_FIELDS = [
  ApiKeyFields.royalty_rate,
  ApiKeyFields.contract_term,
  ApiKeyFields.contract_renewal,
  ApiKeyFields.marketing_service,
  ApiKeyFields.advance,
  ApiKeyFields.advance_currency,
];

export const DISTRIBUTION_AGREEMENT_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.royalty_rate, required: true },
  { field: ApiKeyFields.contract_term, required: true },
  { field: ApiKeyFields.marketing_service, required: true },
  { field: ApiKeyFields.advance, required: false },
  { field: ApiKeyFields.advance_currency, required: false },
  { field: ApiKeyFields.advance_detail, required: false },
  { field: ApiKeyFields.contract_type, required: true },
  { field: ApiKeyFields.reviewer_name, required: true },
  { field: ApiKeyFields.contract_renewal, required: true },
  { field: ApiKeyFields.special_provisions, required: false },
] as SubRouteFieldProps[];

export const CLIENT_DISTRIBUTION_AGREEMENT_SIDEBAR_FIELDS = [
  ...DISTRIBUTION_AGREEMENT_SIDEBAR_FIELDS.map(({ field }) => ({ field, required: false })),
  { field: ApiKeyFields.info_confirmation, required: true },
] as SubRouteFieldProps[];

export const HELPER_TEXT = {
  [ApiKeyFields.royalty_rate]: "The percentage of income that the agreement signatory will receive.",
  [ApiKeyFields.contract_term]: "The number of years from the date of signing that this Agreement is considered to be in effect.",
  [ApiKeyFields.marketing_service]:
    "IMR is supporting the artist's brand through creative campaigns, best practices, ideation, strategy & execution.",
  [ApiKeyFields.advance]: "The amount of the advance payment, if any, specified in the agreement.",
  [ApiKeyFields.contract_renewal]:
    "The number of years from the date of expiration that this Agreement will be automatically renewed.",
} as Record<ApiKeyFields, string>;

export const TEXT_AREA_MAX_LENGTH = 1500;
