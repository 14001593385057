import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

type Props = {
  title: string;
  titleAction?: ReactNode;
};

const LabelContainerStyled = styled.div`
  margin-top: 8px;
  display: flex;
  align-item: flex-end;
  justify-content: space-between;

  .title {
    display: flex;

    .MuiDivider-root {
      border: 2px solid ${({ theme }) => theme.palette.primary.main};
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      margin-right: 16px;
    }

    .MuiTypography-h6 {
      margin-top: 4px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .title-action {
    display: flex;
  }
`;

const FormLabelDivider: FC<Props> = ({ title, titleAction }) => {
  return (
    <>
      <LabelContainerStyled>
        <div className="title">
          <Divider orientation="vertical" flexItem />
          <Typography variant="h6">{title}</Typography>
        </div>
        <div className="title-action">{titleAction}</div>
      </LabelContainerStyled>
      <Divider sx={{ mt: "18px", mb: "8px" }} />
    </>
  );
};

export default FormLabelDivider;
