import React, { useCallback } from "react";
import { FieldValues } from "react-hook-form";
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import styled from "@emotion/styled";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import DraggableGridRow from "./DraggableGridRow";
import { DndTableProps } from "./types";

const TableContainerStyled = styled(TableContainer)`
  .MuiTableCell-root {
    border-bottom-color: ${({ theme }) => theme.palette.divider};
  }
`;

const DndTable = <T extends FieldValues & { id: string }>({
  columns,
  rows,
  onOrderChange,
  isDragEnabled = true,
  emptyText = "No data available",
  footer,
  ...props
}: DndTableProps<T>) => {
  const modifiers = [restrictToVerticalAxis, restrictToParentElement];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const items = [...rows];

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over) {
        const oldIndex = rows.findIndex((row) => row.id === active.id);
        const newIndex = rows.findIndex((row) => row.id === over.id);
        const reorderedItems = arrayMove(items, oldIndex, newIndex);

        let afterId = null;
        if (newIndex > 0) {
          afterId = reorderedItems[newIndex - 1]?.id || null;
        }

        onOrderChange?.(reorderedItems, {
          oldIndex,
          newIndex,
          afterId,
          currentItemId: active.id as string,
        });
      }
    },
    [rows, onOrderChange],
  );

  return (
    <DndContext modifiers={modifiers} sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={items.map((row) => row.id)}>
        <TableContainerStyled>
          <Table {...props}>
            <TableHead>
              <TableRow>
                {isDragEnabled && <TableCell style={{ width: 50 }} />}
                {columns.map((column) => (
                  <TableCell
                    key={String(column.field)}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    align={column.align || "left"}
                  >
                    <Typography fontWeight={700} color="text.label">
                      {column.headerName}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length > 0 ? (
                items.map((row) => <DraggableGridRow key={row.id} row={row} columns={columns} isDragEnabled={isDragEnabled} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + (isDragEnabled ? 1 : 0)} align="center">
                    <Typography variant="body1">{emptyText}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {footer}
          </Table>
        </TableContainerStyled>
      </SortableContext>
    </DndContext>
  );
};

export default DndTable;
