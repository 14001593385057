import { useEffect } from "react";

export const useConfirmBeforeUnload = (shouldConfirm: boolean) => {
  useEffect(() => {
    if (!shouldConfirm) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // modern browsers show a generic message
      event.returnValue = "Any changes that you have made will be lost.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldConfirm]);
};
