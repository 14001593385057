import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiTooltip: Components["MuiTooltip"] = {
  styleOverrides: {
    tooltip: {
      color: colors.dark233,
      fontSize: 12,
      fontWeight: 400,
      maxWidth: 304,
      lineHeight: "16px",
      backgroundColor: colors.white,
      padding: "11px 16px 13px 16px",
      boxShadow: "0px 4px 16px 0px #0D1C2626",
    },
    arrow: {
      "&::before": {
        color: colors.white,
      },
    },
  },
};
