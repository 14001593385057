export enum AppUserRole {
  ADMIN = "ADMIN",
  COMMON = "COMMON",
}

export enum DistributionType {
  ARTIST = 1,
  ARTIST_REPRESENTATIVE = 2,
}

export enum DistributionTypeString {
  "Artist" = 1,
  "Artist Representative" = 2,
}

export enum PublishingType {
  SONGWRITER = 1,
  SONGWRITER_REPRESENTATIVE = 2,
}

export enum PublishingTypeString {
  "Songwriter" = 1,
  "Songwriter Representative" = 2,
}

export enum DistributionAndPublishingTypeString {
  "Artist/Songwriter" = 1,
  "Artist/Songwriter Representative" = 2,
}

export enum DistributionTier {
  Entry = 1,
  Emerging = 2,
  Established = 3,
}

export enum PublishingTier {
  Entry = 1,
}

export enum UserStatus {
  INITIAL = 1,
  NOT_QUALIFIED = 2,
  WAIT_FOR_QUALIFICATION = 3,
  EDIT_DISTRIBUTION_INFO_FORM = 101,
  WAIT_FOR_DISTRIBUTION_INFO_FORM_REVIEW = 102,
  UPDATE_DISTRIBUTION_INFO_FORM = 103,
  EDIT_DISTR_LEGAL_FORM = 104,
  WAIT_FOR_DISTR_LEGAL_FORM_REVIEW = 105,
  UPDATE_DISTRIBUTION_LEGAL_FORM = 106,
  DISTR_CONTRACT_GENERATED = 107,
  SIGN_DISTRIBUTION_CONTRACT_PREVIEW = 108,
  DISTRIBUTION_CONTRACT_SIGNED = 109,
  EDIT_PUBLISHING_INFO_FORM = 201,
  DISTR_RELEASE_MANAGER_AVAILABLE = 401,
  PUB_RELEASE_MANAGER_AVAILABLE = 402,
  DAP_RELEASE_MANAGER_AVAILABLE = 403,
}

export enum UserListTable {
  Distribution = "distribution",
  DistributionAndPublishing = "distributionAndPublishing",
  Publishing = "publishing",
}
