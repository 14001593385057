import styled from "@emotion/styled";

import Backdrop from "@mui/material/Backdrop";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import { alpha } from "@mui/material/styles";

import { backgroundGradient } from "constants/layout";
import { LAYOUT } from "theme";

export const PageWrapper = styled.div`
  min-height: calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled(PageWrapper, {
  shouldForwardProp: (prop) => !["justifyContent", "includeStickyBar", "noBottomSpacing"].includes(prop),
})<{ justifyContent?: string; includeStickyBar?: boolean; noBottomSpacing?: boolean }>`
  padding-bottom: ${({ noBottomSpacing }) => (noBottomSpacing ? "0px" : "76px")};
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.background.surface};
  justify-content: ${({ justifyContent }) => justifyContent};
  background: ${backgroundGradient};

  ${({ includeStickyBar }) => includeStickyBar && `padding-top: 121px;`}
`;

export const TablePageContainer = styled(PageContainer)`
  padding-bottom: 0;
  .MuiContainer-root {
    max-width: 100%;
  }
  .MuiAppBar-positionSticky {
    margin-bottom: 0;
  }
`;

export const MessageContainerStyled = styled(Container, {
  shouldForwardProp: (prop) => prop !== "paddingTop",
})<{ paddingTop?: string }>`
  width: fit-content;
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

export const PlaceHolderExample = styled("div", {
  shouldForwardProp: (prop) => prop !== "cardImage",
})<{ cardImage?: string }>(
  ({ theme, cardImage }) => `  
  width: 291px;
  height: 160px;
  margin-bottom: 21px;
  background: ${cardImage ? `url(${cardImage})` : "none"};
  background-color: #${theme.palette.secondary.main};
  background-repeat: no-repeat;
  border-radius: ${theme.shape.borderRadius}px;
  background-size:
    100% auto,
    cover;
`,
);

export const BackdropStyled = styled(Backdrop)`
  display: flex;
  flex-direction: column;
  gap: 17px;
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.6)};
  z-index: 2000;
  position: fixed;
`;

export const ProgressBarStyled = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 44px 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const DrawerBodyStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  gap: 24px;
  padding: 23px 20px;
`;

export const DrawerActionsStyled = styled("div")(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  background-color: ${theme.palette.common.white};
  border-top: 1px solid ${theme.palette.divider};
  padding: 20px 16px;`,
);

export const DrawerStyled = styled(Drawer)`
  z-index: 10;
  width: 347px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: 347px;
    box-sizing: border-box;
    position: absolute;
    overflow-y: visible;
  },
`;

export const PdfPreviewIframeStyled = styled.iframe`
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: none;
`;

export const FormHelperTextContainerStyled = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ToggleTabBtnContainer = styled.div`
  width: fit-content;
  flex: 1;
  .MuiPaper-root {
    width: fit-content;
  }
`;

export const TableWrapperContainerStyled = styled(Container)`
  height: calc(100vh - 173px);
  max-height: 100%;
  overflow-y: scroll;
  min-height: 500px;
  display: flex;
  position: relative;
  max-width: 100%;
`;

export const TableContainerStyled = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 24px 51px;
`;

export const RowActionsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const RowActionsContainerStyled = styled("div")`
  display: none;
  align-items: center;
`;

export const FormBlockHeaderStyled = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? "auto"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const RowHighlightContainerStyled = styled("button")(
  ({ theme }) => `
    background-color: transparent;
    border: none;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px;
    overflow: hidden;
    gap: 6px;

    .MuiTypography-root {
      margin-left: 14px;
    }

    svg {
      display: none;
    }

    &:hover {
        background-color: ${theme.palette.common.white};
        border-radius: ${theme.shape.borderRadius}px;
        cursor: pointer;

        svg {
          display: block;
        }
        .MuiTypography-root {
          margin-left: 0;
        }
    }
`,
);

export const LinkTextStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
`;
