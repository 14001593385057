import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import styled from "@emotion/styled";
import JoditEditor, { IJoditEditorProps, Jodit } from "jodit-react";

import { templateFonts } from "utils/jodit/template";

import { defaultConfig } from "./config";

const JoditStyled = styled(JoditEditor)`
  .jodit-react-container {
    width: 100%;
  }

  a.jodit-status-bar-link,
  .jodit-editor__resize {
    display: none;
  }
`;

Jodit.plugins.add("cleanHTML", function (jodit: Jodit) {
  jodit.e.on(
    "paste",
    (e: ClipboardEvent) => {
      jodit.e.stopPropagation("paste");
      const dataTransfer = Jodit.modules.Helpers.getDataTransfer(e);
      if (dataTransfer) {
        const html = dataTransfer.getData(Jodit.constants.TEXT_HTML) || "";
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const cleanText = tempDiv.textContent || tempDiv.innerText || "";
        jodit.s.insertHTML(cleanText);
      }
      return false;
    },
    { top: true },
  );
});

const Editor = forwardRef<IJoditEditorProps, IJoditEditorProps>((props, ref) => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const editorRef = useRef<any>(null);

  useEffect(() => {
    const loadFont = async (url: string) => {
      const link = document.createElement("link");
      link.href = url;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    };

    Object.entries(templateFonts).forEach(([, url]) => loadFont(url));
  }, []);

  useImperativeHandle(ref, () => ({
    get value() {
      return editorRef.current?.value;
    },
    set value(val: string) {
      if (editorRef.current) {
        editorRef.current.value = val;
      }
    },
  }));

  return <JoditStyled {...props} ref={editorRef} config={defaultConfig} />;
});

Editor.displayName = "JoditEditor";

export default Editor;
