import { useParams } from "react-router-dom";
import { COMMENT_MODE } from "context/CustomFormContext";
import { useStores } from "stores";

import { Comment, CommentForm, PrimaryFormNames, ReleaseStatus, SecondaryFormNames } from "types";

import { ReleaseUrlParams } from "../types";

import { useFetchComments } from "./useFetchComments";
import { ReturnReleaseFormState, usePrimaryFormState } from "./usePrimaryFormState";

interface SecondaryFormStateCbProps {
  secondaryFormName: SecondaryFormNames;
  secondaryFormId: string;
  filterCommentKey: keyof Comment;
}

export const useSecondaryFormState = (): ((props: SecondaryFormStateCbProps) => ReturnReleaseFormState) => {
  const {
    userStore: { isAdmin },
    revisedFormStore: {
      release,
      comments: { postSecondaryComment, deleteSecondaryComment, patchSecondaryComment },
    },
  } = useStores();
  const { updateCommentState } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const formState = usePrimaryFormState();

  const commentCallback =
    ({ secondaryFormName, secondaryFormId, filterCommentKey }: SecondaryFormStateCbProps) =>
    async (comment: CommentForm, mode: COMMENT_MODE) => {
      if (!releaseId) return;
      try {
        switch (mode) {
          case COMMENT_MODE.ADD:
            await postSecondaryComment(userId, PrimaryFormNames.Releases, releaseId, secondaryFormName, secondaryFormId, comment);
            break;
          case COMMENT_MODE.EDIT:
            await patchSecondaryComment(
              userId,
              PrimaryFormNames.Releases,
              releaseId,
              secondaryFormName,
              secondaryFormId,
              comment.fieldName,
              comment,
              filterCommentKey,
            );
            break;
          case COMMENT_MODE.DELETE:
            await deleteSecondaryComment(
              userId,
              PrimaryFormNames.Releases,
              releaseId,
              secondaryFormName,
              secondaryFormId,
              comment.fieldName,
              filterCommentKey,
            );
            break;
        }
        await updateCommentState(secondaryFormName);
      } finally {
        null;
      }
    };

  return (callbackProps) => {
    if (!isAdmin && release?.status === ReleaseStatus.ChangesNeeded) {
      return {
        ...formState,
        readOnly: {
          ...formState.readOnly,
          fields: false,
          excludeFields: [],
        },
        comments: {
          ...formState.comments,
          commentCallback: commentCallback(callbackProps),
        },
      };
    }

    return {
      ...formState,
      comments: {
        ...formState.comments,
        commentCallback: commentCallback(callbackProps),
      },
    };
  };
};
