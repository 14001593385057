import { ApiKeyFields, Comment, CommentForm } from "types";

export enum COMMENT_MODE {
  EDIT,
  ADD,
  DELETE,
}

export enum COMMENT_TYPE {
  ADMIN,
  CLIENT,
  CLIENT_AND_ADMIN,
  READ_ONLY,
}

export interface CommentType {
  fieldName: ApiKeyFields;
  type: COMMENT_TYPE;
}

export interface CustomFormContextState {
  isFieldReadOnly: (field: ApiKeyFields) => boolean;
  isFieldCommented: (field: ApiKeyFields) => boolean;
  isFieldEdited: (field: ApiKeyFields) => boolean;
  getCommentType: (field: ApiKeyFields) => COMMENT_TYPE;
  commentCallback?: (comment: CommentForm, mode: COMMENT_MODE) => Promise<void>;
  formComments: Comment[];
  isAdmin?: boolean;
}

export interface FormStateReadOnlyConfig {
  fields: ApiKeyFields[] | boolean;
  excludeFields?: ApiKeyFields[];
}

export interface FormStateCommentsConfig {
  fields: ApiKeyFields[] | boolean;
  excludeFields?: ApiKeyFields[];
  commentTypes?: CommentType[] | COMMENT_TYPE;
  commentCallback?: (comment: CommentForm, mode: COMMENT_MODE) => Promise<void>;
  formComments?: Comment[];
}

export interface FormStateEditConfig {
  fields: ApiKeyFields[] | boolean;
  excludeFields?: ApiKeyFields[];
}

export interface CustomFormProps {
  readOnly: FormStateReadOnlyConfig;
  comments: FormStateCommentsConfig;
  edit: FormStateEditConfig;
  children?: React.ReactNode;
  isAdmin?: boolean;
}
