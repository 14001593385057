import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const TEXT_AREA_MAX_LENGTH = 1000;

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.genre]: "Genre",
  [ApiKeyFields.sub_genre]: "Sub-Genre (Optional)",
  [ApiKeyFields.territory]: "Territory",
  [ApiKeyFields.territory_notes]: "Territory Notes (Optional)",
};

export const HELPER_TEXT = {
  [ApiKeyFields.territory_notes]:
    "If you have any territories that need to be excluded from this release, please let us know here.",
} as Record<ApiKeyFields, string>;

export const OPTIONAL_FIELDS: { [key: string]: true } = {
  [ApiKeyFields.sub_genre]: true,
  [ApiKeyFields.territory_notes]: true,
};

export const GENRE_TERRITORY_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.genre, required: true },
  { field: ApiKeyFields.sub_genre, required: false },
  { field: ApiKeyFields.territory_notes, required: false },
] as SubRouteFieldProps[];
