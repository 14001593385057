import { FC } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import { ExternalLinkIcon } from "assets/icons/24px";
import { ApiKeyFields } from "types";
import { formatNumberLocale } from "utils";

const FollowersBlockStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

type FollowersInfoProps = {
  label: string;
  url?: string | null;
  btnText: string;
};

const FollowersInfo: FC<FollowersInfoProps> = ({ label, url, btnText }) => (
  <FollowersBlockStyled>
    <Chip variant="filled" size="small" color="primary" label={label} />
    {url ? (
      <Button variant="link" size="small" endIcon={<ExternalLinkIcon />} href={url} target="_blank" rel="noreferrer">
        {btnText}
      </Button>
    ) : null}
  </FollowersBlockStyled>
);

type Props = {
  fieldName: ApiKeyFields;
};

const FollowersHelper: FC<Props> = ({ fieldName }) => {
  const {
    qualificationStore: { qualificationForm },
  } = useStores();

  let content = null;

  if (fieldName === ApiKeyFields.spotifyArtistId) {
    content = (
      <FollowersInfo
        label={`${formatNumberLocale(qualificationForm?.artists[0].spotifyArtistFollowers) ?? "-"} Followers`}
        url={qualificationForm?.artists[0].spotifyArtistUrl}
        btnText="Open Profile"
      />
    );
  } else if (fieldName === ApiKeyFields.youtubeChannelId) {
    content = (
      <FollowersInfo
        label={`${formatNumberLocale(qualificationForm?.artists[0].youtubeChannelSubscribers) ?? "-"} Subscribers`}
        url={qualificationForm?.artists[0].youtubeChannelUrl}
        btnText="Open Channel"
      />
    );
  }

  return content;
};

export default observer(FollowersHelper);
