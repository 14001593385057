import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { RootStore } from "stores";

import { notificationService } from "services";
import { Notification } from "types";

const NOTIFICATIONS_LIMIT = 10;

export class NotificationStore {
  @observable notifications: Notification[] = [];
  @observable notificationsCount: number = 0;
  @observable hasMoreNotifications: boolean = false;
  @observable isNotificationsDisabled: boolean = false;
  @observable isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getCurrentNotifications = async (
    offset: number = 0,
    limit: number = NOTIFICATIONS_LIMIT,
    reset?: boolean,
    notification_with_release_relations?: boolean,
  ) => {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const { notifications, numNotifications } = await notificationService.getCurrentNotifications(
        offset,
        limit,
        notification_with_release_relations,
      );
      runInAction(() => {
        this.notifications = reset ? notifications : [...this.notifications, ...notifications];
        this.hasMoreNotifications = notifications.length === NOTIFICATIONS_LIMIT;
        this.notificationsCount = numNotifications;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteCurrentNotification = async (notificationId: string) => {
    try {
      runInAction(() => {
        this.rootStore.uiStore.setOverlayLoading(true);
      });
      await notificationService.deleteCurrentNotificationsId(notificationId);
      runInAction(() => {
        this.notifications = this.notifications.filter(({ id }) => id !== notificationId);
        this.notificationsCount = this.notificationsCount - 1;
      });
    } finally {
      runInAction(() => {
        this.rootStore.uiStore.resetLoading();
      });
    }
  };

  @action
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setNotificationsDisabled = (isNotificationsDisabled: boolean) => {
    this.isNotificationsDisabled = isNotificationsDisabled;
  };

  @action
  resetNotifications = () => {
    this.notifications = [];
    this.isNotificationsDisabled = false;
    this.isLoading = false;
  };
}
