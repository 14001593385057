import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ReleaseTableListBlock, Table } from "components";
import { LabelWithAsterisk } from "components";
import { TableNoDataMessage, usePrimaryFormState } from "components/ReleaseForm";
import { Contributors } from "types";

import { getColumns } from "./columns";
import { CONTRIBUTORS_MAX_LIMIT } from "./constants";
import { ContributorsBlockProps } from "./types";
import { useContributors } from "./useContributors";

const TableNoData = () => <TableNoDataMessage text="There are no contributor(s) added." />;

const ContributorsBlock: FC<ContributorsBlockProps> = ({ track }) => {
  const {
    revisedFormStore: {
      comments: { tertiaryComments },
    },
  } = useStores();
  const { fields, contributorsLoading, handleAddContributor, handleEditContributor, handleDeleteClick, handleCopyContributor } =
    useContributors(track);
  const { isReadOnly } = usePrimaryFormState();

  const getRowComments = (contributor: Contributors) =>
    tertiaryComments.filter(({ releaseContributorId }) => releaseContributorId === contributor.id);

  const columns = useMemo(
    () => getColumns({ onEditClick: handleEditContributor, onDeleteClick: handleDeleteClick, getRowComments, isReadOnly }),
    [handleEditContributor, handleDeleteClick],
  );

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <ReleaseTableListBlock
      title={<LabelWithAsterisk>Contributor(s)</LabelWithAsterisk>}
      itemsCount={itemsCount}
      maxLimit={CONTRIBUTORS_MAX_LIMIT}
      onAddClick={handleAddContributor}
      addBtnText="Add Contributor"
      isLoading={contributorsLoading}
      copyBtnText="Copy Contributor"
      onCopyClick={handleCopyContributor}
      hideActionBtn={isReadOnly}
    >
      <Table rows={fields} columns={columns} slots={{ noRowsOverlay: TableNoData }} autoHeight hideFooter />
    </ReleaseTableListBlock>
  );
};

export default observer(ContributorsBlock);
