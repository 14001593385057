import React from "react";

import Fallback from "./Fallback";
import { Props, State } from "./types";

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public render() {
    const { hasError, error } = this.state;
    if (hasError && error) {
      return <Fallback error={error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
