import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { SubRouteFieldProps } from "components/FormProgressBar";
import { FormHelperTextContainerStyled } from "components/Layout/index";
import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.schedules_at]: "Schedule the Date",
  [ApiKeyFields.date_confirmation]: "I confirm that the Integrated Music Rights Team will choose the date and keep me informed.",
  [ApiKeyFields.schedule_earlier]: "Request earlier date?",
  [ApiKeyFields.additional_options]: "Additional Options",
};

export const HELPER_TEXT: { [key: string]: string | ReactNode } = {
  [ApiKeyFields.schedules_at]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        For efficient release planning, we schedule release dates starting from 5 weeks ahead of today.
      </Typography>
      <Typography variant="body1" color="text.label">
        This allows us to effectively coordinate multiple artists' releases.
      </Typography>
    </FormHelperTextContainerStyled>
  ),
  [ApiKeyFields.additional_options]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        One size does not fit all.
      </Typography>
      <Typography variant="body1" color="text.label">
        If your situation doesn't align with our standard release verification process, please provide details in a comment and
        specify your preferred release dates (beginning 2 weeks from today).
      </Typography>
      <Typography variant="body1" color="text.label">
        We will kindly evaluate your request. If feasible, we will consider your preferred dates and contact you outside the
        system by email or phone. Otherwise, the release will proceed as per the date selected in the above calendar view.
      </Typography>
    </FormHelperTextContainerStyled>
  ),
};

export const RELEASE_DATE_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.schedule_earlier, required: false },
  { field: ApiKeyFields.schedules_at, required: true },
  { field: ApiKeyFields.date_confirmation, required: true },
  { field: ApiKeyFields.additional_options, required: false },
] as SubRouteFieldProps[];
