import { SupportedLocale } from "i18n/constants";

import { en_us } from "./en_us";
// import { es } from "./es";

// To ensure consistency across localizations, English (en) is designated
// as the primary language for the project. All other language files must align
// with the structure and content of this primary English JSON file.
export const resources: { [locale in SupportedLocale]: typeof en_us } = {
  // replace unknown with strict type typeof en
  en_us,
  // es,
};
