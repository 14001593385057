import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OptionFieldsAlert } from "components/FormComponents";
import { StepFlowController } from "components/FormComponents/StepFlowController";
import ArtistInformation from "components/QualificationForm/ArtistInformation";
import ContactInformation from "components/QualificationForm/ContactInformation";
import SelectTier from "components/QualificationForm/SelectTier";
import { ROUTES } from "constants/routes";
import { RESOURCES_KEYS } from "i18n";

const DistributionForm: FC<{
  contactBlockTitle?: string;
  isEmailReadOnly?: boolean;
  readOnly?: boolean;
}> = ({ contactBlockTitle, isEmailReadOnly, readOnly }) => {
  return (
    <>
      <ContactInformation blockTitle={contactBlockTitle} isEmailReadOnly={isEmailReadOnly || readOnly} readOnly={readOnly} />
      <ArtistInformation readOnly={readOnly} />
    </>
  );
};

const GenericDistributionForm: FC<{
  isInvited?: boolean;
}> = ({ isInvited, ...props }) => {
  const { t } = useTranslation([RESOURCES_KEYS.QUALIFICATION]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    const backRoute = isInvited ? `${ROUTES.INVITE}${ROUTES.DISTRIBUTION}` : ROUTES.DISTRIBUTION;
    navigate(backRoute);
  };

  return (
    <StepFlowController onBackBtnClick={handleBackClick} nextBtnDisabled {...props}>
      <OptionFieldsAlert />
      {isInvited && <SelectTier />}
      <DistributionForm contactBlockTitle={isInvited ? t("contactInfo.contactInfo") : undefined} isEmailReadOnly={!isInvited} />
    </StepFlowController>
  );
};

export default GenericDistributionForm;
export { DistributionForm };
