import styled from "@emotion/styled/macro";

import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { CloseIcon } from "assets/icons/14px";
import { DeleteIcon } from "assets/icons/24px";

import { getBorderColor } from "./helpers";

export const DropZoneBoxStyled = styled("div", {
  shouldForwardProp: (prop) => !["disabled", "isDragAccept", "isFocused"].includes(prop),
})<{ disabled?: boolean }>`
  min-height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-style: dashed;
  background-color: ${(props) => getBorderColor(props)};
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: border 0.24s ease-in-out;
  overflow: hidden;

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

export const BrowseFilesTextStyled = styled(Typography)`
  cursor: pointer;
`;

export const ActionOverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => alpha(theme.palette.background.surface!, 0.9)};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .MuiIconButton-root {
    &:hover {
      background-color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const ActionContainerStyled = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const ReuploadContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${ActionOverlayStyled} {
    display: none;
  }

  &:hover {
    ${ActionOverlayStyled} {
      display: flex;
    }
  }
`;

export const DeleteIconSvgStyled = styled(DeleteIcon)`
  path {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

export const CloseIconSvgStyled = styled(CloseIcon)`
  path {
    fill: ${({ theme }) => theme.palette.text.error};
  }
`;
