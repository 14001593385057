import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "i18n/constants";
import { resources } from "i18n/locales";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: [DEFAULT_LOCALE],
    supportedLngs: SUPPORTED_LOCALES,
    resources,
  });

export default i18next;
