export async function validateTiffFile(files: File[]) {
  const file = files[0];

  // Check for .tif or .tiff extension
  const fileName = file.name.toLowerCase();
  if (!fileName.endsWith(".tif") && !fileName.endsWith(".tiff")) {
    return { valid: false, error: "File must have a .tif or .tiff extension." };
  }

  const buffer = await file.arrayBuffer();
  const dataView = new DataView(buffer);

  // TIFF files start with a header: first two bytes indicate the byte order ("II" for little-endian, "MM" for big-endian)
  const byteOrder = dataView.getUint16(0);
  const isLittleEndian = byteOrder === 0x4949; // "II" for little-endian

  // Support only valid TIFF byte orders (either "II" for little-endian or "MM" for big-endian)
  if (!isLittleEndian && byteOrder !== 0x4d4d) {
    return { valid: false, error: "Invalid TIFF byte order." };
  }

  // Check for valid TIFF magic number (42)
  const magicNumber = dataView.getUint16(2, isLittleEndian);
  if (magicNumber !== 42) {
    return { valid: false, error: "Invalid TIFF magic number." };
  }

  // Read the first Image File Directory (IFD)
  const ifdOffset = dataView.getUint32(4, isLittleEndian);

  let width = 0;
  let height = 0;
  let bitsPerSampleOffset = 0;
  let samplesPerPixel = 0;
  let photometricInterpretation = 0;
  let planarConfiguration = 0;
  let compression = 0;
  const extraSamples: number[] = [];

  // Number of tags in the IFD (Image File Directory)
  const numOfTags = dataView.getUint16(ifdOffset, isLittleEndian);

  // Iterate through the IFD tags to extract relevant information
  for (let i = 0; i < numOfTags; i++) {
    const tagOffset = ifdOffset + 2 + i * 12;
    const tag = dataView.getUint16(tagOffset, isLittleEndian);
    const count = dataView.getUint32(tagOffset + 4, isLittleEndian);
    const valueOffset = tagOffset + 8;

    switch (tag) {
      case 256: // Image Width
        width = dataView.getUint32(valueOffset, isLittleEndian);
        break;
      case 257: // Image Height
        height = dataView.getUint32(valueOffset, isLittleEndian);
        break;
      case 258: // Bits Per Sample (store the offset for later reading)
        bitsPerSampleOffset = dataView.getUint32(valueOffset, isLittleEndian);
        break;
      case 259: // Compression
        compression = dataView.getUint16(valueOffset, isLittleEndian);
        break;
      case 262: // Photometric Interpretation (defines color model)
        photometricInterpretation = dataView.getUint16(valueOffset, isLittleEndian);
        break;
      case 277: // Samples Per Pixel (number of color channels)
        samplesPerPixel = dataView.getUint16(valueOffset, isLittleEndian);
        break;
      case 284: // Planar Configuration (defines how color channels are stored)
        planarConfiguration = dataView.getUint16(valueOffset, isLittleEndian);
        break;
      case 338: // Extra Samples (e.g., alpha channels)
        {
          const extraSamplesOffset = count > 1 ? dataView.getUint32(valueOffset, isLittleEndian) : valueOffset;
          for (let j = 0; j < count; j++) {
            extraSamples.push(dataView.getUint16(extraSamplesOffset + j * 2, isLittleEndian));
          }
        }
        break;
    }
  }

  // Ensure the image uses LZW compression (Compression: 5)
  if (compression !== 5) {
    return { valid: false, error: "Image must be LZW compressed." };
  }

  // Check for 8-bit RGB color mode
  const bitsPerSample = [];
  for (let i = 0; i < samplesPerPixel; i++) {
    bitsPerSample.push(dataView.getUint16(bitsPerSampleOffset + i * 2, isLittleEndian));
  }

  if (!bitsPerSample.every((bit) => bit === 8)) {
    return { valid: false, error: "Image must be 8-bit RGB." };
  }

  // Ensure the image uses the RGB color mode (PhotometricInterpretation: 2 = RGB)
  if (photometricInterpretation !== 2) {
    return { valid: false, error: "Image must be in RGB color mode. No CMYK or other color modes allowed." };
  }

  // Check that the image has exactly 3 channels (RGB)
  if (samplesPerPixel !== 3) {
    return { valid: false, error: "Image must have 3 channels (RGB). No alpha channels or layers allowed." };
  }

  // Ensure there are no extra samples (no alpha channels or layers)
  if (extraSamples.length > 0) {
    return { valid: false, error: "Image must not contain alpha channels or layers." };
  }

  // Ensure the color channels are interleaved (PlanarConfiguration: 1 = interleaved)
  if (planarConfiguration !== 1) {
    return { valid: false, error: "Image data must be stored in interleaved format (no separate layers)." };
  }

  // Check the image dimensions
  if (width < 1400 || height < 1400) {
    return { valid: false, error: "Dimensions are too small. Minimum is 1400x1400px." };
  }

  if (width > 4000 || height > 4000) {
    return { valid: false, error: "Dimensions exceed the maximum limit of 4000x4000px." };
  }

  // Ensure the aspect ratio is 1:1
  if (width !== height) {
    return { valid: false, error: "Aspect ratio is not 1:1." };
  }

  // If all checks pass, the file is valid
  return { valid: true, error: null };
}
