import { useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { CommentNotificationsIcon, EditNotifications } from "assets/icons/14px";
import { progressBarFieldLabels as updateReleaseLabels } from "components/ReleaseForm/constants";
import { EN_DATE_TIME_FORMAT } from "constants/options";
import { ROUTES } from "constants/routes";
import { progressBarFieldLabels as updateDistrInfoBarLabels } from "pages/User/Registration/DistributionInfo/constants";
import { progressBarConfig as updateDistrInfoBarConfig } from "pages/User/Registration/DistributionInfo/progressBarConfig";
import { progressBarFieldLabels as updateDistrLegalBarLabels } from "pages/User/Registration/DistrLegal/constants";
import { progressBarConfig as updateDistrLegalBarConfig } from "pages/User/Registration/DistrLegal/progressBarConfig";
import { baseSubRouteConfig as releaseSidebarBarConfig } from "pages/User/Releases/Release/progressBarConfig";
import { ApiKeyFields, NOTIFICATION_TYPES, UserStatus } from "types";
import { utcToFormat } from "utils";
import { convertCamelToSnakeCase } from "utils";

import {
  InfoDividerStyled,
  NotificationActionStyled,
  NotificationBodyStyled,
  NotificationInfoStyled,
  NotificationItemStyled,
  NotificationTitleStyled,
} from "./styled";
import { NotificationItemProps } from "./types";
import { useFieldRoute } from "./useFieldRoute";

const NotificationItem = ({ notification }: NotificationItemProps) => {
  const {
    userStore: { user },
  } = useStores();
  const { handleNotificationAction } = useFieldRoute();

  const getRouteConfig = useMemo(() => {
    switch (user?.status) {
      case UserStatus.UPDATE_DISTRIBUTION_INFO_FORM:
        return updateDistrInfoBarConfig;
      case UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM:
        return updateDistrLegalBarConfig;
      case UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE:
      case UserStatus.DAP_RELEASE_MANAGER_AVAILABLE:
        return releaseSidebarBarConfig(ROUTES.CLIENT_DRAFT_RELEASE_ID(notification.releaseId!));
      default:
        return [];
    }
  }, [user?.status]);

  const handleNotificationClick = () => {
    handleNotificationAction(getRouteConfig, notification);
  };

  const getRouteLabels = () => {
    switch (user?.status) {
      case UserStatus.UPDATE_DISTRIBUTION_INFO_FORM:
        return updateDistrInfoBarLabels;
      case UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM:
        return updateDistrLegalBarLabels;
      case UserStatus.DAP_RELEASE_MANAGER_AVAILABLE:
      case UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE:
        return convertCamelToSnakeCase(updateReleaseLabels);
      default:
        return {};
    }
  };

  const fieldLabel = getRouteLabels()[notification.fieldName as ApiKeyFields] ?? notification.fieldName;
  const formattedDate = utcToFormat(notification.createdAt, EN_DATE_TIME_FORMAT);
  const isRelease = [UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE, UserStatus.DAP_RELEASE_MANAGER_AVAILABLE].includes(
    user?.status as UserStatus,
  );

  switch (notification.type) {
    case NOTIFICATION_TYPES.EDIT_FIELD:
      return (
        <NotificationItemStyled>
          <EditNotifications />
          <NotificationBodyStyled>
            <NotificationTitleStyled>
              This field in the {isRelease ? "release" : "registration"} form has been updated.
            </NotificationTitleStyled>
            <NotificationInfoStyled>
              <InfoDividerStyled />
              <Typography variant="body1">
                Field: <span>{fieldLabel as string}</span>
              </Typography>
            </NotificationInfoStyled>
            <NotificationActionStyled>
              <Button variant="contained" color="primary" size="small" onClick={handleNotificationClick}>
                Go to Update
              </Button>
              <Typography variant="caption" color="text.label">
                {formattedDate}
              </Typography>
            </NotificationActionStyled>
          </NotificationBodyStyled>
        </NotificationItemStyled>
      );
    case NOTIFICATION_TYPES.COMMENT:
      return (
        <NotificationItemStyled>
          <CommentNotificationsIcon />
          <NotificationBodyStyled>
            <NotificationTitleStyled>
              Your {isRelease ? "release" : "registration"} form has been reviewed. Please review this comment.
            </NotificationTitleStyled>
            <NotificationInfoStyled>
              <InfoDividerStyled />
              <Typography variant="body1">
                Field: <span>{fieldLabel as string}</span>
              </Typography>
            </NotificationInfoStyled>
            <NotificationActionStyled>
              <Button variant="contained" color="primary" size="small" onClick={handleNotificationClick}>
                Go to Comment
              </Button>
              <Typography variant="caption" color="text.label">
                {formattedDate}
              </Typography>
            </NotificationActionStyled>
          </NotificationBodyStyled>
        </NotificationItemStyled>
      );
    default:
      return null;
  }
};

export default observer(NotificationItem);
