import { isNil } from "lodash";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import some from "lodash/some";

import { fieldsHaveActiveComment, fieldsHaveResolvedComment, SubRouteConfigProps } from "components/FormProgressBar";
import { RELEASE_DATE_SIDEBAR_FIELDS } from "components/ReleaseForm/ReleaseDate/constants";
import { TRACKS_SIDEBAR_FIELDS } from "components/ReleaseForm/TrackManager/Tracks/constants";
import { TERRITORY_WITH_CODE_OPTIONS } from "constants/release";
import { ApiKeyFields, ReleaseCommentCount, SecondaryFormNames, TracksForm } from "types";
import { DigitalReleaseFormPayload, WorksForm } from "types/revisedForms";
import { convertCamelToSnakeCase } from "utils";

import { RevisedListCounts } from "./types";

export const prepareReleaseForm = (formData: DigitalReleaseFormPayload) => {
  const result = convertCamelToSnakeCase({
    ...formData,
    [ApiKeyFields.territory]: isString(formData[ApiKeyFields.territory])
      ? formData[ApiKeyFields.territory].split(",")
      : TERRITORY_WITH_CODE_OPTIONS,
    [ApiKeyFields.schedule_earlier]: formData[ApiKeyFields.scheduleEarlier] ?? false,
  });
  return result;
};

export const areAllWorksComplete = (works: WorksForm[]): boolean => {
  return works?.every((work: WorksForm): boolean => ![work[ApiKeyFields.lyrics]].some((value) => isNil(value)));
};

export const isTrackComplete = (track: TracksForm): boolean => {
  return ![track[ApiKeyFields.title], track[ApiKeyFields.language], track[ApiKeyFields.audioBlobPath]].some((value) =>
    isNil(value),
  );
};

export const areAllTracksComplete = (tracks: TracksForm[]): boolean => {
  return tracks?.every(isTrackComplete);
};

export function listHasComments(counts: ReleaseCommentCount): boolean {
  return counts[ApiKeyFields.numCommentsForUser] > 0 || counts[ApiKeyFields.numCommentsForAdmin] > 0;
}

export const worksSubRouteConfig = ({ theme, watch, comments }: SubRouteConfigProps & { comments: RevisedListCounts }) => {
  if (listHasComments(comments[SecondaryFormNames.ReleaseWorks])) {
    return theme.palette.warning.main;
  }
  const works = watch("works");
  const requiredFields = [ApiKeyFields.lyrics];
  const hasIncompleteWorks = some(works, (work) => some(requiredFields, (field) => isEmpty(work[field])));
  return hasIncompleteWorks
    ? theme.palette.branding.main
    : works?.length
      ? theme.palette.success.main
      : theme.palette.secondary.main;
};

export const tracksSubRouteConfig = (
  { theme, watch, comments }: SubRouteConfigProps & { comments: RevisedListCounts },
  isClientReview: boolean = false,
) => {
  if (
    listHasComments(comments[SecondaryFormNames.ReleaseTracks]) ||
    fieldsHaveActiveComment(TRACKS_SIDEBAR_FIELDS, comments.lvl1Comments)
  ) {
    return theme.palette.warning.main;
  }
  if (fieldsHaveResolvedComment(TRACKS_SIDEBAR_FIELDS, comments.lvl1Comments)) {
    return isClientReview ? theme.palette.success.main : theme.palette.primary.main;
  }
  const tracks = watch("tracks");
  const requiredFields = [ApiKeyFields.title, ApiKeyFields.language, ApiKeyFields.audioBlobPath];
  const hasIncompleteTracks = some(tracks, (track) => some(requiredFields, (field) => isEmpty(track[field])));
  return hasIncompleteTracks
    ? theme.palette.branding.main
    : tracks?.length
      ? theme.palette.success.main
      : theme.palette.secondary.main;
};

export const releaseDateRouteConfig = (
  { watch, theme, comments }: SubRouteConfigProps & { comments: RevisedListCounts },
  isClientReview: boolean = false,
) => {
  if (fieldsHaveActiveComment(RELEASE_DATE_SIDEBAR_FIELDS, comments.lvl1Comments)) {
    return theme.palette.warning.main;
  }
  if (fieldsHaveResolvedComment(RELEASE_DATE_SIDEBAR_FIELDS, comments.lvl1Comments)) {
    return isClientReview ? theme.palette.success.main : theme.palette.primary.main;
  }
  const [schedulesAt, dateConfirmation, earlyDate, additionalOptions] = watch([
    ApiKeyFields.schedules_at,
    ApiKeyFields.date_confirmation,
    ApiKeyFields.schedule_earlier,
    ApiKeyFields.additional_options,
  ]);
  if ((earlyDate === true && !additionalOptions) || !dateConfirmation || !schedulesAt) {
    return theme.palette.secondary.main;
  }
  return theme.palette.success.main;
};
