import { useParams } from "react-router-dom";
import { COMMENT_MODE } from "context/CustomFormContext";
import { useStores } from "stores";

import { ApiKeyFields, Comment, CommentForm, PrimaryFormNames, SecondaryFormNames, TertiaryFormNames } from "types";

import { ReleaseUrlParams } from "../types";

import { useFetchComments } from "./useFetchComments";
import { ReturnReleaseFormState, usePrimaryFormState } from "./usePrimaryFormState";

export interface TertiaryFormStateCbProps {
  secondaryFormName: SecondaryFormNames;
  secondaryFormId: string;
  tertiaryFormName: TertiaryFormNames;
  tertiaryFormId: string;
  updateCommentCb?: (tertiaryFormId: string, comment: CommentForm) => (comment: Comment) => Comment;
  deleteCommentCb?: (tertiaryFormId: string, fieldName: ApiKeyFields) => (comment: Comment) => boolean;
}

export const useTertiaryFormState = (): ((props: TertiaryFormStateCbProps) => ReturnReleaseFormState) => {
  const {
    revisedFormStore: {
      comments: { postTertiaryComment, patchTertiaryComment, deleteTertiaryComment },
    },
  } = useStores();
  const { updateCommentState } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const formState = usePrimaryFormState();

  const commentCallback =
    ({
      secondaryFormName,
      secondaryFormId,
      tertiaryFormName,
      tertiaryFormId,
      updateCommentCb,
      deleteCommentCb,
    }: TertiaryFormStateCbProps) =>
    async (comment: CommentForm, mode: COMMENT_MODE) => {
      if (!releaseId) return;
      try {
        switch (mode) {
          case COMMENT_MODE.ADD:
            await postTertiaryComment(
              userId,
              PrimaryFormNames.Releases,
              releaseId,
              secondaryFormName,
              secondaryFormId,
              tertiaryFormName,
              tertiaryFormId,
              comment,
            );
            break;
          case COMMENT_MODE.EDIT:
            await patchTertiaryComment(
              userId,
              PrimaryFormNames.Releases,
              releaseId,
              secondaryFormName,
              secondaryFormId,
              tertiaryFormName,
              tertiaryFormId,
              comment.fieldName,
              comment,
              updateCommentCb?.(tertiaryFormId, comment),
            );
            break;
          case COMMENT_MODE.DELETE:
            await deleteTertiaryComment(
              userId,
              PrimaryFormNames.Releases,
              releaseId,
              secondaryFormName,
              secondaryFormId,
              tertiaryFormName,
              tertiaryFormId,
              comment.fieldName,
              deleteCommentCb?.(tertiaryFormId, comment.fieldName),
            );
            break;
        }
        await updateCommentState(secondaryFormName);
      } finally {
        null;
      }
    };

  return (callbackProps) => {
    return {
      ...formState,
      comments: {
        ...formState.comments,
        commentCallback: commentCallback(callbackProps),
      },
    };
  };
};
