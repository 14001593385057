import { ApiKeyFields } from "types";

export enum NOTIFICATION_TYPES {
  EDIT_FIELD = 1,
  COMMENT = 2,
}

export interface Notification {
  [ApiKeyFields.id]: string;
  [ApiKeyFields.userId]: string;
  [ApiKeyFields.distributionContractId]: string;
  [ApiKeyFields.type]: NOTIFICATION_TYPES;
  [ApiKeyFields.fieldName]: string;
  [ApiKeyFields.createdAt]: string;
  [ApiKeyFields.releaseId]: string | null;
  [ApiKeyFields.releaseWorkId]: string | null;
  [ApiKeyFields.releaseTrackId]: string | null;
  [ApiKeyFields.releasePublisherId]: string | null;
  [ApiKeyFields.releaseSongwriterId]: string | null;
  [ApiKeyFields.releaseArtistId]: string | null;
  [ApiKeyFields.releaseContributorId]: string | null;
}

export interface NotificationsResponse {
  [ApiKeyFields.numNotifications]: number;
  [ApiKeyFields.notifications]: Notification[];
}
