import { IControlType, IJodit } from "jodit/types";

export const toolbarButtons: Array<string | IControlType<IJodit>> = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "eraser",
  "|",
  "ul",
  "ol",
  "|",
  "indent",
  "outdent",
  "align",
  "|",
  "brush",
  "font",
  "fontsize",
  "paragraph",
  "lineHeight",
  "|",
  "cut",
  "copy",
  "paste",
  "selectall",
  "|",
  "image",
  "link",
  "table",
  "hr",
  "\n",
];

export const placeholderList = [
  "legal_first_name",
  "legal_last_name",
  "artist_name",
  "email",
  "phone_number",
  "mailing_address_1",
  "mailing_address_2",
  "city",
  "state_or_province",
  "country",
  "postal_or_zipcode",
  "album_names",
  "eps",
  "singles",
  "agreement_signer_name",
  "agreement_signer_signature",
  "imr_signer_signature",
  "imr_signer_name",
  "legal_name_entity",
  "integrated_fee",
  "term_of_the_agreement",
  "agreement_renewal",
  "effective_date",
  "youtube_channel_url",
].sort();
