import styled from "@emotion/styled";

export const FormContainerStyled = styled("div")<{ isRegistrationFlow?: boolean }>(
  ({ theme, isRegistrationFlow }) => `
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${!isRegistrationFlow && theme.breakpoints.between("md", "lg")} {
    padding-left: 50px;
    padding-right: 50px;
  }

  ${isRegistrationFlow && "padding: 56px 0; justify-content: center; gap: 16px;"}
  `,
);
