import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";

import { Modal } from "components";
import { RESOURCES_KEYS } from "i18n";

interface ConfirmModalProps extends BaseModalProps {
  title: string;
  text: string | React.ReactNode;
  confirmBtnText?: string;
  onConfirmClick: (closeModal: () => void) => Promise<void>;
}

const ConfirmModal: FC<ConfirmModalProps> = ({ title, text, confirmBtnText, onConfirmClick, closeModal }) => {
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);

  const handleConfirmClick = () => onConfirmClick(closeModal);

  return (
    <>
      <Modal.ModalTitle title={title} closeModal={closeModal} />
      <Modal.ModalContent>{text}</Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          {t("cancel")}
        </Button>
        <Button size="small" variant="contained" onClick={handleConfirmClick}>
          {confirmBtnText ?? t("confirm")}
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(ConfirmModal);
