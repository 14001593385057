import { useParams } from "react-router-dom";
import { useFormNavigation } from "hooks";

import ReleaseDate from "components/ReleaseForm/ReleaseDate";
import GenreTerritory from "components/ReleaseForm/ReleaseInformation/GenreTerritory";
import TitleCoverArt from "components/ReleaseForm/ReleaseInformation/TitleCoverArt";
import Tracks from "components/ReleaseForm/TrackManager/Tracks";
import Works from "components/ReleaseForm/TrackManager/Works";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { ROUTES } from "constants/routes";

import { useReleaseService } from "./useReleaseService";

export { ClientTitleCoverArt, ClientGenreTerritory, ClientWorks, ClientTracks, ClientReleaseDate };

function ClientTitleCoverArt() {
  const { releaseId } = useParams<ReleaseUrlParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    null,
    ROUTES.CLIENT_DRAFT_RELEASE_ID_GENRE(releaseId),
    {
      prev: true,
      next: false,
    },
  );
  const { getCurrentReleaseBlobLvl1 } = useReleaseService();

  return (
    <TitleCoverArt
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      fileAction={getCurrentReleaseBlobLvl1}
    />
  );
}

function ClientGenreTerritory() {
  const { releaseId } = useParams<ReleaseUrlParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.CLIENT_DRAFT_RELEASE_ID(releaseId),
    ROUTES.CLIENT_DRAFT_RELEASE_ID_WORKS(releaseId),
  );
  return (
    <GenreTerritory
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function ClientWorks() {
  const { releaseId } = useParams<ReleaseUrlParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.CLIENT_DRAFT_RELEASE_ID_GENRE(releaseId),
    ROUTES.CLIENT_DRAFT_RELEASE_ID_TRACKS(releaseId),
  );
  return (
    <Works
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function ClientTracks() {
  const { releaseId } = useParams<ReleaseUrlParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.CLIENT_DRAFT_RELEASE_ID_WORKS(releaseId),
    ROUTES.CLIENT_DRAFT_RELEASE_ID_RELEASE_DATE(releaseId),
  );
  return (
    <Tracks
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function ClientReleaseDate() {
  const { releaseId } = useParams<ReleaseUrlParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.CLIENT_DRAFT_RELEASE_ID_TRACKS(releaseId),
    null,
    {
      prev: false,
      next: true,
    },
  );

  return (
    <ReleaseDate
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}
