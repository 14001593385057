import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled/macro";

import Paper from "@mui/material/Paper";
import ToggleButtonMui from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";

import { ConditionalTooltip } from "components/FormComponents";
import { RESOURCES_KEYS } from "i18n";

type DarkVariant = {
  dark?: boolean;
};

export interface ToggleBtnLabelValue<Value = string> {
  label: string;
  value: Value;
  disabled?: boolean;
  tooltipText?: string | ReactNode;
}

type Props = ToggleButtonGroupProps &
  DarkVariant & {
    options: ToggleBtnLabelValue<string | number | boolean>[];
    tooltipHiddenOnActiveBtn?: boolean;
  };

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "dark",
})<DarkVariant>`
  background-color: ${({ theme }) => theme.palette.background.surface};
  padding: 2px;
  box-shadow: none;

  ${({ dark, theme }) => dark && `background-color: ${theme.palette.common.white};`};
`;

const StyledToggleButton = styled(ToggleButtonMui, {
  shouldForwardProp: (prop) => prop !== "dark",
})<DarkVariant>`
  color: ${({ theme }) => theme.palette.text.label};
  height: 32px;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.palette.background.surface};
  border-radius: 8px !important;
  border: none;
  text-transform: none;
  font-weight: 500;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border: 1px solid ${({ theme }) => theme.palette.icon};
    font-weight: 700;

    &:not(:first-of-type) {
      border-left-color: ${({ theme }) => theme.palette.icon};
      &:hover {
        border-left-color: ${({ theme }) => theme.palette.secondary.main};
      }
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.text.label};
      background-color: ${({ theme }) => theme.palette.background.surface};
      border: 1px solid ${({ theme }) => theme.palette.secondary.main};

      &:hover {
        color: ${({ theme }) => theme.palette.text.label};
      }
    }
  }

  &.Mui-disabled {
    border: none;
    pointer-events: unset;
    color: ${({ theme }) => theme.palette.text.disabled};

    &:hover {
      color: ${({ theme }) => theme.palette.text.disabled};
      background-color: transparent;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.text.header};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 8px;
  }

  ${({ dark, theme }) => dark && `background-color: ${theme.palette.common.white};`};
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== "dark",
})<DarkVariant>`
  display: flex;
  gap: 3px;
  background-color: ${({ theme }) => theme.palette.background.surface};

  ${({ dark, theme }) => dark && `background-color: ${theme.palette.common.white};`};
`;

const ToggleButton = forwardRef<HTMLDivElement, Props>(
  ({ options, dark, exclusive = true, disabled, tooltipHiddenOnActiveBtn, ...props }, ref) => {
    const { t } = useTranslation([RESOURCES_KEYS.OPTIONS, RESOURCES_KEYS.COMMON]);

    return (
      <StyledPaper dark={dark}>
        <StyledToggleButtonGroup fullWidth size="small" dark={dark} exclusive={exclusive} {...props} ref={ref}>
          {options.map((option) => (
            <StyledToggleButton
              key={option.label}
              size="small"
              value={option.value}
              dark={dark}
              disabled={disabled || option.disabled}
            >
              <ConditionalTooltip
                isTooltipVisible={tooltipHiddenOnActiveBtn ? option.disabled : Boolean(option.tooltipText)}
                title={option.tooltipText}
              >
                <span>{t(option.label)}</span>
              </ConditionalTooltip>
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </StyledPaper>
    );
  },
);

ToggleButton.displayName = "ToggleButton";

export default ToggleButton;
