import { forwardRef } from "react";
import dayjs from "dayjs";

import { TextFieldStyled } from "components/FormControls";

interface ReadOnlyFieldProps {
  value?: string | dayjs.Dayjs | null | unknown;
  multiline?: boolean;
  rows?: string | number;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
}

const ReadOnlyField = forwardRef<HTMLInputElement, ReadOnlyFieldProps>((props, ref) => (
  <TextFieldStyled
    InputProps={{ endAdornment: props.endAdornment }}
    fullWidth
    {...props}
    variant="filled"
    disabled
    inputRef={ref}
  />
));

ReadOnlyField.displayName = "ReadOnlyField";

export default ReadOnlyField;
