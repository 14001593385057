import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";

import { ApiKeyFields } from "types";
import { DistributionAdvanceCurrency } from "types/revisedForms";
import { DistributionLegal, DistributionLegalFormPayload } from "types/revisedForms";
import { convertCamelToSnakeCase, convertSnakeToCamel, replaceEmptyAndUndefinedWithNull } from "utils";

export const prepareDistributionLegalForm = (formData: DistributionLegalFormPayload) => {
  return convertCamelToSnakeCase({
    ...formData,
    [ApiKeyFields.contractRenewal]: formData[ApiKeyFields.contractRenewal]
      ? formData[ApiKeyFields.contractRenewal].toString()
      : null,
    [ApiKeyFields.advanceCurrency]: formData[ApiKeyFields.advanceCurrency] ?? DistributionAdvanceCurrency.USD,
  });
};

export const prepareDistributionLegalFormRequest = (
  formData: DistributionLegal,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  const omitData = omit(formData, [ApiKeyFields.id]);
  const filteredData = pickBy(omitData, (_, key) => dirtyFields[key]);
  return replaceEmptyAndUndefinedWithNull(convertSnakeToCamel(filteredData));
};
