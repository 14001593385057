import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthError, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useStores } from "stores";

import { authorities, clientId } from "constants/msalConfig";

export const useMsalEventCallback = () => {
  const { instance } = useMsal();
  const { i18n } = useTranslation();
  const {
    uiStore: { setOverlayLoading },
  } = useStores();

  const handleLoginFailure = async (error: AuthError) => {
    if (error.errorMessage.includes("AADB2C90118")) {
      await setOverlayLoading(true);
      instance.loginRedirect({
        scopes: [clientId],
        redirectUri: window.location.origin,
        extraQueryParameters: { ui_locales: i18n.language },
        authority: authorities.resetPassword,
      });
    }
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE && event.error instanceof AuthError) {
        handleLoginFailure(event.error);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, i18n.language, setOverlayLoading]);
};
