import styled from "@emotion/styled";

import { TextFieldStyled } from "components/FormControls";
import { isCloseToMaxLength } from "utils/numbersUtils";

export const TextAreaFieldStyled = styled(TextFieldStyled)`
  .MuiFormHelperText-root {
    margin-top: 8px;
    margin-right: 0;
  }
`;

export const HelperTextContainerStyled = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CharLabelStyled = styled.div<{ charCount: number; maxLength: number }>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  justify-self: self-end;
  align-items: center;

  .MuiTypography-caption {
    margin-bottom: 8px;
  }

  ${({ charCount, maxLength, theme }) =>
    isCloseToMaxLength(charCount, maxLength) &&
    `.MuiTypography-caption {
    color: ${theme.palette.text.warning};
  }`}

  ${({ charCount, maxLength, theme }) =>
    charCount > maxLength &&
    `.MuiTypography-caption {
    color: ${theme.palette.error.main};
  }`}
`;
