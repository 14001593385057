import styled from "@emotion/styled";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export const InfoDividerStyled = styled(Divider)`
  width: 1px;
  background-color: ${({ theme }) => theme.palette.divider};
`;

export const NotificationActionStyled = styled.div`
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const NotificationBodyStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
`;

export const NotificationInfoStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const NotificationItemStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export const NotificationTitleStyled = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;
