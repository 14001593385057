import { FC } from "react";
import { useFormContext } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { FormBlock, FormBlockGridGap, OptionFieldsAlert } from "components/FormComponents";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { PhoneField, TextField } from "components/HookFormControls";
import { ApiKeyFields } from "types";

import { CONTACT_TEXT_FIELDS, FORM_LABELS, HELPER_TEXT } from "./constants";

interface ClientInformationProps extends StepFlowControllerProps {
  isAdmin?: boolean;
}

const ClientInformation: FC<ClientInformationProps> = (props) => {
  const { isAdmin } = props;
  const { control } = useFormContext();

  const getDefaultProps = () => ({
    control: control,
  });

  return (
    <StepFlowController {...props}>
      {!isAdmin ? <OptionFieldsAlert /> : null}
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          {CONTACT_TEXT_FIELDS.map((fieldName) => (
            <FormGridFieldItem
              fieldName={fieldName}
              label={FORM_LABELS[fieldName]}
              helperText={HELPER_TEXT[fieldName]}
              key={fieldName}
            >
              {(field) => <TextField {...field} {...getDefaultProps()} />}
            </FormGridFieldItem>
          ))}

          <FormGridFieldItem
            fieldName={ApiKeyFields.email}
            label={FORM_LABELS[ApiKeyFields.email]}
            helperText={HELPER_TEXT[ApiKeyFields.email]}
          >
            {(field) => <TextField {...field} {...getDefaultProps()} />}
          </FormGridFieldItem>

          <FormGridFieldItem
            fieldName={ApiKeyFields.phone}
            label={FORM_LABELS[ApiKeyFields.phone]}
            helperText={HELPER_TEXT[ApiKeyFields.phone]}
          >
            {(field) => <PhoneField {...field} {...getDefaultProps()} />}
          </FormGridFieldItem>
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default ClientInformation;
