import React from "react";
import { Control, Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { IJoditEditorProps } from "jodit-react";

import FormControl from "@mui/material/FormControl";

import JoditEditor from "components/JoditEditor";

const FormControlStyled = styled(FormControl)`
  height: 100%;
`;

type HTMLeditorFieldType<FieldType extends FieldValues> = Omit<IJoditEditorProps, "value"> & {
  fieldName: string;
  control: Control<FieldType>;
};

const HTMLeditorField = <FieldType extends FieldValues>({ fieldName, control, ...props }: HTMLeditorFieldType<FieldType>) => {
  const { setValue, trigger } = useFormContext();

  const handleBlur = (value: string) => {
    setValue(fieldName, value, { shouldValidate: true, shouldDirty: true });
    trigger(fieldName);
  };

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { ...fieldProps } }) => {
        return (
          <FormControlStyled fullWidth>
            <JoditEditor {...fieldProps} {...props} onChange={void 0} onBlur={handleBlur} />
          </FormControlStyled>
        );
      }}
    />
  );
};

export default HTMLeditorField;
