import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { NotResolvedCommentIcon, ResolvedCommentAdmin, ResolvedCommentClientIcon } from "assets/icons/24px";
import { haveActiveComment, haveResolvedComment } from "components/FormProgressBar";
import { Comment } from "types";

interface CommentsStatusProps {
  comments?: Comment[];
}

const CommentsStatus: FC<CommentsStatusProps> = ({ comments }) => {
  const {
    userStore: { isAdmin },
  } = useStores();

  if (comments && haveActiveComment(comments)) {
    return <NotResolvedCommentIcon />;
  }

  if (comments && haveResolvedComment(comments)) {
    if (isAdmin) return <ResolvedCommentAdmin />;
    return <ResolvedCommentClientIcon />;
  }

  return null;
};

export default observer(CommentsStatus);
