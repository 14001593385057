import { FC, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomFormContext } from "context";

import { NumberInputField, SelectField, TextField, ToggleButtonField } from "components";
import { FormGridFieldItem, FormGridItem } from "components/FormComponents/FormGridItems";
import { YES_NO } from "constants/options";
import { TERRITORY_LABEL_VALUE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT } from "./contstants";
import { AddEditFormProps } from "./types";

const AddEditForm: FC<AddEditFormProps> = ({ hideAdministratorField, labelsOverride, helpersOverride }) => {
  const { isAdmin, isFieldReadOnly } = useContext(CustomFormContext);
  const { control, watch, setValue } = useFormContext();

  const [advance] = watch([ApiKeyFields.advance]);

  useEffect(() => {
    if (advance === true) {
      setValue(ApiKeyFields.email, "", { shouldValidate: true, shouldDirty: true });
    }
  }, [advance]);

  const FIELD_LABELS = { ...FORM_LABELS, ...labelsOverride };
  const HELPERS_LABELS = { ...HELPER_TEXT, ...helpersOverride };
  const isEmailDisabled = !(advance === false && !isAdmin);

  const isEmailReadOnly = isFieldReadOnly(ApiKeyFields.email);

  return (
    <>
      <FormGridFieldItem fieldName={ApiKeyFields.name} label={FIELD_LABELS[ApiKeyFields.name]}>
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      <FormGridItem label={FIELD_LABELS[ApiKeyFields.advance]}>
        <ToggleButtonField
          fieldName={ApiKeyFields.advance}
          options={YES_NO}
          control={control}
          disabled={isAdmin || isEmailReadOnly}
        />
      </FormGridItem>
      <FormGridFieldItem
        fieldName={ApiKeyFields.email}
        label={FIELD_LABELS[ApiKeyFields.email]}
        helperText={HELPERS_LABELS[ApiKeyFields.email]}
        isOptionalField={advance !== false}
      >
        {(field) => <TextField {...field} control={control} disabled={isEmailDisabled} />}
      </FormGridFieldItem>
      {!hideAdministratorField ? (
        <FormGridFieldItem fieldName={ApiKeyFields.rightsAdministrator} label={FIELD_LABELS[ApiKeyFields.rightsAdministrator]}>
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ) : null}
      <FormGridFieldItem fieldName={ApiKeyFields.affiliation} label={FIELD_LABELS[ApiKeyFields.affiliation]}>
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      <FormGridFieldItem fieldName={ApiKeyFields.country} label={FIELD_LABELS[ApiKeyFields.country]}>
        {(field) => <SelectField {...field} control={control} options={TERRITORY_LABEL_VALUE_OPTIONS} />}
      </FormGridFieldItem>
      <FormGridFieldItem
        fieldName={ApiKeyFields.split}
        label={FIELD_LABELS[ApiKeyFields.split]}
        helperText={HELPERS_LABELS[ApiKeyFields.split]}
      >
        {(field) => <NumberInputField {...field} control={control} addonAfter={<span>%</span>} />}
      </FormGridFieldItem>
    </>
  );
};

export default AddEditForm;
