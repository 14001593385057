import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";

import { CURRENCIES, DEFAULT_CURRENCY_VALUE } from "constants/options";
import { TERRITORY_LABEL_VALUE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";
import { DistributionInfo, DistributionInfoFormPayload } from "types/revisedForms";
import { convertCamelToSnakeCase, convertSnakeToCamel, replaceEmptyAndUndefinedWithNull } from "utils";

export const prepareDistributionInfoForm = (formData: DistributionInfoFormPayload) => {
  return convertCamelToSnakeCase({
    ...formData,
    [ApiKeyFields.contract_renewal]: formData[ApiKeyFields.contractRenewal]
      ? formData[ApiKeyFields.contractRenewal].toString()
      : null,
    [ApiKeyFields.country]: TERRITORY_LABEL_VALUE_OPTIONS.find(({ value }) => value === formData[ApiKeyFields.country]) || null,
    [ApiKeyFields.advance_currency]: formData["advanceCurrency"]
      ? CURRENCIES.find(({ value }) => value === formData["advanceCurrency"])?.value
      : DEFAULT_CURRENCY_VALUE,
  });
};

export const prepareDistributionFormRequest = (
  formData: DistributionInfo,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
  isAdmin?: boolean,
) => {
  const omitData = omit(formData, [ApiKeyFields.id, ApiKeyFields.comments, ApiKeyFields.edits, ApiKeyFields.advance_currency]);
  const adminData = isAdmin
    ? pick(omitData, [
        ApiKeyFields.royalty_rate,
        ApiKeyFields.contract_term,
        ApiKeyFields.marketing_service,
        ApiKeyFields.advance,
        ApiKeyFields.advance_currency,
        ApiKeyFields.advance_detail,
        ApiKeyFields.contract_type,
        ApiKeyFields.reviewer_name,
        ApiKeyFields.special_provisions,
        ApiKeyFields.contract_renewal,
      ])
    : {};

  const preparedFormData = {
    ...omitData,
    ...adminData,
    [ApiKeyFields.country]: formData[ApiKeyFields.country]
      ? TERRITORY_LABEL_VALUE_OPTIONS.find(({ value }) => {
          const country = formData[ApiKeyFields.country] as LabelValue;
          return value === country.value;
        })?.value || null
      : null,
  };

  const filteredData = pickBy(preparedFormData, (_, key) => dirtyFields[key]);

  return replaceEmptyAndUndefinedWithNull(
    convertSnakeToCamel({
      ...filteredData,
      ...(isAdmin
        ? {
            ...((formData[ApiKeyFields.advance] && dirtyFields[ApiKeyFields.advance]) ||
            (formData[ApiKeyFields.advance] && dirtyFields[ApiKeyFields.advance_currency])
              ? {
                  [ApiKeyFields.advance]: String(formData[ApiKeyFields.advance]),
                  [ApiKeyFields.advance_currency]: formData[ApiKeyFields.advance_currency],
                }
              : {
                  ...(isNull(formData[ApiKeyFields.advance]) && Boolean(dirtyFields[ApiKeyFields.advance])
                    ? {
                        [ApiKeyFields.advance]: null,
                        [ApiKeyFields.advance_currency]: null,
                      }
                    : {}),
                }),
          }
        : {}),
    }),
  );
};
