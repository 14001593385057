import React, { ReactNode, useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useUserNotificationState } from "hooks/useUserNotificationState";
import { observer } from "mobx-react";
import { useStores } from "stores";

import CircularProgress from "@mui/material/CircularProgress";

import { PageWrapper } from "components/Layout";

const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {
    userStore: { isLoading, retrieveUser, user },
    notificationStore: { getCurrentNotifications },
  } = useStores();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { showNotification, notification_with_release_relations } = useUserNotificationState();

  useEffect(() => {
    isAuthenticated && retrieveUser();
  }, [isAuthenticated]);

  useEffect(() => {
    showNotification && getCurrentNotifications(void 0, void 0, void 0, notification_with_release_relations);
  }, [user, showNotification]);

  if ((isAuthenticated && isLoading) || inProgress !== InteractionStatus.None) {
    return (
      <PageWrapper>
        <CircularProgress />
      </PageWrapper>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default observer(UserProvider);
