import { axiosInstance } from "services";
import { NotificationsResponse } from "types";

export function getCurrentNotifications(
  notification_offset?: number,
  notification_limit?: number,
  notification_with_release_relations?: boolean,
): Promise<NotificationsResponse> {
  return axiosInstance.get(`/users/current/notifications`, {
    params: { notification_offset, notification_limit, notification_with_release_relations },
  });
}

export function deleteCurrentNotificationsId(notificationId: string) {
  return axiosInstance.delete(`/users/current/notifications/${notificationId}`);
}
