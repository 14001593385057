import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
  fontFamily: "'Inter', sans-serif",
  h1: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "40px",
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
  },
  h3: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "26px",
  },
  body1: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
  },
  // hint in component library
  caption: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
};
