import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

export const DetailBlock = styled.div<{ gap?: string }>`
  display: flex;
  gap: ${({ gap }) => gap ?? `8px`};
  align-items: center;
`;

export const HeaderDetailInfo: FC<{ title: string; value: string | ReactNode }> = ({ title, value }) => (
  <DetailBlock>
    <Typography color="text.header" fontWeight={700}>
      {title}
    </Typography>
    <Typography color="text.header" component="div">
      {value}
    </Typography>
  </DetailBlock>
);
