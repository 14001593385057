import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { CheckboxField, FormBlockGridGap, Modal, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { useTertiaryListFormState } from "components/ReleaseForm/Hooks";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseArtistsForm,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
} from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import { defaultValues, FORM_LABELS, HELPER_TEXT, TEXT_FIELDS } from "./constants";
import { prepareRequest } from "./helpers";
import { AddEditArtistProps } from "./types";
import { schema } from "./validation";

const AddEditContributor: FC<AddEditArtistProps> = ({ closeModal, track, record, successCallback }) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const formState = useTertiaryListFormState()({
    secondaryFormName: SecondaryFormNames.ReleaseTracks,
    tertiaryFormName: TertiaryFormNames.ReleaseArtists,
    secondaryFormId: track.id as string,
    tertiaryFormId: record?.id || "",
    filterCommentsCb: ({ releaseArtistId }) => releaseArtistId === record?.id,
    updateCommentCb: (tertiaryFormId, c) => {
      return (comment) =>
        comment.releaseArtistId === tertiaryFormId && comment.fieldName === c.fieldName ? { ...comment, ...c } : comment;
    },
    deleteCommentCb: (tertiaryFormId, fieldName) => {
      return (comment) => !(comment.releaseArtistId === tertiaryFormId && comment.fieldName === fieldName);
    },
  });

  const form = useForm<ReleaseArtistsForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    record && reset(record);
  }, [record]);

  const onSubmit = handleSubmit(async (formData) => {
    if (!track.id || !track.releaseId) return;
    try {
      setOverlayLoading(true);
      const requestParams: TertiarySecondaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseArtists,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id,
      };
      if (record) {
        const patchRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: record.id! };
        const requestData = prepareRequest(formData, dirtyFields);
        await revisedFormService.patchTertiaryForm(patchRequestParams, requestData);
        const { revisedForm } = await revisedFormService.getTertiaryFormId(patchRequestParams);
        successCallback?.(revisedForm);
      } else {
        const {
          revisedForm: { id },
        } = await revisedFormService.postTertiaryForm(requestParams, replaceEmptyAndUndefinedWithNull(formData));
        const getRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: id! };
        const { revisedForm } = await revisedFormService.getTertiaryFormId(getRequestParams);
        successCallback?.(revisedForm);
      }
      closeModal();
    } finally {
      resetLoading();
    }
  });

  const modalContent = (
    <FormProvider {...form}>
      <Modal.ModalTitle title={`${record ? "Edit" : "Add"} Artist`} closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem
            fieldName={ApiKeyFields.artistName}
            label={FORM_LABELS[ApiKeyFields.artistName]}
            helperText={HELPER_TEXT[ApiKeyFields.artistName]}
          >
            {(field) => <TextField {...field} control={control} />}
          </FormGridFieldItem>
          <FormGridFieldItem fieldName={ApiKeyFields.isFeatured} label={FORM_LABELS[ApiKeyFields.isFeatured]} isOptionalField>
            {(fieldName) => <CheckboxField {...fieldName} control={control} label={HELPER_TEXT[ApiKeyFields.isFeatured]} />}
          </FormGridFieldItem>
          {TEXT_FIELDS.map((fieldName) => (
            <FormGridFieldItem
              key={fieldName}
              fieldName={fieldName}
              label={FORM_LABELS[fieldName]}
              helperText={HELPER_TEXT[fieldName]}
              isOptionalField
            >
              {(field) => <TextField {...field} control={control} />}
            </FormGridFieldItem>
          ))}
        </Grid>
      </Modal.ModalContent>
      {!formState.isReadOnly || formState.isEditable ? (
        <Modal.ModalActions>
          <Button size="small" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={!isValid || !isDirty} onClick={onSubmit}>
            {record ? "Edit" : "Add"}
          </Button>
        </Modal.ModalActions>
      ) : null}
    </FormProvider>
  );

  if (record) {
    return <CustomFormProvider {...formState}>{modalContent}</CustomFormProvider>;
  }

  return modalContent;
};

export default observer(AddEditContributor);
