import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AntConfigProvider from "antd/lib/config-provider";
import { Provider } from "mobx-react";
import { SnackbarProvider } from "notistack";
import { RootStore } from "stores";

import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import SnackbarVariants, { anchorOrigin } from "components/SnackbarVariants";
import msalInstance from "constants/msalConfig";
import { i18n } from "i18n";
import App from "pages/App";
import { theme } from "theme";
import { antTheme } from "theme/antd";

import "dayjs/locale/es";
import "dayjs/locale/de";

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance.enableAccountStorageEvents();

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <MsalProvider instance={msalInstance}>
      <Provider rootStore={new RootStore()}>
        <StyledEngineProvider injectFirst>
          <AntConfigProvider theme={antTheme}>
            <MuiThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n}>
                <SnackbarProvider anchorOrigin={anchorOrigin} Components={SnackbarVariants} autoHideDuration={4000} maxSnack={5}>
                  <React.Fragment>
                    <CssBaseline />
                    <App />
                  </React.Fragment>
                </SnackbarProvider>
              </I18nextProvider>
            </MuiThemeProvider>
          </AntConfigProvider>
        </StyledEngineProvider>
      </Provider>
    </MsalProvider>,
  );
});
