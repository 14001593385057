export const declinedAccountsOptions = [
  { label: "Hide", value: true },
  { label: "Display", value: "" },
  { label: "Only", value: false },
];

export const invitedClientsOptions = [
  { label: "Hide", value: false },
  { label: "Display", value: "" },
  { label: "Only", value: true },
];
