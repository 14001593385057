import { useParams } from "react-router-dom";
import { BlobDownloadOptions, BlockBlobClient } from "@azure/storage-blob";
import { useStores } from "stores";

import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, FileOptionProps, PrimaryFormNames } from "types";
import { onDownloadFile } from "utils";

export const useReleaseService = () => {
  const { userId, releaseId } = useParams<ReleaseUrlParams>();
  const {
    uiStore: { setOverlayLoading, resetLoading, onUploadingProgress },
  } = useStores();

  const getPrimaryBlob = async (field_name: ApiKeyFields, options: FileOptionProps) => {
    try {
      const { blob_action } = options;
      if (!userId || !releaseId) return;
      setOverlayLoading(true, "Downloading the file…");
      const { blobFieldSasUrl } = await revisedFormService.getPrimaryBlob({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId: releaseId,
        field_name,
        blob_action,
      });

      if (blob_action === "get") {
        const blobClient = new BlockBlobClient(blobFieldSasUrl);
        const properties = await blobClient.getProperties();
        const totalBytes = properties.contentLength;
        const downloadOptions: BlobDownloadOptions = {
          onProgress: (ev) => onUploadingProgress(ev.loadedBytes, totalBytes!),
        };
        const response = await blobClient.download(void 0, void 0, downloadOptions);
        const blob = await response.blobBody;
        if (!blob) {
          throw new Error("Failed to download blob");
        }
        const file = window.URL.createObjectURL(blob);
        onDownloadFile({ title: blob.size.toString(), file, fileFormat: "tiff" });
      }
    } finally {
      resetLoading();
    }
  };

  return { getPrimaryBlob };
};
