import { makeObservable } from "mobx";
import { RootStore } from "stores";
import { TableStore } from "stores/_tableStore";

import { GridSortModel } from "@mui/x-data-grid";

import { userService } from "services";
import { DigitalRelease, UserReleaseResponse } from "types/revisedForms/digitalRelease";

import { ReleaseListParams } from "../types";
import { getSortingData } from "../utils";

import { AdminReleaseFilters } from "./types";
import { processFilters } from "./utils";

export class AdminReleaseTable extends TableStore<UserReleaseResponse, DigitalRelease, ReleaseListParams> {
  constructor(public rootStore: RootStore) {
    super();
    makeObservable(this);
  }

  getTableData = async (defaultSortModel?: GridSortModel) => {
    await this.retrieveTableData({
      service: userService.getReleaseList,
      extractData: (response: UserReleaseResponse) => ({
        rows: response.revisedForms,
        totalCount: response.numRevisedForms,
      }),
      prepareGenericParams: (data) => {
        const { paginationModel, sortModel, filters } = data;
        const offset = paginationModel.page * paginationModel.pageSize;
        const limit = paginationModel.pageSize;
        return {
          release_with_num_release_artists: 1,
          release_artist_is_featured_is_set: 0,
          release_with_num_release_tracks: 1,
          offset,
          limit,
          ...getSortingData(sortModel),
          ...processFilters(filters as unknown as AdminReleaseFilters),
        };
      },
      defaultSortModel,
    });
  };
}
