import { ThemeConfig } from "antd";

import { colors } from "../colors";

export const antTheme: ThemeConfig = {
  token: {
    colorPrimary: colors.blue364,
    colorLink: colors.blue364,
    borderRadius: 8,
    controlHeightSM: 36,
    colorBgContainer: colors.gray257,
    colorBgContainerDisabled: colors.white,
  },
  components: {
    InputNumber: {
      activeBorderColor: colors.blue364,
      hoverBorderColor: colors.gray564,
      controlHeightSM: 37,
      activeShadow: "none",
    },
    DatePicker: {
      cellHeight: 25,
      cellWidth: 35,
      paddingXXS: 2,
      activeBorderColor: colors.blue364,
      hoverBorderColor: colors.gray564,
      cellHoverBg: colors.gray257,
      cellActiveWithRangeBg: colors.blue30,
      paddingInlineSM: 16,
      activeShadow: "none",
    },
  },
};
