import { useNavigate, useParams } from "react-router-dom";
import { useFormNavigation } from "hooks";
import { observer } from "mobx-react";

import QualificationForm from "components/QualificationForm/Form";
import AgreementInformation from "components/RegistrationForm/AgreementInformation";
import DistributionCatalog from "components/RegistrationForm/ArtistCatalog/DistributionCatalog";
import Address from "components/RegistrationForm/ClientInformation/Address";
import Contact from "components/RegistrationForm/ClientInformation/Contact";
import DistributionInformation from "components/RegistrationForm/DistributionInformation";
import { ROUTES } from "constants/routes";

import { useDistributionService } from "../../Shared/useDistributionService";

export { AccountQualification, AccountCatalog, AccountInformation, AccountContact, AccountAgreement, AccountDistribution };

function AccountQualification() {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleNextBtnClick = () => {
    navigate(ROUTES.ACCOUNTS_ID_CONTACT(id));
  };
  return <QualificationForm onNextBtnClick={handleNextBtnClick} />;
}

function AccountCatalog() {
  const { id } = useParams();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.ACCOUNTS_ID_ADDRESS(id),
    ROUTES.ACCOUNTS_ID_AGREEMENT(id),
  );
  return (
    <DistributionCatalog
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      isAdmin
    />
  );
}

function AccountInformation() {
  const { id } = useParams();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.ACCOUNTS_ID_CONTACT(id),
    ROUTES.ACCOUNTS_ID_CATALOG(id),
  );
  return (
    <Address
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function AccountContact() {
  const { id } = useParams();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.ACCOUNTS_ID(id),
    ROUTES.ACCOUNTS_ID_ADDRESS(id),
  );
  return (
    <Contact
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      isAdmin
    />
  );
}

function AccountAgreement() {
  const { id } = useParams();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.ACCOUNTS_ID_CATALOG(id),
    ROUTES.ACCOUNTS_ID_DISTRIBUTION(id),
  );
  return (
    <AgreementInformation
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      isClientConfirmation
      isAdmin
    />
  );
}

const AccountDistribution = observer(() => {
  const { id } = useParams();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.ACCOUNTS_ID_AGREEMENT(id),
    null,
    { prev: false, next: true },
  );
  const { getDistrUserIdBlobLvl1 } = useDistributionService();

  return (
    <DistributionInformation
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      fileAction={getDistrUserIdBlobLvl1}
      isDeleteAllowed
      isAdmin
    />
  );
});
