import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useStores } from "stores";

import { prepareReleaseForm } from "components/ReleaseForm/helpers";

import { defaultValues } from "../../../pages/User/Releases/Release/constants";
import { schema } from "../../../pages/User/Releases/Release/validation";
import { DraftForm } from "../ReleaseFormHeader/types";
import { ReleaseUrlParams } from "../types";

export const useDraftForm = () => {
  const {
    revisedFormStore: {
      release,
      resetRevisedForm,
      works: { worksList },
      tracks: { tracksList },
      retrieveRelease,
    },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const form = useForm<DraftForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reset, setValue } = form;

  useEffect(() => {
    if (release) reset(prepareReleaseForm(release));
  }, [release, reset]);

  useEffect(() => {
    if (worksList) setValue("works", worksList, { shouldDirty: false });
  }, [worksList, setValue]);

  useEffect(() => {
    if (tracksList) setValue("tracks", tracksList, { shouldDirty: false });
  }, [tracksList, setValue]);

  useEffect(() => {
    if (releaseId) retrieveRelease(userId, releaseId);
    return () => resetRevisedForm();
  }, [userId, releaseId, retrieveRelease, resetRevisedForm]);

  return form;
};
