import { ReactNode } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import { FormHelperTextContainerStyled } from "components/Layout";
import { ApiKeyFields } from "types";

const HelperTextContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const defaultValues = {
  [ApiKeyFields.artistName]: "",
  [ApiKeyFields.appleUrl]: null,
  [ApiKeyFields.spotifyUrl]: null,
  [ApiKeyFields.isFeatured]: null,
};

export const TEXT_FIELDS = [ApiKeyFields.appleUrl, ApiKeyFields.spotifyUrl];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.artistName]: "Artist Name",
  [ApiKeyFields.isFeatured]: "Is this a featured artist?",
  [ApiKeyFields.appleUrl]: "Apple URL",
  [ApiKeyFields.spotifyUrl]: "Spotify URL",
};

export const HELPER_TEXT: { [key: string]: string | ReactNode } = {
  [ApiKeyFields.appleUrl]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        {`E.g.: "https://music.apple.com/us
        /artist/name/id"`}
      </Typography>
      <Typography variant="body1" color="text.label">
        Leave this blank if it’s a new artist and we’ll request a new artist profile
      </Typography>
    </FormHelperTextContainerStyled>
  ),
  [ApiKeyFields.spotifyUrl]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label" whiteSpace="pre-line">
        {`E.g.: "https://open.spotify.com/artist"`}
      </Typography>
      <Typography variant="body1" color="text.label">
        Leave this blank if it’s a new artist and we’ll request a new artist profile
      </Typography>
    </FormHelperTextContainerStyled>
  ),
  [ApiKeyFields.isFeatured]: (
    <HelperTextContainerStyled>
      <Typography variant="caption" color="text.label">
        By default this artist is primary. Check here if the artist is featured.
      </Typography>
    </HelperTextContainerStyled>
  ),
};
