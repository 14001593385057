import { NotificationStore, QualificationStore, RevisedFormStore, TablesStore, UiStore, UserStore } from "..";

const UI_STORE = "uiStore";
const QUALIFICATION_STORE = "qualificationStore";
const REVISED_FORM_STORE = "revisedFormStore";
const USER_STORE = "userStore";
const NOTIFICATION_STORE = "notificationStore";
const TABLES_STORE = "tablesStore";

export class RootStore {
  [UI_STORE]!: UiStore;
  [QUALIFICATION_STORE]!: QualificationStore;
  [REVISED_FORM_STORE]!: RevisedFormStore;
  [USER_STORE]!: UserStore;
  [NOTIFICATION_STORE]!: NotificationStore;
  [TABLES_STORE]!: TablesStore;

  constructor() {
    Object.assign(this, {
      [UI_STORE]: new UiStore(this),
      [QUALIFICATION_STORE]: new QualificationStore(this),
      [REVISED_FORM_STORE]: new RevisedFormStore(this),
      [USER_STORE]: new UserStore(this),
      [NOTIFICATION_STORE]: new NotificationStore(this),
      [TABLES_STORE]: new TablesStore(this),
    });
  }
}
