import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

import { AdminGenreTerritory, AdminReleaseDate, AdminTitleCoverArt, AdminTracks, AdminWorks } from "./Pages";
import Release from "./Release";

export const AdminReleaseRoute: RouteObject[] = [
  {
    path: ROUTES.RELEASE_ID(),
    element: <Release />,
    children: [
      {
        index: true,
        element: <AdminTitleCoverArt />,
      },
      {
        path: ROUTES.RELEASE_ID_GENRE(),
        element: <AdminGenreTerritory />,
      },
      {
        path: ROUTES.RELEASE_ID_WORKS(),
        element: <AdminWorks />,
      },
      {
        path: ROUTES.RELEASE_ID_TRACKS(),
        element: <AdminTracks />,
      },
      {
        path: ROUTES.RELEASE_ID_RELEASE_DATE(),
        element: <AdminReleaseDate />,
      },
    ],
  },
];
