import React from "react";
import styled from "@emotion/styled";
import InputNumber from "antd/lib/input-number";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {
  gridPageSelector,
  gridPageSizeSelector,
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid";

import { ViewBlueIcon } from "assets/icons/24px";
import { Select } from "components/FormControls";

import { getPageCount } from "./helpers";

export default TablePagination;

const GoToContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-input-number {
    width: 60px;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      background-color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  .MuiPaginationItem-root {
    svg > path {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-disabled {
      opacity: 1;

      svg > path {
        color: ${({ theme }) => theme.palette.divider};
      }
    }
  }
`;

function TablePagination() {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const pageCount = getPageCount(rootProps.rowCount || 0, pageSize);

  const handlePaginationClick = (event: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value - 1);
  };

  const handleGoTo = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const value = event.currentTarget.value;
      const pageNumber = parseInt(value, 10);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
        apiRef.current.setPage(pageNumber - 1);
      }
    }
  };

  return (
    <>
      <ItemsPerPageSelect />
      <div style={{ display: "flex", alignItems: "center" }}>
        <PaginationStyled
          color="primary"
          shape="rounded"
          showLastButton
          showFirstButton
          page={paginationModel.page + 1}
          count={pageCount}
          renderItem={(itemProps) => <PaginationItem {...itemProps} />}
          onChange={handlePaginationClick}
        />
        <GoToContainerStyled>
          <Typography variant="caption" color="text.header">
            Go to
          </Typography>
          <InputNumber size="small" color="primary" type="number" onKeyDown={handleGoTo} />
        </GoToContainerStyled>
      </div>
      <Results />
    </>
  );
}

const ItemsPerPageContainerStyled = styled.div`
  .MuiTypography-caption {
    margin-right: 8px;
  }

  .MuiSelect-select {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

function ItemsPerPageSelect() {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const handleChangePageSize = (event: SelectChangeEvent<number | string>) => {
    const newPageSize = event.target.value as number;
    apiRef.current.setPageSize(newPageSize);
  };

  return (
    <ItemsPerPageContainerStyled>
      <Typography variant="caption" color="text.header">
        {`Items per Page`}
      </Typography>
      <Select
        value={pageSize}
        options={[20, 50, 100].map((value) => ({
          label: value.toString(),
          value,
        }))}
        onChange={handleChangePageSize}
      />
    </ItemsPerPageContainerStyled>
  );
}

const ResultStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

function Results() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const start = pageSize * page + 1;
  const end = Math.min(pageSize * (page + 1), rowCount);
  const resultsText = `${rowCount === 0 ? 0 : start} – ${end} of ${rowCount} Results`;

  return (
    <ResultStyled>
      <ViewBlueIcon />
      <Typography variant="caption" color="text.header">
        {resultsText}
      </Typography>
    </ResultStyled>
  );
}
