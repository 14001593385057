import { FC, useContext } from "react";
import { CustomFormContext } from "context/CustomFormContext";

import { EmptyCommentIcon, NotResolvedCommentIcon, ResolvedCommentAdmin, ResolvedCommentClientIcon } from "assets/icons/24px";
import { Comment, CommentStatus } from "types/revisedForms";

interface CommentStatusProps {
  comment?: Comment;
}

const CommentIcon: FC<CommentStatusProps> = ({ comment }) => {
  const { isAdmin } = useContext(CustomFormContext);

  return comment ? (
    comment?.status === CommentStatus.RESOLVED ? (
      isAdmin ? (
        <ResolvedCommentAdmin />
      ) : (
        <ResolvedCommentClientIcon />
      )
    ) : (
      <NotResolvedCommentIcon />
    )
  ) : (
    <EmptyCommentIcon />
  );
};

export default CommentIcon;
