import { Accept } from "react-dropzone";

/**
 * Extracts file extensions from an Accept object, converts them to uppercase, and joins them into a comma-separated string.
 * @param {Object} fileTypes - An object containing file types and their corresponding extensions.
 * @returns {string} - File extensions in uppercase, separated by commas.
 */
export const getFileExtensionsString = (fileTypes: Accept): string => {
  return Object.values(fileTypes)
    .flat() // Flattens all arrays into one
    .map((extension) => extension.toUpperCase()) // Converts each extension to uppercase
    .join(", "); // Joins extensions into a comma-separated string
};
