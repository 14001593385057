import { ProgressBarConfigProps } from "components/FormProgressBar";
import { DISTRIBUTION_CATALOG_SIDEBAR_FIELDS } from "components/RegistrationForm/ArtistCatalog/DistributionCatalog/constants";
import { ADDRESS_SIDEBAR_FIELDS } from "components/RegistrationForm/ClientInformation/Address/constants";
import { CONTACT_SIDEBAR_FIELDS } from "components/RegistrationForm/ClientInformation/Contact/constants";
import { ROUTES } from "constants/routes";

export const progressBarConfig: ProgressBarConfigProps[] = [
  {
    title: "progress.clientInformation.title",
    subRoutes: [
      { route: ROUTES.DISTR_INFO, fields: CONTACT_SIDEBAR_FIELDS },
      { route: ROUTES.DISTR_INFO_ADDRESS(), fields: ADDRESS_SIDEBAR_FIELDS },
    ],
    description: "progress.clientInformation.description",
  },
  {
    title: "progress.artistCatalog.title",
    subRoutes: [{ route: ROUTES.DISTR_INFO_CATALOG(), fields: DISTRIBUTION_CATALOG_SIDEBAR_FIELDS }],
    description: "progress.artistCatalog.description",
  },
];
