import { ApiKeyFields } from "types";
import { DistributionAdvanceCurrency, DistributionLegalForm } from "types/revisedForms";

export const defaultDistributionLegalValues: DistributionLegalForm = {
  [ApiKeyFields.royalty_rate]: "",
  [ApiKeyFields.contract_term]: "",
  [ApiKeyFields.marketing_service]: null,
  [ApiKeyFields.advance]: "",
  [ApiKeyFields.advance_currency]: DistributionAdvanceCurrency.USD,
  [ApiKeyFields.advance_detail]: "",
  [ApiKeyFields.contract_type]: "",
  [ApiKeyFields.reviewer_name]: "",
  [ApiKeyFields.signer_name]: "",
  [ApiKeyFields.legal_name]: "",
  [ApiKeyFields.tax_form_blob_path]: null,
  [ApiKeyFields.payment_form_blob_path]: null,
  [ApiKeyFields.form_confirmation]: null,
  [ApiKeyFields.info_confirmation]: null,
  [ApiKeyFields.contract_renewal]: "",
  [ApiKeyFields.special_provisions]: "",
};
