import { GridSortModel } from "@mui/x-data-grid";

import { ApiKeyFields } from "types";

import { sortingMapping } from "./constants";

export const defaultSortData = {
  user_order_by_field_name: "qualified_at",
  user_order: "desc",
};

export const defaultSortModel: GridSortModel = [
  {
    field: ApiKeyFields.qualifiedAt,
    sort: "desc",
  },
];

export const getSortingData = (sortModel: GridSortModel) => {
  if (!sortModel.length) {
    return defaultSortData;
  }
  const { field, sort } = sortModel[0];
  if (!field || !sort) return defaultSortData;
  const isArtistField = field === ApiKeyFields.artistName || field === ApiKeyFields.songwriterName;
  const orderField = isArtistField ? "artist_order_by_field_name" : "user_order_by_field_name";
  return {
    [orderField]: sortingMapping[field as ApiKeyFields],
    [isArtistField ? "artist_order" : "user_order"]: sort,
  };
};
