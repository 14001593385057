import * as zod from "zod";

import { MAX_LEN_TEXTAREA_ERR_MSG, MIN_LEN_ERR_MSG } from "constants/validation";
import { ApiKeyFields } from "types/apiKeyFields";

export const schema = zod.object({
  [ApiKeyFields.forAdmin]: zod.boolean(),
  [ApiKeyFields.fieldName]: zod.string(),
  [ApiKeyFields.text]: zod.string().trim().min(1, MIN_LEN_ERR_MSG).max(1000, MAX_LEN_TEXTAREA_ERR_MSG),
  [ApiKeyFields.status]: zod.number(),
});
