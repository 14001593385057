import React from "react";
import styled from "@emotion/styled";

import TextField, { TextFieldProps } from "@mui/material/TextField";

const StyledTextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <TextField color="primary" size="small" ref={ref} {...props} />
));

StyledTextField.displayName = "StyledTextField";

export const TextFieldStyled = styled(StyledTextField, {
  shouldForwardProp: (prop) => !["endAdornment", "addonAfter"].includes(prop),
})`` as typeof TextField;
