import { FC } from "react";

import InputAdornment from "@mui/material/InputAdornment";

import { CloseIcon } from "assets/icons/24px";

import { InputIconButton } from ".";

interface ClearInputAdornmentProps {
  handleClearClick: () => void;
  isReadOnly?: boolean;
}

const ClearInputAdornment: FC<ClearInputAdornmentProps> = ({ handleClearClick, isReadOnly }) => {
  return !isReadOnly ? (
    <InputAdornment position="end">
      <InputIconButton size="small" edge="end" onClick={handleClearClick} tabIndex={-1}>
        <CloseIcon />
      </InputIconButton>
    </InputAdornment>
  ) : null;
};

export default ClearInputAdornment;
