import { action, makeObservable } from "mobx";
import { RootStore } from "stores";
import { TableStore } from "stores/_tableStore";

import { FiltersProps } from "pages/Admin/Accounts/Filters/types";
import { userService } from "services";
import { UserArtist, UserListParams, UserListResponse, UserStatus } from "types";

import { getSortingData } from "../../AdminsTable/utils";
import { processFilters } from "../AccountDistributionTable/utils";

export class AccountDapTable extends TableStore<UserListResponse, UserArtist, UserListParams> {
  constructor(public rootStore: RootStore) {
    super();
    makeObservable(this);
  }

  getTableData = async () => {
    await this.retrieveTableData({
      service: userService.getUsersList,
      extractData: (response: UserListResponse) => ({
        rows: response.users,
        totalCount: response.numUsers,
      }),
      prepareGenericParams: (data) => {
        const { paginationModel, sortModel, filters } = data;
        const user_offset = paginationModel.page * paginationModel.pageSize;
        const user_limit = paginationModel.pageSize;
        return {
          user_is_admin: false,
          user_offset,
          user_limit,
          user_statuses: UserStatus.DAP_RELEASE_MANAGER_AVAILABLE,
          ...getSortingData(sortModel),
          ...processFilters(filters as unknown as FiltersProps),
        };
      },
    });
  };

  @action
  changeUserIsActive = (id: string, isActive: boolean) => {
    const users = this.rows;
    if (users.length) {
      this.rows = users.map((user) => (user.id === id ? { ...user, isActive } : user));
    }
  };
}
