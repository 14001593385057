import { action, computed, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { ApiKeyFields, ReleaseCommentCount, SecondaryFormNames } from "types";

const commentsState = (): ReleaseCommentCount => ({
  [ApiKeyFields.numCommentsForUser]: 0,
  [ApiKeyFields.numCommentsForAdmin]: 0,
});

type CommentField = SecondaryFormNames.ReleaseWorks | SecondaryFormNames.ReleaseTracks;

export class ReleaseCommentStore {
  @observable comments = {
    [SecondaryFormNames.ReleaseWorks]: commentsState(),
    [SecondaryFormNames.ReleaseTracks]: commentsState(),
  };

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @action
  setCommentState(commentType: CommentField, commentState: ReleaseCommentCount) {
    this.comments[commentType] = commentState;
  }

  @computed
  get commentsTotal(): ReleaseCommentCount {
    return [this.comments[SecondaryFormNames.ReleaseWorks], this.comments[SecondaryFormNames.ReleaseTracks]].reduce(
      (acc, comments) => {
        acc.numCommentsForUser += comments.numCommentsForUser;
        acc.numCommentsForAdmin += comments.numCommentsForAdmin;
        return acc;
      },
      { numCommentsForUser: 0, numCommentsForAdmin: 0 },
    );
  }

  @computed
  get isAdminComments() {
    return this.commentsTotal.numCommentsForAdmin > 0;
  }

  @computed
  get isClientComments() {
    return this.commentsTotal.numCommentsForUser > 0;
  }

  @action
  resetCommentsTotal() {
    this.comments[SecondaryFormNames.ReleaseWorks] = commentsState();
    this.comments[SecondaryFormNames.ReleaseTracks] = commentsState();
  }
}
