import { FC, ReactNode, useMemo } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { ReactComponent as BackIcon } from "assets/icons/24px/Back.svg";
import { ReactComponent as NextIcon } from "assets/icons/24px/Next.svg";
import { FormFab } from "components/FormControls/FormFab";

import FormGrid from "../FormGrid";

export type StepFlowControllerProps = {
  onBackBtnClick?: () => void;
  prevBtnText?: string;
  prevBtnDisabled?: boolean;
  onNextBtnClick?: () => void;
  nextBtnText?: string;
  nextBtnDisabled?: boolean;
  isRegistrationFlow?: boolean;
  children?: ReactNode;
  isAdmin?: boolean;
};

const StepFlowController: FC<StepFlowControllerProps> = ({
  prevBtnText = "Previous",
  prevBtnDisabled,
  onBackBtnClick,
  onNextBtnClick,
  nextBtnText = "Next",
  nextBtnDisabled,
  isRegistrationFlow,
  children,
}) => {
  const defaultProps = useMemo(
    () => ({
      item: true,
      xs: 0,
      md: 1,
      lg: 2,
      display: { xs: "none", md: "flex" },
      alignItems: "center",
      justifyContent: "center",
      ...(isRegistrationFlow ? { xs: 2, md: 2 } : {}),
    }),
    [isRegistrationFlow],
  );

  return (
    <>
      <FormGrid
        leftSideGrid={
          <Grid {...defaultProps} paddingLeft={"56px !important"} justifyContent="flex-start">
            <FormFab onClick={onBackBtnClick} text={prevBtnText} disabled={prevBtnDisabled}>
              <BackIcon />
            </FormFab>
          </Grid>
        }
        rightSideGrid={
          <Grid {...defaultProps} paddingRight={"56px"} justifyContent="flex-end">
            <FormFab onClick={onNextBtnClick} text={nextBtnText} disabled={nextBtnDisabled}>
              <NextIcon />
            </FormFab>
          </Grid>
        }
        isRegistrationFlow={isRegistrationFlow}
      >
        {children}
      </FormGrid>
      <Grid
        container
        flex={1}
        spacing={2}
        display={{
          xs: "flex",
          md: "none",
          position: "fixed",
          bottom: "0",
          backgroundColor: "#fff",
          padding: "10px",
        }}
        justifyContent="center"
      >
        <Grid item xs={6} style={{ paddingTop: "0" }}>
          <Button variant="contained" size="small" fullWidth disabled={prevBtnDisabled} onClick={onBackBtnClick}>
            {prevBtnText}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: "0" }}>
          <Button variant="contained" size="small" fullWidth disabled={nextBtnDisabled} onClick={onNextBtnClick}>
            {nextBtnText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default StepFlowController;
