import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ContainerStyled, ContentContainerStyled } from "components/FormComponents/FormBlock";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import { SelectField } from "components/HookFormControls";
import { SELECT_TIER } from "constants/options";
import { RESOURCES_KEYS } from "i18n";
import { ApiKeyFields } from "types";

const SelectTier: FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation([RESOURCES_KEYS.QUALIFICATION]);

  return (
    <ContainerStyled>
      <ContentContainerStyled>
        <FormGridItem label={t("selectTier.title")}>
          <SelectField control={control} fieldName={ApiKeyFields.distributionTier} options={SELECT_TIER} />
        </FormGridItem>
      </ContentContainerStyled>
    </ContainerStyled>
  );
};

export default SelectTier;
