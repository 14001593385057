import dayjs from "dayjs";
import * as zod from "zod";

import { CHECKBOX_ERR_MSG, MAX_LEN_TEXTAREA_ERR_MSG, MIN_LEN_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const releaseDateSchema = zod.object({
  [ApiKeyFields.schedules_at]: zod.custom<string>(
    (value) => {
      return dayjs(value).isValid();
    },
    { message: "Please select a release date." },
  ),
  [ApiKeyFields.date_confirmation]: zod.literal(true, { errorMap: () => CHECKBOX_ERR_MSG }),
});

export const isScheduleEarlier = zod
  .object({
    [ApiKeyFields.schedule_earlier]: zod.boolean().nullable().optional(),
    [ApiKeyFields.additional_options]: zod.string().trim().max(1500, MAX_LEN_TEXTAREA_ERR_MSG).nullable(),
  })
  .superRefine((data, ctx) => {
    if (data[ApiKeyFields.schedule_earlier] === true) {
      const additionalOptions = data[ApiKeyFields.additional_options];
      if (!additionalOptions || additionalOptions.trim() === "") {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: MIN_LEN_ERR_TEXT,
          path: [ApiKeyFields.additional_options],
        });
      }
    }
  });
