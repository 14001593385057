import { GridColDef } from "@mui/x-data-grid";

import { TERRITORY_LABEL_VALUE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

export const AFFILIATION_COL: GridColDef = {
  field: ApiKeyFields.affiliation,
  headerName: "Affiliation",
  sortable: false,
  flex: 1,
  minWidth: 250,
  disableColumnMenu: true,
};

export const COUNTRY_COL: GridColDef = {
  field: ApiKeyFields.country,
  headerName: "Country",
  sortable: false,
  renderCell: ({ value }) => TERRITORY_LABEL_VALUE_OPTIONS.find((option) => option.value === value)?.label,
  flex: 1,
  minWidth: 200,
  disableColumnMenu: true,
};

export const SPLIT_COL: GridColDef = {
  field: ApiKeyFields.split,
  headerName: "Split",
  sortable: false,
  renderCell: ({ value }) => <span>{value}%</span>,
  flex: 1,
  minWidth: 150,
  disableColumnMenu: true,
};
