import { ROUTES } from "constants/routes";
import { DistributionType, PublishingType } from "types";

export const OPTIONS = {
  [ROUTES.DISTRIBUTION]: [
    { label: "roles.artist", value: String(DistributionType.ARTIST) },
    {
      label: "roles.artistRepresentative",
      value: String(DistributionType.ARTIST_REPRESENTATIVE),
    },
  ],
  [ROUTES.DISTRIBUTION_AND_PUBLISHING]: [
    {
      label: "roles.artistSongwriter",
      value: `${DistributionType.ARTIST}:${PublishingType.SONGWRITER}`,
    },
    {
      label: "roles.artistSongwriterRepresentative",
      value: `${DistributionType.ARTIST_REPRESENTATIVE}:${PublishingType.SONGWRITER_REPRESENTATIVE}`,
    },
  ],
  [ROUTES.PUBLISHING]: [
    { label: "roles.songwriter", value: String(PublishingType.SONGWRITER) },
    {
      label: "roles.songwriterRepresentative",
      value: String(PublishingType.SONGWRITER_REPRESENTATIVE),
    },
  ],
};

export const HELPER_TEXT = {
  [ROUTES.DISTRIBUTION]: function (value: string) {
    return value === String(DistributionType.ARTIST) ? "helperText.Artist" : "helperText.ArtistRepresentative";
  },
  [ROUTES.DISTRIBUTION_AND_PUBLISHING]: function (value: string) {
    return value === `${DistributionType.ARTIST}:${PublishingType.SONGWRITER}`
      ? "helperText.ArtistSongwriter"
      : "helperText.ArtistSongwriterRepresentative";
  },
  [ROUTES.PUBLISHING]: function (value: string) {
    return value === String(PublishingType.SONGWRITER) ? "helperText.Songwriter" : "helperText.SongwriterRepresentative";
  },
};
