import { GridSortModel } from "@mui/x-data-grid";

import { ApiKeyFields } from "types";

import { sortingMapping } from "./constants";

export const defaultSortData = {
  order_by_field_name: ApiKeyFields.status,
  order: "asc",
};

export const defaultSortModel: GridSortModel = [
  {
    field: ApiKeyFields.status,
    sort: "asc",
  },
];

export const getSortingData = (sortModel: GridSortModel) => {
  if (!sortModel.length) {
    return defaultSortData;
  }
  const { field, sort } = sortModel[0];
  if (!field || !sort) return defaultSortData;
  return {
    order_by_field_name: sortingMapping[field as ApiKeyFields],
    order: sort,
  };
};
