import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ProfileSettingsBlackIcon } from "assets/icons/24px";
import { ROUTES } from "constants/routes";

import { VerticalDividerStyled } from "../VerticalDividerStyled";

import { IconButtonStyled, SettingsNavLinkStyled } from "./styled";

const ApplicationSettings: FC = () => {
  const {
    userStore: { user },
  } = useStores();

  return user?.isAdmin ? (
    <>
      <VerticalDividerStyled orientation="vertical" />
      <SettingsNavLinkStyled to={ROUTES.APPLICATION_SETTINGS}>
        <IconButtonStyled size="medium">
          <ProfileSettingsBlackIcon />
        </IconButtonStyled>
      </SettingsNavLinkStyled>
    </>
  ) : null;
};

export default observer(ApplicationSettings);
