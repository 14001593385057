import { countries, languages } from "countries-list";

import { SelectCheckboxOptions } from "components/HookFormControls";
import { LOCALE_LABEL, SUPPORTED_LOCALES } from "i18n";
import { colors } from "theme/colors";
import { ReleaseOpStatus, ReleaseStatus, UserStatus } from "types";
import { DistributionContractType } from "types/revisedForms";

export const EN_DATE_FORMAT = "MM/DD/YYYY";
export const EN_DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm a";

export const OTHER_VALUE = "OTHER";

export const DEFAULT_CURRENCY_VALUE = "USD";

export const CURRENCIES: LabelValue[] = [
  { label: "$ (USD)", value: DEFAULT_CURRENCY_VALUE },
  { label: "£ (GBP)", value: "GBP" },
  { label: "€ (EUR)", value: "EUR" },
];

export const ADVANCE_CURRENCIES: LabelValue[] = CURRENCIES.slice(0, -1);

export const YES_NO: LabelValue<boolean>[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const EXCLUDE_COUNTRIES_ARRAY = [
  "aq",
  "ax",
  "bl",
  "bm",
  "bv",
  "cc",
  "cx",
  "eh",
  "gf",
  "gg",
  "gi",
  "gs",
  "hk",
  "hm",
  "io",
  "ky",
  "mf",
  "mo",
  "mq",
  "ms",
  "nc",
  "nf",
  "pf",
  "pm",
  "pn",
  "pr",
  "sh",
  "sj",
  "tc",
  "tf",
  "tk",
  "um",
  "vg",
  "vi",
  "wf",
  "yt",
];

export const COUNTRIES: LabelValue[] = [
  ...Object.entries(countries)
    .filter(([c]) => !EXCLUDE_COUNTRIES_ARRAY.includes(c.toLowerCase()))
    .sort((a, b) => a[1].name.localeCompare(b[1].name))
    .map(([value, { name }]) => ({
      label: name,
      value,
    })),
  {
    label: "Other",
    value: OTHER_VALUE,
  },
];

export const LANGUAGES: LabelValue[] = Object.entries(languages).map(([value, { name }]) => ({
  label: name,
  value: value.toUpperCase(),
}));
const OTHER_LANGUAGE: LabelValue = {
  label: "Another Language",
  value: OTHER_VALUE,
};
export const RECORDING_LANGUAGES = [
  ...Object.entries(languages)
    .filter(([lng]) => ["en", "es", "pt", "la", "ar", "he", "de", "it", "fr", "hi", "ko"].includes(lng))
    .map(([value, { name }]) => ({
      label: name,
      value: value.toUpperCase(),
    })),
  OTHER_LANGUAGE,
];

export const SELECT_TIER: LabelValue<number | string>[] = [
  {
    label: "Entry",
    value: 1,
  },
  {
    label: "Emerging",
    value: 2,
  },
  {
    label: "Established",
    value: 3,
  },
];

export const APP_LANGUAGES = SUPPORTED_LOCALES.map((lang) => ({
  label: LOCALE_LABEL[lang],
  value: lang,
}));

export const USER_DISTRIBUTION_APPLICATION_STATUSES = [
  "Client Statuses",
  { label: "Pending Registration", value: UserStatus.EDIT_DISTRIBUTION_INFO_FORM, color: colors.blue364 },
  {
    label: "Pending Feedback",
    value: UserStatus.UPDATE_DISTRIBUTION_INFO_FORM,
    color: colors.blue364,
  },
  {
    label: "Pending Forms",
    value: UserStatus.EDIT_DISTR_LEGAL_FORM,
    color: colors.blue364,
  },
  {
    label: "Pending Form Feedback",
    value: UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM,
    color: colors.blue364,
  },
  {
    label: "Pending Signature",
    value: UserStatus.DISTR_CONTRACT_GENERATED,
    color: colors.green607,
  },
  {
    label: "Agreement Preview",
    value: UserStatus.SIGN_DISTRIBUTION_CONTRACT_PREVIEW,
    color: colors.green607,
  },
  {
    label: "Agreement Signed",
    value: UserStatus.DISTRIBUTION_CONTRACT_SIGNED,
    color: colors.green607,
  },
  "Admin Statuses",
  {
    label: "Registration Review",
    value: UserStatus.WAIT_FOR_DISTRIBUTION_INFO_FORM_REVIEW,
    color: colors.orange618,
  },
  {
    label: "Form Review",
    value: UserStatus.WAIT_FOR_DISTR_LEGAL_FORM_REVIEW,
    color: colors.orange618,
  },
  "Other Statuses",
  {
    label: "Not Qualified",
    value: UserStatus.NOT_QUALIFIED,
    color: colors.red666,
  },
] as SelectCheckboxOptions;

export const USER_PUBLISHING_APPLICATION_STATUSES: LabelValueColor<UserStatus>[] = [
  { label: "Qualified", value: UserStatus.EDIT_PUBLISHING_INFO_FORM, color: colors.green607 },
  {
    label: "Need Review",
    value: UserStatus.WAIT_FOR_QUALIFICATION,
    color: colors.orange618,
  },
  {
    label: "Not Qualified",
    value: UserStatus.NOT_QUALIFIED,
    color: colors.red666,
  },
];

export const USER_DP_APPLICATION_STATUSES: LabelValueColor<UserStatus>[] = [
  { label: "Qualified", value: UserStatus.EDIT_DISTRIBUTION_INFO_FORM, color: colors.green607 },
  {
    label: "Need Review",
    value: UserStatus.WAIT_FOR_QUALIFICATION,
    color: colors.orange618,
  },
  {
    label: "Not Qualified",
    value: UserStatus.NOT_QUALIFIED,
    color: colors.red666,
  },
];

export const CONTRACT_TYPE: LabelValue<DistributionContractType>[] = [
  { label: "Custom", value: DistributionContractType.MANUAL },
  { label: "One-click Agreement", value: DistributionContractType.ONE_CLICK },
];

export const RELEASE_STATUSES: LabelValueColor<ReleaseStatus>[] = [
  { label: "Draft", value: ReleaseStatus.Draft, color: colors.blue364 },
  { label: "Release In Review", value: ReleaseStatus.InRevision, color: colors.orange618 },
  { label: "Release Pending", value: ReleaseStatus.ChangesNeeded, color: colors.blue364 },
  { label: "Approved", value: ReleaseStatus.Released, color: colors.green200 },
];

export const RELEASE_OP_STATUSES: LabelValueColor<ReleaseOpStatus>[] = [
  { label: "Draft", value: ReleaseOpStatus.Draft },
  { label: "Validated", value: ReleaseOpStatus.Validated },
  { label: "Submitted", value: ReleaseOpStatus.Submitted },
  { label: "Distributed", value: ReleaseOpStatus.Distributed },
  { label: "Released", value: ReleaseOpStatus.Released },
  { label: "Taken Down", value: ReleaseOpStatus.TakenDown },
  { label: "Deleted", value: ReleaseOpStatus.Deleted },
  { label: "Retracted", value: ReleaseOpStatus.Retracted },
  { label: "Error", value: ReleaseOpStatus.Error },
];
