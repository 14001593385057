import { FC } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

export const EmptyTableListStyled = styled.div`
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const TableNoDataMessage: FC<{ text: string }> = ({ text }) => (
  <EmptyTableListStyled>
    <Typography variant="body1">{text}</Typography>
  </EmptyTableListStyled>
);

export default TableNoDataMessage;
