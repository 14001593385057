import { FC, useMemo } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { ApiKeyFields } from "types";

import { SplitTotalContainer, TableFooterStyled } from "./styled";
import { FooterProps } from "./types";

const FooterSplitTotal: FC<FooterProps> = ({ fields = [], columns = [], isDragEnabled }) => {
  const totalSplit = useMemo(() => {
    return fields.reduce((acc, row) => acc + (row.split || 0), 0);
  }, [fields]);

  const { splitText, splitTextColor } = useMemo(() => {
    if (totalSplit === 0) {
      return { splitText: "100% Remaining", splitTextColor: "text.success" };
    } else if (totalSplit === 100) {
      return { splitText: "0% Remaining", splitTextColor: "text.success" };
    } else {
      return { splitText: "The sum of the splits must equal 100%", splitTextColor: "text.error" };
    }
  }, [totalSplit]);

  return (
    <TableFooterStyled>
      <TableRow sx={{ padding: "16px" }}>
        {isDragEnabled ? <TableCell /> : null}
        <TableCell
          style={{
            textAlign: "start",
            padding: "0 8px 0 16px",
            boxSizing: "border-box",
            width: columns.slice(0, 2).reduce((totalWidth, column) => totalWidth + (column.minWidth || 100), 0),
          }}
          colSpan={2}
        >
          <Typography variant="h3" color="text.primary">
            Total
          </Typography>
        </TableCell>

        {columns.slice(2).map((column) => (
          <TableCell
            key={column.field}
            style={{
              minWidth: column.minWidth || 100,
              width: column.width || "auto",
              padding: "0 8px",
              boxSizing: "border-box",
            }}
          >
            {column.field === ApiKeyFields.split ? (
              <SplitTotalContainer>
                <Typography variant="h3" color="text.primary">
                  {totalSplit.toFixed(2)}%
                </Typography>
                <Typography variant="caption" color={splitTextColor}>
                  {splitText}
                </Typography>
              </SplitTotalContainer>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableFooterStyled>
  );
};

export default FooterSplitTotal;
