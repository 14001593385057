import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { AngleDownIcon, ProfileBlueIcon, SignOutIcon } from "assets/icons/24px";
import GenericDialog from "components/GenericDialog";
import { ROUTES } from "constants/routes";
import { RESOURCES_KEYS } from "i18n";

const ListItemIconStyled = styled(ListItemIcon)`
  svg path {
    fill: ${({ theme }) => theme.palette.icon};
  }
`;

const UserMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutAlert, setLogoutAlert] = useState<boolean>(false);
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const {
    userStore: { user },
  } = useStores();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogoutAlertClose = () => {
    setLogoutAlert(false);
  };

  const handleSettingsClick = () => {
    navigate(ROUTES.SETTINGS);
    handleMenuClose();
  };

  const handleLogoutMenuClick = () => {
    handleMenuClose();
    setLogoutAlert(true);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}${ROUTES.LOGIN}`,
    });
  };

  const userName = useMemo(() => {
    return user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : `Your Account`;
  }, [user]);

  return (
    <>
      <Button variant="text" size="small" endIcon={<AngleDownIcon />} onClick={handleMenu}>
        <Typography variant="h3" color="common.white" maxWidth={240} noWrap>
          {userName}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{ paper: { sx: { width: "200px" } } }}
      >
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIconStyled>
            <ProfileBlueIcon />
          </ListItemIconStyled>
          <ListItemText>Profile Settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogoutMenuClick}>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText>{t`buttons.logout`}</ListItemText>
        </MenuItem>
      </Menu>
      <GenericDialog
        open={logoutAlert}
        onClose={handleLogoutAlertClose}
        title="Sign out of the account?"
        PaperProps={{ sx: { width: "448px" } }}
        content={<Typography variant="body1">All unsaved data will be lost.</Typography>}
        actions={
          <>
            <Button size="small" variant="outlined" onClick={handleLogoutAlertClose}>
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleLogout}>
              Sign Out
            </Button>
          </>
        }
      />
    </>
  );
};

export default observer(UserMenu);
