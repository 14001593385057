const wrapSignPlaceholdersInSpan = (text: string) => {
  const span = document.createElement("span");
  span.style.fontFamily = "Oregano";
  span.style.fontStyle = "normal";
  span.style.textDecoration = "none";
  span.style.fontWeight = "400";
  span.style.color = "black";
  span.setAttribute("data-signature-placeholder", text);
  span.textContent = text;
  return span.outerHTML;
};

export const joditCleanContent = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  doc.querySelectorAll<HTMLDivElement>('div[data-page-break="true"]').forEach((div) => {
    div.textContent = "";
  });
  doc.querySelectorAll<HTMLSpanElement>('span[data-line-break="true"]').forEach((span) => {
    span.style.border = "";
  });

  doc.body.innerHTML = doc.body.innerHTML.replace(
    /{{agreement_signer_signature}}/g,
    wrapSignPlaceholdersInSpan("{{agreement_signer_signature}}"),
  );
  doc.body.innerHTML = doc.body.innerHTML.replace(
    /{{imr_signer_signature}}/g,
    wrapSignPlaceholdersInSpan("{{imr_signer_signature}}"),
  );

  return doc.body.innerHTML;
};

export const joditAddContent = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  doc.querySelectorAll<HTMLDivElement>('div[data-page-break="true"]').forEach((div) => {
    div.textContent = "Page break";
  });
  doc.querySelectorAll<HTMLSpanElement>('span[data-line-break="true"]').forEach((span) => {
    span.style.border = "1px solid black";
  });

  doc.querySelectorAll<HTMLSpanElement>("span[data-signature-placeholder]").forEach((span) => {
    const parent = span.parentNode;
    if (parent) {
      parent.replaceChild(document.createTextNode(span.textContent || ""), span);
    }
  });

  return doc.body.innerHTML;
};

export const extractPlaceholders = (text: string): string[] => {
  const regex = /\{\{(.*?)\}\}/g;
  const matches = Array.from(text.matchAll(regex));
  return matches.map((match) => match[1].trim());
};
