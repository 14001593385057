import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: {
      borderRadius: 8,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray749,
      },
      overflow: "hidden",
    },
  },
  variants: [
    {
      props: { color: "primary" },
      style: {
        ".MuiInputBase-root": {
          backgroundColor: colors.gray257,
        },
        borderColor: colors.gray749,
      },
    },
    {
      props: { color: "primaryDark" },
      style: {
        ".MuiInputBase-root": {
          backgroundColor: colors.white,
        },
      },
    },
  ],
};

export const MuiFilledInput: Components["MuiFilledInput"] = {
  styleOverrides: {
    root: {
      "& .Mui-disabled": {
        "-webkit-text-fill-color": "inherit !important",
        color: colors.black418,
      },
      borderRadius: 0,
      "&.MuiFilledInput-root": {
        backgroundColor: "transparent",
        "&:before": {
          borderBottomStyle: "solid",
          borderColor: colors.gray749,
        },
      },
      "& .MuiInputAdornment-root": {
        marginTop: "0px !important",
      },
      ".MuiFilledInput-input": {
        padding: "0 0 9px 16px",
      },
    },
  },
};

export const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray749,
      },
      "&.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.gray215,
        },
      },
      // Primary
      "&.Mui-disabled.MuiInputBase-colorPrimary": {
        backgroundColor: colors.white,
        "& .MuiInputBase-input": {
          backgroundColor: colors.white,
        },
      },
      // Primary Dark
      "&.Mui-disabled.MuiInputBase-colorPrimaryDark": {
        backgroundColor: colors.gray257,
        "& .MuiInputBase-input": {
          backgroundColor: colors.gray257,
        },
      },

      "&.Mui-error": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: colors.gray749,
        },
      },
    },
    sizeSmall: {
      minHeight: "36px",
    },
  },
};

export const MuiInput: Components["MuiInput"] = {
  defaultProps: {
    disableUnderline: true,
  },
};
