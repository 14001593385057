import React from "react";
import { Control, Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { MuiTelInput, MuiTelInputCountry, MuiTelInputProps } from "mui-tel-input";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { EXCLUDE_COUNTRIES_ARRAY } from "constants/options";

import ClearInputAdornment from "../ClearInputAdornment";
import { useReadOnlyParams } from "../useReadOnlyParams";

const MuiTelInputStyled = styled(MuiTelInput)`
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent;
  }
`;

type PhoneFieldType<FieldType extends FieldValues> = MuiTelInputProps & {
  fieldName: string;
  control: Control<FieldType>;
  readOnly?: boolean;
};

const PhoneField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  readOnly,
  ...props
}: PhoneFieldType<FieldType>) => {
  const { setValue } = useFormContext();

  const handleClearClick = () => {
    setValue(fieldName, "", { shouldDirty: true, shouldValidate: true });
  };

  const excludedCountries = EXCLUDE_COUNTRIES_ARRAY.map((c) => c.toUpperCase()) as MuiTelInputCountry[];

  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label ? <FormLabel>{label}</FormLabel> : null}
          <MuiTelInputStyled
            size="small"
            defaultCountry="US"
            InputProps={{
              endAdornment: fieldProps.value?.length ? (
                <ClearInputAdornment handleClearClick={handleClearClick} isReadOnly={readOnly} />
              ) : null,
            }}
            {...fieldProps}
            {...props}
            {...(readOnlyProps as { disabled?: boolean })}
            helperText={error?.message?.trim() ?? props.helperText}
            InputLabelProps={{ shrink: true }}
            onChange={onChange}
            fullWidth
            excludedCountries={excludedCountries}
            error={!!error}
          />
        </FormControl>
      )}
    />
  );
};

export default PhoneField;
