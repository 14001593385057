import { FC } from "react";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import styled from "@emotion/styled";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Logo from "assets/images/im_logo_dark.png";
import { SelectStyled } from "components/FormControls";
// import LanguageSelect from "components/LanguageSelect";
import { LAYOUT } from "theme";

import ApplicationSettings from "./ApplicationSettings/ApplicationSettings";
import AdminNav from "./AdminNav";
import UserMenu from "./UserMenu";
import UserNotifications from "./UserNotifications";

export const AppBarStyled = styled(AppBar)`
  box-shadow: none;
`;

export const ToolbarStyled = styled(Toolbar)`
  && {
    height: ${LAYOUT.APP_BAR_HEIGHT}px;
    padding: 22px 40px;
  }

  ${SelectStyled} {
    color: ${({ theme }) => theme.palette.common.white};
    background: none;
    border: none;

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.icon};
    }
  }
`;

const NavBar: FC = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppBarStyled position="sticky">
      <ToolbarStyled>
        <Link to="/" style={{ flexGrow: 1 }}>
          <Box component="img" src={Logo} sx={{ width: 242 }} />
        </Link>
        {
          isAuthenticated ? (
            <>
              <AdminNav />
              <UserMenu />
              <UserNotifications />
              <ApplicationSettings />
            </>
          ) : null
          // <LanguageSelect />
        }
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default NavBar;
