import { ApiKeyFields, UserStatus } from "types";

import { ApplicationFilters, ProcessedFilters } from "./types";

export const processFilters = (filters?: ApplicationFilters) => {
  if (!filters) {
    return {};
  }

  const processedFilters: ProcessedFilters = {};

  if (filters[ApiKeyFields.user_statuses]?.length) {
    processedFilters[ApiKeyFields.user_statuses] = filters[ApiKeyFields.user_statuses];
  } else {
    processedFilters[ApiKeyFields.user_statuses] = [
      UserStatus.NOT_QUALIFIED,
      UserStatus.EDIT_DISTRIBUTION_INFO_FORM,
      UserStatus.WAIT_FOR_DISTRIBUTION_INFO_FORM_REVIEW,
      UserStatus.UPDATE_DISTRIBUTION_INFO_FORM,
      UserStatus.EDIT_DISTR_LEGAL_FORM,
      UserStatus.WAIT_FOR_DISTR_LEGAL_FORM_REVIEW,
      UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM,
      UserStatus.DISTR_CONTRACT_GENERATED,
      UserStatus.SIGN_DISTRIBUTION_CONTRACT_PREVIEW,
      UserStatus.DISTRIBUTION_CONTRACT_SIGNED,
    ];
  }

  const processBooleanOrString = (value: boolean | string): boolean | undefined => {
    return typeof value === "string" ? undefined : value;
  };

  processedFilters[ApiKeyFields.user_is_active] = processBooleanOrString(filters[ApiKeyFields.user_is_active]);
  processedFilters[ApiKeyFields.user_is_invited] = processBooleanOrString(filters[ApiKeyFields.user_is_invited]);

  const createdAtRange: DateRangeValue | null = filters[ApiKeyFields.createdAt];
  if (createdAtRange) {
    const [from, to] = createdAtRange;
    processedFilters[ApiKeyFields.user_qualified_at_from] = from
      ? from.set("hour", 0).set("minute", 0).set("second", 0).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    processedFilters[ApiKeyFields.user_qualified_at_to] = to
      ? to.set("hour", 23).set("minute", 59).set("second", 59).format("YYYY-MM-DDTHH:mm:ss")
      : null;
  }

  return processedFilters;
};
