import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { RangePickerProps } from "antd/lib/date-picker";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

import { RangePicker } from "components/FormControls";
import { EN_DATE_FORMAT } from "constants/options";

type RangePickerFieldType<FieldType extends FieldValues> = RangePickerProps & {
  fieldName: string;
  control: Control<FieldType>;
  label?: string;
};

const DatePickerField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  ...props
}: RangePickerFieldType<FieldType>) => {
  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <RangePicker
              format={EN_DATE_FORMAT}
              {...fieldProps}
              {...props}
              separator={<span style={{ color: "#9DA2A6" }}>–</span>}
              onChange={onChange}
            />
            {error?.message ? (
              <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
                {error?.message}
              </Typography>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default DatePickerField;
