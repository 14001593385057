import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

export const AdminDistrLegalRoute: RouteObject[] = [
  {
    path: ROUTES.APPLICATIONS_DISTR_LEGAL_ID(),
    async lazy() {
      return { Component: (await import("./DistributionLegal")).default };
    },
    children: [
      {
        index: true,
        async lazy() {
          return { Component: (await import("./Pages")).AdminWaitForDistrLegalQualification };
        },
      },
      {
        async lazy() {
          return {
            Component: (await import("components/RegistrationForm/RegistrationFormsContext")).DistributionInfoFormProvider,
          };
        },
        children: [
          {
            path: ROUTES.CONTACT_ROUTE,
            async lazy() {
              return { Component: (await import("./Pages")).AdminWaitForDistrLegalContact };
            },
          },
          {
            path: ROUTES.ADDRESS_ROUTE,
            async lazy() {
              return { Component: (await import("./Pages")).AdminWaitForDistrLegalAddressInformation };
            },
          },
          {
            path: ROUTES.CATALOG_ROUTE,
            async lazy() {
              return { Component: (await import("./Pages")).AdminWaitForDistrLegalCatalog };
            },
          },
        ],
      },
      {
        path: ROUTES.AGREEMENT_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminWaitForDistrLegalAgreement };
        },
      },
      {
        path: ROUTES.DISTRIBUTION_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).AdminWaitForDistrLegalDistribution };
        },
      },
    ],
  },
];
