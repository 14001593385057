import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export function utcToFormat(
  date: string,
  format: string,
  amountToAdd: number = 0,
  unit: dayjs.ManipulateType = "day",
  timeZone: string | undefined = undefined,
) {
  const parsedDate = dayjs.utc(date);
  if (!parsedDate.isValid()) {
    return null;
  }
  const result = parsedDate.add(amountToAdd, unit).tz(timeZone);
  return result.format(format);
}

/**
 * Converts a time string in the format "HH:MM:SS" into the total number of seconds.
 * This total number of seconds can then be used to represent the duration in ISO8601 format.
 *
 * @param {string} time - The time string in the format "HH:MM:SS".
 * @returns {number} - The total number of seconds corresponding to the given time.
 *
 * @example
 * timeStringToSeconds("12:12:12"); // Outputs 43932
 */
export function timeStringToSeconds(time: string): number {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

/**
 * Converts a total number of seconds into a time string in the format "HH:MM:SS".
 * This time string represents a duration that can be formatted in ISO8601 format as `PT${H}H${M}M${S}S`.
 *
 * @param {number} seconds - The total number of seconds to convert into a time string.
 * @returns {string} - The time string in the format "HH:MM:SS", representing the ISO8601 duration.
 *
 * @example
 * secondsToTimeString(43932); // Outputs "12:12:12"
 */
export function secondsToTimeString(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
  return `${hours}:${minutes}:${remainingSeconds}`;
}
