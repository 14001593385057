import { COMMENT_TYPE } from "context/CustomFormContext";

import { ApiKeyFields } from "types/apiKeyFields";
import { Comment } from "types/revisedForms";
import { convertCamelToSnakeCase } from "utils";

export const getDefaultValues = (fieldName: ApiKeyFields, commentType: COMMENT_TYPE) => {
  return {
    [ApiKeyFields.forAdmin]: commentType === COMMENT_TYPE.ADMIN,
    [ApiKeyFields.fieldName]: convertCamelToSnakeCase(fieldName) as ApiKeyFields,
    [ApiKeyFields.text]: "",
    [ApiKeyFields.status]: 1,
  };
};

export const prepareFormData = (data: Comment) => {
  return { ...data, [ApiKeyFields.fieldName]: convertCamelToSnakeCase(data[ApiKeyFields.fieldName]) as ApiKeyFields };
};
