import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.name]: "",
  [ApiKeyFields.advance]: null,
  [ApiKeyFields.email]: null,
  [ApiKeyFields.rightsAdministrator]: "",
  [ApiKeyFields.affiliation]: "",
  [ApiKeyFields.country]: "",
  [ApiKeyFields.split]: null,
};

export const HELPER_LINKS = {
  CCLI: "https://songselect.ccli.com/",
  ASCAP: "https://www.ascap.com/repertory#/",
  BMI: "https://repertoire.bmi.com/",
  MLC: "https://portal.themlc.com/search#work",
};
