import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiAppBar: Components["MuiAppBar"] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.dark950,
    },
  },
};
