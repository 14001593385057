import styled from "@emotion/styled";

import Divider from "@mui/material/Divider";

export const VerticalDividerStyled = styled(Divider)(
  ({ theme }) => `
      border-color: ${theme.palette.dividerDark};
      margin: 0 24px;
    `,
);
