import { FC } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import EmptyTable2x from "assets/images/2x/Illustration_Empty-Table2x.png";
import { MessageContainerStyled, PlaceHolderExample } from "components/Layout";

const ContainerStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const NoRowsOverlay: FC = () => {
  return (
    <ContainerStyled>
      <MessageContainerStyled>
        <PlaceHolderExample cardImage={EmptyTable2x} />
        <Typography variant="body1" color="text.header">
          There are no releases(s) added.
        </Typography>
      </MessageContainerStyled>
    </ContainerStyled>
  );
};

export default NoRowsOverlay;
