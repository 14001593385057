import applications from "./applications.json";
import common from "./common.json";
import errorsAndNotifications from "./errorsAndNotifications.json";
import landing from "./landing.json";
import options from "./options.json";
import qualification from "./qualification.json";
import registration from "./registration.json";
import welcome from "./welcome.json";

export const en_us = {
  applications,
  common,
  landing,
  qualification,
  options,
  errorsAndNotifications,
  welcome,
  registration,
};
