import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { FormBlockGridGap, Modal, SelectField, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, PrimaryFormNames, SecondaryFormNames, TracksForm } from "types";

import { TRACK_LANGUAGES_OPTIONS } from "../constants";
import { useTracks } from "../useTracks";

import { defaultValues, FORM_LABELS } from "./constants";
import { schema } from "./validation";

interface AddTrackProps extends BaseModalProps {
  successCallback?: (work: TracksForm) => void;
}

const AddTrack: FC<AddTrackProps> = ({ successCallback, closeModal }) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId: primaryFormId } = useParams<ReleaseUrlParams>();
  const { handleOpenTrackClick } = useTracks();

  const form = useForm<TracksForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = form;

  const onSubmit = handleSubmit(async (data) => {
    if (!primaryFormId) return;
    try {
      setOverlayLoading(true);
      const { revisedForm } = await revisedFormService.postSecondaryForm(
        {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseTracks,
          primaryFormId,
        },
        data,
      );
      successCallback?.(revisedForm);
      closeModal();
      handleOpenTrackClick(revisedForm);
    } finally {
      resetLoading();
    }
  });

  return (
    <FormProvider {...form}>
      <Modal.ModalTitle title="Add Track" closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.title]} fieldName={ApiKeyFields.title}>
            {(field) => <TextField {...field} control={control} />}
          </FormGridFieldItem>
          <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.language]} fieldName={ApiKeyFields.language}>
            {(field) => <SelectField {...field} control={control} options={TRACK_LANGUAGES_OPTIONS} />}
          </FormGridFieldItem>
        </Grid>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" disabled={!isValid} onClick={onSubmit}>
          Add
        </Button>
      </Modal.ModalActions>
    </FormProvider>
  );
};

export default observer(AddTrack);
