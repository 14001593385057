import React, { FC, ReactNode, useContext } from "react";
import { CustomFormContext } from "context";

import Grid, { RegularBreakpoints } from "@mui/material/Grid";

import { ApiKeyFields } from "types";

import FormGridItem, { FormGridItemProps } from "./FormGridItem";
import { CommentMode, EditMode } from "./Mode";

type WithFieldNameChildren<P> = P & {
  children: (props: { fieldName: ApiKeyFields; readOnly?: boolean }) => React.ReactNode;
};

type FormGridFieldItemProps = WithFieldNameChildren<Omit<FormGridItemProps, "children">> & {
  label?: string | ReactNode;
  fieldName: ApiKeyFields;
  inputGrid?: RegularBreakpoints;
  hideLabelGrid?: boolean;
  isResolvingMode?: boolean;
  isOptionalField?: boolean;
};

const FormGridFieldItem: FC<FormGridFieldItemProps> = ({
  fieldName,
  children,
  inputGrid,
  hideLabelGrid,
  isResolvingMode,
  isOptionalField,
  ...props
}) => {
  const { isFieldReadOnly, isFieldEdited, isFieldCommented } = useContext(CustomFormContext);

  const readOnly = isFieldReadOnly(fieldName);
  const isEdited = isFieldEdited(fieldName);
  const isCommented = isFieldCommented(fieldName);

  const inputGridOverride = inputGrid
    ? inputGrid
    : isEdited || isCommented
      ? { xs: 10, md: isEdited && isCommented ? 4.5 : 5 }
      : void 0;

  return (
    <FormGridItem
      {...props}
      inputGrid={inputGridOverride}
      hideLabelGrid={hideLabelGrid}
      isOptionalField={isOptionalField}
      additionalActions={
        <React.Fragment>
          {isEdited || isCommented ? (
            <Grid
              item
              xs={1}
              md={isEdited && isCommented && !isResolvingMode ? 1.5 : 1}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-around"
            >
              {isEdited ? (
                <EditMode label={props.label} fieldName={fieldName}>
                  {children({ fieldName })}
                </EditMode>
              ) : null}
              {isCommented ? <CommentMode fieldName={fieldName} /> : null}
            </Grid>
          ) : null}
        </React.Fragment>
      }
    >
      <React.Fragment>{children({ fieldName, readOnly })}</React.Fragment>
    </FormGridItem>
  );
};

export default FormGridFieldItem;
