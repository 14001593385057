import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import pickBy from "lodash/pickBy";

import { ReleaseArtistsForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils/transformObjects";

export const prepareRequest = (
  formData: ReleaseArtistsForm,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  const filteredData = pickBy(formData, (_, key) => dirtyFields[key]);
  return replaceEmptyAndUndefinedWithNull(filteredData);
};
