import { createTheme, Theme } from "@mui/material/styles";

import { breakpoints } from "./breakpoints";
import { paletteDark, paletteLight } from "./colors";
import { components } from "./components";
import { typography } from "./typography";

export const LAYOUT = {
  APP_BAR_HEIGHT: 80,
  BORDER_RADIUS: 8,
  DROPDOWN_MAX_HEIGHT: "412px",
};

export const theme: Theme = createTheme({
  breakpoints,
  palette: {
    ...paletteLight,
    ...paletteDark,
  },
  shape: {
    borderRadius: LAYOUT.BORDER_RADIUS,
  },
  components,
  typography,
});
