import { FC } from "react";
import styled from "@emotion/styled";
import DatePickerAnt, { DatePickerProps } from "antd/lib/date-picker";

import { CalendarIcon, CloseIcon } from "assets/icons/24px";

export const DatePickerStyled = styled(DatePickerAnt)(
  ({ theme }) => `
  &.ant-picker-focused {
    border-width: 2px;
  }
  &.ant-picker-disabled, &.ant-picker-disabled input {
    cursor: unset !important;
  }

  svg path { fill: ${theme.palette.icon} }
`,
);

const DatePicker: FC<DatePickerProps> = (props) => {
  return (
    <DatePickerStyled
      placeholder=""
      size="small"
      getPopupContainer={(trigger) => trigger}
      suffixIcon={<CalendarIcon />}
      allowClear={{
        clearIcon: <CloseIcon />,
      }}
      {...props}
    />
  );
};

export default DatePicker;
