import { FC } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import NoSearchResults2x from "assets/images/2x/Illustration_No-Search-Results2x.png";
import { MessageContainerStyled, PlaceHolderExample } from "components/Layout";

const ContainerStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const NoRowsOverlay: FC = () => {
  return (
    <ContainerStyled>
      <MessageContainerStyled>
        <PlaceHolderExample cardImage={NoSearchResults2x} />
        <Typography variant="body1" color="text.header">
          No values found. Try changing the search parameters.
        </Typography>
      </MessageContainerStyled>
    </ContainerStyled>
  );
};

export default NoRowsOverlay;
