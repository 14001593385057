import { WaveFile } from "wavefile";

export async function validateWavFile(files: File[]) {
  const file = files[0];

  try {
    // Check file size
    if (file.size > 2.5 * 1024 * 1024 * 1024) {
      return { valid: false, error: "File size exceeds 2.5 GB limit." };
    }

    // Read the file as an ArrayBuffer
    const buffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);

    // Initialize WaveFile for bit depth validation
    const wav = new WaveFile();
    wav.fromBuffer(uint8Array);

    const bitDepth = parseInt(wav.bitDepth, 10);
    if (bitDepth < 16 || bitDepth > 32) {
      return { valid: false, error: "Bit depth is out of valid range (16-32)." };
    }

    // Initialize AudioContext to validate duration, sample rate, and channels
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(buffer);

    // Check audio duration
    const duration = audioBuffer.duration;
    if (duration < 3 || duration > 99 * 60 + 59) {
      return { valid: false, error: "Audio duration is out of valid range (3 sec - 99:59)." };
    }

    if (wav.fmt && "sampleRate" in wav.fmt) {
      const sampleRate: number = wav.fmt.sampleRate as number;
      if (sampleRate < 24000) {
        return { valid: false, error: "Sample rate is lower than 24 kHz." };
      }
    } else {
      return { valid: false, error: "Sample rate is lower than 24 kHz." };
    }

    return { valid: true, error: null };
  } catch {
    return { valid: false, error: "The file was not uploaded. This file is corrupted. Please upload another file. " };
  }
}
