import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { COOKIE_POLICY_LINK } from "constants/href";

const MessageContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.header};
  background-color: ${({ theme }) => theme.palette.background.blue};
  position: fixed;
  padding: 14px 40px;
  bottom: 0;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }

  .MuiButton-root {
    background-color: ${({ theme }) => theme.palette.secondaryDark.main};

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryDark.light};
    }
  }
`;

const AgreeBtnStyled = styled(Button)`
  && {
    background-color: transparent;
  }
`;

const COOKIE_KEY = "cookies_accepted";

const CookiePolicy: FC = () => {
  const cookiesAccepted = localStorage.getItem(COOKIE_KEY);

  const { t } = useTranslation("common");
  const [agree, setAgreement] = useState(Boolean(cookiesAccepted));

  const handleAgreement = () => {
    localStorage.setItem(COOKIE_KEY, "true");
    setAgreement(true);
  };

  return agree ? null : (
    <MessageContainer maxWidth={false}>
      <div>
        <Typography component="span" fontSize={14}>{t`cookiePolicy.agreementText`}</Typography>{" "}
        <Typography
          component="a"
          href={COOKIE_POLICY_LINK}
          fontSize={14}
          rel="noreferrer"
          target="_blank"
        >{t`cookiePolicy.reviewLink`}</Typography>
      </div>
      <AgreeBtnStyled variant="outlined" color="primary" size="small" onClick={handleAgreement}>{t`agree`}</AgreeBtnStyled>
    </MessageContainer>
  );
};

export default CookiePolicy;
