import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { InputNumberProps } from "antd/lib/input-number";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

import { NumberInput } from "components/FormControls";

import { ReadOnlyField } from "..";

type NumberInputFieldType<FieldType extends FieldValues> = InputNumberProps & {
  fieldName: string;
  label?: string;
  control: Control<FieldType>;
  hideControlArrows?: boolean;
  readOnly?: boolean;
};

const NumberInputField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  readOnly,
  ...props
}: NumberInputFieldType<FieldType>) => {
  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabel>{label}</FormLabel> : null}
            {readOnly ? (
              <ReadOnlyField endAdornment={props.addonAfter} {...fieldProps} {...props} />
            ) : (
              <>
                <NumberInput {...props} {...fieldProps} onChange={onChange} />
                {error?.message?.trim() ? (
                  <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
                    {error?.message}
                  </Typography>
                ) : null}
              </>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default NumberInputField;
