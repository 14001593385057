import qs from "qs";
import type { ReleaseListParams } from "stores/tablesStore/ReleasesTable/types";
import type {
  BulkInviteUser,
  CurrentUser,
  PatchCurrentUser,
  PatchUserById,
  PostUser,
  UserByIdResponse,
  UserListParams,
  UserListResponse,
} from "types";

import { axiosInstance } from "services";
import type { ArtistForm } from "types/qualification";
import type { UserReleaseResponse } from "types/revisedForms/digitalRelease";

export function getUsersList(params: UserListParams): Promise<UserListResponse> {
  return axiosInstance.get("/users", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function getCurrentReleaseList(params: ReleaseListParams): Promise<UserReleaseResponse> {
  return axiosInstance.get("/users/current/revised-forms/releases", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function getReleaseList(params: ReleaseListParams): Promise<UserReleaseResponse> {
  return axiosInstance.get("/users/revised-forms/releases", {
    params,
  });
}

export function getCurrent(): Promise<CurrentUser> {
  return axiosInstance.get("/users/current");
}

export function patchCurrent(data: PatchCurrentUser): Promise<string> {
  return axiosInstance.patch("/users/current", data);
}

export function postUser(data: PostUser): Promise<CurrentUser> {
  return axiosInstance.post("/users", data);
}

export function postArtist(artist: ArtistForm) {
  return axiosInstance.post("/users/current/artists", { artist });
}

export function postArtistById(id: string, artist: ArtistForm) {
  return axiosInstance.post(`/users/${id}/artists`, { artist });
}

export function getUserById(id: string): Promise<UserByIdResponse> {
  return axiosInstance.get(`/users/${id}`);
}

export function patchUserById(id: string, data: PatchUserById): Promise<string> {
  return axiosInstance.patch(`/users/${id}`, data);
}

export function importUser(user: BulkInviteUser): Promise<UserByIdResponse> {
  return axiosInstance.post(`/users/import`, { user });
}
