import React from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { CancelEditRedIcon, CheckGreenIcon } from "assets/icons/24px";

import { ANCHOR } from "../../constants";
import { BlockContainer, ButtonsContainer } from "../../Styled";

import { EditPopoverProps } from "./types";

const EditPopover = ({ fieldName, label, open, anchorEl, setAnchorEl, children }: EditPopoverProps) => {
  const { setValue, getValues, control: controlContext } = useFormContext();
  const form = useForm({
    resolver: controlContext._options?.resolver || void 0,
    defaultValues: {
      [fieldName]: getValues(fieldName),
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    control,
    formState: { isDirty, errors },
  } = form;

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child)
      ? React.cloneElement(child, {
          ...child.props,
          control: control,
          disabled: false,
          readOnly: false,
        })
      : child;
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValueSave = () => {
    setValue(fieldName, form.watch(fieldName), { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    handleClose();
  };

  const isValidValue = !errors[fieldName];

  return (
    <FormProvider {...form}>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={ANCHOR}
        transformOrigin={ANCHOR}
        slotProps={{ paper: { sx: { width: "348px", overflow: "visible" } } }}
        keepMounted={false}
      >
        <BlockContainer>
          <Typography variant="body1">
            Edit the Field <b>{label}</b>
          </Typography>
        </BlockContainer>
        <Divider />
        <BlockContainer>{childrenWithProps}</BlockContainer>
        <Divider />
        <ButtonsContainer>
          <Button variant="outlined" color="error" size="small" onClick={handleClose} startIcon={<CancelEditRedIcon />}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={handleValueSave}
            disabled={!isValidValue || !isDirty}
            startIcon={<CheckGreenIcon />}
          >
            Approve
          </Button>
        </ButtonsContainer>
      </Popover>
    </FormProvider>
  );
};

export default EditPopover;
