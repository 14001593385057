import { FC } from "react";
import styled from "@emotion/styled";

import { InvitedIcon } from "assets/icons/14px";
import { Chip, ConditionalTooltip } from "components/FormComponents";
import { UserStatus } from "types";

interface UserStatusChipProps {
  status: UserStatus | null;
  handleDelete?: (e: React.MouseEvent) => void;
}

const UserStatusChip: FC<UserStatusChipProps> = ({ status, handleDelete }) => {
  const props = {
    ...(handleDelete
      ? {
          onDelete: (event: React.MouseEvent) => handleDelete(event),
          onMouseDown: (event: React.MouseEvent) => event.stopPropagation(),
          onClick: (event: React.MouseEvent) => handleDelete(event),
        }
      : void 0),
  };

  switch (status) {
    case UserStatus.NOT_QUALIFIED:
      return <Chip color="error" label="Not Qualified" {...props} />;
    case UserStatus.WAIT_FOR_QUALIFICATION:
      return <Chip color="warning" label="Need Review" {...props} />;
    case UserStatus.EDIT_DISTRIBUTION_INFO_FORM:
      return <Chip color="primary" label="Pending Registration" {...props} />;
    case UserStatus.EDIT_PUBLISHING_INFO_FORM:
      return <Chip color="success" label="Qualified" {...props} />;
    case UserStatus.WAIT_FOR_DISTRIBUTION_INFO_FORM_REVIEW:
      return <Chip color="warning" label="Registration Review" {...props} />;
    case UserStatus.EDIT_DISTR_LEGAL_FORM:
      return <Chip color="primary" label="Pending Forms" {...props} />;
    case UserStatus.UPDATE_DISTRIBUTION_INFO_FORM:
      return <Chip color="primary" label="Pending Feedback" {...props} />;
    case UserStatus.UPDATE_DISTRIBUTION_LEGAL_FORM:
      return <Chip color="primary" label="Pending Form Feedback" {...props} />;
    case UserStatus.DISTR_CONTRACT_GENERATED:
      return <Chip color="success" label="Pending Signature" {...props} />;
    case UserStatus.SIGN_DISTRIBUTION_CONTRACT_PREVIEW:
      return <Chip color="success" label="Agreement Preview" {...props} />;
    case UserStatus.DISTRIBUTION_CONTRACT_SIGNED:
      return <Chip color="success" label="Agreement Signed" {...props} />;
    case UserStatus.WAIT_FOR_DISTR_LEGAL_FORM_REVIEW:
      return <Chip color="warning" label="Form Review" {...props} />;
    case UserStatus.DISTR_RELEASE_MANAGER_AVAILABLE:
      return <Chip color="success" label="Signed" {...props} />;
    default:
      return null;
  }
};

const BlockContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

interface UserStatusChipInvitedProps extends UserStatusChipProps {
  isInvited: boolean;
}
export const UserStatusChipInvited: FC<UserStatusChipInvitedProps> = ({ isInvited, status }) => {
  return (
    <ConditionalTooltip title="Invited by Administrator" isTooltipVisible={isInvited}>
      <BlockContainerStyled>
        {isInvited ? <InvitedIcon /> : null}
        <UserStatusChip status={status} />
      </BlockContainerStyled>
    </ConditionalTooltip>
  );
};

export default UserStatusChip;
