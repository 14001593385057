import { IControlType, IJodit } from "jodit/types";
import { IJoditEditorProps, Jodit } from "jodit-react";

import { templateFonts } from "utils/jodit";

import { placeholderList, toolbarButtons } from "./constants";

const fontList = {
  "": "Default",
  ...Object.fromEntries(Object.entries(templateFonts).map(([name]) => [`${name},sans-serif`, name])),
};

export const defaultConfig: IJoditEditorProps["config"] = {
  height: "100%",
  width: "100%",
  toolbarButtonSize: "middle",
  editHTMLDocumentMode: false,
  extraPlugins: ["cleanHTML"],
  controls: {
    font: {
      list: Jodit.atom(fontList),
    },
  },
  buttons: [
    ...toolbarButtons,
    {
      name: "Line break",
      tooltip: "Insert line break",
      exec: (editor: IJodit) => {
        const selectedText = editor.selection.sel?.toString();
        if (selectedText) {
          const wrappedText = `<span data-line-break="true" style="white-space: pre-wrap; border: 1px solid black;">${selectedText}</span>`;
          editor.selection.insertHTML(wrappedText);
        }
      },
    },
    {
      name: "Page break",
      tooltip: "Insert page break",
      exec: (editor: IJodit) => {
        editor.s.insertHTML(
          '<p><br></p><div data-page-break="true" style="page-break-before: always" contenteditable="false">Page break</div><p><br></p>',
        );
      },
    },
    "|",
    {
      name: "Placeholders",
      list: placeholderList,
      template: (editor, _, value) => value,
      childExec: (editor, _, { control }) => {
        const value = control.args && control.args[0];
        if (value) {
          const node = editor.createInside.text(` {{${value}}} `);
          editor.s.insertNode(node);
        }
      },
    },
    "|",
    "undo",
    "redo",
  ] as Array<string | IControlType<IJodit>>,
  disablePlugins: "mobile",
};
