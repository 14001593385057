import { Components } from "@mui/material/styles";

export const MuiFormLabel: Components["MuiFormLabel"] = {
  styleOverrides: {
    root: {
      width: "fit-content",
      display: "flex",
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "16px",
      marginBottom: 8,
      svg: {
        marginLeft: 4,
      },
    },
  },
};
