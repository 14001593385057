import styled from "@emotion/styled";

export const LabelWithAsterisk = styled("span")<{ required?: boolean }>`
  position: relative;

  ${({ required = true, theme }) =>
    required &&
    `
      &::after {
        content: "*";
        color: ${theme.palette.error.main};
      }
  `}
`;
