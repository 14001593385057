import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export const TypographyTitleStyled = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-left: 15px;
  margin-bottom: 7px;
`;

export const StepsContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepStatusIcon = styled.div<{ status?: string }>`
  height: 16px;
  width: 6px;
  background-color: ${({ theme, status }) => status ?? theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export const NavigationBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationBlockContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionBlock = styled.div`
  display: flex;
  padding-top: 2px;
`;

export const ActionBlock = styled.div`
  display: flex;
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 4px 9px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.surface};
  }
`;

export const NavLinkStyled = styled(NavLink)`
  padding-left: 7px;
  position: relative;

  &.active {
    background: linear-gradient(90deg, #e7f4fe 0%, rgba(231, 244, 254, 0) 100%);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      border-radius: 2px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.surface};
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
