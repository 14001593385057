import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import { FormGridItem } from "components/FormComponents/FormGridItems";
import FormLabelDivider from "components/FormComponents/FormLabelDivider";
import { TextField } from "components/HookFormControls";
import { MAX_LENGTH_INPUT } from "constants/validation";
import { RESOURCES_KEYS } from "i18n";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT, SOCIAL_MEDIA_FIELD_ICONS, SOCIAL_MEDIA_FIELDS } from "./constants";

const SocialMediaBlock: FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const { control } = useFormContext();
  const { t } = useTranslation([RESOURCES_KEYS.QUALIFICATION]);

  const defaultFieldFormProps = (fieldName: ApiKeyFields) => ({
    fieldName,
    control: control,
    inputProps: { maxLength: MAX_LENGTH_INPUT },
    readOnly,
  });

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormLabelDivider title={t`artistInfo.socialMedia`} />
      </Grid>

      {SOCIAL_MEDIA_FIELDS.map((fieldName) => {
        const IconComponent = SOCIAL_MEDIA_FIELD_ICONS[fieldName];

        return (
          <FormGridItem label={t(FORM_LABELS[fieldName])} helperText={HELPER_TEXT[fieldName]} key={fieldName} isOptionalField>
            <TextField
              {...defaultFieldFormProps(fieldName)}
              InputProps={{
                startAdornment: <InputAdornment position="start">{IconComponent ? <IconComponent /> : null}</InputAdornment>,
              }}
            />
          </FormGridItem>
        );
      })}
    </React.Fragment>
  );
};

export default SocialMediaBlock;
