import { FC } from "react";

import { Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import { AddIcon } from "assets/icons/24px";
import { ConditionalTooltip, FormBlock, FormBlockHeaderStyled } from "components";

import { ActionsContainerStyled } from "./styled";
import { ReleaseTableListBlockProps } from "./types";

const ReleaseTableListBlock: FC<ReleaseTableListBlockProps> = ({
  title,
  itemsCount,
  maxLimit,
  isLoading,
  onAddClick,
  onCopyClick,
  addBtnText = "Add New",
  copyBtnText = "Copy",
  hideActionBtn,
  children,
}) => {
  const isMaxItemsLimit = itemsCount === maxLimit;

  return (
    <FormBlock
      ContainerHeader={
        <FormBlockHeaderStyled>
          <Typography variant="h3">
            {title} {itemsCount}
          </Typography>
          {!hideActionBtn ? (
            <ActionsContainerStyled>
              {onCopyClick ? (
                <ConditionalTooltip title={`You can add up to ${maxLimit} items.`} isTooltipVisible={isMaxItemsLimit}>
                  <Button
                    variant="link"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={onCopyClick}
                    disabled={isLoading || isMaxItemsLimit}
                  >
                    {copyBtnText}
                  </Button>
                </ConditionalTooltip>
              ) : null}
              {onCopyClick ? <Divider orientation="vertical" /> : null}
              <ConditionalTooltip title={`You can add up to ${maxLimit} items.`} isTooltipVisible={isMaxItemsLimit}>
                <Button
                  variant="link"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={onAddClick}
                  disabled={isLoading || isMaxItemsLimit}
                >
                  {addBtnText}
                </Button>
              </ConditionalTooltip>
            </ActionsContainerStyled>
          ) : null}
        </FormBlockHeaderStyled>
      }
      noPaddings
    >
      {children}
    </FormBlock>
  );
};

export default ReleaseTableListBlock;
