import { Comment } from "types";

export function extractComments<T extends { comments: Comment[] }>(objectsArray: T[]) {
  const commentsArray: Comment[] = [];
  objectsArray.forEach((obj) => {
    if (Array.isArray(obj.comments)) {
      commentsArray.push(...obj.comments);
    }
  });
  return commentsArray;
}
