import React from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { gridPageSizeSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";

const TableLoadingOverlay = () => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "100%",
      }}
    >
      {[...Array(pageSize)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" sx={{ my: 4, mx: 1 }} />
      ))}
    </Box>
  );
};

export default TableLoadingOverlay;
