import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.name]: "Publisher Name",
  [ApiKeyFields.advance]: "Is this Publisher controlled or administered by Integrity/Integrated Music Rights?",
  [ApiKeyFields.email]: "Email",
  [ApiKeyFields.rightsAdministrator]: "Rights Administrator",
  [ApiKeyFields.affiliation]: "PRO Affiliation",
  [ApiKeyFields.country]: "Country",
  [ApiKeyFields.split]: "Split",
};

export const HELPER_TEXT = {
  [ApiKeyFields.split]: "A percentage can have 2 decimal places. E.g. “33.34”",
  [ApiKeyFields.email]:
    "To ensure all publishers receive their publishing royalties, we’ll need their publisher’s email addresses to send the required licenses",
} as Record<ApiKeyFields, string>;
