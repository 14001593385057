import { FC } from "react";
import styled from "@emotion/styled";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { InfoIcon } from "assets/icons/14px";
import { AngleDownIcon } from "assets/icons/24px";

const HelpAccordionContainerStyled = styled("div", {
  shouldForwardProp: (prop) => !["position"].includes(prop),
})<{ position?: string }>`
  position: ${(props) => props.position ?? "absolute"};
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
`;

const IconStyled = styled(InfoIcon)`
  path {
    fill: ${({ theme }) => theme.palette.branding.main};
  }
`;

export const AccordionStyled = styled(Accordion)`
  max-height: 240px;
  box-shadow: none;
  overflow: hidden;

  box-shadow: 0px 0px 16px 0px #0d1c2626;
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  min-height: 56px;
  box-shadow: 0px 0px 16px 0px #0d1c2626;
  gap: 5px;

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 13px 12px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    margin: 0;

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.surface};
    }

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  height: 184px;
  overflow-x: scroll;
  padding: 17px 20px;
`;

interface HelpAccordionProps {
  title: string;
  children: React.ReactNode;
  position?: string;
}

const HelpAccordion: FC<HelpAccordionProps> = ({ title, children, position }) => {
  return (
    <HelpAccordionContainerStyled position={position}>
      <AccordionStyled square>
        <AccordionSummaryStyled expandIcon={<AngleDownIcon />}>
          <IconStyled />
          <Typography variant="button" textTransform="none" fontWeight="600">
            {title}
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
      </AccordionStyled>
    </HelpAccordionContainerStyled>
  );
};

export default HelpAccordion;
