import { ApiKeyFields } from "types";
import { DistributionInfoForm } from "types/revisedForms";

export const defaultDistributionInfoValues: DistributionInfoForm = {
  [ApiKeyFields.legal_first_name]: "",
  [ApiKeyFields.legal_last_name]: "",
  [ApiKeyFields.artist_name]: "",
  [ApiKeyFields.email]: "",
  [ApiKeyFields.phone]: "",
  [ApiKeyFields.business_address_1]: "",
  [ApiKeyFields.business_address_2]: null,
  [ApiKeyFields.city]: "",
  [ApiKeyFields.state]: "",
  [ApiKeyFields.zip_code]: "",
  [ApiKeyFields.country]: "",
  [ApiKeyFields.album_names]: "",
  [ApiKeyFields.eps]: "",
  [ApiKeyFields.singles]: "",
};
