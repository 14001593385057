import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const ADDRESS_FIELDS = [
  ApiKeyFields.business_address_1,
  ApiKeyFields.business_address_2,
  ApiKeyFields.city,
  ApiKeyFields.state,
  ApiKeyFields.zip_code,
];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.business_address_1]: "Mailing Address",
  [ApiKeyFields.business_address_2]: "Mailing Address Cont'd (Optional)",
  [ApiKeyFields.city]: "City",
  [ApiKeyFields.state]: "State / Province",
  [ApiKeyFields.zip_code]: "Postal or Zip Code",
  [ApiKeyFields.country]: "Country",
};

export const OPTIONAL_FIELDS: { [key: string]: true } = {
  [ApiKeyFields.business_address_2]: true,
};

export const HELPER_TEXT = {
  [ApiKeyFields.business_address_1]:
    "The address you provide here will be our go-to for sending all future documents and correspondence.",
  [ApiKeyFields.business_address_2]: "Please add the secondary address unit, i.e. suite, apartment or floor number.",
  [ApiKeyFields.zip_code]: "E.g. “00000” or “K00 L00”",
  [ApiKeyFields.country]: "Please select the country of residence.",
} as Record<ApiKeyFields, string>;

export const ADDRESS_SIDEBAR_FIELDS = [...ADDRESS_FIELDS, ApiKeyFields.country].map((field) => ({
  field,
  required: field !== ApiKeyFields.business_address_2,
})) as SubRouteFieldProps[];
