import { axiosInstance } from "services";
import { Threshold, ThresholdResponse } from "types";

export function getSettings(): Promise<ThresholdResponse> {
  return axiosInstance.get("/settings");
}

export function patchSettings(settings: Threshold[]): Promise<string> {
  return axiosInstance.patch("/settings", { settings });
}
