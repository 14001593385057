import { RouteObject } from "react-router-dom";

import { ROUTES } from "constants/routes";

export const ClientDistrInfoRoute: RouteObject[] = [
  {
    path: ROUTES.DISTR_INFO,
    async lazy() {
      return { Component: (await import("./DistributionInfo")).default };
    },
    children: [
      {
        index: true,
        async lazy() {
          return { Component: (await import("./Pages")).UserContact };
        },
      },
      {
        path: ROUTES.ADDRESS_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).UserAddressInformation };
        },
      },
      {
        path: ROUTES.CATALOG_ROUTE,
        async lazy() {
          return { Component: (await import("./Pages")).UserDistributionAgreement };
        },
      },
    ],
  } as RouteObject,
];
