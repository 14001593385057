import { ChangeEvent, FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { CheckboxStyled } from "components/FormControls";
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from "constants/href";
import { RESOURCES_KEYS } from "i18n";

const ConfirmTextBlock = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
`;

const TaCModal: FC = () => {
  const [isChecked, setChecked] = useState<boolean>(false);
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);
  const {
    userStore: { isTaCRequired, patchUser },
  } = useStores();

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked);
  };

  const handleAgreeClick = async () => {
    await patchUser({ acceptConditions: true });
    window.location.reload();
  };

  return (
    <Dialog open={isTaCRequired} maxWidth="md">
      <DialogTitle>
        <Typography variant="h3">{t("checkBeforeState")}</Typography>
      </DialogTitle>
      <DialogContent>
        <ConfirmTextBlock>
          <CheckboxStyled value={isChecked} onChange={handleCheck} />
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey={"readTaCandPP"}
              components={{
                terms: <a href={TERMS_OF_USE_LINK} rel="noreferrer" target="_blank" />,
                privacy: <a href={PRIVACY_POLICY_LINK} rel="noreferrer" target="_blank" />,
              }}
            />
          </Typography>
        </ConfirmTextBlock>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button size="small" variant="contained" disabled={!isChecked} onClick={handleAgreeClick}>
          {t("agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(TaCModal);
