import React, { createContext } from "react";

import { ApiKeyFields } from "types";
import { PostComment } from "types/revisedForms";

import { checkFieldPropType } from "./helpers";
import { COMMENT_MODE, COMMENT_TYPE, CustomFormContextState, CustomFormProps } from "./types";

export const CustomFormContext = createContext<CustomFormContextState>({
  isFieldReadOnly: () => false,
  isFieldCommented: () => false,
  isFieldEdited: () => false,
  getCommentType: () => COMMENT_TYPE.CLIENT_AND_ADMIN,
  formComments: [],
  commentCallback: undefined as ((comment: PostComment, mode: COMMENT_MODE) => Promise<void>) | undefined,
});

export const CustomFormProvider: React.FC<CustomFormProps> = ({ readOnly, comments, edit, children, isAdmin }) => {
  const isFieldReadOnly = (field: ApiKeyFields) => checkFieldPropType(readOnly.fields, readOnly.excludeFields, field);
  const isFieldCommented = (field: ApiKeyFields) => checkFieldPropType(comments.fields, comments.excludeFields, field);
  const isFieldEdited = (field: ApiKeyFields) => checkFieldPropType(edit.fields, edit.excludeFields, field);

  const getCommentType = (field: ApiKeyFields): COMMENT_TYPE => {
    if (typeof comments.commentTypes === "number") return comments.commentTypes;

    const commentTypeForField = comments.commentTypes?.find((commentType) => commentType.fieldName === field);
    return commentTypeForField ? commentTypeForField.type : COMMENT_TYPE.CLIENT_AND_ADMIN;
  };

  return (
    <CustomFormContext.Provider
      value={{
        isFieldReadOnly,
        isFieldCommented,
        isFieldEdited,
        commentCallback: comments.commentCallback,
        getCommentType,
        formComments: comments?.formComments || [],
        isAdmin,
      }}
    >
      {children}
    </CustomFormContext.Provider>
  );
};
