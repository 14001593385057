import React, { FC } from "react";
import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { DownloadIcon } from "assets/icons/24px";

const DownloadBlock = styled("div", { shouldForwardProp: (prop) => !["isReversedGradient"].includes(prop) })<{
  isReversedGradient?: boolean;
}>(
  ({ theme, isReversedGradient }) => `
    background: linear-gradient(to right, ${
      isReversedGradient ? theme.palette.common.white : theme.palette.background.surface
    }, ${isReversedGradient ? theme.palette.background.surface : theme.palette.common.white});
    padding: 11px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
  `,
);

const DownloadTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg path {
    fill: ${({ theme }) => theme.palette.text.header};
  }
`;

interface HintLinkBlockProps {
  children?: React.ReactNode;
  hideDownloadText?: boolean;
  isReversedGradient?: boolean;
  icon?: React.ElementType;
}

export const HintLinkBlock: FC<HintLinkBlockProps> = ({
  children,
  hideDownloadText,
  isReversedGradient,
  icon: Icon = DownloadIcon,
}) => {
  return (
    <DownloadBlock isReversedGradient={isReversedGradient}>
      <DownloadTitle>
        {!hideDownloadText ? (
          <Typography variant="button" color="text.header" textTransform="none" fontWeight={600}>
            Download
          </Typography>
        ) : null}
        <Icon />
      </DownloadTitle>
      {children}
    </DownloadBlock>
  );
};

export const HintLinkButtonStyled = styled(Button)`
  min-height: fit-content;
  min-width: fit-content;

  &:hover {
    text-decoration: none;
  }
` as typeof Button;
