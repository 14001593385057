import { memo } from "react";
import { useSortable } from "@dnd-kit/sortable";

import { DragAndDropIcon } from "assets/icons/14px";

interface DragHandleProps {
  id: string;
}

const DragHandle = memo(({ id }: DragHandleProps) => {
  const { listeners, setNodeRef } = useSortable({ id });

  return (
    <div ref={setNodeRef} {...listeners}>
      <DragAndDropIcon />
    </div>
  );
});

DragHandle.displayName = "DragHandle";

export default DragHandle;
