import { action, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { revisedFormService } from "services";
import { PrimaryFormNames, Publishers, SecondaryFormNames, Songwriters, TertiaryFormNames, Works } from "types";

import { extractComments } from "./helpers";

export class WorksStore {
  @observable worksList: Works[] = [];

  @observable songwriters: Songwriters[] = [];
  @observable songwritersLoading: boolean = false;
  @observable publishers: Publishers[] = [];
  @observable publishersLoading: boolean = false;

  @observable isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  retrieveSongwriters = async (userId: string | null, primaryFormId: string, secondaryFormId: string) => {
    try {
      this.songwritersLoading = true;
      const { revisedForms } = await revisedFormService.getTertiaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
        tertiaryFormName: TertiaryFormNames.ReleaseSongwriters,
        primaryFormId,
        secondaryFormId,
      });
      this.rootStore.revisedFormStore.comments.tertiaryComments = [
        ...this.rootStore.revisedFormStore.comments.tertiaryComments,
        ...extractComments(revisedForms),
      ];
      this.songwriters = revisedForms as Songwriters[];
    } finally {
      this.songwritersLoading = false;
    }
  };

  retrievePublishers = async (userId: string | null, primaryFormId: string, secondaryFormId: string) => {
    try {
      this.publishersLoading = true;
      const { revisedForms } = await revisedFormService.getTertiaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
        tertiaryFormName: TertiaryFormNames.ReleasePublishers,
        primaryFormId,
        secondaryFormId,
      });
      this.rootStore.revisedFormStore.comments.tertiaryComments = [
        ...this.rootStore.revisedFormStore.comments.tertiaryComments,
        ...extractComments(revisedForms),
      ];
      this.publishers = revisedForms as Publishers[];
    } finally {
      this.publishersLoading = false;
    }
  };

  @flow.bound
  *retrieveWorkLists(userId: string | null, primaryFormId: string, secondaryFormId: string) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield Promise.all([
        this.retrieveSongwriters(userId, primaryFormId, secondaryFormId),
        this.retrievePublishers(userId, primaryFormId, secondaryFormId),
      ]);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @action.bound
  resetWorkLists = () => {
    this.songwriters = [];
    this.publishers = [];
  };

  @action.bound
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
