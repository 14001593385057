import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import styled from "@emotion/styled";
import { getCountryCode } from "countries-list";

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { AngleDownIcon } from "assets/icons/14px";
import { CloseIcon } from "assets/icons/24px";
import { TERRITORY_LABEL_VALUE_OPTIONS } from "constants/release";

import { InputIconButton, ReadOnlyField } from "..";

const AutocompleteStyled = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding: 0 14px;

    .MuiAutocomplete-endAdornment {
      top: auto;
    }
  }
` as typeof Autocomplete;

type CountrySelectFieldType<FieldType extends FieldValues> = TextFieldProps & {
  fieldName: string;
  control: Control<FieldType>;
  readOnly?: boolean;
};

const ClearIcon = (
  <InputAdornment position="end">
    <InputIconButton size="small" edge="end" tabIndex={-1}>
      <CloseIcon />
    </InputIconButton>
  </InputAdornment>
);

const CountrySelectField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  readOnly,
  ...props
}: CountrySelectFieldType<FieldType>) => {
  const getOptionLabel = (option: LabelValue) => {
    return option.label;
  };

  const isOptionEqualToValue = (option: LabelValue, value: LabelValue) => {
    return option.value === value.value ?? "";
  };

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabel>{label}</FormLabel> : null}
            {readOnly ? (
              <ReadOnlyField {...fieldProps} {...props} value={fieldProps.value?.label} ref={fieldProps.ref} />
            ) : (
              <AutocompleteStyled
                {...fieldProps}
                fullWidth
                options={TERRITORY_LABEL_VALUE_OPTIONS}
                getOptionLabel={getOptionLabel}
                renderOption={(props, { label, value }) => (
                  <MenuItem {...props} key={value} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img
                      width="20px"
                      src={`https://flagcdn.com/w40/${String(getCountryCode(label)).toLowerCase()}.webp`}
                      onError={(e) => {
                        (e.target as HTMLImageElement).style.visibility = "hidden";
                      }}
                    />
                    <span>{label}</span>
                  </MenuItem>
                )}
                clearIcon={ClearIcon}
                disableClearable
                popupIcon={<AngleDownIcon />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true, children: undefined }}
                    helperText={error?.message?.trim() ?? props.helperText}
                    error={!!error}
                  />
                )}
                onChange={(_, option) => onChange(option)}
                isOptionEqualToValue={isOptionEqualToValue}
                multiple={false}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default CountrySelectField;
