import React from "react";
import { Controller, FieldPath, FieldValues } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { AngleDownIcon } from "assets/icons/14px";
import { SelectStyled } from "components/FormControls";

import { useReadOnlyParams } from "../useReadOnlyParams";

import { ColorCircle, ListSubheaderStyled, MenuItemStyled, SelectCheckboxStyled } from "./styled";
import type { SelectFieldType } from "./types";

const SelectCheckboxField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  options,
  readOnly,
  ...props
}: SelectFieldType<FieldType>) => {
  const readOnlyProps = useReadOnlyParams({ isReadOnly: readOnly });

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <SelectStyled
              value={value || ""}
              multiple
              IconComponent={AngleDownIcon}
              displayEmpty
              {...props}
              {...fieldProps}
              onChange={(event: SelectChangeEvent<string | number>) => {
                const value = event.target.value;
                onChange(value);
              }}
              disabled={props.disabled}
              {...(readOnlyProps as { disabled?: boolean })}
            >
              {options.map((option) => {
                return typeof option === "string" ? (
                  <ListSubheaderStyled key={option}>{option}</ListSubheaderStyled>
                ) : (
                  <MenuItemStyled key={option.value} value={option.value}>
                    {"color" in option && <ColorCircle color={option.color} />}
                    <SelectCheckboxStyled checked={value?.includes(option.value)} />
                    <ListItemText primary={option.label} />
                  </MenuItemStyled>
                );
              })}
            </SelectStyled>
            {error?.message?.trim() ? (
              <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
                {error?.message}
              </Typography>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default SelectCheckboxField;
