import { useStores } from "stores";

import { ReturnReleaseFormState, useSecondaryFormState } from "components/ReleaseForm/Hooks";
import { ApiKeyFields, ReleaseStatus, SecondaryFormNames } from "types";
import { convertSnakeToCamel } from "utils";

export const useWorkFormState = (): ((secondaryFormId: string) => ReturnReleaseFormState) => {
  const getSecondaryFormState = useSecondaryFormState();
  const {
    userStore: { isAdmin },
    revisedFormStore: {
      comments: { secondaryComments },
      release,
    },
  } = useStores();

  return (secondaryFormId: string) => {
    const formState = getSecondaryFormState({
      secondaryFormName: SecondaryFormNames.ReleaseWorks,
      secondaryFormId,
      filterCommentKey: ApiKeyFields.releaseWorkId,
    });

    const getFormComments = secondaryComments.filter(({ releaseWorkId }) => releaseWorkId === secondaryFormId);
    const getCommentsKeys = getFormComments.map(({ fieldName }) => convertSnakeToCamel(fieldName) as ApiKeyFields);

    if (isAdmin) {
      switch (release?.status) {
        case ReleaseStatus.InRevision:
          return {
            ...formState,
            edit: {
              ...formState.edit,
              excludeFields: [...(formState.edit.excludeFields ?? []), ApiKeyFields.isMedley, ApiKeyFields.isCover],
            },
            comments: {
              ...formState.comments,
              formComments: getFormComments,
            },
          };
        case ReleaseStatus.ChangesNeeded:
          return {
            ...formState,
            readOnly: {
              ...formState.readOnly,
              fields: true,
            },
            comments: {
              ...formState.comments,
              fields: getCommentsKeys,
              formComments: getFormComments,
            },
          };
      }
    }

    switch (release?.status) {
      case ReleaseStatus.ChangesNeeded:
        return {
          ...formState,
          comments: {
            ...formState.comments,
            fields: getCommentsKeys,
            formComments: getFormComments,
          },
        };
    }
    return formState;
  };
};
