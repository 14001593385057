import { ApiKeyFields } from "types";

import { Artist } from "./artistTypes";
import { DistributionContracts } from "./contractTypes";
import { DistributionTier, DistributionType, PublishingTier, PublishingType, UserStatus } from "./enums";

export type User = {
  [ApiKeyFields.id]: string;
  [ApiKeyFields.email]: string;
  [ApiKeyFields.isAdmin]: boolean;
  [ApiKeyFields.isActive]: boolean;
  [ApiKeyFields.isInvited]: boolean;
  [ApiKeyFields.language]: string;
  [ApiKeyFields.firstName]: string | null;
  [ApiKeyFields.lastName]: string | null;
  [ApiKeyFields.phone]: string | null;
  [ApiKeyFields.distributionType]: DistributionType | null;
  [ApiKeyFields.publishingType]: PublishingType | null;
  [ApiKeyFields.qualifiedType]: string | null;
  [ApiKeyFields.distributionTier]: DistributionTier | null;
  [ApiKeyFields.publishingTier]: PublishingTier | null;
  [ApiKeyFields.status]: UserStatus | null;
  [ApiKeyFields.acceptConditions]: boolean | null;
  [ApiKeyFields.receiveInfo]: boolean | null;
  [ApiKeyFields.qualifiedAt]: string | null;
  [ApiKeyFields.createdAt]: string;
  [ApiKeyFields.updatedAt]: string | null;
  [ApiKeyFields.isExisting]: boolean | null;
};

export type CurrentUser = {
  user: User;
};

export type UserArtist = User & { artists: Artist[]; distributionContracts: DistributionContracts[]; numReleases: number | null };

export type UserByIdResponse = { [ApiKeyFields.user]: UserArtist };

export type UserListResponse = { numUsers: number; users: UserArtist[] };

export type PatchUser = {
  [ApiKeyFields.isActive]?: boolean;
  [ApiKeyFields.declineReason]?: DECLINE_REASON;
  [ApiKeyFields.distributionTier]?: DistributionTier | null;
  [ApiKeyFields.publishingTier]?: PublishingTier | null;
};

export type PatchUserById = { [ApiKeyFields.user]: PatchUser };

export type InviteUser = Pick<User, (typeof ApiKeyFields)[keyof typeof ApiKeyFields] & keyof User>;

export type EditableUserFields = Pick<User, (typeof ApiKeyFields)[keyof typeof ApiKeyFields] & keyof User>;

export type PatchCurrentUser = { user: Partial<EditableUserFields> };

export type PostUser = { user: Partial<EditableUserFields> };

export type UserListParams = {
  [ApiKeyFields.user_is_admin]: boolean;
  [ApiKeyFields.user_is_active]?: boolean | null;
  [ApiKeyFields.user_is_invited]?: boolean | null;
  [ApiKeyFields.user_distribution_type_is_set]?: boolean;
  [ApiKeyFields.user_publishing_type_is_set]?: boolean;
  [ApiKeyFields.user_statuses]?: UserStatus | UserStatus[] | null;
  [ApiKeyFields.user_qualified_at_from]?: string | null;
  [ApiKeyFields.user_qualified_at_to]?: string | null;
  [ApiKeyFields.user_created_at_from]?: string | null;
  [ApiKeyFields.user_created_at_to]?: string | null;
  [ApiKeyFields.user_order_by_field_name]?: string;
  [ApiKeyFields.user_order]?: string;
  [ApiKeyFields.artist_order_by_field_name]?: string;
  [ApiKeyFields.artist_order]?: string;
  [ApiKeyFields.user_offset]?: number;
  [ApiKeyFields.user_limit]?: number;
};

export interface BulkInviteUser {
  [ApiKeyFields.distributionType]?: DistributionType;
  [ApiKeyFields.publishingType]?: PublishingType;
  [ApiKeyFields.email]: string;
  [ApiKeyFields.isAdmin]: boolean;
  [ApiKeyFields.language]: string;
  [ApiKeyFields.firstName]: string;
  [ApiKeyFields.lastName]: string;
  [ApiKeyFields.phone]: string;
  [ApiKeyFields.distributionTier]?: DistributionTier;
  [ApiKeyFields.publishingTier]?: PublishingTier;
  [ApiKeyFields.artistName]?: string;
  [ApiKeyFields.songwriterName]?: string;
  [ApiKeyFields.distributionContractCountry]?: string;
}

export enum DECLINE_REASON {
  CULTURAL_FIT = "CULTURAL_FIT",
}
