import { useTheme } from "@mui/material";

import { BorderColorProps } from "./types";

export const getBorderColor = (props: BorderColorProps) => {
  const theme = useTheme();

  if (props.isDragAccept) {
    return theme.palette.background.blue;
  }
  if (props.disabled) {
    return theme.palette.common.white;
  }
  return theme.palette.background.surface;
};
