import * as zod from "zod";

import { MAX_LEN_TEXTAREA_ERR_MSG, MIN_LEN_ERR_MSG } from "constants/validation";
import { ApiKeyFields } from "types";

import { TEXT_AREA_MAX_LENGTH } from "./constants";

export const genreTerritorySchema = zod.object({
  [ApiKeyFields.genre]: zod.string().min(1, MIN_LEN_ERR_MSG),
  [ApiKeyFields.sub_genre]: zod.string().nullable().optional(),
  [ApiKeyFields.territory_notes]: zod.string().max(TEXT_AREA_MAX_LENGTH, MAX_LEN_TEXTAREA_ERR_MSG).nullable().optional(),
});
