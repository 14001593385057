import styled from "@emotion/styled";

import TableFooter from "@mui/material/TableFooter";

export const SplitTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableFooterStyled = styled(TableFooter)`
  padding: 16px 0;

  .MuiTableCell-root {
    padding: 8px 16px !important;
    border-bottom-color: transparent;
  }
`;
