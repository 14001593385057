import { AxiosRequestConfig } from "axios";

import { axiosInstance } from "services";
import { TemplateName, TemplatePayload, TemplatePreviewPayload, TemplateResponse } from "types";

export function getTemplate(template_name: TemplateName): Promise<TemplateResponse> {
  return axiosInstance.get(`/templates/${template_name}`);
}

export function putTemplate(template_name: TemplateName, data: TemplatePayload): Promise<TemplateResponse> {
  return axiosInstance.put(`/templates/${template_name}`, data);
}

export function postPreviewTemplate(body: TemplatePreviewPayload, config?: AxiosRequestConfig): Promise<Blob> {
  return axiosInstance.post("/templates/preview", body, config);
}
