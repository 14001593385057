import React from "react";
import { Controller, FieldPath, FieldValues } from "react-hook-form";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useInfiniteLoading from "hooks/useInfiniteLoading";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

import { InfiniteSelect } from "components/FormControls";

import { ReadOnlyField } from "..";

import { InfiniteSelectFieldType } from "./types";

const InfiniteSelectField = <FieldType extends FieldValues, DataType, ValueType extends string | number>({
  fieldName,
  control,
  label,
  fetchFunction,
  mapFunction,
  readOnly,
  ...props
}: InfiniteSelectFieldType<FieldType, DataType, ValueType>) => {
  const { options, loading, hasNextPage, fetchMore } = useInfiniteLoading(fetchFunction, mapFunction, {
    initialOffset: 0,
    limit: 10,
  });

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
  });

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label ? <FormLabel>{label}</FormLabel> : null}
          {readOnly ? (
            <ReadOnlyField value={value} {...fieldProps} {...props} ref={fieldProps.ref} />
          ) : (
            <InfiniteSelect
              value={value || ""}
              {...fieldProps}
              {...props}
              loading={loading}
              hasNextPage={hasNextPage}
              onChange={onChange}
              options={options}
              sentryRef={sentryRef}
            />
          )}
          {error?.message?.trim() ? (
            <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
              {error?.message}
            </Typography>
          ) : null}
        </FormControl>
      )}
    />
  );
};

export default InfiniteSelectField;
