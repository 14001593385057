import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import CircularProgress from "@mui/material/CircularProgress";

import { CoverArtProps } from "./types";
import { useCoverArt } from "./useCoverArt";

const CoverArtImgStyled = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const CoverArt: FC<CoverArtProps> = ({ id, userId = null, coverArtBlobPath, sasUrlKey }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { retrieveCoverArt } = useCoverArt();

  const getCoverArt = useCallback(
    async (signal: AbortSignal) => {
      if (!id || !coverArtBlobPath) return;
      setLoading(true);
      try {
        const data = await retrieveCoverArt({ primaryFormId: id, userId }, signal, sasUrlKey);
        setImageSrc(data);
      } finally {
        setLoading(false);
      }
    },
    [id, userId],
  );

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    id && getCoverArt(signal);
    return () => {
      abortController.abort();
    };
  }, [id, userId]);

  if (imageSrc) {
    return <CoverArtImgStyled src={imageSrc} />;
  }

  return isLoading ? <CircularProgress size={"24px"} /> : null;
};

export default CoverArt;
