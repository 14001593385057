import React, { FC } from "react";
import { useUserNotificationState } from "hooks/useUserNotificationState";
import { observer } from "mobx-react";
import { useStores } from "stores";

import IconButton from "@mui/material/IconButton";

import { NotificationActiveIcon, NotificationIcon } from "assets/icons/24px";
import Notifications from "components/Notifications";

import { VerticalDividerStyled } from "../VerticalDividerStyled";

const UserNotifications: FC = () => {
  const {
    notificationStore: { notificationsCount, isNotificationsDisabled },
    uiStore: { openDrawer },
  } = useStores();
  const { showNotification } = useUserNotificationState();

  const handleNotificationsClick = () => {
    openDrawer({
      component: () => <Notifications />,
      PaperProps: { sx: { minWidth: "445px", maxWidth: "30%", width: "100%" } },
    });
  };

  return showNotification ? (
    <React.Fragment>
      <VerticalDividerStyled orientation="vertical" />
      <IconButton size="small" color="inherit" onClick={handleNotificationsClick} disabled={isNotificationsDisabled}>
        {Boolean(notificationsCount) ? <NotificationActiveIcon /> : <NotificationIcon />}
      </IconButton>
    </React.Fragment>
  ) : null;
};

export default observer(UserNotifications);
