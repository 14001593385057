import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { FormHelperTextContainerStyled, LinkTextStyled } from "components/Layout";
import { ApiKeyFields } from "types";

import { defaultValues as addTrackDefaultValues, FORM_LABELS as ADD_TRACK_FORM_LABELS } from "../../AddTrack/constants";
import { TRACK_LANGUAGES_OPTIONS } from "../../constants";

export const defaultValues = {
  ...addTrackDefaultValues,
  [ApiKeyFields.versionTitle]: "",
  [ApiKeyFields.previewStartsAt]: "",
  [ApiKeyFields.audioBlobPath]: "",
  [ApiKeyFields.aiAudio]: null,
};

export const FORM_LABELS: { [key: string]: string } = {
  ...ADD_TRACK_FORM_LABELS,
  [ApiKeyFields.versionTitle]: "Version Title (Optional)",
  [ApiKeyFields.previewStartsAt]: "Preview Start Time (Optional)",
  [ApiKeyFields.audioBlobPath]: "Upload Master Audio",
  [ApiKeyFields.aiAudio]:
    "Please check if artificial intelligence tools were used to assist in the creation of the master audio or its elements.",
};

export const OPTIONAL_FIELDS: { [key: string]: true } = {
  [ApiKeyFields.versionTitle]: true,
  [ApiKeyFields.previewStartsAt]: true,
};

export const HELPER_TEXT: { [key: string]: string | ReactNode } = {
  [ApiKeyFields.versionTitle]: "E.g. Live, Acoustic, Remix, etc.",
  [ApiKeyFields.previewStartsAt]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        Timestamp to start the preview. This will be used as the preview on iTunes and TikTok.
      </Typography>
      <Typography variant="body1" color="text.label">
        Please use the following format: HH:MM:SS
      </Typography>
    </FormHelperTextContainerStyled>
  ),
  [ApiKeyFields.audioBlobPath]: (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label">
        Please make sure that the audio file you use meets the following requirements:
      </Typography>
      <Typography variant="body1" color="text.label" display="flex" flexDirection="column">
        <span>Format: .WAV</span> <span>Bit Depth: At least 16</span> <span>Sample Rate: At least 24 khz</span>
      </Typography>
      <Typography variant="body1" color="text.label" flexDirection="column">
        If you have Dolby Atmos Immersive Spatial Audio, please email the file directly to the{" "}
        <LinkTextStyled href="mailto:imradmin@integritymusic.com">imradmin@integritymusic.com</LinkTextStyled>
      </Typography>
    </FormHelperTextContainerStyled>
  ),
};

export const FORM_BASIC_FIELDS = [ApiKeyFields.title, ApiKeyFields.versionTitle, ApiKeyFields.previewStartsAt];

export { TRACK_LANGUAGES_OPTIONS };
