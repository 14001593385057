import { axiosInstance } from "services";
import { ApiKeyFields } from "types";
import {
  Contributors,
  DeleteUserIdRevisedFormIdBlobUrl,
  DistributionInfoFormPayload,
  FormId,
  PatchPrimaryComment,
  PostComment,
  PrimaryForm,
  PrimaryFormId,
  PrimaryFormIdBlobUrl,
  PrimaryFormNames,
  PrimaryId,
  PrimaryUser,
  PrimaryUserForm,
  PrimaryUserFormComment,
  Publishers,
  ReleaseArtists,
  ReleaseCommentCount,
  SecondaryFormId,
  SecondaryFormIdBlobUrl,
  SecondaryFormNames,
  SecondaryPrimaryFormId,
  SecondaryUserFormComment,
  Songwriters,
  TertiaryCommentList,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
  TertiaryUserFormComment,
  Tracks,
} from "types/revisedForms";

import {
  BaseReleaseSongwritersResponse,
  CommentResponse,
  FormBlobFileResponse,
  PatchCurrentPrimaryFormResponseMap,
  PostCurrentPrimaryFormResponseMap,
  PostFocusTrack,
  PostSecondaryFormPayload,
  PostSecondaryFormResponse,
  PostTertiaryFormPayload,
  PostTertiaryFormResponse,
  PrimaryFormResponse,
  PrimaryFormResponseMap,
  ReleaseListWorksOptionsParams,
  ReleaseTableArtistsOptionsParams,
  ReleaseTableTracksOptionsParams,
  ReleaseWorksOptionsParams,
  ReleaseWorksResponse,
  ReorderPayload,
  SecondaryFormResponse,
  TertiaryFormResponse,
} from "./types";

export function getPrimaryForm<P extends PrimaryFormNames>({
  userId,
  primaryFormName,
}: PrimaryForm): Promise<PrimaryFormResponse<P>> {
  return axiosInstance.get(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}`);
}

export function getSecondaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames>({
  userId,
  primaryFormName,
  secondaryFormName,
  primaryFormId,
}: SecondaryPrimaryFormId): Promise<SecondaryFormResponse<P, S>> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}`,
  );
}

export function getTertiaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames, T extends TertiaryFormNames>({
  userId,
  primaryFormId,
  primaryFormName,
  secondaryFormName,
  secondaryFormId,
  tertiaryFormName,
}: TertiarySecondaryFormId): Promise<TertiaryFormResponse<P, S, T>> {
  return axiosInstance.get(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}`,
  );
}

export function getPrimaryFormId<P extends PrimaryFormNames>({
  userId,
  primaryFormName,
  primaryFormId,
}: PrimaryFormId): Promise<PrimaryFormResponse<P>> {
  return axiosInstance.get(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}`);
}

export function getSecondaryFormId<P extends PrimaryFormNames, S extends SecondaryFormNames>({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
  secondaryFormId,
}: SecondaryFormId): Promise<PostSecondaryFormResponse<P, S>> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}`,
  );
}

export function getTertiaryFormId<P extends PrimaryFormNames, S extends SecondaryFormNames, T extends TertiaryFormNames>({
  userId,
  primaryFormName,
  secondaryFormName,
  tertiaryFormName,
  primaryFormId,
  secondaryFormId,
  tertiaryFormId,
}: TertiaryFormId): Promise<PostTertiaryFormResponse<P, S, T>> {
  return axiosInstance.get(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}`,
  );
}

export function deletePrimaryFormId({ userId, primaryFormName, primaryFormId }: PrimaryFormId) {
  return axiosInstance.delete(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}`);
}

export function deleteSecondaryFormId({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
  secondaryFormId,
}: SecondaryFormId) {
  return axiosInstance.delete(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}`,
  );
}

export function deleteTertiaryFormId({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
  secondaryFormId,
  tertiaryFormName,
  tertiaryFormId,
}: TertiaryFormId) {
  return axiosInstance.delete(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}`,
  );
}

export function postCurrentPrimaryForm<P extends PrimaryFormNames>(
  formName: P,
  revisedForm: PostCurrentPrimaryFormResponseMap[P],
): Promise<PrimaryFormResponseMap[P]> {
  return axiosInstance.post(`/users/current/revised-forms/${formName}`, { revisedForm });
}

export function postSecondaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames>(
  { userId, primaryFormName, secondaryFormName, primaryFormId }: SecondaryPrimaryFormId,
  revisedForm: PostSecondaryFormPayload<P, S>,
): Promise<PostSecondaryFormResponse<P, S>> {
  return axiosInstance.post(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}`,
    {
      revisedForm,
    },
  );
}

export function postTertiaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames, T extends TertiaryFormNames>(
  { userId, primaryFormName, secondaryFormName, primaryFormId, secondaryFormId, tertiaryFormName }: TertiarySecondaryFormId,
  revisedForm: PostTertiaryFormPayload<P, S, T>,
): Promise<PostTertiaryFormResponse<P, S, T>> {
  return axiosInstance.post(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}`,
    {
      revisedForm,
    },
  );
}

export function submitForm<P extends PrimaryFormNames>(
  { userId, primaryFormName, primaryFormId }: PrimaryFormId,
  revisedForm: PatchCurrentPrimaryFormResponseMap[P],
) {
  return axiosInstance.post(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/submit`, {
    revisedForm,
  });
}

export function patchPrimaryForm<P extends PrimaryFormNames>(
  { userId, primaryFormName, primaryFormId }: PrimaryFormId,
  revisedForm: PatchCurrentPrimaryFormResponseMap[P],
) {
  return axiosInstance.patch(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}`, { revisedForm });
}

export function patchSecondaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames>(
  { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId }: SecondaryFormId,
  revisedForm: PostSecondaryFormPayload<P, S>,
) {
  return axiosInstance.patch(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}`,
    { revisedForm },
  );
}

export function patchTertiaryForm<P extends PrimaryFormNames, S extends SecondaryFormNames, T extends TertiaryFormNames>(
  {
    userId,
    primaryFormName,
    primaryFormId,
    secondaryFormName,
    secondaryFormId,
    tertiaryFormName,
    tertiaryFormId,
  }: TertiaryFormId,
  revisedForm: PostTertiaryFormPayload<P, S, T>,
) {
  return axiosInstance.patch(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}`,
    { revisedForm },
  );
}

export function patchPrimaryComment(
  { userId, primaryFormId, primaryFormName, commentFieldName }: PrimaryUserFormComment,
  comment: PatchPrimaryComment,
) {
  return axiosInstance.patch(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/comments/${commentFieldName}`,
    {
      comment,
    },
  );
}

export function getPrimaryBlob({
  userId,
  primaryFormName,
  primaryFormId,
  blob_action,
  field_name,
}: PrimaryFormIdBlobUrl): Promise<FormBlobFileResponse> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/blob-fields/${field_name}`,
    {
      params: { blob_action },
    },
  );
}

export function getSecondaryBlob({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
  secondaryFormId,
  blob_action,
  field_name,
}: SecondaryFormIdBlobUrl): Promise<FormBlobFileResponse> {
  return axiosInstance.get(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/blob-fields/${field_name}`,
    {
      params: { blob_action },
    },
  );
}

export function patchPrimaryBlob({ userId, primaryFormName, primaryFormId }: PrimaryFormId, field_name: ApiKeyFields) {
  return axiosInstance.patch(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/blob-fields`, {
    [ApiKeyFields.revised_form]: { [ApiKeyFields.blob_field_name]: field_name },
  });
}

export function patchSecondaryBlob(
  { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId }: SecondaryFormId,
  field_name: ApiKeyFields,
) {
  return axiosInstance.patch(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/blob-fields`,
    {
      [ApiKeyFields.revised_form]: { [ApiKeyFields.blob_field_name]: field_name },
    },
  );
}

export function reorderSecondaryForm(
  { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId }: SecondaryFormId,
  revisedForm: ReorderPayload,
) {
  return axiosInstance.post(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/reorder`,
    { revisedForm },
  );
}

export function reorderTertiaryForm(
  {
    userId,
    primaryFormName,
    primaryFormId,
    secondaryFormName,
    secondaryFormId,
    tertiaryFormName,
    tertiaryFormId,
  }: TertiaryFormId,
  revisedForm: ReorderPayload,
) {
  return axiosInstance.post(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}/reorder`,
    { revisedForm },
  );
}

export function retrieveReleaseWorksOptions(
  { primaryFormName, primaryFormId }: PrimaryFormId,
  params: ReleaseWorksOptionsParams,
): Promise<ReleaseWorksResponse> {
  return axiosInstance.get(`/users/current/revised-forms/${primaryFormName}/${primaryFormId}/release-works`, { params });
}

export function retrieveReleaseSongwritersOptions(
  { primaryFormId }: FormId,
  params: ReleaseListWorksOptionsParams,
): Promise<BaseReleaseSongwritersResponse<Songwriters>> {
  return axiosInstance.get(`/users/current/revised-forms/releases/${primaryFormId}/release-works/release-songwriters`, {
    params,
  });
}

export function retrieveReleasePublishersOptions(
  { primaryFormId }: FormId,
  params: ReleaseListWorksOptionsParams,
): Promise<BaseReleaseSongwritersResponse<Publishers>> {
  return axiosInstance.get(`/users/current/revised-forms/releases/${primaryFormId}/release-works/release-publishers`, {
    params,
  });
}

export function retrieveReleaseTracksOptions(
  { userId, primaryFormId }: PrimaryId,
  params: ReleaseTableTracksOptionsParams,
): Promise<BaseReleaseSongwritersResponse<Tracks>> {
  return axiosInstance.get(`/users/${userId ?? "current"}/revised-forms/releases/${primaryFormId}/release-tracks`, { params });
}

export function retrieveReleaseArtistsOptions(
  { userId, primaryFormId }: PrimaryId,
  params: ReleaseTableArtistsOptionsParams,
): Promise<BaseReleaseSongwritersResponse<ReleaseArtists>> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/releases/${primaryFormId}/release-tracks/release-artists`,
    { params },
  );
}

export function retrieveExistingTrackOptions(
  params: ReleaseTableTracksOptionsParams,
): Promise<BaseReleaseSongwritersResponse<Tracks>> {
  return axiosInstance.get(`/users/current/revised-forms/releases/release-tracks`, { params });
}

export function retrieveReleaseContributorsOptions(
  { userId, primaryFormId }: PrimaryId,
  params: ReleaseTableTracksOptionsParams,
): Promise<BaseReleaseSongwritersResponse<Contributors>> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/releases/${primaryFormId}/release-tracks//release-contributors`,
    {
      params,
    },
  );
}

export function postFocusTrack(
  { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId }: SecondaryFormId,
  revisedForm: PostFocusTrack,
) {
  return axiosInstance.post(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/update-focus`,
    { revisedForm },
  );
}

export function postPrimaryComment(
  { userId, primaryFormId, primaryFormName }: PrimaryUserForm,
  comment: PostComment,
): Promise<CommentResponse> {
  return axiosInstance.post(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/comments`, { comment });
}

export function postSecondaryComment(
  { userId, primaryFormId, primaryFormName, secondaryFormName, secondaryFormId }: SecondaryFormId,
  comment: PostComment,
): Promise<CommentResponse> {
  return axiosInstance.post(
    `/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/comments`,
    { comment },
  );
}

export function postTertiaryComment(
  {
    userId,
    primaryFormId,
    primaryFormName,
    secondaryFormName,
    secondaryFormId,
    tertiaryFormName,
    tertiaryFormId,
  }: TertiaryFormId,
  comment: PostComment,
): Promise<CommentResponse> {
  return axiosInstance.post(
    `/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}/comments`,
    { comment },
  );
}

export function patchSecondaryComment(
  { userId, primaryFormId, primaryFormName, secondaryFormName, secondaryFormId, commentFieldName }: SecondaryUserFormComment,
  comment: PatchPrimaryComment,
) {
  return axiosInstance.patch(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/comments/${commentFieldName}`,
    {
      comment,
    },
  );
}

export function patchTertiaryComment(
  {
    userId,
    primaryFormId,
    primaryFormName,
    secondaryFormName,
    secondaryFormId,
    tertiaryFormName,
    tertiaryFormId,
    commentFieldName,
  }: TertiaryUserFormComment,
  comment: PatchPrimaryComment,
) {
  return axiosInstance.patch(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}/comments/${commentFieldName}`,
    {
      comment,
    },
  );
}

export function deletePrimaryComment({ userId, primaryFormId, primaryFormName, commentFieldName }: PrimaryUserFormComment) {
  return axiosInstance.delete(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/comments/${commentFieldName}`);
}

export function deleteSecondaryComment({
  userId,
  primaryFormId,
  primaryFormName,
  secondaryFormName,
  secondaryFormId,
  commentFieldName,
}: SecondaryUserFormComment) {
  return axiosInstance.delete(
    `/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/comments/${commentFieldName}`,
  );
}

export function deleteTertiaryComment({
  userId,
  primaryFormId,
  primaryFormName,
  secondaryFormName,
  secondaryFormId,
  tertiaryFormName,
  tertiaryFormId,
  commentFieldName,
}: TertiaryUserFormComment) {
  return axiosInstance.delete(
    `/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${secondaryFormId}/${tertiaryFormName}/${tertiaryFormId}/comments/${commentFieldName}`,
  );
}

export function getPrimaryUserIdForm<P extends PrimaryFormNames>({
  userId,
  primaryFormName,
}: PrimaryUser<P>): Promise<PrimaryFormResponse<P>> {
  return axiosInstance.get(`/users/${userId}/revised-forms/${primaryFormName}`);
}

export function getPrimaryUserIdFormId<P extends PrimaryFormNames>({
  userId,
  primaryFormName,
  primaryFormId,
}: PrimaryUserForm): Promise<PrimaryFormResponse<P>> {
  return axiosInstance.get(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}`);
}

export function patchPrimaryUserIdFormId(
  { userId, primaryFormId, primaryFormName }: PrimaryUserForm,
  revisedForm: DistributionInfoFormPayload,
) {
  return axiosInstance.patch(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}`, { revisedForm });
}

export function returnForm({ userId, primaryFormName, primaryFormId }: PrimaryUserForm) {
  return axiosInstance.post(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/return`);
}

export function approveForm({ userId, primaryFormName, primaryFormId }: PrimaryUserForm) {
  return axiosInstance.post(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/approve`);
}

export function deletePrimaryBlob({ userId, primaryFormName, primaryFormId, field_name }: DeleteUserIdRevisedFormIdBlobUrl) {
  return axiosInstance.delete(`/users/${userId}/revised-forms/${primaryFormName}/${primaryFormId}/blob-fields/${field_name}`);
}

export function getPrimaryCommentList({ userId, primaryFormName, primaryFormId }: PrimaryFormId): Promise<ReleaseCommentCount> {
  return axiosInstance.get(`/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/comments`);
}

export function getSecondaryCommentList({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
}: SecondaryPrimaryFormId): Promise<ReleaseCommentCount> {
  return axiosInstance.get(
    `/users/${userId ?? "current"}/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/comments`,
  );
}

export function getTertiaryCommentList({
  userId,
  primaryFormName,
  primaryFormId,
  secondaryFormName,
  tertiaryFormName,
}: TertiaryCommentList): Promise<ReleaseCommentCount> {
  return axiosInstance.get(
    `/users/${
      userId ?? "current"
    }/revised-forms/${primaryFormName}/${primaryFormId}/${secondaryFormName}/${tertiaryFormName}/comments`,
  );
}
