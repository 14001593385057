import { FC } from "react";
import { useFormContext } from "react-hook-form";
import isNil from "lodash/isNil";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { useTheme } from "@mui/material";

import { StepStatusIcon } from "../styled";
import { SubRouteFieldProps } from "../types";

import { fieldsHaveActiveComment, fieldsHaveResolvedComment } from "./helpers";
import { StepStatusIconColorProps } from "./types";

const StepStatusIconColor: FC<StepStatusIconColorProps> = ({ fields, comments, subRouteConfig, isClientReview }) => {
  const theme = useTheme();
  const {
    watch,
    formState: { errors, touchedFields, dirtyFields, isDirty },
  } = useFormContext();
  const {
    uiStore: { isOverlayLoading },
  } = useStores();

  const getStepStatusIconColor = (fields: SubRouteFieldProps[]): string => {
    if (isOverlayLoading && !isDirty) {
      return theme.palette.secondary.main;
    }

    const haveSomeComment = fieldsHaveActiveComment(fields, comments);
    const resolvedComment = fieldsHaveResolvedComment(fields, comments);
    const isAnyFieldError = fields.some(({ field }) => errors[field]);
    const isValid =
      (fields.every(({ field }) => touchedFields[field]) && fields.every(({ field }) => dirtyFields[field])) ||
      fields.filter(({ required }) => required).every(({ field }) => !isNil(watch(field)));

    if (isAnyFieldError) {
      return theme.palette.error.main;
    } else if (subRouteConfig && typeof subRouteConfig === "function") {
      return subRouteConfig({
        fields,
        errors,
        touchedFields,
        dirtyFields,
        watch,
        theme,
      });
    } else if (haveSomeComment) {
      return theme.palette.warning.main;
    } else if (resolvedComment) {
      return isClientReview ? theme.palette.success.main : theme.palette.primary.main;
    } else if (isValid) {
      return theme.palette.success.main;
    }
    return theme.palette.secondary.main;
  };

  return <StepStatusIcon status={getStepStatusIconColor(fields)} />;
};

export default observer(StepStatusIconColor);
