import filter from "lodash/filter";
import { action, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { revisedFormService } from "services";
import {
  Comment,
  PatchPrimaryComment,
  PostComment,
  PrimaryFormNames,
  SecondaryFormNames,
  TertiaryFormNames,
} from "types/revisedForms";
import { convertCamelToSnakeCase } from "utils";

export class CommentsStore {
  @observable primaryComments: Comment[] = [];
  @observable secondaryComments: Comment[] = [];
  @observable tertiaryComments: Comment[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @flow.bound
  *postPrimaryComment(userId: string | null, primaryFormName: PrimaryFormNames, primaryFormId: string, commentText: PostComment) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      const { comment } = yield revisedFormService.postPrimaryComment({ userId, primaryFormName, primaryFormId }, commentText);
      this.primaryComments = [...this.primaryComments, comment];
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *postSecondaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    commentText: PostComment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      const { comment } = yield revisedFormService.postSecondaryComment(
        { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId },
        commentText,
      );
      this.secondaryComments = [...this.secondaryComments, comment];
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *postTertiaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    tertiaryFormName: TertiaryFormNames,
    tertiaryFormId: string,
    commentText: PostComment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      const { comment } = yield revisedFormService.postTertiaryComment(
        { userId, primaryFormName, primaryFormId, secondaryFormName, tertiaryFormName, tertiaryFormId, secondaryFormId },
        commentText,
      );
      this.tertiaryComments = [...this.tertiaryComments, comment];
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *patchPrimaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    commentFieldName: string,
    comment: PatchPrimaryComment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.patchPrimaryComment({ userId, primaryFormName, primaryFormId, commentFieldName }, comment);
      this.primaryComments = this.primaryComments.map((c) => (c.fieldName === commentFieldName ? { ...c, ...comment } : c));
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *patchSecondaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    commentFieldName: string,
    comment: PatchPrimaryComment,
    filterKey: keyof Comment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.patchSecondaryComment(
        { userId, primaryFormName, primaryFormId, secondaryFormName, secondaryFormId, commentFieldName },
        comment,
      );
      this.secondaryComments = this.secondaryComments.map((c) =>
        c[filterKey] === secondaryFormId && c.fieldName === commentFieldName ? { ...c, ...comment } : c,
      );
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *patchTertiaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    tertiaryFormName: TertiaryFormNames,
    tertiaryFormId: string,
    commentFieldName: string,
    comment: PatchPrimaryComment,
    mapCommentsCb?: (comment: Comment) => Comment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.patchTertiaryComment(
        {
          userId,
          primaryFormName,
          primaryFormId,
          secondaryFormName,
          secondaryFormId,
          tertiaryFormName,
          tertiaryFormId,
          commentFieldName,
        },
        comment,
      );
      if (mapCommentsCb) {
        this.tertiaryComments = this.tertiaryComments.map(mapCommentsCb);
      }
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *deletePrimaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    commentFieldName: string,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.deletePrimaryComment({
        userId,
        primaryFormName,
        primaryFormId,
        commentFieldName,
      });
      this.primaryComments = this.primaryComments.filter((c) => c.fieldName !== commentFieldName);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *deleteSecondaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    commentFieldName: string,
    filterKey: keyof Comment,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.deleteSecondaryComment({
        userId,
        primaryFormName,
        primaryFormId,
        secondaryFormName,
        secondaryFormId,
        commentFieldName,
      });
      this.secondaryComments = filter(
        this.secondaryComments,
        (c) => !(c[filterKey] === secondaryFormId && c.fieldName === convertCamelToSnakeCase(commentFieldName)),
      );
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *deleteTertiaryComment(
    userId: string | null,
    primaryFormName: PrimaryFormNames,
    primaryFormId: string,
    secondaryFormName: SecondaryFormNames,
    secondaryFormId: string,
    tertiaryFormName: TertiaryFormNames,
    tertiaryFormId: string,
    commentFieldName: string,
    filterCommentsCb?: (comment: Comment) => boolean,
  ) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield revisedFormService.deleteTertiaryComment({
        userId,
        primaryFormName,
        primaryFormId,
        secondaryFormName,
        secondaryFormId,
        tertiaryFormName,
        tertiaryFormId,
        commentFieldName,
      });
      if (filterCommentsCb) {
        this.tertiaryComments = filter(this.tertiaryComments, filterCommentsCb);
      }
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @action
  resetTertiaryComments = () => {
    this.tertiaryComments = [];
  };
}
