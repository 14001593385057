import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import msalConfig from "constants/msalConfig";
import { getAccessToken } from "utils/getAccessToken";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();

    if (accessToken) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error: AxiosApiError) => {
    if (error.response?.data?.errorCode === "INACTIVE_CURRENT_USER") {
      return msalConfig.logoutRedirect();
    }

    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const accessToken = await getAccessToken();
        if (accessToken) {
          originalRequest.headers = originalRequest.headers || {};
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
