import { Components } from "@mui/material/styles";

import { MuiAlert } from "./MuiAlert";
import { MuiAppBar } from "./MuiAppBar";
import { MuiButton } from "./MuiButton";
import { MuiCheckbox } from "./MuiCheckbox";
import { MuiChip } from "./MuiChip";
import MuiDataGrid from "./MuiDataGrid";
import MuiDialog from "./MuiDialog";
import { MuiFab } from "./MuiFab";
import { MuiFormHelperText } from "./MuiFormHelperText";
import { MuiFormLabel } from "./MuiFormLabel";
import { MuiIconButton } from "./MuiIconButton";
import { MuiFilledInput, MuiInput, MuiOutlinedInput, MuiTextField } from "./MuiInputs";
import { MuiRadio } from "./MuiRadio";
import { MuiSelect } from "./MuiSelect";
import MuiTable from "./MuiTable";
import { MuiToggleButton } from "./MuiToggleButton";
import { MuiTooltip } from "./MuiTooltip";

export const components: Components = {
  ...MuiDataGrid,
  ...MuiDialog,
  ...MuiTable,
  MuiAlert,
  MuiAppBar,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiFab,
  MuiFilledInput,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInput,
  MuiOutlinedInput,
  MuiSelect,
  MuiTextField,
  MuiToggleButton,
  MuiTooltip,
  MuiRadio,
};
