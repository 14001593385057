import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import isNull from "lodash/isNull";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import { ToggleButton } from "components/FormControls/ToggleButton";

type ToggleButtonFieldType<FieldType extends FieldValues> = ToggleButtonGroupProps & {
  fieldName: string;
  label?: string | React.ReactNode;
  control: Control<FieldType>;
  options: LabelValue<string | number | boolean>[];
  dark?: boolean;
  readOnly?: boolean;
};

const ToggleButtonField = <FieldType extends FieldValues>({
  fieldName,
  control,
  label,
  options,
  readOnly,
  ...props
}: ToggleButtonFieldType<FieldType>) => {
  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
        <FormControl fullWidth={props.fullWidth}>
          {label ? <FormLabel>{label}</FormLabel> : null}
          <ToggleButton
            fullWidth
            {...fieldProps}
            {...props}
            ref={fieldProps.ref}
            disabled={props.disabled || readOnly}
            onChange={(event, value) => {
              if (props.onChange) {
                props.onChange(event, value);
              }
              if (!isNull(value)) {
                onChange(value);
              }
            }}
            options={options}
          />
          {error?.message?.trim() ? (
            <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
              {error?.message}
            </Typography>
          ) : null}
        </FormControl>
      )}
    />
  );
};

export default ToggleButtonField;
