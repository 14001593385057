import React, { ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import omit from "lodash/omit";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { ModalProps } from "stores/uiStore/UiStore";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { BackBlackIcon, CloseIcon } from "assets/icons/24px";
import { InputIconButton } from "components";
import { backgroundGradient } from "constants/layout";
import { RESOURCES_KEYS } from "i18n";

export const DialogTitleStyled = styled(DialogTitle)`
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DialogFullScreenTitleStyled = styled(DialogTitleStyled)`
  max-height: 101px;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 32px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

const DialogFullScreenContentStyled = styled.div`
  background: ${backgroundGradient};
  padding: 16px 10px 24px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

interface ModalTitleProps extends Pick<BaseModalProps, "closeModal"> {
  title: string;
  subTitle?: string | ReactNode;
}
const ModalTitle: React.FC<ModalTitleProps> = ({ title, subTitle, closeModal }) => {
  return (
    <DialogTitleStyled>
      <Typography variant="h2">{title}</Typography>
      {subTitle ?? null}
      <InputIconButton
        size="small"
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
        }}
        onClick={closeModal}
      >
        <CloseIcon />
      </InputIconButton>
    </DialogTitleStyled>
  );
};

interface ModalFullScreenTitleProps extends Omit<BaseModalProps, "modalId"> {
  title: string;
  children?: React.ReactNode;
}
const ModalFullScreenTitle: React.FC<ModalFullScreenTitleProps> = ({ title, children, closeModal }) => {
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);

  return (
    <DialogFullScreenTitleStyled>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        startIcon={<BackBlackIcon />}
        fullWidth={false}
        onClick={closeModal}
      >{t`back`}</Button>
      <Typography variant="h2" flex="1">
        {title}
      </Typography>
      {children}
    </DialogFullScreenTitleStyled>
  );
};

interface ModalFullScreenContentProps {
  children: React.ReactNode;
}
const ModalFullScreenContent: React.FC<ModalFullScreenContentProps> = ({ children }) => {
  return <DialogFullScreenContentStyled>{children}</DialogFullScreenContentStyled>;
};

interface ModalContentProps {
  dividers?: boolean;
  children: React.ReactNode;
}
const ModalContent: React.FC<ModalContentProps> = ({ dividers, children }) => {
  return (
    <React.Fragment>
      <DialogContent dividers={dividers}>{children}</DialogContent>
      {!dividers ? <Divider /> : null}
    </React.Fragment>
  );
};

interface ModalActionsProps {
  children: React.ReactNode;
}
const ModalActions: React.FC<ModalActionsProps> = ({ children }) => {
  return <DialogActions sx={{ paddingRight: "24px" }}>{children}</DialogActions>;
};

interface ModalInterface extends React.FC {
  ModalTitle: React.FC<ModalTitleProps>;
  ModalFullScreenTitle: React.FC<ModalFullScreenTitleProps>;
  ModalContent: React.FC<ModalContentProps>;
  ModalFullScreenContent: React.FC<ModalFullScreenContentProps>;
  ModalActions: React.FC<ModalActionsProps>;
}

const Modal: ModalInterface = () => {
  const {
    uiStore: { modals, closeModal },
  } = useStores();

  return (
    <>
      {modals.map(({ id, modalProps }) => {
        return <ModalDialog key={id} modalProps={modalProps} id={id} closeModal={() => closeModal(id)} />;
      })}
    </>
  );
};

interface ModalDialogProps {
  modalProps: ModalProps;
  id: string;
  closeModal: () => void;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ modalProps, id, closeModal }) => {
  const location = useLocation();
  const prevLocation = useRef(location);

  const handleBackNavigation = () => {
    if (prevLocation.current !== location) {
      closeModal();
    }
    prevLocation.current = location;
  };

  useEffect(() => {
    !modalProps.ignoreRedirect && handleBackNavigation();
  }, [location, closeModal]);

  const dialogProps = omit(modalProps, ["open", "component"]);
  const ModalContent = modalProps.component;

  return (
    <Dialog {...dialogProps} open={true} onClose={closeModal}>
      <ModalContent {...modalProps} modalId={id} closeModal={closeModal} />
    </Dialog>
  );
};

Modal.ModalTitle = ModalTitle;
Modal.ModalFullScreenTitle = ModalFullScreenTitle;
Modal.ModalContent = ModalContent;
Modal.ModalFullScreenContent = ModalFullScreenContent;
Modal.ModalActions = ModalActions;

export default observer(Modal);
