import React, { useEffect, useState } from "react";

type SrcSetData = {
  srcSet: string;
  sizes: string;
};

interface ProgressiveImageProps {
  children: (image: string, loading: boolean, srcSetData: SrcSetData) => React.ReactNode;
  onError?: (errorEvent: Event) => void;
  placeholder: string;
  src: string;
  srcSetData?: SrcSetData;
}

const ProgressiveImage = ({ children, onError, placeholder, src, srcSetData }: ProgressiveImageProps) => {
  const [image, setImage] = useState(placeholder);
  const [loading, setLoading] = useState(true);
  const [currentSrcSetData, setCurrentSrcSetData] = useState<SrcSetData>({ srcSet: "", sizes: "" });
  const [highResLoading, setHighResLoading] = useState(true);

  useEffect(() => {
    const placeholderImg = new Image();
    placeholderImg.onload = () => {
      setImage(placeholderImg.src);
      setLoading(false);

      const highResImg = new Image();
      highResImg.onload = () => {
        setHighResLoading(false);
        setImage(highResImg.src);
        setCurrentSrcSetData({
          srcSet: highResImg.srcset || "",
          sizes: highResImg.sizes || "",
        });
      };
      highResImg.onerror = (event) => {
        const errorEvent = event as Event;
        if (onError) {
          onError(errorEvent);
        }
      };
      highResImg.src = src;
      if (srcSetData) {
        highResImg.srcset = srcSetData.srcSet;
        highResImg.sizes = srcSetData.sizes;
      }
    };
    placeholderImg.onerror = (event) => {
      const errorEvent = event as Event;
      if (onError) {
        onError(errorEvent);
      }
    };
    placeholderImg.src = placeholder;

    return () => {
      placeholderImg.onload = null;
      placeholderImg.onerror = null;
    };
  }, [src, srcSetData, onError, placeholder]);

  return <>{children(image, loading || highResLoading, currentSrcSetData)}</>;
};

export default ProgressiveImage;
