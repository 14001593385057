import { EnqueueSnackbar } from "notistack";

export const handleDuplicationError = (axiosError: AxiosApiError, enqueueSnackbar: EnqueueSnackbar, isPublisher?: boolean) => {
  if (axiosError.response?.data.errorCode === "EXISTING_REVISED_FORM") {
    enqueueSnackbar(
      `This ${
        isPublisher ? "publisher" : "songwriter"
      } is already added to the composition. Ensure the name and country are unique.`,
      {
        iconType: "warning",
      },
    );
  }
};
