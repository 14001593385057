import styled from "@emotion/styled";

export const BlockContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
`;

export const ButtonsContainer = styled(BlockContainer)`
  min-height: 56px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;
