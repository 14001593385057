import { useCallback, useState } from "react";

type FetchFunction<DataType> = (params: { offset: number; limit: number }) => Promise<DataType[]>;
type MapFunction<DataType, ValueType> = (data: DataType) => LabelValue<ValueType>;

type LabelValue<T> = {
  label: string;
  value: T;
};

const useInfiniteLoading = <DataType, ValueType>(
  fetchFunction: FetchFunction<DataType>,
  mapFunction: MapFunction<DataType, ValueType>,
  { initialOffset = 0, limit = 10 }: { initialOffset?: number; limit?: number } = {},
) => {
  const [options, setOptions] = useState<LabelValue<ValueType>[]>([]);
  const [rawOptions, setRawOptions] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(initialOffset);
  const [hasNextPage, setHasNextPage] = useState(true);

  const fetchOptions = useCallback(async () => {
    if (!hasNextPage || loading) return;

    setLoading(true);
    try {
      const data = await fetchFunction({ offset, limit });
      const newOptions = data.map(mapFunction);

      setRawOptions((prevRawOptions) => [...prevRawOptions, ...data]);
      setOptions((prevOptions) => [...prevOptions, ...newOptions]);

      setOffset((prevOffset) => prevOffset + limit);
      setHasNextPage(newOptions.length === limit);
    } finally {
      setLoading(false);
    }
  }, [fetchFunction, mapFunction, offset, limit, hasNextPage, loading]);

  return {
    options,
    rawOptions,
    loading,
    fetchMore: fetchOptions,
    hasNextPage,
  };
};

export default useInfiniteLoading;
