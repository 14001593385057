import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiDataGrid: Components["MuiDataGrid"] = {
  styleOverrides: {
    root: {
      width: "100%",
      border: "none",
      ".MuiDataGrid-cell, .MuiDataGrid-columnHeaders": {
        borderColor: colors.gray749,
      },
      ".MuiDataGrid-main": {
        backgroundColor: colors.white,
        borderRadius: 8,
      },
      ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
      },

      ".MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
      },
      ".MuiDataGrid-sortIcon": {
        opacity: "inherit !important",
      },
      ".Mui-hovered": {
        backgroundColor: `${colors.blue30} !important`,
      },
    },
    columnHeaderTitle: {
      color: colors.gray564,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "18px",
    },
    footerContainer: {
      "& > div:first-of-type": {
        display: "none",
      },
    },
  },
};

export default { MuiDataGrid };
