import { FC } from "react";

import ChipMui, { ChipProps } from "@mui/material/Chip";

import { CloseIcon } from "assets/icons/14px";

const Chip: FC<ChipProps> = (props) => {
  return (
    <ChipMui
      variant="filled"
      size="small"
      {...props}
      deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
    />
  );
};

export default Chip;
