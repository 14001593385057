import { useMemo } from "react";
import { useStores } from "stores";

import { AppUserRole } from "types";

export const useUserRole = () => {
  const {
    userStore: { user },
  } = useStores();

  return useMemo(() => {
    return !user || !user.isActive ? [] : user.isAdmin ? [AppUserRole.ADMIN] : [AppUserRole.COMMON];
  }, [user]);
};
