import React, { useCallback } from "react";
import { FieldValues } from "react-hook-form";
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { GridRenderCellParams } from "@mui/x-data-grid";

import { Table } from "components";

import DraggableGridRow from "./DraggableGridRow";
import DragHandle from "./DragHandle";
import { DndTableProps } from "./types";

const DndTable = <T extends FieldValues>({ columns, rows, onOrderChange, isDragEnabled = true, ...props }: DndTableProps<T>) => {
  const modifiers = [restrictToVerticalAxis, restrictToParentElement];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const items = [...rows];

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over) {
        const oldIndex = rows.findIndex((rows) => rows.id === active.id);
        const newIndex = rows.findIndex((rows) => rows.id === over.id);
        const reorderedItems = arrayMove(items, oldIndex, newIndex);

        let afterId = null;
        if (newIndex > 0) {
          afterId = reorderedItems[newIndex - 1]?.id || null;
        }

        onOrderChange?.(reorderedItems, { oldIndex, newIndex, afterId, currentItemId: active.id as string });
      }
    },
    [rows, onOrderChange],
  );

  return (
    <DndContext modifiers={modifiers} sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={items}>
        <Table
          columns={[
            ...(isDragEnabled
              ? [
                  {
                    field: "drag",
                    headerName: "",
                    width: 50,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params: GridRenderCellParams<T>) => {
                      return <DragHandle {...params} />;
                    },
                  },
                ]
              : []),
            ...columns,
          ]}
          rows={rows}
          hideFooter
          {...props}
          slots={{
            row: DraggableGridRow,
            ...props.slots,
          }}
          paginationMode="client"
          autoHeight
        />
      </SortableContext>
    </DndContext>
  );
};

export default DndTable;
