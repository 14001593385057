import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { StepFlowController } from "components/FormComponents/StepFlowController";
import { ToggleButtonField } from "components/HookFormControls";
import { ROUTES } from "constants/routes";
import { RESOURCES_KEYS } from "i18n";
import { DistributionType, PublishingType } from "types";

import { HELPER_TEXT, OPTIONS } from "./constants";

const SelectContainerStyle = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .title {
    margin-top: 32px;

    .MuiTypography-h2 {
      margin-bottom: 10px;
    }
  }

  .roleSelect {
    width: 100%;
    max-width: 520px;
    margin-top: 53px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down("md")} {
      flex-direction: column;
      gap: 5px;
    }

    .MuiTypography-body1 {
      margin-right: 8px;
    }
  }
`;

type Props = {
  inviteUser?: boolean;
};

const RoleSelect: FC<Props> = ({ inviteUser }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { control, watch } = useFormContext();
  const {
    userStore: { setDistributionAndPublishing },
  } = useStores();
  const { t } = useTranslation([inviteUser ? RESOURCES_KEYS.APPLICATIONS : RESOURCES_KEYS.QUALIFICATION]);

  const options = useMemo(() => OPTIONS[pathname.replace(ROUTES.INVITE, "")] || [], [pathname]);

  const handleRoleChange = (value: string) => {
    if (value && !inviteUser) {
      switch (pathname) {
        case ROUTES.DISTRIBUTION:
          return setDistributionAndPublishing({
            distributionType: Number(value) as DistributionType,
          });
        case ROUTES.PUBLISHING:
          return setDistributionAndPublishing({
            publishingType: Number(value) as PublishingType,
          });
        case ROUTES.DISTRIBUTION_AND_PUBLISHING: {
          const [distributionType, publishingType] = value.split(":");
          return setDistributionAndPublishing({
            distributionType: Number(distributionType) as DistributionType,
            publishingType: Number(publishingType) as PublishingType,
          });
        }
      }
    }
  };

  const handleNextBtnClick = () => {
    navigate(`${pathname}${ROUTES.FORM}`);
  };

  const userRoleWatch = watch("userRole");

  const helperTextKey = HELPER_TEXT[pathname] ? HELPER_TEXT[pathname](userRoleWatch) : "";

  return (
    <StepFlowController prevBtnDisabled onNextBtnClick={handleNextBtnClick}>
      <SelectContainerStyle>
        <div className="title">
          {!inviteUser && (
            <Typography variant="h2" align="center">
              {t(`general.getToKnowYouBetter`)}
            </Typography>
          )}
          <Typography variant="body1" whiteSpace="pre-line" align="center">
            {t(inviteUser ? "invite.roleSelect" : "distribution.selectRolePrompt")}
          </Typography>
        </div>

        <div className="roleSelect">
          {!inviteUser ? (
            <Typography variant="body1" whiteSpace="nowrap">
              {t`iam`}
            </Typography>
          ) : null}
          <ToggleButtonField
            fieldName="userRole"
            control={control}
            options={options}
            fullWidth={false}
            onChange={(event, value) => handleRoleChange(value)}
            dark
          />
        </div>
        {!inviteUser ? (
          <Typography color="text.label" variant="body1" whiteSpace="pre-line" textAlign="center" sx={{ mt: "12px" }}>
            {t(helperTextKey)}
          </Typography>
        ) : null}
      </SelectContainerStyle>
    </StepFlowController>
  );
};

export default observer(RoleSelect);
