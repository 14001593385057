import { Comment, CommentStatus } from "types";

import { SubRouteFieldProps } from "../types";

export const haveActiveComment = (comments: Comment[]) => comments.some((comment) => comment.status === CommentStatus.ACTIVE);

export const fieldsHaveActiveComment = (fields: SubRouteFieldProps[], comments: Comment[]): boolean => {
  return fields.some(({ field }) => {
    return comments.some((comment) => comment.fieldName === field && comment.status === CommentStatus.ACTIVE);
  });
};

export const haveResolvedComment = (comments: Comment[]) => comments.some((comment) => comment.status === CommentStatus.RESOLVED);

export const fieldsHaveResolvedComment = (fields: SubRouteFieldProps[], comments: Comment[]): boolean => {
  return fields.some(({ field }) => {
    return comments.some((comment) => comment.fieldName === field && comment.status === CommentStatus.RESOLVED);
  });
};
