import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";

import { ApiKeyFields, Songwriters, SongwritersForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils/transformObjects";

import { defaultValues } from "./constants";

export const prepareFormData = (formData: Songwriters) => {
  return replaceEmptyAndUndefinedWithNull({
    ...pick(formData, Object.keys(defaultValues)),
    [ApiKeyFields.advance]: !formData[ApiKeyFields.email]?.length,
  });
};

export const prepareRequest = (
  formData: SongwritersForm,
  dirtyFields?: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  let data = formData;

  if (dirtyFields) {
    data = pickBy(formData, (_, key) => dirtyFields[key]);

    const shouldIncludeNameOrCountry = dirtyFields[ApiKeyFields.name] || dirtyFields[ApiKeyFields.country];
    if (shouldIncludeNameOrCountry) {
      data[ApiKeyFields.name] = formData[ApiKeyFields.name] ?? void 0;
      data[ApiKeyFields.country] = formData[ApiKeyFields.country] ?? void 0;
    }
  }

  return replaceEmptyAndUndefinedWithNull(data);
};
