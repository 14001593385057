import React, { FC } from "react";

import IconButton from "@mui/material/IconButton";

import { EditIcon } from "assets/icons/24px";

import EditPopover from "./EditPopover";
import { EditModeProps } from "./types";

const EditMode: FC<EditModeProps> = ({ fieldName, label, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClick}>
        <EditIcon />
      </IconButton>
      {open ? (
        <EditPopover fieldName={fieldName} label={label} open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          {children}
        </EditPopover>
      ) : null}
    </React.Fragment>
  );
};

export default EditMode;
