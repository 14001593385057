import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    root: {
      borderRadius: "8px",
      fontWeight: 700,
    },
    clickable: {
      ":hover": {
        backgroundColor: colors.gray215,
      },
    },
    colorPrimary: {
      backgroundColor: colors.blue30,
      color: colors.blue364,
    },
    colorSuccess: {
      backgroundColor: colors.green175,
      color: colors.green607,
    },
    colorWarning: {
      backgroundColor: colors.yellow902,
      color: colors.yellow800,
    },
    colorError: {
      backgroundColor: colors.pink101,
      color: colors.red701,
    },
  },
};
