import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { useMobileViewBlock, useMsalEventCallback, useUserInactivity } from "hooks";
import { observer } from "mobx-react";
import { useStores } from "stores";

import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { AppBar, BackdropStyled, Drawer, Modal, TaCModal } from "components";
import UserProvider from "components/UserProvider";

import MobileLock from "./MobileLock";

const Root: FC = () => {
  useUserInactivity();
  useMsalEventCallback();
  const { isViewNotAllowed } = useMobileViewBlock();
  const {
    userStore: { isAdmin },
    uiStore: { isOverlayLoading, loadingText },
  } = useStores();
  const theme = useTheme();

  return (
    <UserProvider>
      <React.Fragment>
        {isViewNotAllowed && !isAdmin ? (
          <MobileLock />
        ) : (
          <React.Fragment>
            <div style={{ minWidth: isAdmin ? `${theme.breakpoints.values.md}px` : "auto" }}>
              <AppBar />
              <Outlet />
            </div>
          </React.Fragment>
        )}
        <TaCModal />
        <Modal />
        <Drawer />
        <BackdropStyled open={isOverlayLoading}>
          <CircularProgress thickness={4} />
          {loadingText ? (
            <Typography color="text.header" variant="h3">
              {loadingText}
            </Typography>
          ) : null}
        </BackdropStyled>
      </React.Fragment>
    </UserProvider>
  );
};

export default observer(Root);
