import { memo } from "react";
import { useSortable } from "@dnd-kit/sortable";

import { GridCellParams } from "@mui/x-data-grid";

import { DragAndDropIcon } from "assets/icons/14px";

const DragHandle = memo((params: GridCellParams) => {
  const { listeners } = useSortable({ id: params.id });

  return (
    <div {...listeners}>
      <DragAndDropIcon />
    </div>
  );
});

DragHandle.displayName = "DragHandle";

export default DragHandle;
