import { FC } from "react";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Modal } from "components";

export type DeleteFileCallback = () => Promise<void>;

interface DeleteFileModalProps extends BaseModalProps {
  onDeleteClick: DeleteFileCallback;
}

const DeleteFileModal: FC<DeleteFileModalProps> = ({ onDeleteClick, closeModal }) => {
  const handleDeleteClick = async () => {
    closeModal();
    await onDeleteClick();
  };

  return (
    <>
      <Modal.ModalTitle title="Remove the file?" closeModal={closeModal} />
      <Modal.ModalContent>
        <Typography variant="body1">The file will be removed immediately and cannot be restored.</Typography>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={handleDeleteClick}>
          Remove
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(DeleteFileModal);
