import { FC } from "react";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

type Props = TooltipProps & {
  isTooltipVisible?: boolean;
};

const ConditionalTooltip: FC<Props> = ({ isTooltipVisible, title, children, placement = "top" }) => {
  if (isTooltipVisible) {
    return (
      <Tooltip title={title} placement={placement} arrow>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

export default ConditionalTooltip;
