import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiIconButton: Components["MuiIconButton"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
      ":hover": {
        backgroundColor: colors.gray257,
      },
      ":disabled": {
        cursor: "not-allowed",
        pointerEvents: "inherit",
        ":hover": {
          backgroundColor: "transparent",
        },
        "svg path": {
          fill: colors.gray749,
        },
      },
    },
    sizeMedium: {
      minHeight: "48px",
      padding: "12px",
    },
    colorSecondary: {
      border: `1px solid ${colors.orange633}`,
      ":hover": {
        backgroundColor: colors.orange129,
      },
    },
    colorInfo: {
      ":hover": {
        backgroundColor: colors.white,
      },
    },
  },
};
