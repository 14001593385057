import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useUserPermissions, useUserRole } from "hooks";
import { observer } from "mobx-react";

import { ROUTES } from "constants/routes";
import { AppUserRole } from "types";

export const UnauthenticatedRoute = (
  <>
    <AuthenticatedTemplate>
      <Navigate to={ROUTES.ROOT} />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Outlet />
    </UnauthenticatedTemplate>
  </>
);

export const AuthenticatedRoute = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
};

export const UserRoleRoute: FC<{ allowedRole: AppUserRole[] }> = observer(({ allowedRole }) => {
  const location = useLocation();
  const userRoles = useUserRole();
  return userRoles.find((role) => allowedRole.includes(role as AppUserRole)) ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.ROOT} state={{ from: location }} replace />
  );
});

export const UserPermissionRoute: FC<{ allowedPermission: string[] }> = observer(({ allowedPermission }) => {
  const userPermissions = useUserPermissions();
  return userPermissions.some((role) => allowedPermission.includes(role)) ? <Outlet /> : <Navigate to={ROUTES.ROOT} />;
});
