import React from "react";
import styled from "@emotion/styled";

import RadioMui, { RadioProps } from "@mui/material/Radio";

import { RadioCheckedIcon, RadioIcon } from "assets/icons";

const StyledRadio = React.forwardRef<HTMLButtonElement, RadioProps>((props, ref) => {
  return (
    <RadioMui
      icon={<RadioIcon />}
      checkedIcon={<RadioCheckedIcon />}
      disableTouchRipple
      size="small"
      color="primary"
      ref={ref}
      {...props}
    />
  );
});

StyledRadio.displayName = "StyledRadio";

export const RadioStyled = styled(StyledRadio)`` as typeof RadioMui;
