import { FC } from "react";
import styled from "@emotion/styled/macro";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { PageWrapper } from "components/Layout";

import { Buttons, ToggleButtons } from "./Buttons";
import Checkboxes from "./Checkboxes";
import { DatePickers, Selects, TextFields } from "./Inputs";
import Tooltips from "./Tooltips";
import Typographies from "./Typographies";

const SamplesContainer = styled(PageWrapper)`
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 40px;
  box-sizing: border-box;

  .MuiTypography-h1,
  .MuiTypography-h2 {
    margin-top: 25px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 15px;
`;

const Samples: FC = () => {
  return (
    <SamplesContainer>
      <Container>
        <Typography variant="h1">Samples</Typography>
        <StyledDivider />
        <DatePickers />
        <Typographies />
        <Buttons />
        <ToggleButtons />
        <Checkboxes />
        <Tooltips />
        <TextFields />
        <DatePickers />
        <Selects />
      </Container>
    </SamplesContainer>
  );
};

export default Samples;
