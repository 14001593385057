import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export function useFormNavigation(
  backRoute: string | null,
  nextRoute: string | null,
  additionalConditions = { next: false, prev: false },
) {
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useFormContext();

  const navigateBack = () => backRoute && navigate(backRoute);
  const navigateNext = () => nextRoute && navigate(nextRoute);
  const haveErrors = Object.keys(errors).length > 0;

  const nextBtnDisabled = haveErrors || additionalConditions.next;
  const prevBtnDisabled = haveErrors || additionalConditions.prev;

  return { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled };
}
