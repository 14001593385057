import { useSnackbar } from "notistack";

type UpdateFunctionWithParams<T, R> = (data: T) => Promise<R>;
type UpdateFunctionWithoutParams<R> = () => Promise<R>;

interface UpdateParamsWith<T, R> {
  serviceFunc: UpdateFunctionWithParams<T, R>;
  data: T;
  successMessage?: string;
  errorMessage?: string;
}

interface UpdateParamsWithout<R> {
  serviceFunc: UpdateFunctionWithoutParams<R>;
  successMessage?: string;
  errorMessage?: string;
}

export const useGenericRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  function genericRequest<T, R>(params: UpdateParamsWith<T, R>): Promise<R>;
  function genericRequest<R>(params: UpdateParamsWithout<R>): Promise<R>;
  async function genericRequest<T, R>(params: UpdateParamsWith<T, R> | UpdateParamsWithout<R>): Promise<R> {
    let result: R;

    if ("data" in params) {
      result = await (params as UpdateParamsWith<T, R>).serviceFunc((params as UpdateParamsWith<T, R>).data);
    } else {
      result = await (params as UpdateParamsWithout<R>).serviceFunc();
    }

    if (params.successMessage) {
      enqueueSnackbar(params.successMessage);
    }

    return result;
  }

  return genericRequest;
};
