import { action, makeObservable, observable, runInAction } from "mobx";

import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

export interface TableDataResponse<Data> {
  totalCount: number;
  rows: Data[];
}

export class TableStore<Response, Data, RequestParams> {
  @observable isLoading: boolean = false;
  @observable rows: Array<Data> = [];
  @observable totalCount: number = 0;
  @observable paginationModel: GridPaginationModel = { page: 0, pageSize: 20 };
  @observable sortModel: GridSortModel = [];
  @observable filters: { [key: string]: unknown } = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setSortModel(sortModel: GridSortModel) {
    this.sortModel = sortModel || [];
  }

  @action.bound
  setPaginationModel = (paginationModel: GridPaginationModel) => {
    if (!paginationModel) return;
    this.paginationModel = paginationModel;
  };

  @action.bound
  setFilters = (filters: { [key: string]: unknown }) => {
    this.filters = filters;
  };

  protected async retrieveTableData({
    service,
    extractData,
    prepareGenericParams,
    defaultSortModel,
  }: {
    service: (params: RequestParams) => Promise<Response>;
    extractData: (response: Response) => TableDataResponse<Data>;
    prepareGenericParams: (store: TableStore<Response, Data, RequestParams>) => RequestParams;
    defaultSortModel?: GridSortModel;
  }) {
    runInAction(() => {
      this.isLoading = true;
    });

    if (defaultSortModel && defaultSortModel.length) {
      this.setSortModel(defaultSortModel);
    }

    try {
      const params = prepareGenericParams(this);
      const data = await service(params);
      const extractedData = extractData(data);

      runInAction(() => {
        this.rows = extractedData.rows || [];
        this.totalCount = extractedData.totalCount || 0;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  resetTableState = () => {
    this.setFilters({});
    this.setPaginationModel({ page: 0, pageSize: 20 });
    this.setSortModel([]);
    this.rows = [];
    this.totalCount = 0;
  };
}
