import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import without from "lodash/without";

import Box from "@mui/material/Box";

import UserStatusChip from "components/UserStatusChip";
import { ApiKeyFields, UserStatus } from "types";

import { ApplicationFilters } from "./types";

const BoxStatusStyled = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const RenderStatuses = (selected: number | string) => {
  const { watch, setValue } = useFormContext<ApplicationFilters>();

  const onDelete = (e: React.MouseEvent, value: UserStatus) => {
    e.preventDefault();
    setValue(ApiKeyFields.user_statuses, without(watch(ApiKeyFields.user_statuses), value));
  };

  return Array.isArray(selected) && selected.length ? (
    <BoxStatusStyled>
      {selected.sort().map((value) => (
        <UserStatusChip key={value} status={value} handleDelete={(e) => onDelete(e, value)} />
      ))}
    </BoxStatusStyled>
  ) : (
    "No Status(es)"
  );
};
