import { FC } from "react";
import { useFormContext } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { CountrySelectField, TextField } from "components/HookFormControls";
import { ApiKeyFields } from "types";

import { ADDRESS_FIELDS, FORM_LABELS, HELPER_TEXT, OPTIONAL_FIELDS } from "./constants";

const AddressInformation: FC<StepFlowControllerProps> = (props) => {
  const { control } = useFormContext();

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          {ADDRESS_FIELDS.map((fieldName) => (
            <FormGridFieldItem
              fieldName={fieldName}
              label={FORM_LABELS[fieldName]}
              helperText={HELPER_TEXT[fieldName]}
              isOptionalField={OPTIONAL_FIELDS[fieldName]}
              key={fieldName}
            >
              {(field) => <TextField {...field} control={control} />}
            </FormGridFieldItem>
          ))}

          <FormGridFieldItem
            fieldName={ApiKeyFields.country}
            label={FORM_LABELS[ApiKeyFields.country]}
            helperText={HELPER_TEXT[ApiKeyFields.country]}
          >
            {(field) => <CountrySelectField {...field} control={control} />}
          </FormGridFieldItem>
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default AddressInformation;
