import { AxiosRequestConfig } from "axios";

import { axiosInstance } from "services";

import { ContractBlobFileResponse } from "./types";

export function getCurrentDistrContract(config?: AxiosRequestConfig): Promise<Blob> {
  return axiosInstance.get("/users/current/contracts/distribution-contract", config);
}

export function getCurrentDistrContractBlob(): Promise<ContractBlobFileResponse> {
  return axiosInstance.get("/users/current/contracts/distribution-contract-sas-url");
}

export function postSignCurrentDistrContract() {
  return axiosInstance.post("/users/current/contracts/distribution-contract/sign");
}

export function postReviewCurrentContract() {
  return axiosInstance.post("/users/current/contracts/distribution-contract/review");
}
