import { forwardRef, ReactNode } from "react";
import styled from "@emotion/styled/macro";

import MenuItem from "@mui/material/MenuItem";
import SelectInput, { SelectChangeEvent, SelectProps } from "@mui/material/Select";

import { AngleDownIcon } from "assets/icons/14px";

export interface SelectOptionsType extends SelectProps<string | number> {
  value: string | number;
  options?: LabelValueColor<string | number>[];
  children?: ReactNode;
}

const SelectInputComponent = forwardRef<HTMLDivElement, SelectProps<string | number>>((props, ref) => (
  <SelectInput color="primary" size="small" {...props} ref={ref} />
));
SelectInputComponent.displayName = "SelectInputComponent";

const SelectStyled = styled(SelectInputComponent)``;

const Select = forwardRef<HTMLDivElement, SelectOptionsType>(({ value, options, onChange, children, sx, ...props }, ref) => {
  const handleChange = (event: SelectChangeEvent<string | number>, child: ReactNode) => {
    onChange?.(event, child);
  };
  return (
    <SelectStyled {...props} value={value} onChange={handleChange} sx={{ ...sx }} IconComponent={AngleDownIcon} ref={ref}>
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      {children}
    </SelectStyled>
  );
});
Select.displayName = "Select";

export default Select;
export { SelectStyled };
