import { makeAutoObservable } from "mobx";
import { RootStore } from "stores";

import { AccountDapTable } from "./AccountsTable/AccountDapTable";
import { AccountDistributionTable } from "./AccountsTable/AccountDistributionTable";
import { AccountPublishingTable } from "./AccountsTable/AccountPublishingTable";
import { ApplicationDistributionTable } from "./ApplicationsTable/ApplicationDistributionTable";
import { AdminReleaseTable } from "./ReleasesTable/AdminReleaseTable/AdminReleaseTable";
import { ClientReleasesTable } from "./ReleasesTable/ClientReleasesTable/ClientReleasesTable";
import { AdminsTable } from "./AdminsTable";

export class TablesStore {
  adminsTable: AdminsTable;
  accountDistributionTable: AccountDistributionTable;
  accountPublishingTable: AccountPublishingTable;
  accountDapTable: AccountDapTable;
  applicationDistributionTable: ApplicationDistributionTable;
  clientReleaseTable: ClientReleasesTable;
  adminReleaseTable: AdminReleaseTable;

  constructor(private rootStore: RootStore) {
    this.adminsTable = new AdminsTable(rootStore);
    this.accountDistributionTable = new AccountDistributionTable(rootStore);
    this.accountPublishingTable = new AccountPublishingTable(rootStore);
    this.accountDapTable = new AccountDapTable(rootStore);
    this.applicationDistributionTable = new ApplicationDistributionTable(rootStore);
    this.clientReleaseTable = new ClientReleasesTable(rootStore);
    this.adminReleaseTable = new AdminReleaseTable(rootStore);

    makeAutoObservable(this, {}, { autoBind: true });
  }
}
