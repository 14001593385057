import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiFab: Components["MuiFab"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:disabled": {
        color: colors.gray215,
        backgroundColor: "transparent",
        borderColor: colors.gray215,
      },
    },
    primary: {
      color: colors.blue364,
      border: `1px solid ${colors.blue364}`,
      svg: {
        g: {
          stroke: colors.blue364,
        },
        "g > path": {
          fill: colors.blue364,
        },
      },
      "&:disabled": {
        g: {
          stroke: colors.gray749,
        },
        "g > path": {
          fill: colors.gray749,
        },
      },
    },
  },
};
