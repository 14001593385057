import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const ARTIST_CATALOG_FIELDS = [ApiKeyFields.album_names, ApiKeyFields.eps, ApiKeyFields.singles];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.album_names]: "Album Name(s)",
  [ApiKeyFields.eps]: "EP(s)",
  [ApiKeyFields.singles]: "Single(s)",
};

export const FORM_PLACEHOLDERS: { [key: string]: string } = {
  [ApiKeyFields.album_names]: 'Enter Album Name(s) or type "None"',
  [ApiKeyFields.eps]: 'Enter EP name(s) or type "None"',
  [ApiKeyFields.singles]: 'Enter Single(s) or type "None"',
};

export const DISTRIBUTION_CATALOG_SIDEBAR_FIELDS = [ApiKeyFields.album_names, ApiKeyFields.eps, ApiKeyFields.singles].map(
  (field) => ({ field, required: true }),
) as SubRouteFieldProps[];

export const MAX_LENGTH_TEXT = 300;
