import { FC } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ROUTES } from "constants/routes";

import { VerticalDividerStyled } from "./VerticalDividerStyled";

export const NavLinkStyled = styled(NavLink)`
  width: 106px;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding: 11px 0;

  &:hover {
    font-weight: 600;
  }

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.palette.primaryDark.main};
  }
`;

const NavGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  text-align: center;
`;

const ADMIN_ROUTES = [
  { path: ROUTES.APPLICATIONS, name: "Applications" },
  { path: ROUTES.ACCOUNTS, name: "Accounts" },
  { path: ROUTES.RELEASES, name: "Releases" },
  { path: ROUTES.ADMINS, name: "Admins" },
];

const AdminNav: FC = () => {
  const {
    userStore: { user },
  } = useStores();

  if (user?.isAdmin) {
    return (
      <>
        <NavGrid>
          {ADMIN_ROUTES.map(({ path, name }) => (
            <NavLinkStyled key={path} to={path}>
              {name}
            </NavLinkStyled>
          ))}
        </NavGrid>
        <VerticalDividerStyled orientation="vertical" />
      </>
    );
  }

  return null;
};

export default observer(AdminNav);
